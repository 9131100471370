import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { logout } from 'slices/auth';

// ------------------------------------
// Constants
// ------------------------------------

const INTERACTION_MODE = {
  default: 'default',
  sourceSelection: 'sourceSelection',
  wordGraphyAttestationSelection: 'wordGraphyAttestationSelection',
  titleGraphyAttestationSelection: 'titleGraphyAttestationSelection',
  sequenceAttestationSelection: 'sequenceAttestationSelection',
};

// ------------------------------------
// Selectors
// ------------------------------------

const isThesaurusOpen = (state) => state.thesaurus.open || isInSelectionMode(state);

const getSelectedSourceId = (state) => state.thesaurus.selectedSourceId;

const isInSelectionMode = (state) => state.thesaurus.interactionMode !== INTERACTION_MODE.default;

const isSelectionModeTriggeredOnEntry = (state, id) => {
  return (
    isInSelectionMode(state) && get(state, 'thesaurus.interactionModeTriggeredBy.id', null) === id
  );
};

const isSelectionModeTriggeredForTitleGraphyAttestation = (state) => {
  return (
    isInSelectionMode(state) &&
    get(state, 'thesaurus.interactionMode', null) ===
      INTERACTION_MODE.titleGraphyAttestationSelection
  );
};

const isSelectionModeTriggeredForWordGraphyAttestation = (state) => {
  return (
    isInSelectionMode(state) &&
    get(state, 'thesaurus.interactionMode', null) ===
      INTERACTION_MODE.wordGraphyAttestationSelection
  );
};

const isSelectionModeTriggeredForSource = (state) => {
  return (
    isInSelectionMode(state) &&
    get(state, 'thesaurus.interactionMode', null) === INTERACTION_MODE.sourceSelection
  );
};

const isSelectionModeTriggeredForSequenceAttestation = (state) => {
  return (
    isInSelectionMode(state) &&
    get(state, 'thesaurus.interactionMode', null) === INTERACTION_MODE.sequenceAttestationSelection
  );
};

const getInteractionModeTriggeredBy = (state) =>
  get(state, 'thesaurus.interactionModeTriggeredBy', null);

const getInteractionModeTriggeredGraphyId = (state) =>
  get(state, 'thesaurus.interactionModeTriggeredBy.graphyId', null);

const getInteractionModeTriggeredSequenceId = (state) =>
  get(state, 'thesaurus.interactionModeTriggeredBy.sequenceId', null);

// ------------------------------------
// Slice
// ------------------------------------
const initialState = {
  open: false,
  selectedSourceId: null,
  interactionMode: INTERACTION_MODE.default,
  interactionModeTriggeredBy: null,
};

const thesaurusSlice = createSlice({
  name: 'thesaurus',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open;
    },
    open: (state) => {
      state.open = true;
    },
    close: () => {
      return initialState;
    },
    openSource: (state, action) => {
      state.open = true;
      state.selectedSourceId = action.payload;
    },
    closeSource: (state) => {
      state.selectedSourceId = null;
    },
    setInteractionMode: (state, action) => {
      state.interactionMode = action.payload.interactionMode;
      state.interactionModeTriggeredBy = action.payload.interactionModeTriggeredBy;
    },
    resetInteractionMode: (state) => {
      state.interactionMode = INTERACTION_MODE.default;
      state.interactionModeTriggeredBy = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

const { actions, reducer } = thesaurusSlice;
const { open, close, toggle, openSource, closeSource, setInteractionMode, resetInteractionMode } =
  actions;

export {
  reducer,
  open,
  close,
  toggle,
  openSource,
  closeSource,
  isThesaurusOpen,
  getSelectedSourceId,
  isInSelectionMode,
  isSelectionModeTriggeredOnEntry,
  getInteractionModeTriggeredBy,
  getInteractionModeTriggeredGraphyId,
  getInteractionModeTriggeredSequenceId,
  isSelectionModeTriggeredForWordGraphyAttestation,
  isSelectionModeTriggeredForTitleGraphyAttestation,
  isSelectionModeTriggeredForSource,
  isSelectionModeTriggeredForSequenceAttestation,
  setInteractionMode,
  resetInteractionMode,
  INTERACTION_MODE,
};
