import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { ReactComponent as CrossIcon } from 'images/icon-cross.svg';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import './Modal.scss';

const Modal = ({ title, children, actions, className, onClose }) => {
  const { i18n } = useTranslation();

  return (
    <ReactModal
      isOpen
      onRequestClose={onClose}
      contentLabel={title}
      overlayClassName="modal-overlay"
      className={classNames('modal-content', className)}
      bodyOpenClassName="is-opened"
      htmlOpenClassName="modal-is-opened"
      testId="modal"
      aria={{
        labelledby: 'modal-heading',
        describedby: 'modal-full-description',
      }}
    >
      <div className="modal-body" dir={i18n.dir()}>
        <div className="modal-header">
          {onClose && (
            <div className="modal-close-button">
              <IconButton data-testid="modal-close" onClick={onClose}>
                <Icon size={16}>
                  <CrossIcon />
                </Icon>
              </IconButton>
            </div>
          )}
          {title && (
            <h1 className="modal-title" id="modal-heading">
              {title}
            </h1>
          )}
        </div>
        <div className="modal-description" id="modal-full-description">
          {children}
        </div>
        {actions && <div className="modal-actions">{actions}</div>}
      </div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  title: '',
  actions: null,
  onClose: null,
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  onClose: PropTypes.func,
};

export default Modal;
