import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Cluster, Stack } from 'components/Layout';
import { canCreateEntry } from 'slices/auth';
import EntryEditionStatus from 'components/Entry/EntryEditionStatus';
import V4Tag from 'components/V4Tag';
import './EntrySummary.scss';

const EntrySummary = forwardRef(
  (
    {
      entry,
      isOpen,
      isHighlighted,
      publicStatusComponent: PublicStatusComponent,
      onSelect,
      onEnter,
      onLeave,
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const userIsAnEditor = useSelector(canCreateEntry);

    return (
      <div
        className={classNames('entry-summary', {
          'is-unpublished': !entry.published,
          'is-highlighted': isHighlighted,
          'is-shown': isOpen,
        })}
        data-testid="search-popup-item"
        dir={i18n.dir()}
        onMouseDown={onSelect}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        ref={ref}
      >
        <Stack space="var(--s-2)">
          <Cluster space="0">
            <div className="entry-summary-left">
              <div
                className="entry-summary-transliteration"
                dangerouslySetInnerHTML={{ __html: entry.transliteration }}
                data-testid="entry-summary-translit"
              />
              <div
                className="entry-summary-nuance"
                dangerouslySetInnerHTML={{ __html: entry.mainNuance }}
                data-testid="entry-summary-nuance"
              />
            </div>
            {entry.mainGraphyUrl && (
              <div
                className="entry-summary-graphy"
                style={{ backgroundImage: `url(${entry.mainGraphyUrl})` }}
                data-testid="entry-summary-graphy"
              />
            )}
          </Cluster>
          <Cluster space="var(--s-3)" align="center" justify="space-between">
            <span className="t-xsmall entry-info" data-testid="entry-summary-id">
              ID {entry.id}
            </span>
            <EntryEditionStatus status={entry.editionStatus} hasTooltip />
            {PublicStatusComponent && (
              <PublicStatusComponent status={entry.publicStatus} hasTooltip />
            )}
          </Cluster>
          {userIsAnEditor && entry.isCheckedForV4 && (
            <div className="entry-summary-v4-tag-container">
              <V4Tag />
            </div>
          )}
        </Stack>
      </div>
    );
  }
);

EntrySummary.defaultProps = {
  isHighlighted: false,
  isOpen: false,
  publicStatusComponent: null,
};

EntrySummary.propTypes = {
  entry: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
  isOpen: PropTypes.bool,
  publicStatusComponent: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};

export default EntrySummary;
