import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSearchLang } from 'slices/search';
import classNames from 'classnames';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import { ReactComponent as CommentIcon } from 'images/icon-tab-comments.svg';
import FlagIcon from 'components/FlagIcon';
import './SearchPopupFilterLang.scss';

const LANGUAGE_FILTERS = ['fr', 'en', 'de', 'ar'];
const TRANS_FILTER = 'trans';
const COMMENT_FILTER = 'comment';
const FILTERS = [TRANS_FILTER, ...LANGUAGE_FILTERS, COMMENT_FILTER];

const SearchPopupFilterLang = ({ onUpdate, excludeFilters }) => {
  const { t, i18n } = useTranslation();
  const selectedFilter = useSelector(getSearchLang);

  const handleChangeLang = (e, filter) => {
    e.preventDefault();
    const newFilter = filter === selectedFilter ? null : filter;
    onUpdate(newFilter);
  };

  return (
    <div className="search-lang-buttons" data-testid="search-filter-lang-buttons" dir={i18n.dir()}>
      {FILTERS.filter((filter) => !excludeFilters.includes(filter)).map((filter) => (
        <TooltipManager key={filter}>
          <TooltipTrigger>
            <button
              className={classNames('search-lang-button', {
                'is-selected': selectedFilter === filter,
                [`is-${filter}`]: true,
              })}
              onMouseDown={(e) => handleChangeLang(e, filter)}
              data-testid={`filter-lang-${filter}`}
            >
              {filter === TRANS_FILTER && 'ȝ'}
              {LANGUAGE_FILTERS.includes(filter) && <FlagIcon iso={filter} size="medium" />}
              {filter === COMMENT_FILTER && <CommentIcon />}
            </button>
          </TooltipTrigger>
          <TooltipContent>{t(`searchBar.options.${filter}`)}</TooltipContent>
        </TooltipManager>
      ))}
    </div>
  );
};

SearchPopupFilterLang.defaultProps = {
  excludeFilters: [],
};

SearchPopupFilterLang.propTypes = {
  excludeFilters: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func.isRequired,
};

export default SearchPopupFilterLang;
