import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import LoginDataService from '../services/LoginDataService';

// ------------------------------------
// Selectors
// ------------------------------------

const getAuthenticatedUser = (state) => state.auth.user;

const canCreateEntry = (state) => {
  const GROUP_AUTHORIZED_TO_CREATE_ENTRY = ['committee-publisher', 'committee-director'];
  const user = getAuthenticatedUser(state);

  if (user) {
    return GROUP_AUTHORIZED_TO_CREATE_ENTRY.includes(user.group);
  }
  return false;
};

const isCommitteeDirector = (state) => get(state, 'auth.user.group', null) === 'committee-director';

const canUnlockEntry = isCommitteeDirector;

const canPublishEntry = canCreateEntry;

const canEditEntry = canCreateEntry;

// ------------------------------------
// Async actions
// ------------------------------------

const loadAuthUser = createAsyncThunk('auth/loadUser', async () => {
  const res = await LoginDataService.user();
  return res.data;
});

const logout = createAsyncThunk('auth/logout', async () => {
  const res = await LoginDataService.logout();
  return res.data;
});

// ------------------------------------
// Slice
// ------------------------------------
const initialState = {};

const configSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadAuthUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(loadAuthUser.rejected, () => {
        return initialState;
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { reducer } = configSlice;

export {
  reducer,
  getAuthenticatedUser,
  canCreateEntry,
  canEditEntry,
  canPublishEntry,
  canUnlockEntry,
  loadAuthUser,
  logout,
};
