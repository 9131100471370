import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIndividualNameIdsByIndividualId } from 'slices/individualNames';
import { Stack } from 'components/Layout';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualNameRead from './IndividualNameRead';

const IndividualNamesRead = ({ individualId, publishableProp }) => {
  const { t } = useTranslation();
  const nameIds = useSelector((state) => getIndividualNameIdsByIndividualId(state, individualId));

  return (
    <EntryTabContentLayout title={t('individualNames.title')}>
      <Stack space="var(--s-1)">
        {nameIds.map((nameId) => (
          <IndividualNameRead key={nameId} id={nameId} publishableProp={publishableProp} />
        ))}
      </Stack>
    </EntryTabContentLayout>
  );
};

IndividualNamesRead.defaultProps = {
  publishableProp: 'published',
};

IndividualNamesRead.propTypes = {
  publishableProp: PropTypes.oneOf(['edited', 'published']),
  individualId: PropTypes.number.isRequired,
};

export default IndividualNamesRead;
