import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadTitle } from 'slices/titles';
import LinkDecorator from './LinkDecorator';

const TitleLinkDecorator = ({ contentState, entityKey, children }) => {
  const dispatch = useDispatch();
  const { titleId } = contentState.getEntity(entityKey).getData();

  const handleOpeningTitle = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadTitle(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkDecorator id={titleId} type="title" onOpen={handleOpeningTitle}>
      {children}
    </LinkDecorator>
  );
};

TitleLinkDecorator.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TitleLinkDecorator;
