import { get } from 'lodash/fp';
import usePublishable from 'hooks/usePublishable';

const useDictionaryReferencesPublishable = (references, dictionaries) => {
  const [isPublishable] = usePublishable(references);
  const referenceKeys = Object.entries(dictionaries).flatMap(([, dictionariesOfGroup]) =>
    dictionariesOfGroup.map(get('key'))
  );
  return referenceKeys.some((key) => isPublishable(`dictionaryReferences.${key}`));
};

export default useDictionaryReferencesPublishable;
