import { configureStore } from '@reduxjs/toolkit';
import { reducer as authReducer } from 'slices/auth';
import { reducer as searchReducer } from 'slices/search';
import { reducer as openEntriesReducer } from 'slices/openEntries';
import { reducer as wordsReducer } from 'slices/words';
import { reducer as wordGraphiesReducer } from 'slices/wordGraphies';
import { reducer as wordCommentsReducer } from 'slices/wordComments';
import { reducer as wordDictionaryReferencesReducer } from 'slices/wordDictionaryReferences';
import { reducer as titlesReducer } from 'slices/titles';
import { reducer as titleGraphiesReducer } from 'slices/titleGraphies';
import { reducer as titleCommentsReducer } from 'slices/titleComments';
import { reducer as titleHoldersReducer } from 'slices/titleHolders';
import { reducer as titleDictionaryReferencesReducer } from 'slices/titleDictionaryReferences';
import { reducer as individualsReducer } from 'slices/individuals';
import { reducer as individualGraphiesReducer } from 'slices/individualGraphies';
import { reducer as individualCommentsReducer } from 'slices/individualComments';
import { reducer as individualTitulariesReducer } from 'slices/individualTitularies';
import { reducer as individualNamesReducer } from 'slices/individualNames';
import { reducer as individualNameGraphiesReducer } from 'slices/individualNameGraphies';
import { reducer as individualRelativesReducer } from 'slices/individualRelatives';
import { reducer as individualSequencesReducer } from 'slices/individualSequences';
import { reducer as dictionariesReducer } from 'slices/dictionaries';
import { reducer as periodsReducer } from 'slices/periods';
import { reducer as unfetchableEntriesReducer } from 'slices/unfetchableEntries';
import { reducer as entryCreationReducer } from 'slices/ui/entryCreation';
import { reducer as entryHighlightReducer } from 'slices/ui/entryHighlight';
import { reducer as entryLockReducer } from 'slices/ui/entryLock';
import { reducer as attributionsReducer } from 'slices/attributions';
import { reducer as sourcesReducer } from 'slices/sources';
import { reducer as attestationsReducer } from 'slices/attestations';
import { reducer as nuancesReducer } from 'slices/nuances';
import { reducer as thesaurusReducer } from 'slices/ui/thesaurus';
import { reducer as entryLoadingReducer } from 'slices/ui/entryLoading';
import { reducer as printEntryReducer } from 'slices/ui/printEntry';

const reducer = {
  auth: authReducer,
  openEntries: openEntriesReducer,
  words: wordsReducer,
  wordGraphies: wordGraphiesReducer,
  wordComments: wordCommentsReducer,
  wordDictionaryReferences: wordDictionaryReferencesReducer,
  titles: titlesReducer,
  titleGraphies: titleGraphiesReducer,
  titleComments: titleCommentsReducer,
  titleHolders: titleHoldersReducer,
  individualTitularies: individualTitulariesReducer,
  individualNames: individualNamesReducer,
  individualNameGraphies: individualNameGraphiesReducer,
  individualRelatives: individualRelativesReducer,
  individualSequences: individualSequencesReducer,
  titleDictionaryReferences: titleDictionaryReferencesReducer,
  individuals: individualsReducer,
  individualGraphies: individualGraphiesReducer,
  individualComments: individualCommentsReducer,
  dictionaries: dictionariesReducer,
  periods: periodsReducer,
  entryCreation: entryCreationReducer,
  entryHighlight: entryHighlightReducer,
  entryLock: entryLockReducer,
  unfetchableEntries: unfetchableEntriesReducer,
  search: searchReducer,
  attributions: attributionsReducer,
  sources: sourcesReducer,
  attestations: attestationsReducer,
  nuances: nuancesReducer,
  thesaurus: thesaurusReducer,
  entryLoading: entryLoadingReducer,
  printEntry: printEntryReducer,
};

const createMyStore = (preloadedState) => {
  return configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: preloadedState,
  });
};

const store = createMyStore();

export default store;
export { createMyStore };
