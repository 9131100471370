import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  createIndividualRelative,
  getIndividualRelativeIdsByIndividualId,
} from 'slices/individualRelatives';
import { Stack } from 'components/Layout';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualSelector from 'components/IndividualSelector';
import IndividualRelativeEdition from './IndividualRelativeEdition';
import './IndividualRelatives.scss';

const IndividualRelativesEdition = ({ individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const individualRelativeIds = useSelector((state) =>
    getIndividualRelativeIdsByIndividualId(state, individualId)
  );

  const handleAddNewRelative = useCallback(
    (relatedId) => {
      dispatch(createIndividualRelative({ individualId, relatedId }));
    },
    [dispatch, individualId]
  );

  return (
    <EntryTabContentLayout title={t('individualRelatives.title')}>
      <div data-publishable="false">
        <IndividualSelector
          placeholder={t('individualRelatives.addRelative')}
          onSelect={handleAddNewRelative}
        />
      </div>
      <Stack space="var(--s-1)">
        {individualRelativeIds.map((realtiveId) => (
          <IndividualRelativeEdition key={realtiveId} id={realtiveId} individualId={individualId} />
        ))}
      </Stack>
    </EntryTabContentLayout>
  );
};

IndividualRelativesEdition.propTypes = {
  individualId: PropTypes.number.isRequired,
};

export default IndividualRelativesEdition;
