import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, intersection } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getCapeaDictionaries } from 'slices/dictionaries';
import {
  createOrUpdateDictionaryReferences,
  selectors as titleDictionaryReferencesSelectors,
} from 'slices/titleDictionaryReferences';
import usePublishable from 'hooks/usePublishable';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import Editor from 'components/Editor';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';

const isEmptyRef = (ref) => ref === '<p><br></p>' || ref === '<p></p>';
const notEmptyRef = (ref) => ref && !isEmptyRef(ref);

const getDictionnaryReferences = (refs, publishableProp) =>
  get(refs, `${publishableProp}.dictionaryReferences`, null);
const getDictionnaryOtherReferences = (refs, publishableProp) =>
  get(refs, `${publishableProp}.otherReferences`, null);
const getDictionnaryReference = (refs, publishableProp, key) =>
  get(getDictionnaryReferences(refs, publishableProp), key, null);

const TitleDictionaryReferences = ({ titleId, isEditVersion, publishableProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dictionaries = useSelector(getCapeaDictionaries);
  const dictionaryReferences = useSelector((state) =>
    titleDictionaryReferencesSelectors.getByTitleId(state, titleId)
  );
  const [isPublishable] = usePublishable(dictionaryReferences);

  const handleDictionariesUpdate = useCallback(
    (dictionaryKey) => {
      return (newDictionaryValue) => {
        return dispatch(
          createOrUpdateDictionaryReferences({
            ...dictionaryReferences,
            titleId,
            edited: {
              ...dictionaryReferences?.edited,
              dictionaryReferences: {
                ...dictionaryReferences?.edited.dictionaryReferences,
                [dictionaryKey]: newDictionaryValue,
              },
            },
          })
        );
      };
    },
    [dispatch, dictionaryReferences, titleId]
  );

  const handleOtherReferencesUpdate = useCallback(
    (newOtherReferencesValue) => {
      return dispatch(
        createOrUpdateDictionaryReferences({
          ...dictionaryReferences,
          titleId,
          edited: {
            ...dictionaryReferences?.edited,
            otherReferences: newOtherReferencesValue,
          },
        })
      );
    },
    [dispatch, dictionaryReferences, titleId]
  );

  const hasRefInDictionaryGroup = (refs, groups) => {
    if (!refs) {
      return false;
    }
    const refKeys = Object.keys(refs).filter((refKey) => notEmptyRef(refs[refKey]));
    const subGroupKeys = groups.map((group) => group.key);
    return intersection(refKeys, subGroupKeys).length > 0;
  };

  const showRef = (ref) => isEditVersion || notEmptyRef(ref);

  return (
    <EntryTabContentLayout title={t('dictionary.title')} isEditVersion={isEditVersion}>
      {Object.entries(dictionaries).map(([groupKey, dictionariesOfGroup]) => {
        if (
          isEditVersion ||
          hasRefInDictionaryGroup(
            getDictionnaryReferences(dictionaryReferences, publishableProp),
            dictionaries[groupKey]
          )
        ) {
          return (
            <div className="entry-dictionary-group" key={groupKey}>
              <div className="entry-dictionary-group-title">{t(`dictionary.${groupKey}`)}</div>
              {dictionariesOfGroup.map((dictionary) => {
                if (
                  showRef(
                    getDictionnaryReference(dictionaryReferences, publishableProp, dictionary.key)
                  )
                ) {
                  return (
                    <div
                      className="entry-dictionary"
                      key={dictionary.key}
                      data-publishable={
                        isEditVersion && isPublishable(`dictionaryReferences.${dictionary.key}`)
                      }
                    >
                      <TooltipManager>
                        <div className="entry-dictionary-label">
                          <TooltipTrigger>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(`dictionary.abbr.${dictionary.key}`),
                              }}
                            />
                          </TooltipTrigger>
                        </div>
                        <TooltipContent>
                          <div
                            className="entry-dictionary-tooltip"
                            dangerouslySetInnerHTML={{
                              __html: t(`dictionary.longName.${dictionary.key}`),
                            }}
                          />
                        </TooltipContent>
                      </TooltipManager>
                      <div className="entry-dictionary-value" dir="auto">
                        <Editor
                          placeholder="123…"
                          initialValue={getDictionnaryReference(
                            dictionaryReferences,
                            publishableProp,
                            dictionary.key
                          )}
                          readOnly={!isEditVersion}
                          onValidate={handleDictionariesUpdate(dictionary.key)}
                          enableTlaDecorator={!isEditVersion && dictionary.key === 'TLA'}
                          enablePnmDecorator={!isEditVersion && dictionary.key === 'PNM'}
                        />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }
        return null;
      })}

      {showRef(getDictionnaryOtherReferences(dictionaryReferences, publishableProp)) && (
        <div
          className="entry-dictionary-group"
          data-publishable={isEditVersion && isPublishable('otherReferences')}
        >
          <div className="entry-dictionary-group-title">{t('dictionary.otherReferences')}</div>
          <div className="entry-dictionary-value" dir="auto">
            <Editor
              placeholder={t('dictionary.otherReferencesPlaceholder')}
              initialValue={getDictionnaryOtherReferences(dictionaryReferences, publishableProp)}
              readOnly={!isEditVersion}
              onValidate={handleOtherReferencesUpdate}
            />
          </div>
        </div>
      )}
    </EntryTabContentLayout>
  );
};

TitleDictionaryReferences.defaultProps = {
  publishableProp: 'edited',
};

TitleDictionaryReferences.propTypes = {
  titleId: PropTypes.number.isRequired,
  isEditVersion: PropTypes.bool.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']),
};

export default TitleDictionaryReferences;
