import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIndividualById, isIndividualPublished } from 'slices/individuals';
import EntryEdition from 'components/Entry/EntryEdition';
import IndividualEditionContent from './IndividualEditionContent';

const IndividualEdition = ({
  id,
  isHighlighted,
  isPreviewed,
  onStopEdition,
  onPublish,
  onUnpublish,
  onClose,
  onDelete,
  onPreview,
}) => {
  const entry = useSelector((state) => getIndividualById(state, id));
  const showUnpublishButton = useSelector((state) => isIndividualPublished(state, id));

  return (
    <EntryEdition
      id={id}
      isHighlighted={isHighlighted}
      isPreviewed={isPreviewed}
      isPublished={!!entry?.published}
      showUnpublishButton={showUnpublishButton}
      onStopEdition={onStopEdition}
      onPublish={onPublish}
      onUnpublish={onUnpublish}
      onClose={onClose}
      onDelete={onDelete}
      onPreview={onPreview}
    >
      {entry && entry.edited && <IndividualEditionContent entry={entry} />}
    </EntryEdition>
  );
};

IndividualEdition.defaultProps = {
  isHighlighted: false,
  isPreviewed: false,
};

IndividualEdition.propTypes = {
  isHighlighted: PropTypes.bool,
  isPreviewed: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onStopEdition: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IndividualEdition;
