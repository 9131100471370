import axios from 'axios';

class GraphiesDataService {
  constructor(parentKeyName) {
    this.parentKeyName = parentKeyName || 'entryId';
  }

  get(parentId, isEditVersion, contentAll = true) {
    const contentAllQueryParam = contentAll ? '&contentAll=true' : '';
    return axios.get(
      `/graphies?edited=${isEditVersion}&${this.parentKeyName}=${parentId}${contentAllQueryParam}`
    );
  }

  createOrUpdate(graphy) {
    return axios.post('/graphies', graphy);
  }

  delete(graphyId) {
    return axios.delete(`/graphies/${graphyId}`);
  }

  unpublish(graphyData) {
    return axios.post('/graphies/unpublish', graphyData);
  }

  publish(graphyData) {
    return axios.post('/graphies/publish', graphyData);
  }
}

export default GraphiesDataService;
