import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import Root from './Root';
import './utils/i18n';
import './styles/style.scss';

const VEgASuspense = () => {
  return (
    <>
      <div className="app-header">
        <div />
        <div
          style={{
            flex: '1 1 auto',
            maxWidth: 540,
            height: 40,
            borderRadius: 7,
            backgroundColor: 'white',
          }}
        />
        <div />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="rotateLoading" />
      </div>
    </>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Suspense fallback={VEgASuspense()}>
    <Root />
  </Suspense>
);
