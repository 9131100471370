import commentsFactory from './commentsFactory';
import CommentsDataService from 'services/CommentsDataService';

const commentsDataService = new CommentsDataService('individualId');

const extraReducers = {
  'individual/delete/fulfilled': (state, action) => {
    delete state.byIndividualId[action.payload.individualId];
  },
};

const { reducer, actions, selectors } = commentsFactory(
  'individualComments',
  'individual',
  extraReducers,
  commentsDataService
);

const {
  loadComment,
  unpublishEntryComment: unpublishIndividualComment,
  publishEntryComment: publishIndividualComment,
  createOrUpdateComment,
  resetCommentOfEntry: resetCommentOfIndividual,
} = actions;

export {
  reducer,
  loadComment,
  unpublishIndividualComment,
  publishIndividualComment,
  createOrUpdateComment,
  resetCommentOfIndividual,
  selectors,
};
