import { PUBLIC_STATUS } from 'utils/constants';
import PublicStatusSelector from './PublicStatusSelector';

const statusList = Object.values(PUBLIC_STATUS);

const EntryPublicStatusSelector = (props) => {
  return <PublicStatusSelector statusList={statusList} {...props} translationKey="entry" />;
};

export default EntryPublicStatusSelector;
