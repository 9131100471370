import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable';
import { isPublishable } from 'utils/util';
import TitulariesDataService from 'services/TitulariesDataService';
import { deleteIndividual } from './individuals';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getMainTitularyIdsByIndividualId = (state, individualId) =>
  state.individualTitularies.mainIdsByIndividualId[individualId] || emptyArray;

const getOtherTitularyIdsByIndividualId = (state, individualId) =>
  state.individualTitularies.otherIdsByIndividualId[individualId] || emptyArray;

const getIndividualTitularyById = (state, titleId) => state.individualTitularies.byId[titleId];

const doesIndividualHaveTitularies = (state, individualId) => {
  return (
    getMainTitularyIdsByIndividualId(state, individualId).filter((id) =>
      isIndividualTitularyTitlePublished(state, id)
    ).length > 0 ||
    getOtherTitularyIdsByIndividualId(state, individualId).filter((id) =>
      isIndividualTitularyTitlePublished(state, id)
    ).length > 0
  );
};

const isIndividualTitularyTitlePublished = (state, titularyId) => {
  const titulary = getIndividualTitularyById(state, titularyId);
  return titulary.title.published;
};

const isIndividualTitularyPublishable = (state, titularyId) => {
  const titulary = getIndividualTitularyById(state, titularyId);

  return isPublishable(titulary) || isPublishable(titulary, 'rank');
};

const isIndividualTitulariesPublishable = (state, individualId) => {
  const mainIds = getMainTitularyIdsByIndividualId(state, individualId);
  const otherIds = getOtherTitularyIdsByIndividualId(state, individualId);
  return [...mainIds, ...otherIds].some((titularyId) =>
    isIndividualTitularyPublishable(state, titularyId)
  );
};

// ------------------------------------
// Async actions
// ------------------------------------

const loadIndividualTitularies = createAsyncThunk(
  'individualTitularies/load',
  async ({ individualId, isEditVersion }) => {
    const res = await TitulariesDataService.getAllOfIndividual(individualId, isEditVersion);
    return { individualId, data: res.data };
  }
);

const unpublishIndividualTitulary = createAsyncThunk(
  'individualTitularies/unpublish',
  async (titulary) => {
    const res = await TitulariesDataService.unpublish(titulary);
    return res.data;
  }
);

const publishIndividualTitulary = createAsyncThunk(
  'individualTitularies/publish',
  async (titulary) => {
    const res = await TitulariesDataService.publish(titulary);
    return res.data;
  }
);

const createIndividualTitulary = createAsyncThunk(
  'individualTitularies/create',
  async ({ individualId, data, isMain }, { getState }) => {
    const ids = isMain
      ? getMainTitularyIdsByIndividualId(getState(), individualId)
      : getOtherTitularyIdsByIndividualId(getState(), individualId);
    let rank = -1;
    if (ids.length > 0) {
      const lastTitulary = getIndividualTitularyById(getState(), ids[ids.length - 1]);
      rank = lastTitulary.edited.rank;
    }
    data.edited.rank = rank + 1;
    const res = await TitulariesDataService.createOrUpdate({
      individualId,
      ...data,
    });
    return res.data;
  }
);

const updateIndividualTitulary = createAsyncThunk(
  'individualTitularies/update',
  async (titulary) => {
    const res = await TitulariesDataService.createOrUpdate(titulary);
    return res.data;
  }
);

const updateTitulariesRanks = (ids, newRanks) => {
  return (dispatch, getState) => {
    ids.forEach((id) => {
      const newRank = newRanks.indexOf(id);
      const titulary = getIndividualTitularyById(getState(), id);
      const updatedTitulary = { ...titulary, edited: { ...titulary.edited, rank: newRank + 1 } };
      dispatch(updateIndividualTitulary(updatedTitulary));
    });
  };
};

const updateMainTitulariesRanks = (individualId, oldIndex, newIndex) => {
  return (dispatch, getState) => {
    const mainIds = getMainTitularyIdsByIndividualId(getState(), individualId);
    const newRanks = arrayMove(mainIds, oldIndex, newIndex);
    dispatch(reorderMainTitularies({ individualId, oldIndex, newIndex }));
    dispatch(updateTitulariesRanks(mainIds, newRanks));
  };
};

const updateOtherTitulariesRanks = (individualId, oldIndex, newIndex) => {
  return (dispatch, getState) => {
    const otherIds = getOtherTitularyIdsByIndividualId(getState(), individualId);
    const newRanks = arrayMove(otherIds, oldIndex, newIndex);
    dispatch(reorderOtherTitularies({ individualId, oldIndex, newIndex }));
    dispatch(updateTitulariesRanks(otherIds, newRanks));
  };
};

const deleteIndividualTitulary = createAsyncThunk(
  'individualTitularies/delete',
  async ({ id, individualId }) => {
    await TitulariesDataService.delete(id);
    return { individualId, id };
  }
);

const publishTitulariesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const individualMainTitularyIds = getMainTitularyIdsByIndividualId(getState(), individualId);
    const individualOtherTitularyIds = getOtherTitularyIdsByIndividualId(getState(), individualId);
    [...individualMainTitularyIds, ...individualOtherTitularyIds].forEach((titularyId) =>
      dispatch(publishIndividualTitulary(getIndividualTitularyById(getState(), titularyId)))
    );
  };
};

const unpublishTitulariesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const individualMainTitularyIds = getMainTitularyIdsByIndividualId(getState(), individualId);
    const individualOtherTitularyIds = getOtherTitularyIdsByIndividualId(getState(), individualId);
    [...individualMainTitularyIds, ...individualOtherTitularyIds].forEach((titularyId) =>
      dispatch(unpublishIndividualTitulary(getIndividualTitularyById(getState(), titularyId)))
    );
  };
};

const resetTitulariesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const individualMainTitularyIds = getMainTitularyIdsByIndividualId(getState(), individualId);
    const individualOtherTitularyIds = getOtherTitularyIdsByIndividualId(getState(), individualId);
    [...individualMainTitularyIds, ...individualOtherTitularyIds].forEach((titularyId) =>
      dispatch(resetIndividualTitulary({ individualId, id: titularyId }))
    );
  };
};

const initialState = {
  mainIdsByIndividualId: {},
  otherIdsByIndividualId: {},
  byId: {},
};

const individualTitulariesSlice = createSlice({
  name: 'individualTitularies',
  initialState,
  reducers: {
    reorderMainTitularies: (state, action) => {
      state.mainIdsByIndividualId[action.payload.individualId] = arrayMove(
        state.mainIdsByIndividualId[action.payload.individualId],
        action.payload.oldIndex,
        action.payload.newIndex
      );
    },
    reorderOtherTitularies: (state, action) => {
      state.otherIdsByIndividualId[action.payload.individualId] = arrayMove(
        state.otherIdsByIndividualId[action.payload.individualId],
        action.payload.oldIndex,
        action.payload.newIndex
      );
    },
    resetIndividualTitulary: (state, { payload }) => {
      if (
        state.mainIdsByIndividualId[payload.individualId] &&
        state.mainIdsByIndividualId[payload.individualId].length > 0
      ) {
        state.mainIdsByIndividualId[payload.individualId] = state.mainIdsByIndividualId[
          payload.individualId
        ].filter((titleId) => payload.id !== titleId);
      }
      if (
        state.otherIdsByIndividualId[payload.individualId] &&
        state.otherIdsByIndividualId[payload.individualId].length > 0
      ) {
        state.otherIdsByIndividualId[payload.individualId] = state.otherIdsByIndividualId[
          payload.individualId
        ].filter((titleId) => payload.id !== titleId);
      }
      delete state.byId[payload.id];
    },
  },
  extraReducers: (builder) => {
    const replaceTitulary = (state, action) => {
      state.byId[action.payload.id] = action.payload;
    };
    const getType = (titulary) =>
      titulary.edited ? titulary.edited.type : titulary.published.type;

    builder
      .addCase(loadIndividualTitularies.fulfilled, (state, action) => {
        const mainTitles = action.payload.data.filter((titulary) => getType(titulary) === 'main');
        const otherTitles = action.payload.data.filter((titulary) => getType(titulary) === 'other');
        state.mainIdsByIndividualId[action.payload.individualId] = mainTitles.map(
          (title) => title.id
        );
        state.otherIdsByIndividualId[action.payload.individualId] = otherTitles.map(
          (title) => title.id
        );
        action.payload.data.forEach((title) => (state.byId[title.id] = title));
      })
      .addCase(createIndividualTitulary.fulfilled, (state, action) => {
        if (getType(action.payload) === 'main') {
          state.mainIdsByIndividualId[action.payload.individualId] = state.mainIdsByIndividualId[
            action.payload.individualId
          ]
            ? [...state.mainIdsByIndividualId[action.payload.individualId], action.payload.id]
            : [action.payload.id];
        } else {
          state.otherIdsByIndividualId[action.payload.individualId] = state.otherIdsByIndividualId[
            action.payload.individualId
          ]
            ? [...state.otherIdsByIndividualId[action.payload.individualId], action.payload.id]
            : [action.payload.id];
        }
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(updateIndividualTitulary.fulfilled, replaceTitulary)
      .addCase(unpublishIndividualTitulary.fulfilled, replaceTitulary)
      .addCase(publishIndividualTitulary.fulfilled, replaceTitulary)
      .addCase(deleteIndividualTitulary.fulfilled, (state, action) => {
        if (
          state.mainIdsByIndividualId[action.payload.individualId] &&
          state.mainIdsByIndividualId[action.payload.individualId].length > 0
        ) {
          state.mainIdsByIndividualId[action.payload.individualId] = state.mainIdsByIndividualId[
            action.payload.individualId
          ].filter((titleId) => action.payload.id !== titleId);
        }
        if (
          state.otherIdsByIndividualId[action.payload.individualId] &&
          state.otherIdsByIndividualId[action.payload.individualId].length > 0
        ) {
          state.otherIdsByIndividualId[action.payload.individualId] = state.otherIdsByIndividualId[
            action.payload.individualId
          ].filter((titleId) => action.payload.id !== titleId);
        }
        delete state.byId[action.payload.id];
      })
      .addCase(deleteIndividual.fulfilled, (state, action) => {
        const individualMainTitleIdsToRemove =
          state.mainIdsByIndividualId[action.payload.individualId] || [];
        const individualOtherTitleIdsToRemove =
          state.otherIdsByIndividualId[action.payload.individualId] || [];
        [...individualMainTitleIdsToRemove, ...individualOtherTitleIdsToRemove].forEach(
          (id) => delete state.byId[id]
        );
        delete state.mainIdsByIndividualId[action.payload.individualId];
        delete state.otherIdsByIndividualId[action.payload.individualId];
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { resetIndividualTitulary, reorderMainTitularies, reorderOtherTitularies } =
  individualTitulariesSlice.actions;

const { reducer } = individualTitulariesSlice;

export {
  reducer,
  loadIndividualTitularies,
  unpublishIndividualTitulary,
  publishIndividualTitulary,
  createIndividualTitulary,
  deleteIndividualTitulary,
  publishTitulariesOfIndividual,
  unpublishTitulariesOfIndividual,
  resetTitulariesOfIndividual,
  updateMainTitulariesRanks,
  updateOtherTitulariesRanks,
  getMainTitularyIdsByIndividualId,
  getOtherTitularyIdsByIndividualId,
  getIndividualTitularyById,
  isIndividualTitularyPublishable,
  isIndividualTitulariesPublishable,
  doesIndividualHaveTitularies,
};
