import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, upperFirst } from 'lodash';
import { isPublishable } from 'utils/util';
import FilesDataService from 'services/FilesDataService';
// import { getNuanceByGraphyIdAndAttestationId } from './nuances';
import { close } from './ui/thesaurus';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const getSelectors = (name, index, byIndexId) => {
  const emptyArray = [];

  const sliceSelector = (state) => state[name];
  const indexBySliceSelector = (state) => sliceSelector(state)[byIndexId];

  const getIdsByEntryId = (state, entryId) => indexBySliceSelector(state)[entryId] || emptyArray;

  const getById = (state, graphyId) => sliceSelector(state).byId[graphyId];

  const getMain1GraphyByEntryId = (state, entryId) => {
    const entryIds = getIdsByEntryId(state, entryId);
    return entryIds.length > 0 ? getById(state, entryIds[0]) : null;
  };
  const getMain2GraphyByEntryId = (state, entryId, publishableProp) => {
    const entryIds = getIdsByEntryId(state, entryId);
    const secondGraphyId = entryIds.length > 1 ? entryIds[1] : null;
    const secondGraphy = getById(state, secondGraphyId);
    if (get(secondGraphy, `${publishableProp}.main2`, false)) {
      return secondGraphy;
    }
    return null;
  };

  const isAllOfEntryPublishable = (state, entryId) => {
    const graphyIds = getIdsByEntryId(state, entryId);
    return graphyIds.some((graphyId) => isPublishableGraphy(state, graphyId, true));
  };

  const isPublishableGraphy = (state, graphyId, exhaustiveCheck = false) => {
    const graphy = getById(state, graphyId);
    const publishedAttestationIds = get(graphy, 'published.attestationIds', []);
    const editedAttestationIds = get(graphy, 'edited.attestationIds', []);
    const hasUnpublishedMain = isPublishable(graphy, 'main1') || isPublishable(graphy, 'main2');
    const hasUnpublishedUrl = isPublishable(graphy, 'url');
    const hasNoPublishedAttestations =
      publishedAttestationIds.length === 0 && editedAttestationIds.length > 0;
    const getHasUnpublishedAddedAttestation = (attestationId) =>
      !publishedAttestationIds.includes(attestationId);
    const hasUnpublishedAddedAttestation = () =>
      editedAttestationIds.length > 0 &&
      editedAttestationIds.some(getHasUnpublishedAddedAttestation);
    const hasUnpublishedRemovedAttestation = () =>
      publishedAttestationIds.length > 0 &&
      publishedAttestationIds.some(
        (publishedAttestationId) => !editedAttestationIds.includes(publishedAttestationId)
      );
    const hasDataToPublished = exhaustiveCheck
      ? hasUnpublishedMain || hasUnpublishedUrl
      : (hasUnpublishedMain && hasUnpublishedUrl) ||
        hasNoPublishedAttestations ||
        hasUnpublishedAddedAttestation() ||
        hasUnpublishedRemovedAttestation();

    return hasDataToPublished;
  };

  return {
    getById,
    isPublishable: isPublishableGraphy,
    [`getIds${upperFirst(byIndexId)}`]: getIdsByEntryId,
    [`getMain1Graphy${upperFirst(byIndexId)}`]: getMain1GraphyByEntryId,
    [`getMain2Graphy${upperFirst(byIndexId)}`]: getMain2GraphyByEntryId,
    [`isAllOf${upperFirst(index)}Publishable`]: isAllOfEntryPublishable,
  };
};

// ------------------------------------
// Async actions
// ------------------------------------

const getActions = (name, indexIdKey, byIndexId, selectors, sliceActions, graphiesDataService) => {
  const { resetGraphy, toFirstRank, toSecondRank } = sliceActions;

  const actionName = (action) => `${name}/${action}`;
  const getIdsByIndexId = selectors[`getIds${upperFirst(byIndexId)}`];

  const unpublishEntryGraphies = (entryId) => {
    return (dispatch, getState) => {
      getIdsByIndexId(getState(), entryId).forEach((graphyId) => {
        dispatch(unpublishGraphy(selectors.getById(getState(), graphyId)));
      });
    };
  };

  const publishEntryGraphies = (entryId) => {
    return (dispatch, getState) => {
      getIdsByIndexId(getState(), entryId).forEach((graphyId) => {
        dispatch(publishGraphy(selectors.getById(getState(), graphyId)));
      });
    };
  };

  const loadGraphies = createAsyncThunk(actionName('load'), async (arg) => {
    const res = await graphiesDataService.get(arg[indexIdKey], arg.isEditVersion, arg.contentAll);
    return { [indexIdKey]: arg[indexIdKey], data: res.data };
  });

  const unpublishGraphy = createAsyncThunk(actionName('unpublish'), async (graphy) => {
    const res = await graphiesDataService.unpublish(graphy);
    return res.data;
  });

  const publishGraphy = createAsyncThunk(actionName('publish'), async (graphy) => {
    const res = await graphiesDataService.publish(graphy);
    return res.data;
  });

  const udpateMainGraphies = (graphyId, value) => {
    return (dispatch, getState) => {
      const graphy = selectors.getById(getState(), graphyId);
      const newMain1Graphy = !graphy.edited.main1 && value.main1;
      const newMain2Graphy = !graphy.edited.main2 && value.main2;
      const findGraphyByMainProp = (prop) =>
        getIdsByIndexId(getState(), graphy[indexIdKey])
          .map((id) => selectors.getById(getState(), id))
          .find(({ edited }) => edited[prop]);

      if (newMain1Graphy) {
        const oldMain1Graphy = findGraphyByMainProp('main1');
        if (oldMain1Graphy) {
          const updatedOldMain1Graphy = {
            ...oldMain1Graphy,
            edited: {
              ...oldMain1Graphy.edited,
              main1: false,
            },
          };
          dispatch(updateGraphy(updatedOldMain1Graphy));
        }
      }

      if (newMain2Graphy) {
        const oldMain2Graphy = findGraphyByMainProp('main2');
        if (oldMain2Graphy) {
          const updatedOldMain2Graphy = {
            ...oldMain2Graphy,
            edited: {
              ...oldMain2Graphy.edited,
              main2: false,
            },
          };
          dispatch(updateGraphy(updatedOldMain2Graphy));
        }
      }

      const updatedGraphy = {
        ...graphy,
        edited: {
          ...graphy.edited,
          main1: newMain1Graphy ? true : false,
          main2: newMain2Graphy ? true : false,
        },
      };

      // main2 › main1  -› to first rank
      const fromMain2ToMain1 = graphy.edited.main2 && value.main1;
      // … › main1      -› to frist rank
      const fromNothingToMain1 = !graphy.edited.main1 && !graphy.edited.main2 && value.main1;
      // … › main2      -› if main1 exist? to second rank otherwise, to first rank
      const fromNothingToMain2WithMain1 =
        !graphy.edited.main1 &&
        !graphy.edited.main2 &&
        value.main2 &&
        findGraphyByMainProp('main1');
      const fromNothingToMain2WithoutMain1 =
        !graphy.edited.main1 &&
        !graphy.edited.main2 &&
        value.main2 &&
        !findGraphyByMainProp('main1');
      // main1 › …      -› if main2 exist? to second rank otherwise, do nothing
      const fromMain1ToNothing =
        graphy.edited.main1 && !value.main1 && !value.main2 && findGraphyByMainProp('main2');
      // main1 › main2  -› do nothing
      // main2 › …      -› do nothing

      if (fromMain2ToMain1 || fromNothingToMain1 || fromNothingToMain2WithoutMain1) {
        dispatch(toFirstRank({ [indexIdKey]: graphy[indexIdKey], graphyId: graphy.id }));
      } else if (fromNothingToMain2WithMain1 || fromMain1ToNothing) {
        dispatch(toSecondRank({ [indexIdKey]: graphy[indexIdKey], graphyId: graphy.id }));
      }
      return dispatch(updateGraphy(updatedGraphy));
    };
  };

  const addAttestationToGraphy = ({ graphyId, attestationId }) => {
    return (dispatch, getState) => {
      dispatch(close());
      const graphy = selectors.getById(getState(), graphyId);
      const updatedGraphy = {
        ...graphy,
        edited: {
          ...graphy.edited,
          attestationIds: graphy.edited.attestationIds
            ? [...graphy.edited.attestationIds, attestationId]
            : [attestationId],
        },
      };
      return dispatch(updateGraphy(updatedGraphy));
    };
  };

  const removeAttestationFromGraphy = ({ graphyId, attestationId }) => {
    return (dispatch, getState) => {
      const graphy = selectors.getById(getState(), graphyId);
      const updatedGraphy = {
        ...graphy,
        edited: {
          ...graphy.edited,
          attestationIds: graphy.edited.attestationIds.filter((id) => id !== attestationId),
        },
      };
      return dispatch(updateGraphy(updatedGraphy));
    };
  };

  const uploadFileAndCreateGraphy = (entryId, graphyFile) => {
    return (dispatch, getState) => {
      return dispatch(uploadGraphy(graphyFile)).then(({ payload: filename }) => {
        const graphiesIds = getIdsByIndexId(getState(), entryId);
        return dispatch(
          createGraphy({
            [indexIdKey]: entryId,
            edited: {
              main1: graphiesIds.length === 0,
              main2: graphiesIds.length === 1,
              url: `api/files/${filename}`,
            },
          })
        );
      });
    };
  };

  const uploadFileAndUpdateGraphy = (graphyId, graphyFile) => {
    return (dispatch, getState) => {
      return dispatch(uploadGraphy(graphyFile)).then(({ payload: filename }) => {
        const graphy = selectors.getById(getState(), graphyId);
        return dispatch(
          updateGraphy({
            ...graphy,
            edited: {
              ...graphy.edited,
              url: `api/files/${filename}`,
            },
          })
        );
      });
    };
  };

  const createGraphy = createAsyncThunk(actionName('create'), async (graphy) => {
    const res = await graphiesDataService.createOrUpdate(graphy);
    return res.data;
  });

  const updateGraphy = createAsyncThunk(actionName('update'), async (graphy) => {
    const res = await graphiesDataService.createOrUpdate(graphy);
    return res.data;
  });

  const uploadGraphy = createAsyncThunk(actionName('upload'), async (graphyFile) => {
    const res = await FilesDataService.upload(graphyFile);
    return res.data;
  });

  const deleteGraphy = createAsyncThunk(actionName('delete'), async (arg) => {
    await graphiesDataService.delete(arg.graphyId);
    return { [indexIdKey]: arg[indexIdKey], graphyId: arg.graphyId };
  });

  const resetGraphiesOfEntry = (entryId) => {
    return (dispatch, getState) => {
      const graphyIds = getIdsByIndexId(getState(), entryId);
      graphyIds.forEach((graphyId) => dispatch(resetGraphy({ [indexIdKey]: entryId, graphyId })));
    };
  };

  return {
    unpublishEntryGraphies,
    publishEntryGraphies,
    loadGraphies,
    unpublishGraphy,
    publishGraphy,
    udpateMainGraphies,
    addAttestationToGraphy,
    removeAttestationFromGraphy,
    uploadFileAndCreateGraphy,
    uploadFileAndUpdateGraphy,
    createGraphy,
    updateGraphy,
    uploadGraphy,
    deleteGraphy,
    resetGraphiesOfEntry,
    resetGraphy,
  };
};

function graphiesFactory(name, indexBy, extraReducers = {}, graphiesDataService) {
  const Index = upperFirst(indexBy);
  const byIndexId = `by${Index}Id`;
  const indexIdKey = `${indexBy}Id`;
  const selectors = getSelectors(name, indexBy, byIndexId);

  const initialState = {
    [byIndexId]: {},
    byId: {},
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      resetGraphy: (state, action) => {
        state[byIndexId][action.payload[indexIdKey]] = state[byIndexId][
          action.payload[indexIdKey]
        ].filter((id) => id !== action.payload.graphyId);
        if (state[byIndexId][action.payload[indexIdKey]].length === 0) {
          delete state[byIndexId][action.payload[indexIdKey]];
        }
        delete state.byId[action.payload.graphyId];
      },
      toFirstRank: (state, action) => {
        const ids = state[byIndexId][action.payload[indexIdKey]].filter(
          (id) => id !== action.payload.graphyId
        );
        state[byIndexId][action.payload[indexIdKey]] = [action.payload.graphyId, ...ids];
      },
      toSecondRank: (state, action) => {
        const [firstId, ...ids] = state[byIndexId][action.payload[indexIdKey]].filter(
          (id) => id !== action.payload.graphyId
        );
        if (firstId) {
          state[byIndexId][action.payload[indexIdKey]] = [firstId, action.payload.graphyId, ...ids];
        }
      },
    },
    extraReducers: (builder) => {
      const replaceGraphy = (state, action) => {
        state.byId[action.payload.id] = action.payload;
      };

      builder
        .addCase(actions.loadGraphies.fulfilled, (state, action) => {
          state[byIndexId][action.payload[indexIdKey]] = action.payload.data.map(
            (graphy) => graphy.id
          );
          action.payload.data.forEach((graphy) => {
            const parsedGraphy = { ...graphy };
            if (parsedGraphy.edited?.attestations) {
              parsedGraphy.edited = { ...parsedGraphy.edited };
              delete parsedGraphy.edited.attestations;
            }
            if (parsedGraphy.published?.attestations) {
              parsedGraphy.published = { ...parsedGraphy.published };
              delete parsedGraphy.published.attestations;
            }
            state.byId[parsedGraphy.id] = parsedGraphy;
          });
        })
        .addCase(actions.createGraphy.fulfilled, (state, action) => {
          state[byIndexId][action.payload[indexIdKey]] = [
            ...(state[byIndexId][action.payload[indexIdKey]] || []),
            action.payload.id,
          ];
          state.byId[action.payload.id] = action.payload;
        })
        .addCase(actions.updateGraphy.fulfilled, replaceGraphy)
        .addCase(actions.unpublishGraphy.fulfilled, replaceGraphy)
        .addCase(actions.publishGraphy.fulfilled, replaceGraphy)
        .addCase(actions.deleteGraphy.fulfilled, (state, action) => {
          delete state.byId[action.payload.graphyId];
          state[byIndexId][action.payload[indexIdKey]] = state[byIndexId][
            action.payload[indexIdKey]
          ].filter((id) => id !== action.payload.graphyId);
        })
        .addCase(logout.fulfilled, () => {
          return initialState;
        });

      Object.entries(extraReducers).forEach(([type, reducer]) => {
        builder.addCase(type, reducer);
      });
    },
  });

  const actions = getActions(
    name,
    indexIdKey,
    byIndexId,
    selectors,
    slice.actions,
    graphiesDataService
  );

  const { reducer } = slice;

  return {
    reducer,
    selectors,
    actions,
  };
}

export default graphiesFactory;
