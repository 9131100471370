import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeIndividual,
  deleteIndividual,
  isIndividualEdited,
  isIndividualHighlighted,
  publishAllIndividualData,
  startIndividualEdition,
  stopIndividualEditionAndReload,
  unpublishAllIndividualData,
} from 'slices/individuals';
import IndividualEdition from './IndividualEdition';
import IndividualRead from './IndividualRead';
import IndividualPreview from './IndividualPreview';

const Individual = ({ id }) => {
  const dispatch = useDispatch();
  const [isPreviewed, setIsPreviewed] = useState(false);
  const isEdited = useSelector((state) => isIndividualEdited(state, id));
  const isHighlighted = useSelector((state) => isIndividualHighlighted(state, id));

  const handleStartIndividualEdition = (evt) => {
    evt.preventDefault();
    dispatch(startIndividualEdition(id));
  };

  const handleStopIndividualEdition = (evt) => {
    evt.preventDefault();
    setIsPreviewed(false);
    dispatch(stopIndividualEditionAndReload(id));
  };

  const handlePublishTitle = (evt) => {
    evt.preventDefault();
    dispatch(publishAllIndividualData(id));
  };

  const handleUnpublish = (e) => {
    e.preventDefault();
    dispatch(unpublishAllIndividualData(id));
  };

  const togglePreview = () => {
    setIsPreviewed(!isPreviewed);
  };

  const handleClose = () => {
    dispatch(closeIndividual(id));
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteIndividual(id));
  };

  return (
    <>
      {!isEdited && (
        <IndividualRead
          id={id}
          isHighlighted={isHighlighted}
          onStartEdition={handleStartIndividualEdition}
          onClose={handleClose}
        />
      )}
      {isEdited && (
        <IndividualEdition
          id={id}
          isHighlighted={isHighlighted}
          isPreviewed={isPreviewed}
          onStopEdition={handleStopIndividualEdition}
          onPublish={handlePublishTitle}
          onUnpublish={handleUnpublish}
          onPreview={togglePreview}
          onClose={handleClose}
          onDelete={handleDelete}
        />
      )}
      {isPreviewed && <IndividualPreview id={id} onClose={togglePreview} />}
    </>
  );
};

Individual.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Individual;
