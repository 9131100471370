import axios from 'axios';

class IndividualsDataService {
  get(id, isEditVersion) {
    return axios.get(`/individuals/${id}?edited=${isEditVersion}`);
  }

  createOrUpdate(individual) {
    return axios.post('/individuals', individual);
  }

  lock(individualId) {
    return axios.put(`/individuals/${individualId}/lock`);
  }

  unlock(individualId) {
    return axios.put(`/individuals/${individualId}/unlock`);
  }

  publish(individual) {
    return axios.post('/individuals/publish', individual);
  }

  unpublish(individual) {
    return axios.post('/individuals/unpublish', individual);
  }

  delete(individualId) {
    return axios.delete(`/individuals/${individualId}`);
  }
}

export default new IndividualsDataService();
