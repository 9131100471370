import { createSlice } from '@reduxjs/toolkit';

// ------------------------------------
// Selectors
// ------------------------------------

const getUnfetchableEntryIds = (state) => state.unfetchableEntries;

// ------------------------------------
// Slice
// ------------------------------------

const unfetchableEntriesSlice = createSlice({
  name: 'unfetchableEntries',
  initialState: [],
  reducers: {
    setUnfetchableEntry: (state, action) => {
      return [...state, action.payload];
    },
    setUnfetchableEntries: (state, action) => {
      return action.payload;
    },
    resetUnfetchableEntries: (state) => {
      return [];
    },
  },
});

const { setUnfetchableEntry, setUnfetchableEntries, resetUnfetchableEntries } =
  unfetchableEntriesSlice.actions;

const { reducer } = unfetchableEntriesSlice;

export {
  reducer,
  setUnfetchableEntry,
  setUnfetchableEntries,
  resetUnfetchableEntries,
  getUnfetchableEntryIds,
};
