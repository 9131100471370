import axios from 'axios';

class TitulariesDataService {
  getAllOfIndividual(individualId, isEdited) {
    return axios.get(`/titularies?individualId=${individualId}&edited=${isEdited}`);
  }

  createOrUpdate(titulary) {
    return axios.post('/titularies', titulary);
  }

  publish(titulary) {
    return axios.post('/titularies/publish', titulary);
  }

  unpublish(titulary) {
    return axios.post('/titularies/unpublish', titulary);
  }

  delete(titularyId) {
    return axios.delete(`/titularies/${titularyId}`);
  }
}

export default new TitulariesDataService();
