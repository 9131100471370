import { cond, constant, get, identity, isArray, isEqual, isNil, negate, stubTrue } from 'lodash';
import { eq } from 'lodash/fp';

export const asyncState = {
  pending: 'pending',
  resolved: 'resolved',
  error: 'error',
};

export const makeCancelable = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    return promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

export const isEmptyHtml = (html) => html === '<p><br></p>' || html === '<p></p>';

export const isNotEmptyHtml = (html) => html && !isEmptyHtml(html);

export const isNotNil = negate(isNil);

export const normalizeValue = (value) => {
  return cond([
    [eq('<p></p>'), constant(null)],
    [eq('<p><br></p>'), constant(null)],
    [stubTrue, identity],
  ])(value);
};

export const isPublishable = (publishable, path) => {
  if (isNil(publishable)) {
    return false;
  }
  const publishDate = get(publishable, 'publishDate', null);

  if (isNil(path)) {
    if (isNil(publishDate)) {
      return true;
    } else {
      return false;
    }
  }

  if (isNil(publishDate)) {
    return true;
  }

  const editedValue = get(publishable, `edited.${path}`, null);
  const publishedValue = get(publishable, `published.${path}`, null);

  if (isArray(editedValue)) {
    return !isEqual(editedValue, publishedValue);
  }
  return normalizeValue(editedValue) !== normalizeValue(publishedValue);
};
