import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { decodeDelimitedArray, encodeDelimitedArray, useQueryParams } from 'use-query-params';
import { take } from 'lodash';
import { getOpenEntryIds, isInitialEntriesLoaded, openEntries } from 'slices/openEntries';
import { resetUnfetchableEntries, getUnfetchableEntryIds } from 'slices/unfetchableEntries';
import { WordPrintModal } from 'components/Word';
import MiniMap from 'components/MiniMap';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Entries from './Entries';

const getFormattedList = (arr, separator, lastSeparator) => {
  if (!arr) {
    return null;
  }
  const entries = arr.map((entry) => entry);
  if (arr.length > 1) {
    return `${take(entries, Math.max(1, entries.length - 1)).join(
      separator
    )} ${lastSeparator} ${entries.pop()}`;
  }
  return entries[0];
};

const hyphenArrayParam = {
  encode: (array) => encodeDelimitedArray(array, '-'),
  decode: (arrayStr) => decodeDelimitedArray(arrayStr, '-'),
};

const EntriesManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isNotInitialLoad, setNotIsInitialLoad] = useState(false);
  const openEntryIds = useSelector(getOpenEntryIds);
  const unretrievedEntryIds = useSelector(getUnfetchableEntryIds);
  const hasFinishLoadingEntries = useSelector(isInitialEntriesLoaded);
  const displayUnretrievedModal = unretrievedEntryIds && unretrievedEntryIds.length > 0;
  const [query, setQuery] = useQueryParams({
    entries: hyphenArrayParam,
  });

  // Open and load entries from the url ids query params
  // Only executed on mount not on subsequent update
  useEffect(() => {
    dispatch(openEntries(query.entries));
    setNotIsInitialLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the url ids query params when shownEntryIds is updated
  // This keep in sync the url and the state of the app
  useEffect(() => {
    if (isNotInitialLoad) {
      if (openEntryIds.length > 0) {
        setQuery({ entries: [...openEntryIds] });
      } else {
        setQuery({ entries: null });
      }
    }
  }, [setQuery, openEntryIds, isNotInitialLoad]);

  const closeUnretreivedEntryIds = (e) => {
    e.preventDefault();
    dispatch(resetUnfetchableEntries());
  };

  return (
    <>
      {hasFinishLoadingEntries && <Entries />}
      {hasFinishLoadingEntries && displayUnretrievedModal && (
        <Modal
          title={t('entries.unretrieved.modal.title')}
          actions={<Button onClick={closeUnretreivedEntryIds}>{t('actions.ok')}</Button>}
        >
          {t('entries.unretrieved.modal.content', { count: unretrievedEntryIds.length })}
          <b>{getFormattedList(unretrievedEntryIds, t('words.comma'), t('words.and'))}</b>
        </Modal>
      )}
      <WordPrintModal />
      <MiniMap />
    </>
  );
};

export default EntriesManager;
