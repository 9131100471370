import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { get } from 'lodash/fp';
import useOnCancel from 'hooks/useOnCancel';
import SearchDataService from 'services/SearchDataService';
import { makeCancelable } from 'utils/util';
import { preventEventDefaultAndStopPropagation } from './eventUtils';
import AutoComplete from './Autocomplete';
import './LinkEditor.scss';

const renderEntryItem = (entry) => {
  return (
    <div
      className="link-editor-autocomplete-item"
      data-published={entry.published}
      onMouseDown={preventEventDefaultAndStopPropagation}
    >
      <div dangerouslySetInnerHTML={{ __html: entry.transliteration }} />
      <div dangerouslySetInnerHTML={{ __html: entry.mainNuance }} />
    </div>
  );
};

let cancelablePromise;

const LinkEditor = ({ type, value, onConfirm, onCancel }) => {
  const { i18n } = useTranslation();
  const [entries, setEntries] = useState([]);
  const componentRef = useRef();

  const handleAutoCompleteChange = useCallback(
    (search) => {
      cancelablePromise = makeCancelable(
        SearchDataService.searchByTransliteration({ locale: i18n.resolvedLanguage, search, type })
      );
      cancelablePromise.promise
        .then(({ data }) => {
          setEntries(data.content);
        })
        .catch((reason) => {
          if (!reason.isCanceled) {
            setEntries([]);
          }
        });
    },
    [i18n.resolvedLanguage, type]
  );

  useEffect(() => {
    handleAutoCompleteChange(value);

    return function cleanup() {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, [handleAutoCompleteChange, value]);

  const handleSelect = useCallback(
    (entry) => {
      onConfirm(entry.id);
    },
    [onConfirm]
  );

  useOnCancel(onCancel);

  const handleConfirmOnClickOutside = useCallback(
    (e) => {
      if (
        !componentRef.current.contains(e.target) &&
        isNil(e.target.closest('.autocomplete-container'))
      ) {
        onCancel();
      }
    },
    [componentRef, onCancel]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleConfirmOnClickOutside, true);
    document.addEventListener('contextmenu', handleConfirmOnClickOutside, true);

    return function cleanup() {
      document.removeEventListener('mousedown', handleConfirmOnClickOutside, true);
      document.removeEventListener('contextmenu', handleConfirmOnClickOutside, true);
    };
  }, [handleConfirmOnClickOutside]);

  return (
    <div className="link-editor" ref={componentRef}>
      <AutoComplete
        initialInputValue={value}
        labelGetter={get('transliteration')}
        idGetter={get('id')}
        renderItem={renderEntryItem}
        items={entries}
        onChange={handleAutoCompleteChange}
        onSelect={handleSelect}
      />
    </div>
  );
};

LinkEditor.defaultProps = {
  value: '',
};

LinkEditor.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(['entry', 'title', 'individual']).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default LinkEditor;
