import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Cluster } from 'components/Layout';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Tag from 'components/Tag';
import './NatureSelector.scss';

const NATURE_TYPES = [
  'adjectif',
  'adverbe',
  'conjonction',
  'interjection',
  'particule',
  'preposition',
  'pronom',
  'substantif',
  'verbe',
  'incertain',
];

const NatureSelector = ({ natures, onSelect, onDeselect }) => {
  const { t } = useTranslation();

  const handleNatureToggle = (nature) => {
    if (natures.includes(nature)) {
      onDeselect(nature);
    } else {
      onSelect(nature);
    }
  };

  return (
    <div className="nature-selector">
      <Cluster align="center" space="var(--s0)">
        <DropdownMenu
          itemsCount={NATURE_TYPES.length}
          separatorIndexes={[9]}
          renderButton={(buttonProps) => (
            <DropdownButton {...buttonProps}>{t('entry.natures.label')}</DropdownButton>
          )}
        >
          {NATURE_TYPES.map((nature) => (
            <MenuItem
              key={nature}
              isSelectable
              isSelected={natures.includes(nature)}
              onClick={() => handleNatureToggle(nature)}
            >
              {t(`entry.natures.types.${nature}`)}
            </MenuItem>
          ))}
        </DropdownMenu>
        <Cluster space="var(--s-3)">
          {natures.map((nature) => (
            <Tag key={nature} onDelete={() => handleNatureToggle(nature)}>
              {t(`entry.natures.types.${nature}`)}
            </Tag>
          ))}
        </Cluster>
      </Cluster>
    </div>
  );
};

NatureSelector.defaultProps = {
  natures: [],
};

NatureSelector.propTypes = {
  natures: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
};

export default NatureSelector;
