import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  selectors as titleGraphiesSelectors,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  deleteGraphy,
} from 'slices/titleGraphies';
import GraphiesLayout from 'components/Graphies/GraphiesLayout';
import GraphyEdition from './GraphyEdition';

const GraphiesEdition = ({ titleId }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const graphies = useSelector((state) => titleGraphiesSelectors.getIdsByTitleId(state, titleId));

  const handleGraphySelection = (file) => {
    dispatch(uploadFileAndCreateGraphy(titleId, file));
  };

  const handleReplaceImage = (graphyId, file) => {
    dispatch(uploadFileAndUpdateGraphy(graphyId, file));
  };

  const handleDeleteGraphy = (graphyId) => {
    dispatch(deleteGraphy({ titleId, graphyId }));
  };

  return (
    <GraphiesLayout htmlFor="title" onSelect={handleGraphySelection}>
      {graphies &&
        graphies.map((graphyId) => (
          <GraphyEdition
            key={graphyId}
            titleId={titleId}
            graphyId={graphyId}
            dir={i18n.dir()}
            onDelete={handleDeleteGraphy}
            onReplace={handleReplaceImage}
          />
        ))}
    </GraphiesLayout>
  );
};

GraphiesEdition.propTypes = {
  titleId: PropTypes.number.isRequired,
};

export default GraphiesEdition;
