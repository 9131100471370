import React, { useRef, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { stopEventPropagation } from './eventUtils';
import './EditorPopover.scss';

const findIntrisicWidth = (node) => {
  if (node.clientWidth === 0) {
    return findIntrisicWidth(node.children[0]);
  }
  return node.clientWidth;
};

// eslint-disable-next-line complexity
const EditorPopover = ({ position, withOverlay, children }) => {
  const [leftOffset, setLeftOffset] = useState(0);
  const popoverRef = useRef();
  const popoverStyle = {
    top: position && `${position.top}px`,
    left: position && `${position.left - leftOffset}px`,
  };
  const portalRoot = document.querySelector('#root') || document.body;

  useLayoutEffect(() => {
    if (popoverRef.current) {
      const popoverWidth = findIntrisicWidth(popoverRef.current);

      if (position && position.left + popoverWidth > document.body.offsetWidth) {
        setLeftOffset(position.left + popoverWidth - document.body.offsetWidth);
      }
    }
  }, [position, children]);

  if (!position) {
    return null;
  }

  if (withOverlay) {
    return ReactDOM.createPortal(
      <div
        className="editor-popover-overlay"
        onMouseDown={stopEventPropagation}
        onMouseUp={stopEventPropagation}
        onDoubleClick={stopEventPropagation}
      >
        <div
          className="editor-popover"
          ref={popoverRef}
          onMouseDown={stopEventPropagation}
          onClick={stopEventPropagation}
          style={popoverStyle}
        >
          {children}
        </div>
      </div>,
      portalRoot
    );
  }

  return ReactDOM.createPortal(
    <div
      className="editor-popover"
      ref={popoverRef}
      onMouseDown={stopEventPropagation}
      onMouseUp={stopEventPropagation}
      onClick={stopEventPropagation}
      onDoubleClick={stopEventPropagation}
      style={popoverStyle}
    >
      {children}
    </div>,
    portalRoot
  );
};

EditorPopover.defaultProps = {
  withOverlay: true,
  position: null,
};

EditorPopover.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
  withOverlay: PropTypes.bool,
};

export default EditorPopover;
