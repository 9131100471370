import PropTypes from 'prop-types';
import './CounterFlag.scss';

const CounterFlag = ({ children }) => {
  return <div className="counter-flag">{children}</div>;
};

CounterFlag.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CounterFlag;
