import axios from 'axios';

class DictionaryReferencesDataService {
  constructor(parentKeyName) {
    this.parentKeyName = parentKeyName || 'entryId';
  }

  get(parentId, isEditVersion) {
    return axios.get(
      `/dictionary-references?edited=${isEditVersion}&${this.parentKeyName}=${parentId}`
    );
  }

  createOrUpdate(dictionaryReferences) {
    return axios.post('/dictionary-references', dictionaryReferences);
  }

  unpublish(dictionaryReferences) {
    return axios.post('/dictionary-references/unpublish', dictionaryReferences);
  }

  publish(dictionaryReferences) {
    return axios.post('/dictionary-references/publish', dictionaryReferences);
  }
}

export default DictionaryReferencesDataService;
