import React from 'react';
import PropTypes from 'prop-types';
import './TlaLinkDecorator.scss';

const PnmLinkDecorator = ({ decoratedText, children }) => {
  return (
    <a
      className="pnm-link-decorator"
      href={`https://pnm.uni-mainz.de/title/${decoratedText}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

PnmLinkDecorator.propTypes = {
  decoratedText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PnmLinkDecorator;
