import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Input from 'components/Input';
import EntryCreationToolbar from './EntryCreationToolbar';
import './EntryCreation.scss';

const EntryCreation = ({ onSave, onClose }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => dispatch(onSave({ data }));
  const onError = (errors, e) => console.log(errors, e);

  return (
    <div className={classNames('entry', 'entry-creation')} data-testid="entry-creation">
      <EntryCreationToolbar onSave={onSubmit} onClose={onClose} disableSave={!formState.isValid} />
      <div className="entry-content">
        <div data-publishable>
          <Input
            italic
            serif
            size="large"
            name="edited.transliteration"
            placeholder={t('entry.transliteration')}
            register={register}
            required
            onBlur={handleSubmit(onSubmit, onError)}
          />
        </div>
      </div>
    </div>
  );
};

EntryCreation.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EntryCreation;
