import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SearchDataService from 'services/SearchDataService';
import { makeCancelable } from 'utils/util';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import Transliteration from 'components/Transliteration';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { preventEventDefaultAndStopPropagation } from './eventUtils';
import './LinkPopover.scss';

const LinkPopover = ({ id, type, entityKey, onOpen, onClose, onDelete }) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState(null);
  const componentRef = useRef();

  const handleOpenLink = useCallback(
    (evt) => {
      evt.stopPropagation();
      onOpen(id, !data.published);
      onClose();
    },
    [id, data, onOpen, onClose]
  );

  const handleDeleteLink = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      onDelete(entityKey);
    },
    [onDelete, entityKey]
  );

  useEffect(() => {
    let cancelablePromise;
    cancelablePromise = makeCancelable(
      SearchDataService.getSummary(id, i18n.resolvedLanguage, type)
    );
    cancelablePromise.promise
      .then(({ data: d }) => {
        setData(d);
      })
      .catch((reason) => {
        if (!reason.isCanceled) {
          setData(null);
        }
      });

    return function cleanup() {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, [i18n.resolvedLanguage, id, type]);

  const handleClickOutside = useCallback(
    (e) => {
      if (!componentRef.current.contains(e.target)) {
        onClose();
      }
    },
    [componentRef, onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('contextmenu', handleClickOutside, true);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('contextmenu', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div className="link-popover" ref={componentRef}>
      {data && (
        <div
          data-id={id}
          onMouseDown={preventEventDefaultAndStopPropagation}
          onClick={handleOpenLink}
        >
          <Transliteration>{data.transliteration}</Transliteration>
          <div dangerouslySetInnerHTML={{ __html: data.mainNuance }}></div>
        </div>
      )}
      <IconButton
        onMouseDown={preventEventDefaultAndStopPropagation}
        onClick={handleDeleteLink}
        theme="transparent"
      >
        <Icon size={22}>
          <TrashIcon />
        </Icon>
      </IconButton>
    </div>
  );
};

LinkPopover.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['entry', 'title', 'individual']).isRequired,
  entityKey: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LinkPopover;
