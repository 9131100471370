import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Cluster } from 'components/Layout';
import { ReactComponent as ShiftIcon } from './icon-shift-key.svg';
import './TransliterationKeyboard.scss';

// ȝ, j, y, ʿ, w, b, p, f, m, n, r, h, ḥ
const lineTopKeys = [
  '\u021d',
  'j',
  'y',
  '\u02bf',
  'w',
  'b',
  'p',
  'f',
  'm',
  'n',
  'r',
  'h',
  '\u1e25',
];
// ḫ, ẖ, s, š, q, k, g, t, ṯ, d, ḏ
const lineBottomKeys = [
  '\u1e2b',
  '\u1e96',
  's',
  '\u0161',
  'q',
  'k',
  'g',
  't',
  '\u1e6f',
  'd',
  '\u1e0f',
];
// Ȝ, J, ʿ, W, B, P, F, M, N, R, H, Ḥ
const lineTopMajKeys = [
  '\u021c',
  'J',
  'Y',
  '\u02bf',
  'W',
  'B',
  'P',
  'F',
  'M',
  'N',
  'R',
  'H',
  '\u1e24',
];
// Ḫ, H̱, S, Š, Q, K, G, T, Ṯ, D, Ḏ
const lineBottomMajKeys = [
  '\u1e2a',
  '\u0048\u0331',
  'S',
  '\u0160',
  'Q',
  'K',
  'G',
  'T',
  '\u1e6e',
  'D',
  '\u1e0e',
];

const TransliterationKeyboard = ({ onKeyClick }) => {
  const { i18n } = useTranslation();
  const [isMaj, setIsMaj] = useState(false);
  const lineTop = isMaj ? lineTopMajKeys : lineTopKeys;
  const lineBottom = isMaj ? lineBottomMajKeys : lineBottomKeys;

  const handleKeyMouseDown = (e) => {
    e.preventDefault();
    setIsMaj(false);
    onKeyClick(e.target.value);
  };

  const handleClickAlt = (e) => {
    e.preventDefault();
    setIsMaj(!isMaj);
  };

  return (
    <div
      className="transliteration-keyboard"
      data-testid="transliteration-keyboard"
      dir={i18n.dir()}
    >
      <Cluster space="var(--s-6)">
        {lineTop.map((key) => (
          <button
            key={key}
            type="button"
            className="transliteration-keyboard-key"
            data-testid="transliteration-key"
            onMouseDown={handleKeyMouseDown}
            value={key}
          >
            {key}
          </button>
        ))}
      </Cluster>
      <Cluster space="var(--s-6)">
        {lineBottom.map((key) => (
          <button
            key={key}
            type="button"
            className="transliteration-keyboard-key"
            data-testid="transliteration-key"
            onMouseDown={handleKeyMouseDown}
            value={key}
          >
            {key}
          </button>
        ))}
        <button
          type="button"
          className="transliteration-keyboard-key"
          data-active={isMaj}
          onMouseDown={handleClickAlt}
          data-testid="shift-key"
        >
          <ShiftIcon />
        </button>
      </Cluster>
    </div>
  );
};

TransliterationKeyboard.propTypes = {
  onKeyClick: PropTypes.func.isRequired,
};

export default TransliterationKeyboard;
