import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Cluster, Stack } from 'components/Layout';
import { SOURCE_TYPES } from 'utils/constants';
import { createSource } from 'slices/sources';
import Editor from 'components/Editor';
import Input from 'components/Input';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import PeriodsSelector from 'components/PeriodsSelector';
import SourceIcon from './SourceIcon';
import './SourceForm.scss';

let newSource = {};

const SourceNewForm = ({ type, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState } = useForm();

  const handleUpdate = (value) => {
    newSource = { ...newSource, ...value };
  };

  const onSubmit = (data) => {
    dispatch(createSource({ type, ...data, ...newSource }));
    onCancel();
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <form className="source-form" onSubmit={handleSubmit(onSubmit, onError)} dir="auto">
      <Stack space="var(--s0)">
        <Cluster align="center" space="var(--s-2)">
          <SourceIcon type={type} />
          <Input
            serif
            name="title"
            placeholder={t(`source.types.${type}`)}
            required
            register={register}
          />
        </Cluster>
        <Input
          size="extra-small"
          name="inventoryNumber"
          placeholder={t('source.inventoryNumber')}
          register={register}
        />
        <PeriodsSelector onChange={(periods) => handleUpdate({ periods })} />
        <Checkbox
          label={t(`source.isPeriodUncertain`)}
          size="small"
          {...register('isPeriodUncertain')}
        />
        <Checkbox label={t(`source.hieratic`)} size="small" {...register('hieratic')} />
        <Input
          serif
          label={t(`source.origin`)}
          size="extra-small"
          layout="horizontal"
          name="origin"
          placeholder={t('source.originPlaceholder')}
          register={register}
        />
        <div
          className="input-container input-container--borderless is-serif"
          data-size="extra-small"
        >
          <label>{t('source.bibliography')}</label>
          <Editor
            saveMode="blur"
            placeholder="…"
            onValidate={(value) => handleUpdate({ bibliography: value })}
          />
        </div>
        <Input
          label={t('source.webography')}
          size="extra-small"
          multiline
          name="webography"
          register={register}
        />
        <Input
          label={t('source.webographyConsultedAt')}
          size="extra-small"
          layout="horizontal"
          name="webographyConsultedAt"
          register={register}
        />
        <Input
          size="extra-small"
          name="karnakId"
          layout="horizontal"
          label="KIU"
          placeholder="123"
          register={register}
        />

        <Cluster justify="space-between" className="source-form-toolbar">
          <Button size="small" onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          {formState.isValid && (
            <Button type="submit" size="small" theme="green">
              {t('actions.create')}
            </Button>
          )}
        </Cluster>
      </Stack>
    </form>
  );
};

SourceNewForm.propTypes = {
  type: PropTypes.oneOf(Object.values(SOURCE_TYPES)).isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SourceNewForm;
