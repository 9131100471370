import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children, space }) => {
  return (
    <div className="section" style={{ marginBottom: space }}>
      {children}
    </div>
  );
};

Section.defaultProps = {
  space: 'var(--s0)',
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.string,
};

export default Section;
