import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Cluster } from 'components/Layout';
import {
  selectors as individualNameGraphiesSelectors,
  deleteGraphy,
  updateGraphy,
  uploadFileAndUpdateGraphy,
} from 'slices/individualNameGraphies';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as SandwichIcon } from 'images/icon-sandwich.svg';
import { GraphyPublicStatusSelector } from 'components/PublicStatus';
import DropdownMenu from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Graphy from 'components/Graphy';
import Icon from 'components/Icon';

const IndividualNameGraphyEdition = ({ nameId, graphyId, dir }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const graphyRef = useRef();
  const graphy = useSelector((state) => individualNameGraphiesSelectors.getById(state, graphyId));
  const [isPublishable] = usePublishable(graphy);

  const handlePublicStatusUpdate = (newPublicStatus) => {
    dispatch(updateGraphy({ ...graphy, publicStatus: newPublicStatus }));
  };

  const handleDeleteGraphy = () => {
    dispatch(deleteGraphy({ nameId, graphyId }));
  };

  const handleReplaceImage = (evt) => {
    if (graphyRef.current) {
      // We trigger a click here to close the graphy menu
      // that needed to stay open when selecting the new image
      graphyRef.current.click();
    }
    dispatch(uploadFileAndUpdateGraphy(graphy.id, evt.target.files[0]));
  };

  if (!graphy.edited) {
    return null;
  }

  return (
    <div className="graphy-container" ref={graphyRef}>
      <Cluster
        space="var(--s-1)"
        justify="flex-end"
        className="entry-graphy-toolbar"
        data-publishable="false"
      >
        <GraphyPublicStatusSelector
          status={graphy.publicStatus}
          position="right"
          onSelect={handlePublicStatusUpdate}
        />

        <DropdownMenu
          position="right"
          itemsCount={3}
          renderButton={(buttonProps) => (
            <button className="graphy-menu-button" type="button" {...buttonProps}>
              <Icon size={16}>
                <SandwichIcon />
              </Icon>
            </button>
          )}
          ignoreSelectors=".graphy-menu-file-input"
        >
          <MenuItem onClick={() => {}}>
            <label className="graphy-menu-file-input">
              {t('actions.replaceImage')}
              <input
                onChange={handleReplaceImage}
                id="replaceGraphy"
                name="replaceGraphy"
                type="file"
                accept=".svg"
                multiple={false}
              />
            </label>
          </MenuItem>
          <MenuItem onClick={handleDeleteGraphy}>{t('actions.remove')}</MenuItem>
        </DropdownMenu>
      </Cluster>

      <Graphy url={graphy.edited.url} dir={dir} isPublishable={isPublishable('url')} />
    </div>
  );
};

IndividualNameGraphyEdition.propTypes = {
  nameId: PropTypes.number.isRequired,
  graphyId: PropTypes.number.isRequired,
  dir: PropTypes.string,
};

export default IndividualNameGraphyEdition;
