import graphiesFactory from './graphiesFactory';
import GraphiesDataService from 'services/GraphiesDataService';

const graphiesDataService = new GraphiesDataService('individualId');

const extraReducers = {
  'individual/delete/fulfilled': (state, action) => {
    const graphyIdsToRemove = state.byIndividualId[action.payload.individualId] || [];
    graphyIdsToRemove.forEach((id) => delete state.byId[id]);
    delete state.byIndividualId[action.payload.individualId];
  },
};

const { reducer, actions, selectors } = graphiesFactory(
  'individualGraphies',
  'individual',
  extraReducers,
  graphiesDataService
);

const {
  unpublishEntryGraphies: unpublishIndividualGraphies,
  publishEntryGraphies: publishIndividualGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfEntry: resetGraphiesOfIndividual,
  resetGraphy,
} = actions;

export {
  reducer,
  unpublishIndividualGraphies,
  publishIndividualGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfIndividual,
  resetGraphy,
  selectors,
};
