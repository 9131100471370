import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  isInSelectionMode,
  resetInteractionMode,
  isSelectionModeTriggeredForWordGraphyAttestation,
  isSelectionModeTriggeredForSource,
  isSelectionModeTriggeredForTitleGraphyAttestation,
} from 'slices/ui/thesaurus';
import SelectionBanner from './SelectionBanner';
import './ThesaurusLayout.scss';

const ThesaurusLayout = ({ header, children, backgroundColor }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const showSelectionBanner = useSelector(isInSelectionMode);
  const shouldSelectWordGraphyAttestation = useSelector(
    isSelectionModeTriggeredForWordGraphyAttestation
  );
  const shouldSelectTitleGraphyAttestation = useSelector(
    isSelectionModeTriggeredForTitleGraphyAttestation
  );
  const shouldSelectSource = useSelector(isSelectionModeTriggeredForSource);

  const handleCancelInteractionMode = useCallback(() => {
    dispatch(resetInteractionMode());
  }, [dispatch]);

  return (
    <aside className="thesaurus" data-testid="thesaurus" dir={i18n.dir()}>
      {showSelectionBanner && (
        <SelectionBanner onCancel={handleCancelInteractionMode}>
          {(shouldSelectWordGraphyAttestation || shouldSelectTitleGraphyAttestation) &&
            t('graphy.selectAttestation')}
          {shouldSelectSource && t('attestation.selectSource')}
        </SelectionBanner>
      )}
      <header className="thesaurus-header">{header}</header>
      <div className="thesaurus-content" style={{ backgroundColor }}>
        {children}
      </div>
    </aside>
  );
};

ThesaurusLayout.defaultProps = {
  backgroundColor: null,
};

ThesaurusLayout.propTypes = {
  header: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ThesaurusLayout;
