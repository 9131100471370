import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Section from 'components/Section';
import EntryButton from './EntryButton';

const EntryCreationToolBar = ({ disableSave, onClose, onSave }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(onClose());
  };

  return (
    <Section space="var(--s-2)">
      <div className="entry-header entry-creation-header">
        <div className="entry-header-right">
          <EntryButton
            data-testid="create-entry-save-button"
            onClick={onSave}
            disabled={disableSave}
          >
            {t('actions.create')}
          </EntryButton>
          <EntryButton data-testid="create-entry-close-button" onMouseDown={onClick}>
            {t('actions.cancel')}
          </EntryButton>
        </div>
      </div>
    </Section>
  );
};

EntryCreationToolBar.propTypes = {
  disableSave: false,
};

EntryCreationToolBar.propTypes = {
  disableSave: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EntryCreationToolBar;
