import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadWord } from 'slices/words';
import LinkPopover from './LinkPopover';

const EntryLinkPopover = ({ entityKey, entryId, onClose, onDelete }) => {
  const dispatch = useDispatch();

  const handleOpeningEntry = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadWord(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkPopover
      entityKey={entityKey}
      type="entry"
      id={entryId}
      onOpen={handleOpeningEntry}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

EntryLinkPopover.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entryId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EntryLinkPopover;
