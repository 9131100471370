import axios from 'axios';

class WordsDataService {
  get(id, isEditVersion) {
    return axios.get(`/entries/${id}?edited=${isEditVersion}`);
  }

  createOrUpdate(entry) {
    return axios.post('/entries', entry);
  }

  /**
   * Require the last user the entry is attributed to.
   * @param {id} being the identifier of the VÉgA entry
   * @returns
   */
  getLastAttribution(id) {
    return axios.get(`/entries/${id}/attribution`);
  }

  /**
   * Locks a given VÉgA entry so that no other user can edit it.
   * @param {entryId} being the identifier of the locked VÉgA entry
   * @returns
   */
  lock(entryId) {
    return axios.put(`/entries/${entryId}/lock`);
  }

  /**
   * Unlocks a given VÉgA entry so that another user can edit it.
   * @param {entryId} being the identifier of the unlocked VÉgA entry
   * @returns
   */
  unlock(entryId) {
    return axios.put(`/entries/${entryId}/unlock`);
  }

  /**
   * Publish a given VÉgA entry.
   * @param {entry} being the main data of the entry to publish (i.e. not the grahies,
   * dictionnaries or other modules).
   * @returns the new entry data or a 404 error.
   */
  publish(entry) {
    return axios.post('/entries/publish', entry);
  }

  /**
   * Unpublish a given VÉgA entry.
   * @param {entry} being the main data of the entry to unpublish (i.e. not the grahies,
   * dictionnaries or other modules).
   * @returns the new entry data (indicating it is not published) or a 404 error.
   */
  unpublish(entry) {
    return axios.post('/entries/unpublish', entry);
  }

  delete(entryId) {
    return axios.delete(`/entries/${entryId}`);
  }
}

export default new WordsDataService();
