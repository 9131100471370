import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors as wordGraphiesSelectors } from 'slices/wordGraphies';
import GraphiesLayout from 'components/Graphies/GraphiesLayout';
import GraphyRead from './GraphyRead';
import './Graphies.scss';

const Graphies = ({ entryId, publishableProp, ...rest }) => {
  const { i18n } = useTranslation();
  const graphies = useSelector((state) => wordGraphiesSelectors.getIdsByEntryId(state, entryId));

  return (
    <GraphiesLayout>
      {graphies &&
        graphies.map((graphyId, pos) => (
          <GraphyRead
            key={pos}
            graphyId={graphyId}
            publishableProp={publishableProp}
            dir={i18n.dir()}
            {...rest}
          />
        ))}
    </GraphiesLayout>
  );
};

Graphies.propTypes = {
  entryId: PropTypes.number.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
};

export default Graphies;
