import { constant, get, isNil, upperFirst } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNotEmptyHtml } from 'utils/util';
import { logout } from './auth';
import { ENTRY_STATE, resetEntryState, setEntryState } from 'slices/ui/entryLoading';

// ------------------------------------
// Selectors
// ------------------------------------

const getSelectors = (name, index, byIndexId) => {
  const sliceSelector = (state) => state[name];
  const indexBySliceSelector = (state) => sliceSelector(state)[byIndexId];

  const getDictionaryReferencesByEntryId = (state, entryId) => indexBySliceSelector(state)[entryId];

  const doesEntryHaveDictionaryRefs = (state, entryId, publishableProp) => {
    const dictionaryRefs = getDictionaryReferencesByEntryId(state, entryId);
    const dictionaryReferencesVersions = get(
      dictionaryRefs,
      `${publishableProp}.dictionaryReferences`,
      null
    );
    const otherReferencesVersions = get(dictionaryRefs, `${publishableProp}.otherReferences`, null);
    if (isNil(dictionaryReferencesVersions) && isNil(otherReferencesVersions)) {
      return false;
    }
    return (
      (!isNil(dictionaryReferencesVersions) &&
        Object.values(dictionaryReferencesVersions).some(isNotEmptyHtml)) ||
      isNotEmptyHtml(otherReferencesVersions)
    );
  };

  return {
    [`get${upperFirst(byIndexId)}`]: getDictionaryReferencesByEntryId,
    [`does${upperFirst(index)}HaveOne`]: doesEntryHaveDictionaryRefs,
  };
};

// ------------------------------------
// Actions
// ------------------------------------

const getActions = (
  name,
  indexIdKey,
  byIndexId,
  selectors,
  sliceActions,
  dictionaryReferencesDataService
) => {
  const { resetDictionaryReferencesOfEntry } = sliceActions;

  const actionName = (action) => `${name}/${action}`;
  const getIdsByIndexId = selectors[`get${upperFirst(byIndexId)}`];

  const loadDictionaryReferences = createAsyncThunk(actionName('load'), async (arg) => {
    const res = await dictionaryReferencesDataService.get(arg[indexIdKey], arg.isEditVersion);
    return res.data;
  });

  const unpublishEntryDictionaryReferences = createAsyncThunk(
    actionName('unpublish'),
    async (entryId, { getState }) => {
      const dictionaryReferences = getIdsByIndexId(getState(), entryId);
      const res = await dictionaryReferencesDataService.unpublish(dictionaryReferences);
      return res.data;
    }
  );

  const publishEntryDictionaryReferences = createAsyncThunk(
    actionName('publish'),
    async (entryId, { getState }) => {
      const dictionaryReferences = getIdsByIndexId(getState(), entryId);
      const res = await dictionaryReferencesDataService.publish(dictionaryReferences);
      return res.data;
    }
  );

  const createOrUpdateDictionaryReferences = createAsyncThunk(
    actionName('createOrUpdate'),
    async (dictionaryReferences, { dispatch }) => {
      dispatch(
        setEntryState({ entryId: dictionaryReferences.entryId, entryState: ENTRY_STATE.saving })
      );

      const res = await dictionaryReferencesDataService.createOrUpdate(dictionaryReferences);

      dispatch(resetEntryState({ entryId: dictionaryReferences.entryId }));

      return res.data;
    }
  );

  return {
    resetDictionaryReferencesOfEntry,
    loadDictionaryReferences,
    unpublishEntryDictionaryReferences,
    publishEntryDictionaryReferences,
    createOrUpdateDictionaryReferences,
  };
};

function dictionaryReferencesFactory(
  name,
  indexBy,
  extraReducers,
  dictionaryReferencesDataService
) {
  const Index = upperFirst(indexBy);
  const byIndexId = `by${Index}Id`;
  const indexIdKey = `${indexBy}Id`;
  const selectors = getSelectors(name, indexBy, byIndexId);

  const initialState = {
    [byIndexId]: {},
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      resetDictionaryReferencesOfEntry: (state, { payload: entryId }) => {
        delete state[byIndexId][entryId];
      },
    },
    extraReducers: (builder) => {
      const replace = (state, action) => {
        state[byIndexId][action.payload[indexIdKey]] = action.payload;
      };

      builder
        .addCase(actions.loadDictionaryReferences.fulfilled, replace)
        .addCase(actions.createOrUpdateDictionaryReferences.fulfilled, replace)
        .addCase(actions.unpublishEntryDictionaryReferences.fulfilled, replace)
        .addCase(actions.publishEntryDictionaryReferences.fulfilled, replace)
        .addCase(logout.fulfilled, constant(initialState));

      Object.entries(extraReducers).forEach(([type, reducer]) => {
        builder.addCase(type, reducer);
      });
    },
  });

  const { reducer } = slice;

  const actions = getActions(
    name,
    indexIdKey,
    byIndexId,
    selectors,
    slice.actions,
    dictionaryReferencesDataService
  );

  return {
    reducer,
    selectors,
    actions,
  };
}
export default dictionaryReferencesFactory;
