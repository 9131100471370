import { useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];
const DEFAULT_OPTIONS = { preventDefaultOnIgnoreSelectors: false, capture: false };

const useOutsideClick = (handler, ignoreSelectors, options = DEFAULT_OPTIONS) => {
  const ref = useRef();

  useEffect(() => {
    const { capture = false, preventDefaultOnIgnoreSelectors = false } = options;

    const listener = (evt) => {
      const hasClickOnRef = ref.current && ref.current.contains(evt.target);
      const hasClickOnIgnoreSelectorsButNotRef = ignoreSelectors
        ? !hasClickOnRef && evt.target.closest(ignoreSelectors)
        : false;

      if (hasClickOnIgnoreSelectorsButNotRef && preventDefaultOnIgnoreSelectors) {
        evt.preventDefault();
      }

      if (!hasClickOnIgnoreSelectorsButNotRef && !hasClickOnRef) {
        handler(evt);
      }
    };

    DEFAULT_EVENTS.forEach((event) => document.addEventListener(event, listener, capture));

    return function cleanup() {
      DEFAULT_EVENTS.forEach((event) => document.removeEventListener(event, listener, capture));
    };
  }, [ref, handler, ignoreSelectors, options]);

  return ref;
};

export default useOutsideClick;
