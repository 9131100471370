import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTitleById, isTitlePublished } from 'slices/titles';
import EntryEdition from 'components/Entry/EntryEdition';
import TitleEditionContent from './TitleEditionContent';

const TitleEdition = ({
  id,
  isHighlighted,
  isPreviewed,
  onStopEdition,
  onPublish,
  onUnpublish,
  onClose,
  onDelete,
  onPreview,
}) => {
  const entry = useSelector((state) => getTitleById(state, id));
  const showUnpublishButton = useSelector((state) => isTitlePublished(state, id));

  return (
    <EntryEdition
      id={id}
      isHighlighted={isHighlighted}
      isPreviewed={isPreviewed}
      isPublished={!!entry?.published}
      showUnpublishButton={showUnpublishButton}
      onStopEdition={onStopEdition}
      onPublish={onPublish}
      onUnpublish={onUnpublish}
      onClose={onClose}
      onDelete={onDelete}
      onPreview={onPreview}
    >
      {entry && entry.edited && <TitleEditionContent entry={entry} />}
    </EntryEdition>
  );
};

TitleEdition.defaultProps = {
  isHighlighted: false,
  isPreviewed: false,
};

TitleEdition.propTypes = {
  isHighlighted: PropTypes.bool,
  isPreviewed: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onStopEdition: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TitleEdition;
