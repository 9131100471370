import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  selectors as individualCommentsSelectors,
  createOrUpdateComment,
} from 'slices/individualComments';
import usePublishable from 'hooks/usePublishable';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import CommentsEditor from 'components/CommentsEditor';

const IndividualComments = ({ individualId, isEditVersion, publishableProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comment = useSelector((state) =>
    individualCommentsSelectors.getByIndividualId(state, individualId)
  );
  const [isPublishable] = usePublishable(comment);

  const handleCommentUpdate = useCallback(
    (newCommentText) => {
      return dispatch(
        createOrUpdateComment({ ...comment, individualId, edited: { text: newCommentText } })
      );
    },
    [dispatch, comment, individualId]
  );

  return (
    <EntryTabContentLayout
      title={t('comments.title')}
      isEditVersion={isEditVersion}
      isPublishable={isEditVersion && isPublishable('text')}
    >
      {(comment || isEditVersion) && (
        <CommentsEditor
          value={get(comment, `${publishableProp}.text`, null)}
          isEditVersion={isEditVersion}
          titleLinkEnable
          individualLinkEnable
          onUpdate={handleCommentUpdate}
        />
      )}
    </EntryTabContentLayout>
  );
};

IndividualComments.defaultProps = {
  publishableProp: 'edited',
};

IndividualComments.propTypes = {
  individualId: PropTypes.number.isRequired,
  isEditVersion: PropTypes.bool.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']),
};

export default IndividualComments;
