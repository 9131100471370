import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { preventEventDefault } from 'components/Editor/eventUtils';

const GraphyDropzone = ({ id, onSelect }) => {
  const { t } = useTranslation();
  const handleGraphySelection = useCallback(
    (evt) => {
      evt.preventDefault();
      if (evt.dataTransfer && evt.dataTransfer.files && evt.dataTransfer.files[0]) {
        onSelect(evt.dataTransfer.files[0]);
      } else if (evt.target && evt.target.files && evt.target.files[0]) {
        onSelect(evt.target.files[0]);
      }
    },
    [onSelect]
  );

  return (
    <div
      className="graphy-dropzone"
      onDragOver={preventEventDefault}
      onDragEnter={preventEventDefault}
      onDrop={handleGraphySelection}
    >
      <label htmlFor={id}>
        {t('graphies.addOrDrop')}
        <input
          onChange={handleGraphySelection}
          id={id}
          name="graphy-dropzone"
          type="file"
          accept=".svg"
          multiple={false}
        />
      </label>
    </div>
  );
};

GraphyDropzone.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default GraphyDropzone;
