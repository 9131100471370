import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  deleteIndividualRelative,
  getIndividualRelativeById,
  isIndividualRelativePublishable,
  updateIndividualRelative,
} from 'slices/individualRelatives';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import { Cluster } from 'components/Layout';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Transliteration from 'components/Transliteration';
import { RelativePublicStatusSelector } from 'components/PublicStatus';
import './IndividualRelative.scss';

const RELATION_TYPES = ['undefined', 'daughter', 'son', 'mother', 'father'];

const IndividualRelativeEdition = ({ id, individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const relative = useSelector((state) => getIndividualRelativeById(state, id));
  const hasDataToPublished = useSelector((state) => isIndividualRelativePublishable(state, id));
  const [isPublishable] = usePublishable(relative);

  const handleRelativePublicStatusUpdate = (newRelativePublicStatus) => {
    dispatch(updateIndividualRelative({ id, data: { publicStatus: newRelativePublicStatus } }));
  };

  const handleRelativeTypeUpdate = (newRelativeType) => {
    dispatch(updateIndividualRelative({ id, data: { edited: { type: newRelativeType } } }));
  };

  const handleRemove = () => {
    dispatch(deleteIndividualRelative({ id, individualId }));
  };

  return (
    <div className="individual-relative--edition" data-publishable={hasDataToPublished}>
      <Cluster justify="space-between" align="center">
        <Cluster align="center" space="var(--s-1)">
          <div data-publishable={isPublishable('type')}>
            <DropdownMenu
              itemsCount={RELATION_TYPES.length}
              renderButton={(buttonProps) => (
                <DropdownButton {...buttonProps}>
                  {t(`individualRelatives.relationTypes.${relative.edited.type}`)}
                </DropdownButton>
              )}
            >
              {RELATION_TYPES.map((relationType) => (
                <MenuItem
                  key={relationType}
                  isSelectable
                  isSelected={relative.edited.type === relationType}
                  onClick={() => handleRelativeTypeUpdate(relationType)}
                >
                  {t(`individualRelatives.relationTypes.${relationType}`)}
                </MenuItem>
              ))}
            </DropdownMenu>
          </div>

          <RelativePublicStatusSelector
            onSelect={handleRelativePublicStatusUpdate}
            status={relative.publicStatus}
          />
        </Cluster>

        <IconButton
          onMouseDown={preventEventDefaultAndStopPropagation}
          onClick={handleRemove}
          theme="transparent"
          data-testid="remove-relative-from-individual-button"
        >
          <Icon size={22}>
            <TrashIcon />
          </Icon>
        </IconButton>
      </Cluster>

      <h2 className="individual-relative-transliteration">
        <Transliteration>{relative.related.transliteration}</Transliteration>
        {!relative.related.published && (
          <span className="individual-relative-transliteration-unpublished-label">
            {' '}
            ({t('words.unpublished')})
          </span>
        )}
      </h2>
    </div>
  );
};

IndividualRelativeEdition.propTypes = {
  id: PropTypes.number.isRequired,
  individualId: PropTypes.number.isRequired,
};

export default IndividualRelativeEdition;
