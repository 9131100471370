import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { NUANCE_PUBLIC_STATUS, PROBLEMATIC_NUANCE_COMMENT } from 'utils/constants';
import { getAllPeriodsFromAttestationIds } from 'slices/attestations';
import { selectors as wordGraphiesSelectors } from 'slices/wordGraphies';
import { NuancePublicStatus } from 'components/PublicStatus';
import Accordion from 'components/Accordion';
import Editor from 'components/Editor';
import Graphy from 'components/Graphy';
import CounterFlag from 'components/CounterFlag';
import GraphyAttestation from 'components/Graphy/GraphyAttestation';
import { PeriodsOverview } from 'components/Periods';

const AttestationGraphy = ({ graphyId, publishableProp, dir }) => {
  const graphy = useSelector((state) => wordGraphiesSelectors.getById(state, graphyId));

  return (
    <Graphy
      url={graphy[publishableProp].url}
      publicStatus={graphy.publicStatus}
      height={35}
      dir={dir}
    />
  );
};

const Nuance = ({ description, attestations, publishableProp, dir, ...rest }) => {
  const nuancePeriods = useSelector((state) =>
    getAllPeriodsFromAttestationIds(state, attestations.map(get('attestationId')))
  );
  return (
    <div className="nuance-container">
      <Accordion
        summary={
          <div className="nuance-summary">
            <div className="nuance-summary-content">
              <div className="nuance-summary-description">
                <Editor readOnly initialValue={description} onValidate={noop} />
              </div>
              <div className="nuance-summary-flags">
                {attestations && <CounterFlag>{attestations.length}</CounterFlag>}
                {description.includes(PROBLEMATIC_NUANCE_COMMENT) && (
                  <NuancePublicStatus status={NUANCE_PUBLIC_STATUS.problematic} hasTooltip />
                )}
              </div>
            </div>
            <PeriodsOverview selectedPeriods={nuancePeriods} />
          </div>
        }
        {...rest}
      >
        {attestations.map(({ attestationId, graphyId }) => (
          <div key={`nuance-attestation-${attestationId}-${graphyId}`}>
            <GraphyAttestation id={attestationId} />
            <AttestationGraphy graphyId={graphyId} publishableProp={publishableProp} dir={dir} />
          </div>
        ))}
      </Accordion>
    </div>
  );
};

Nuance.propTypes = {
  description: PropTypes.string.isRequired,
  attestations: PropTypes.array.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
  dir: PropTypes.string.isRequired,
};

export default Nuance;
