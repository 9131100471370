import React from 'react';
import PropTypes from 'prop-types';

function Transliteration({ children }) {
  return <span style={{ fontFamily: 'var(--ff-serif)', fontStyle: 'italic' }}>{children}</span>;
}

Transliteration.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Transliteration;
