import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Cluster } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyHtml } from 'utils/util';
import { createOrUpdateNuance, getNuanceByGraphyIdAndAttestationId } from 'slices/nuances';
import usePublishable from 'hooks/usePublishable';
import { NuancePublicStatusSelector } from 'components/PublicStatus';
import Editor from 'components/Editor';
import NuanceAutocomplete from './NuanceAutocomplete';

const stripTag = (originalString) => {
  if (!originalString) {
    return '';
  }
  return originalString.replace(/(<([^>]+)>)/gi, '');
};

const renderEntryItem = (suggestion) => {
  return (
    <div>
      <Editor initialValue={suggestion} readOnly />
    </div>
  );
};

const GraphyAttestationNuance = ({ graphyId, attestationId, suggestions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const nuance = useSelector((state) =>
    getNuanceByGraphyIdAndAttestationId(state, graphyId, attestationId)
  );
  const nuanceDescription = get(nuance, `edited.description`, null);
  const filteredSugestion = suggestions.filter((suggestion) => suggestion !== nuanceDescription);
  const [isPublishable] = usePublishable(nuance);
  const showCompletionList =
    suggestions &&
    filteredSugestion.length > 0 &&
    (isEmptyHtml(nuanceDescription) ||
      filteredSugestion.some((suggestion) =>
        stripTag(suggestion).startsWith(stripTag(nuanceDescription))
      ));

  const handleUpdateNuance = (data) => {
    if (nuance && nuance.id) {
      return dispatch(createOrUpdateNuance({ ...nuance, ...data }));
    } else {
      return dispatch(createOrUpdateNuance({ ...data, graphyId, attestationId }));
    }
  };

  const handlePublicStatusUpdate = (newPublicStatus) => {
    dispatch(createOrUpdateNuance({ ...nuance, publicStatus: newPublicStatus }));
  };

  const handleReplaceCurrentDescription = async (description) => {
    let response;
    setIsReadOnly(true);
    if (nuance && nuance.id) {
      response = await dispatch(createOrUpdateNuance({ ...nuance, edited: { description } }));
    } else {
      response = await dispatch(
        createOrUpdateNuance({ edited: { description }, graphyId, attestationId })
      );
    }
    setIsReadOnly(false);
    return response;
  };

  return (
    <div className="graphy-attestation-nuance" data-publishable={isPublishable('description')}>
      <Cluster justify="space-between" align="center" space="var(--s-3)">
        <label data-publishable="false">{t('attestation.nuance')}</label>
        {nuance && nuance.id && (
          <NuancePublicStatusSelector
            status={nuance.publicStatus}
            position="right"
            onSelect={handlePublicStatusUpdate}
          />
        )}
      </Cluster>
      <div className="graphy-attestation-nuance-description">
        <NuanceAutocomplete
          items={filteredSugestion}
          renderItem={renderEntryItem}
          isOpen={isFocus && showCompletionList}
          onSelect={handleReplaceCurrentDescription}
        >
          <div>
            <Editor
              placeholder="…"
              readOnly={isReadOnly}
              initialValue={nuanceDescription}
              onBlur={() => setIsFocus(false)}
              onFocus={() => setIsFocus(true)}
              onValidate={(value) => handleUpdateNuance({ edited: { description: value } })}
            />
          </div>
        </NuanceAutocomplete>
      </div>
    </div>
  );
};

GraphyAttestationNuance.defaultProps = {
  suggestions: null,
};

GraphyAttestationNuance.propTypes = {
  graphyId: PropTypes.number.isRequired,
  attestationId: PropTypes.number.isRequired,
  suggestions: PropTypes.array,
};

export default GraphyAttestationNuance;
