import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPeriodByValue } from 'slices/periods';
import './PeriodsLiteral.scss';

const PeriodsLiteral = ({ periods }) => {
  const { t } = useTranslation();
  const startPeriod = useSelector((state) => getPeriodByValue(state, periods ? periods[0] : null));
  const endPeriod = useSelector((state) =>
    getPeriodByValue(state, periods ? periods[periods.length - 1] : null)
  );

  if (!startPeriod) {
    return null;
  }

  return (
    <div className="periods-literal">
      {periods.length === 1 && startPeriod && (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('individual.livedUnder', {
                period: t(`periods.longName.${startPeriod.value}`),
              }),
            }}
          />
          {startPeriod.type === 'king' && (
            <span
              dangerouslySetInnerHTML={{
                __html: ` (${t(`periods.longName.${startPeriod.parentKey}`)})`,
              }}
            />
          )}
        </>
      )}
      {periods.length > 1 && endPeriod && (
        <span
          dangerouslySetInnerHTML={{
            __html: t('individual.livedFromTo', {
              startPeriod: `${t(`periods.longName.${startPeriod.value}`)}${
                startPeriod.type === 'king'
                  ? ` (${t(`periods.longName.${startPeriod.parentKey}`)})`
                  : ''
              }`,
              endPeriod: `${t(`periods.longName.${endPeriod.value}`)}${
                endPeriod.type === 'king'
                  ? ` (${t(`periods.longName.${endPeriod.parentKey}`)})`
                  : ''
              }`,
            }),
          }}
        />
      )}
    </div>
  );
};

PeriodsLiteral.propTypes = {
  periods: null,
};

PeriodsLiteral.propTypes = {
  periods: PropTypes.arrayOf(PropTypes.string),
};

export default PeriodsLiteral;
