import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import Editor from 'components/Editor';
import './CommentsEditor.scss';

const CommentsEditor = ({
  value,
  entryLinkEnable,
  titleLinkEnable,
  individualLinkEnable,
  isEditVersion,
  onUpdate,
}) => {
  const { t } = useTranslation();

  return (
    <div className="comments-editor">
      <Editor
        initialValue={value}
        readOnly={!isEditVersion}
        entryLinkEnable={entryLinkEnable}
        titleLinkEnable={titleLinkEnable}
        individualLinkEnable={individualLinkEnable}
        placeholder={t('comments.text')}
        onValidate={onUpdate}
      />
    </div>
  );
};

CommentsEditor.defaultProps = {
  value: null,
  isEditVersion: false,
  entryLinkEnable: false,
  titleLinkEnable: false,
  individualLinkEnable: false,
  onUpdate: noop,
};

CommentsEditor.propTypes = {
  value: PropTypes.string,
  isEditVersion: PropTypes.bool,
  entryLinkEnable: PropTypes.bool,
  titleLinkEnable: PropTypes.bool,
  individualLinkEnable: PropTypes.bool,
  onUpdate: PropTypes.func,
};

export default CommentsEditor;
