import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import './MenuItem.scss';

const MenuItem = React.forwardRef(
  ({ children, className, isSelectable, isActive, isSelected, ...props }, refProp) => {
    return (
      <button
        ref={refProp}
        type="button"
        className={classNames('menu-item', className)}
        data-active={isActive}
        data-selected={isSelected}
        {...props}
      >
        {isSelectable && <div className="menu-item-check">{isSelected && <CheckIcon />}</div>}
        {children}
      </button>
    );
  }
);

MenuItem.propTypes = {
  className: '',
  isActive: false,
  isSelectable: false,
  isSelected: false,
};

MenuItem.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MenuItem;
