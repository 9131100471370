import axios from 'axios';

class LoginDataService {
  user() {
    return axios.get('/users/auth');
  }
  logout() {
    return axios.get(`/users/logout`);
  }
}

export default new LoginDataService();
