import dictionaryReferencesFactory from './dictionaryReferencesFactory';
import DictionaryReferencesDataService from 'services/DictionaryReferencesDataService';

const dictionaryReferencesDataService = new DictionaryReferencesDataService('entryId');

const extraReducers = {
  'word/delete/fulfilled': (state, action) => {
    delete state.byEntryId[action.payload.entryId];
  },
};

const { reducer, actions, selectors } = dictionaryReferencesFactory(
  'wordDictionaryReferences',
  'entry',
  extraReducers,
  dictionaryReferencesDataService
);

const {
  loadDictionaryReferences,
  unpublishEntryDictionaryReferences,
  publishEntryDictionaryReferences,
  createOrUpdateDictionaryReferences,
  resetDictionaryReferencesOfEntry,
} = actions;

export {
  reducer,
  loadDictionaryReferences,
  unpublishEntryDictionaryReferences,
  publishEntryDictionaryReferences,
  createOrUpdateDictionaryReferences,
  resetDictionaryReferencesOfEntry,
  selectors,
};
