import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closePrintEntry } from 'slices/ui/printEntry';
import Button from 'components/Button';
import Modal from 'components/Modal';

const EntryPrintModal = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePrint = () => {
    window.print();
  };

  return (
    <Modal onClose={() => dispatch(closePrintEntry())} className="entry-print-modal">
      <div>
        <div className="entry-print-modal-print-button">
          <Button onClick={handlePrint}>{t('actions.print')}</Button>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default EntryPrintModal;
