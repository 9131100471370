import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, uniq } from 'lodash';
import { isNotNil } from 'utils/util';
import TitleHoldersDataService from 'services/TitleHoldersDataService';
import {
  selectors as titleGraphiesSelectors,
  deleteGraphy,
  loadGraphies,
  resetGraphy,
  updateGraphy,
} from './titleGraphies';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getTitleHolderIdsByTitleId = (state, titleId) =>
  state.titleHolders.byTitleId[titleId] || emptyArray;

const getTitleHolderById = (state, titleHolderId) => state.titleHolders.byId[titleHolderId];

const getTitleHolderIdsByGraphyIdAndAttestationId = (state, graphyId, attestationId) => {
  return get(
    state,
    `titleHolders.byGraphyIdAndAttestationId.${graphyId}.${attestationId}`,
    emptyArray
  );
};

const hasTitleHoldersByTitleId = (state, titleId) => {
  return (
    getTitleHolderIdsByTitleId(state, titleId).filter((id) =>
      isTitleHolderIndividualPublished(state, id)
    ).length > 0
  );
};

const isTitleHolderIndividualPublished = (state, titleHolderId) => {
  const titleHolder = getTitleHolderById(state, titleHolderId);
  return titleHolder.individual.published;
};

const isTitleHolderPublishable = (state, titleHolderId) => {
  const titleHolder = getTitleHolderById(state, titleHolderId);

  return !titleHolder.published;
};

// ------------------------------------
// Async actions
// ------------------------------------

const publishTitleHolders = (titleId) => {
  return (dispatch, getState) => {
    getTitleHolderIdsByTitleId(getState(), titleId).forEach((titleHolderId) => {
      dispatch(publishTitleHolder(getTitleHolderById(getState(), titleHolderId)));
    });
  };
};

const unpublishTitleHolders = (titleId) => {
  return (dispatch, getState) => {
    getTitleHolderIdsByTitleId(getState(), titleId).forEach((titleHolderId) => {
      dispatch(unpublishTitleHolder(getTitleHolderById(getState(), titleHolderId)));
    });
  };
};

const createTitleHolder = createAsyncThunk(
  'titleHolder/create',
  async (titleHolder, { getState }) => {
    const { titleId } = titleGraphiesSelectors.getById(getState(), titleHolder.graphyId);
    const res = await TitleHoldersDataService.createOrUpdate(titleHolder);
    return { titleId, titleHolder: res.data };
  }
);

const updateTitleHolder = createAsyncThunk(
  'titleHolder/update',
  async ({ id, data }, { getState }) => {
    const titleHolder = getTitleHolderById(getState(), id);
    const updatedTitleHolder = {
      ...titleHolder,
      ...data,
      edited: {
        ...titleHolder.edited,
        ...data.edited,
      },
    };
    const res = await TitleHoldersDataService.createOrUpdate(updatedTitleHolder);
    return res.data;
  }
);

const publishTitleHolder = createAsyncThunk('titleHolder/publish', async (titleHolder) => {
  const res = await TitleHoldersDataService.publish(titleHolder);
  return res.data;
});

const unpublishTitleHolder = createAsyncThunk('titleHolder/unpublish', async (titleHolder) => {
  const res = await TitleHoldersDataService.unpublish(titleHolder);
  return res.data;
});

const deleteTitleHolder = createAsyncThunk(
  'titleHolder/delete',
  async (titleHolderId, { getState }) => {
    const { graphyId, attestationId } = getTitleHolderById(getState(), titleHolderId);
    const { titleId } = titleGraphiesSelectors.getById(getState(), graphyId);
    await TitleHoldersDataService.delete(titleHolderId);
    return { titleId, graphyId, attestationId, id: titleHolderId };
  }
);

const initialState = {
  byTitleId: {},
  byGraphyIdAndAttestationId: {},
  byId: {},
};

const titleHoldersSlice = createSlice({
  name: 'titleHolders',
  initialState,
  reducers: {
    resetTitleHoldersOfTitle: (state, { payload: titleId }) => {
      const titleHolderIdsToRemove = state.byTitleId[titleId] || [];
      titleHolderIdsToRemove.forEach((id) => delete state.byId[id]);
      delete state.byTitleId[titleId];
    },
  },
  extraReducers: (builder) => {
    const replaceTitleHolder = (state, action) => {
      state.byId[action.payload.id] = action.payload;
    };

    const removeTitleHoldersOfGraphy = (state, action) => {
      const { graphyId, titleId } = action.payload;
      if (state.byGraphyIdAndAttestationId[graphyId]) {
        Object.values(state.byGraphyIdAndAttestationId[graphyId]).forEach((titleHolderId) => {
          delete state.byId[titleHolderId];
          if (state.byTitleId[titleId]) {
            state.byTitleId[titleId] = state.byTitleId[titleId].filter(
              (id) => id !== titleHolderId
            );
          }
        });
        delete state.byGraphyIdAndAttestationId[graphyId];
      }

      if (state.byTitleId[titleId]) {
        delete state.byTitleId[titleId];
      }
    };

    builder
      .addCase(loadGraphies.fulfilled, (state, action) => {
        const titleHolders = action.payload.data
          .flatMap((graphy) => [
            ...get(graphy, 'published.attestations', []).flatMap(
              ({ titleHolders }) => titleHolders
            ),
            ...get(graphy, 'edited.attestations', []).flatMap(({ titleHolders }) => titleHolders),
          ])
          .filter(isNotNil);

        titleHolders.forEach(({ id, graphyId, attestationId }) => {
          if (!state.byGraphyIdAndAttestationId[graphyId]) {
            state.byGraphyIdAndAttestationId[graphyId] = {};
          }
          state.byGraphyIdAndAttestationId[graphyId][attestationId] = state
            .byGraphyIdAndAttestationId[graphyId][attestationId]
            ? uniq([...state.byGraphyIdAndAttestationId[graphyId][attestationId], id])
            : [id];
        });

        titleHolders.forEach((titleHolder) => {
          state.byId[titleHolder.id] = titleHolder;
        });

        state.byTitleId[action.payload.titleId] = uniq(titleHolders.map(({ id }) => id));
      })
      .addCase(createTitleHolder.fulfilled, (state, action) => {
        const { id, graphyId, attestationId } = action.payload.titleHolder;
        if (!state.byGraphyIdAndAttestationId[graphyId]) {
          state.byGraphyIdAndAttestationId[graphyId] = {};
        }
        state.byGraphyIdAndAttestationId[graphyId][attestationId] = uniq([
          ...(state.byGraphyIdAndAttestationId[graphyId][attestationId] || []),
          id,
        ]);
        state.byId[id] = action.payload.titleHolder;
        if (
          !state.byTitleId[action.payload.titleId] ||
          !state.byTitleId[action.payload.titleId].includes(id)
        )
          state.byTitleId[action.payload.titleId] = [
            ...(state.byTitleId[action.payload.titleId] || []),
            id,
          ];
      })
      .addCase(updateTitleHolder.fulfilled, replaceTitleHolder)
      .addCase(publishTitleHolder.fulfilled, replaceTitleHolder)
      .addCase(unpublishTitleHolder.fulfilled, replaceTitleHolder)
      .addCase(deleteTitleHolder.fulfilled, (state, action) => {
        const { id, titleId, graphyId, attestationId } = action.payload;
        if (state.byGraphyIdAndAttestationId[graphyId][attestationId].includes(id)) {
          state.byGraphyIdAndAttestationId[graphyId][attestationId] =
            state.byGraphyIdAndAttestationId[graphyId][attestationId].filter(
              (titleHolderId) => titleHolderId !== id
            );
        }
        if (state.byTitleId[titleId].includes(id)) {
          state.byTitleId[titleId] = state.byTitleId[titleId].filter(
            (titleHolderId) => titleHolderId !== id
          );
        }
        delete state.byId[id];
      })
      .addCase(updateGraphy.fulfilled, (state, action) => {
        const { id: graphyId, titleId, edited } = action.payload;
        if (state.byGraphyIdAndAttestationId[graphyId]) {
          Object.keys(state.byGraphyIdAndAttestationId[graphyId]).forEach((attestationId) => {
            if (
              !edited.attestationIds ||
              !edited.attestationIds.includes(parseInt(attestationId, 10))
            ) {
              if (state.byTitleId[titleId]) {
                const titleHolderId = state.byGraphyIdAndAttestationId[graphyId][attestationId];
                state.byTitleId[titleId] = state.byTitleId[titleId].filter(
                  (id) => id !== titleHolderId
                );
              }
              delete state.byGraphyIdAndAttestationId[graphyId][attestationId];
            }
          });
        }
      })
      .addCase(resetGraphy, removeTitleHoldersOfGraphy)
      .addCase(deleteGraphy.fulfilled, removeTitleHoldersOfGraphy)
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { reducer, actions } = titleHoldersSlice;
const { resetTitleHoldersOfTitle } = actions;

export {
  reducer,
  getTitleHolderById,
  getTitleHolderIdsByTitleId,
  getTitleHolderIdsByGraphyIdAndAttestationId,
  hasTitleHoldersByTitleId,
  isTitleHolderPublishable,
  resetTitleHoldersOfTitle,
  createTitleHolder,
  updateTitleHolder,
  publishTitleHolders,
  unpublishTitleHolders,
  deleteTitleHolder,
};
