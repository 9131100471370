import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { Cluster, Stack } from 'components/Layout';
import Button from 'components/Button';
import MainNuancesEditor from 'components/MainNuancesEditor';

const mainNuanceLocales = ['fr'];

const IndividualSequenceCreation = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [transliteration, setTransliteration] = useState(null);
  const [newSequence, setNewSequence] = useState({ edited: { mainNuances: {} } });

  const handleSubmit = () => {
    const mainNuances = newSequence.edited.mainNuances.fr
      ? { mainNuances: { fr: newSequence.edited.mainNuances.fr } }
      : {};
    onSubmit({ edited: { transliteration, ...mainNuances } });
  };

  const handleTransliterationUpdate = (evt) => {
    setTransliteration(evt.target.value);
  };

  const handleMainNuanceUpdate = (locale, newNuance) => {
    setNewSequence({
      ...newSequence,
      edited: {
        ...newSequence.edited,
        mainNuances: {
          ...newSequence.edited.mainNuances,
          [locale]: newNuance,
        },
      },
    });
  };

  return (
    <div
      className="individual-sequence--edition individual-sequence--creation"
      data-publishable="true"
    >
      <Stack space="var(--s0)">
        <div className="input-container is-serif is-italic is-small">
          <TextareaAutosize
            minRows={1}
            defaultValue=""
            name="individual-sequence-transliteration"
            placeholder={t('entry.transliteration')}
            onChange={handleTransliterationUpdate}
            onBlur={handleTransliterationUpdate}
          />
        </div>

        <MainNuancesEditor
          resource={newSequence}
          onUpdate={handleMainNuanceUpdate}
          locales={mainNuanceLocales}
        />

        <Cluster justify="space-between" className="individual-sequence--creation-toolbar">
          <Button size="small" onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          {transliteration && (
            <Button type="submit" size="small" onClick={handleSubmit}>
              {t('actions.create')}
            </Button>
          )}
        </Cluster>
      </Stack>
    </div>
  );
};

IndividualSequenceCreation.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default IndividualSequenceCreation;
