import { createSlice } from '@reduxjs/toolkit';

// ------------------------------------
// Selectors
// ------------------------------------

const getEntryPrinted = (state) => state.printEntry;

// ------------------------------------
// Slice
// ------------------------------------

const printEntrySlice = createSlice({
  name: 'printEntry',
  initialState: null,
  reducers: {
    openPrintEntry: (state, action) => {
      return action.payload;
    },
    closePrintEntry: (state) => {
      return null;
    },
  },
});

const { openPrintEntry, closePrintEntry } = printEntrySlice.actions;

const { reducer } = printEntrySlice;

export { reducer, getEntryPrinted, openPrintEntry, closePrintEntry };
