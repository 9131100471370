import React, { useState, useCallback } from 'react';

const withPopoverManager = () => (WrappedComponent) => {
  const HocComponent = (props) => {
    const [popover, setPopover] = useState(null);
    const [popoverPosition, setPopoverPosition] = useState(null);

    const resetPopoverState = useCallback((evt) => {
      if (!evt) {
        setPopover(null);
        setPopoverPosition(null);
      }
    }, []);

    return (
      <WrappedComponent
        popover={popover}
        onSetPopover={setPopover}
        popoverPosition={popoverPosition}
        onSetPopoverPosition={setPopoverPosition}
        handleCancel={resetPopoverState}
        onResetPopoverState={resetPopoverState}
        {...props}
      />
    );
  };

  HocComponent.displayName = `withPopoverManager(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return HocComponent;
};

export default withPopoverManager;
