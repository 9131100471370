import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ArrowDownIcon } from 'images/icon-arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'images/icon-arrow-up.svg';

const DropdownButton = React.forwardRef(({ children, ...buttonProps }, ref) => {
  return (
    <button
      type="button"
      {...buttonProps}
      className={classNames('dropdown-button', {
        'is-menu-open': buttonProps['aria-expanded'],
      })}
      data-testid="dropdown-button"
      ref={ref}
    >
      {children}
      {buttonProps['aria-expanded'] && <ArrowUpIcon className="arrow" />}
      {!buttonProps['aria-expanded'] && <ArrowDownIcon className="arrow" />}
    </button>
  );
});

DropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownButton;
