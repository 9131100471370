import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from 'slices/auth';
import './LoggedUser.scss';

const LoggedUser = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    return dispatch(logout());
  };

  return (
    <div className="logged-user-container">
      <a
        className="link link--clear"
        data-testid="user-identity"
        href={`${window.location.origin.replace('app', 'accounts')}/my-account`}
      >
        {user.firstName} {user.lastName}
      </a>
      <button
        type="button"
        className="link link--clear"
        onClick={handleLogout}
        data-testid="user-logout"
      >
        {t('user.logout')}
      </button>
    </div>
  );
};

LoggedUser.propTypes = {
  user: PropTypes.object.isRequired,
};

export default LoggedUser;
