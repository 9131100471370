import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cond, constant, groupBy, isNil, stubTrue } from 'lodash';
import { get } from 'lodash/fp';
import PeriodsDataService from 'services/PeriodsDataService';
import { isNotNil } from 'utils/util';
import { logout } from './auth';

const getPeriodValuesWithoutKings = (state, periodValues) => {
  if (periodValues) {
    return [...getPeriods(state), { value: 'root-period' }]
      .filter(({ value }) => periodValues.includes(value))
      .map(({ parentKey, value, type }) => (type === 'king' ? parentKey : value));
  }
  return null;
};

// ------------------------------------
// Selectors
// ------------------------------------

const getPeriods = (state) => state.periods.all;

const getPeriodsGrouped = (state) => state.periods.grouped;

const getPeriodByValue = (state, value) => state.periods.byValue[value];

// ------------------------------------
// Async actions
// ------------------------------------

const loadAllPeriods = createAsyncThunk('periods/loadAll', async () => {
  const res = await PeriodsDataService.getAllPeriods();
  return res.data;
});

const initialState = {
  all: [],
  grouped: [],
  byValue: {},
};

const periodsSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAllPeriods.fulfilled, (state, action) => {
        const getPeriodType = cond([
          [({ type }) => isNotNil(type), get('type')],
          [({ parentKey }) => parentKey === 'root-period', constant('period')],
          [({ key }) => key.startsWith('dyn'), constant('dynasty')],
          [stubTrue, constant('king')],
        ]);
        const periodsTree = action.payload
          .filter(({ parentKey }) => !isNil(parentKey))
          .map((period) => {
            const { key, parentKey } = period;
            return {
              value: key,
              label: key,
              parentKey,
              type: getPeriodType(period),
            };
          });

        const groupedPeriods = Object.entries(
          groupBy(periodsTree, ({ parentKey }) => parentKey)
        ).reduce((acc, [groupkey, periods]) => {
          acc.push({ label: groupkey, options: periods });
          return acc;
        }, []);

        state.all = periodsTree;
        state.grouped = groupedPeriods;
        state.byValue = action.payload.reduce((acc, period) => {
          const { key, parentKey } = period;
          acc[key] = {
            value: key,
            label: key,
            parentKey,
            type: key !== 'root-period' ? getPeriodType(period) : undefined,
          };
          return acc;
        }, {});
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { reducer } = periodsSlice;

export {
  reducer,
  loadAllPeriods,
  getPeriods,
  getPeriodsGrouped,
  getPeriodValuesWithoutKings,
  getPeriodByValue,
};
