import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIndividualRelativeIdsByIndividualId } from 'slices/individualRelatives';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualRelativeRead from './IndividualRelativeRead';
import './IndividualRelatives.scss';

const IndividualRelativesRead = ({ individualId, publishableProp }) => {
  const { t } = useTranslation();
  const relativeIds = useSelector((state) =>
    getIndividualRelativeIdsByIndividualId(state, individualId)
  );

  return (
    <EntryTabContentLayout title={t('individualRelatives.title')} noBodyTopSpace>
      {relativeIds.map((relativeId) => (
        <IndividualRelativeRead
          key={relativeId}
          id={relativeId}
          publishableProp={publishableProp}
        />
      ))}
    </EntryTabContentLayout>
  );
};

IndividualRelativesRead.defaultProps = {
  publishableProp: 'published',
};

IndividualRelativesRead.propTypes = {
  publishableProp: PropTypes.oneOf(['edited', 'published']),
  individualId: PropTypes.number.isRequired,
};

export default IndividualRelativesRead;
