import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { canCreateEntry } from 'slices/auth';
import { closeSource, getSelectedSourceId, openSource } from 'slices/ui/thesaurus';
import Sources from './Sources';
import Source from './Source';

const Thesaurus = () => {
  const dispatch = useDispatch();
  const selectedSourceId = useSelector(getSelectedSourceId);
  const canEdit = useSelector(canCreateEntry);

  const handleNavigateToSource = useCallback(
    (sourceId) => {
      dispatch(openSource(sourceId));
    },
    [dispatch]
  );

  const handleNavigateBack = useCallback(() => {
    dispatch(closeSource());
  }, [dispatch]);

  if (!isNil(selectedSourceId)) {
    return <Source id={selectedSourceId} canEdit={canEdit} onNavigateBack={handleNavigateBack} />;
  }
  return <Sources canEdit={canEdit} onNavigateToSource={handleNavigateToSource} />;
};

export default Thesaurus;
