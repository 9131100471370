import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { ReactComponent as CoffinIcon } from './icons/icon-source-type-coffin.svg';
import { ReactComponent as GraffitoIcon } from './icons/icon-source-type-graffito.svg';
import { ReactComponent as FurnitureIcon } from './icons/icon-source-type-furniture.svg';
import { ReactComponent as MonumentIcon } from './icons/icon-source-type-monument.svg';
import { ReactComponent as OstraconIcon } from './icons/icon-source-type-ostracon.svg';
import { ReactComponent as PapyrusIcon } from './icons/icon-source-type-papyrus.svg';
import { ReactComponent as StatueIcon } from './icons/icon-source-type-statue.svg';
import { ReactComponent as SteleIcon } from './icons/icon-source-type-stele.svg';
import { ReactComponent as TombIcon } from './icons/icon-source-type-tomb.svg';
import { ReactComponent as BeetleIcon } from './icons/icon-source-type-beetle.svg';
import { ReactComponent as OfferingTableIcon } from './icons/icon-source-type-offering-table.svg';
import { SOURCE_TYPES } from 'utils/constants';

const sourceIcons = {
  coffin: <CoffinIcon />,
  graffito: <GraffitoIcon />,
  furniture: <FurnitureIcon />,
  monument: <MonumentIcon />,
  ostracon: <OstraconIcon />,
  papyrus: <PapyrusIcon />,
  statue: <StatueIcon />,
  stele: <SteleIcon />,
  tomb: <TombIcon />,
  beetle: <BeetleIcon />,
  offeringTable: <OfferingTableIcon />,
};

const SourceIcon = ({ type }) => {
  return <Icon size={24}>{sourceIcons[type]}</Icon>;
};

SourceIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(SOURCE_TYPES)).isRequired,
};

export default SourceIcon;
