import axios from 'axios';

class CommentsDataService {
  constructor(parentKeyName) {
    this.parentKeyName = parentKeyName || 'entryId';
  }

  get(parentId, isEditVersion) {
    return axios.get(`/comments?edited=${isEditVersion}&${this.parentKeyName}=${parentId}`);
  }

  createOrUpdate(comment) {
    return axios.post('/comments', comment);
  }

  unpublish(commentData) {
    return axios.post('/comments/unpublish', commentData);
  }

  publish(commentData) {
    return axios.post('/comments/publish', commentData);
  }
}

export default CommentsDataService;
