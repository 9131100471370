import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NamesDataService from 'services/NamesDataService';
import { isPublishable } from 'utils/util';
import { deleteIndividual } from './individuals';
import {
  loadGraphies,
  uploadFileAndCreateGraphy,
  publishIndividualNameGraphies,
  resetGraphiesOfIndividualName,
  unpublishIndividualNameGraphies,
  selectors as individualNameGraphiesSelectors,
} from './individualNameGraphies';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getIndividualNameIdsByIndividualId = (state, individualId) =>
  state.individualNames.byIndividualId[individualId] || emptyArray;

const getIndividualNameById = (state, nameId) => state.individualNames.byId[nameId];

const doesIndividualHaveNames = (state, individualId) => {
  return getIndividualNameIdsByIndividualId(state, individualId).length > 0;
};

const isIndividualNamesPublishable = (state, individualId) => {
  const nameIds = getIndividualNameIdsByIndividualId(state, individualId);
  return nameIds.some((nameId) => isIndividualNamePublishable(state, nameId));
};

const isIndividualNamePublishable = (state, nameId) => {
  const name = getIndividualNameById(state, nameId);
  const hasUnpublishedTransliteration = isPublishable(name, 'transliteration');
  const hasUnpublishedMainNuanceType = isPublishable(name, 'mainNuanceType');
  const hasUnpublishedMainNuanceEn = isPublishable(name, 'mainNuances.en');
  const hasUnpublishedMainNuanceFr = isPublishable(name, 'mainNuances.fr');
  const hasUnpublishedMainNuanceDe = isPublishable(name, 'mainNuances.de');
  const hasUnpublishedMainNuanceAr = isPublishable(name, 'mainNuances.ar');
  const hasUnpublishedGraphies = individualNameGraphiesSelectors.isAllOfNamePublishable(
    state,
    nameId
  );

  return (
    hasUnpublishedTransliteration ||
    hasUnpublishedMainNuanceType ||
    hasUnpublishedMainNuanceEn ||
    hasUnpublishedMainNuanceFr ||
    hasUnpublishedMainNuanceDe ||
    hasUnpublishedMainNuanceAr ||
    hasUnpublishedGraphies
  );
};

// ------------------------------------
// Async actions
// ------------------------------------

const loadIndividualNames = createAsyncThunk(
  'individualNames/load',
  async ({ individualId, isEditVersion }, { dispatch }) => {
    const res = await NamesDataService.getAllOfIndividual(individualId, isEditVersion);
    res.data.forEach((name) => {
      dispatch(loadGraphies({ nameId: name.id, isEditVersion, contentAll: false }));
    });
    return { individualId, data: res.data };
  }
);

const unpublishIndividualName = createAsyncThunk(
  'individualNames/unpublish',
  async (individualName) => {
    const res = await NamesDataService.unpublish(individualName);
    return res.data;
  }
);

const publishIndividualName = createAsyncThunk(
  'individualNames/publish',
  async (individualName) => {
    const res = await NamesDataService.publish(individualName);
    return res.data;
  }
);

const createIndividualName = createAsyncThunk(
  'individualNames/create',
  async ({ individualId, data, graphyFile }, { dispatch }) => {
    const res = await NamesDataService.createOrUpdate({
      individualId,
      ...data,
    });
    await dispatch(uploadFileAndCreateGraphy(res.data.id, graphyFile));
    return res.data;
  }
);

const mergeIndividual = (old, updated) => ({
  ...old,
  ...updated,
  edited: {
    ...old.edited,
    ...updated.edited,
    mainNuances: {
      ...old.edited.mainNuances,
      ...updated.edited?.mainNuances,
    },
  },
});

const updateIndividualName = createAsyncThunk(
  'individualNames/update',
  async ({ id, data }, { getState }) => {
    const oldIndividualName = getIndividualNameById(getState(), id);
    const updatedIndividualName = mergeIndividual(oldIndividualName, data);
    const res = await NamesDataService.createOrUpdate(updatedIndividualName);
    return res.data;
  }
);

const deleteIndividualName = createAsyncThunk(
  'individualNames/delete',
  async ({ id, individualId }) => {
    await NamesDataService.delete(id);
    return { individualId, id };
  }
);

const publishNamesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const nameIds = getIndividualNameIdsByIndividualId(getState(), individualId);
    nameIds.forEach((nameId) => {
      dispatch(publishIndividualNameGraphies(nameId));
      dispatch(publishIndividualName(getIndividualNameById(getState(), nameId)));
    });
  };
};

const unpublishNamesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const nameIds = getIndividualNameIdsByIndividualId(getState(), individualId);
    nameIds.forEach((nameId) => {
      dispatch(unpublishIndividualNameGraphies(nameId));
      dispatch(unpublishIndividualName(getIndividualNameById(getState(), nameId)));
    });
  };
};

const resetNamesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const nameIds = getIndividualNameIdsByIndividualId(getState(), individualId);
    nameIds.forEach((nameId) => {
      dispatch(resetGraphiesOfIndividualName(nameId));
      dispatch(resetIndividualName({ individualId, nameId }));
    });
  };
};

const initialState = {
  byIndividualId: {},
  byId: {},
};

const individualNamesSlice = createSlice({
  name: 'individualNames',
  initialState,
  reducers: {
    resetIndividualName: (state, { payload }) => {
      state.byIndividualId[payload.individualId] = state.byIndividualId[
        payload.individualId
      ].filter((id) => id !== payload.nameId);
      if (state.byIndividualId[payload.individualId].length === 0) {
        delete state.byIndividualId[payload.individualId];
      }
      delete state.byId[payload.nameId];
    },
  },
  extraReducers: (builder) => {
    const replaceIndividualName = (state, action) => {
      state.byId[action.payload.id] = action.payload;
    };

    builder
      .addCase(loadIndividualNames.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = action.payload.data.map(
          (name) => name.id
        );
        action.payload.data.forEach((name) => {
          state.byId[name.id] = name;
        });
      })
      .addCase(createIndividualName.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ]
          ? [...state.byIndividualId[action.payload.individualId], action.payload.id]
          : [action.payload.id];
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(updateIndividualName.fulfilled, replaceIndividualName)
      .addCase(unpublishIndividualName.fulfilled, replaceIndividualName)
      .addCase(publishIndividualName.fulfilled, replaceIndividualName)
      .addCase(deleteIndividualName.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ].filter((nameId) => action.payload.id !== nameId);
        delete state.byId[action.payload.id];
      })
      .addCase(deleteIndividual.fulfilled, (state, action) => {
        const nameIdsToRemove = state.byIndividualId[action.payload.individualId] || [];
        nameIdsToRemove.forEach((id) => delete state.byId[id]);
        delete state.byIndividualId[action.payload.individualId];
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { resetIndividualName } = individualNamesSlice.actions;

const { reducer } = individualNamesSlice;

export {
  reducer,
  loadIndividualNames,
  createIndividualName,
  publishNamesOfIndividual,
  unpublishNamesOfIndividual,
  updateIndividualName,
  deleteIndividualName,
  resetNamesOfIndividual,
  getIndividualNameIdsByIndividualId,
  getIndividualNameById,
  doesIndividualHaveNames,
  isIndividualNamesPublishable,
  isIndividualNamePublishable,
};
