import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import Icon from 'components/Icon';
import { ReactComponent as ValidatedIcon } from 'images/icon-public-status-validated.svg';
import { ReactComponent as UncertainIcon } from 'images/icon-public-status-problematic.svg';
import { NUANCE_PUBLIC_STATUS } from 'utils/constants';
import './PublicStatus.scss';

const statusIcons = {
  validated: <ValidatedIcon />,
  problematic: <UncertainIcon />,
};

function NuancePublicStatus({ status, hasTooltip }) {
  const { i18n, t } = useTranslation();

  if (!status) {
    return null;
  }

  if (!hasTooltip) {
    return (
      <div className="public-status" data-status={status} data-testid="public-status">
        <Icon>{statusIcons[status]}</Icon>
      </div>
    );
  }

  return (
    <TooltipManager>
      <TooltipTrigger>
        <div className="public-status" data-status={status} data-testid="public-status">
          <Icon isInteractive>{statusIcons[status]}</Icon>
        </div>
      </TooltipTrigger>
      <TooltipContent dir={i18n.dir()}>{t(`nuance.publicStatus.tooltip.${status}`)}</TooltipContent>
    </TooltipManager>
  );
}

NuancePublicStatus.defaultProps = {
  status: NUANCE_PUBLIC_STATUS.validated,
  hasTooltip: false,
};

NuancePublicStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(NUANCE_PUBLIC_STATUS)),
  hasTooltip: PropTypes.bool,
};

export default NuancePublicStatus;
