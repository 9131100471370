import axios from 'axios';

class NamesDataService {
  getAllOfIndividual(individualId, isEdited) {
    return axios.get(`/names?individualId=${individualId}&edited=${isEdited}`);
  }

  createOrUpdate(name) {
    return axios.post('/names', name);
  }

  publish(name) {
    return axios.post('/names/publish', name);
  }

  unpublish(name) {
    return axios.post('/names/unpublish', name);
  }

  delete(nameId) {
    return axios.delete(`/names/${nameId}`);
  }
}

export default new NamesDataService();
