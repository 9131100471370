import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import useAutoSave from 'hooks/useAutoSave';
import { hasChanged } from './editorUtils';
import { generateDecorators } from './decorators';
import Editor from './Editor';

const toHtml = convertToHTML({
  styleToHTML: (style) => {
    if (style === 'TRANSLITERATION') {
      return <em data-transliteration />;
    }
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'ENTRY_LINK') {
      return <em data-entry-id={entity.data.entryId}>{originalText}</em>;
    } else if (entity.type === 'TITLE_LINK') {
      return <em data-title-id={entity.data.titleId}>{originalText}</em>;
    } else if (entity.type === 'INDIVIDUAL_LINK') {
      return <em data-individual-id={entity.data.individualId}>{originalText}</em>;
    }
    return originalText;
  },
});

function removeInitialValueExtraLineBreaks(value) {
  // We use a try-catch block here because Safari < 16.4
  // doesn't support regex negative look behind,
  // we don't do the replacement on version of Safari prior to 16.4
  // but we avoid just crashing the app for those users
  try {
    const regex = new RegExp('(?<!<p>s*?)(<br/?>)(?=</p>)', 'gm');
    return value.replace(regex, '');
  } catch {
    return value;
  }
}

const initializeEditor = (initialValue, enableTlaDecorator, enablePnmDecorator) => {
  if (initialValue) {
    return EditorState.createWithContent(
      convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
          if ((nodeName === 'i' || nodeName === 'em') && node.dataset.entryId) {
            return currentStyle.filter((style) => style !== 'ITALIC');
          } else if ((nodeName === 'i' || nodeName === 'em') && node.dataset.transliteration) {
            return currentStyle.filter((style) => style !== 'ITALIC').add('TRANSLITERATION');
          }
          return currentStyle;
        },
        htmlToEntity: (nodeName, node, createEntity) => {
          if ((nodeName === 'i' || nodeName === 'em') && node.dataset.entryId) {
            return createEntity('ENTRY_LINK', 'IMMUTABLE', {
              entryId: parseInt(node.dataset.entryId, 10),
            });
          } else if ((nodeName === 'i' || nodeName === 'em') && node.dataset.titleId) {
            return createEntity('TITLE_LINK', 'IMMUTABLE', {
              titleId: parseInt(node.dataset.titleId, 10),
            });
          } else if ((nodeName === 'i' || nodeName === 'em') && node.dataset.individualId) {
            return createEntity('INDIVIDUAL_LINK', 'IMMUTABLE', {
              individualId: parseInt(node.dataset.individualId, 10),
            });
          }
        },
      })(
        removeInitialValueExtraLineBreaks(initialValue)
          .replace(/<p><br\/?><\/p>/g, '<p></p>')
          .replaceAll('&nbsp;', '\u00A0')
      ),
      generateDecorators({ enableTlaDecorator, enablePnmDecorator })
    );
  }
  return EditorState.createEmpty(generateDecorators({ enableTlaDecorator, enablePnmDecorator }));
};

const EditorManager = ({
  initialValue,
  placeholder,
  readOnly,
  saveMode,
  entryLinkEnable,
  titleLinkEnable,
  individualLinkEnable,
  enableTlaDecorator,
  enablePnmDecorator,
  onChange,
  onValidate,
  onFocus,
  onBlur,
  onKeyCommand,
}) => {
  const [content, setContent] = useState(() => {
    return initializeEditor(initialValue, enableTlaDecorator, enablePnmDecorator);
  });
  const [autoSave] = useAutoSave(onValidate);

  const handleChange = useCallback(
    (editorState, shouldSave = true) => {
      if (hasChanged(content, editorState)) {
        onChange(editorState);
        if (saveMode === 'auto' && shouldSave) {
          autoSave(toHtml(editorState.getCurrentContent()));
        }
      }
      setContent(editorState);
    },
    [content, onChange, autoSave, saveMode]
  );

  const handleBlur = useCallback(() => {
    if (saveMode === 'blur') {
      onValidate(toHtml(content.getCurrentContent()));
    }
    onBlur();
  }, [saveMode, onValidate, content, onBlur]);

  useEffect(() => {
    if (readOnly) {
      setContent(initializeEditor(initialValue, enableTlaDecorator, enablePnmDecorator));
    }
  }, [initialValue, readOnly, enableTlaDecorator, enablePnmDecorator]);

  return (
    <Editor
      editorState={content}
      placeholder={placeholder}
      readOnly={readOnly}
      onFocus={onFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyCommand={onKeyCommand}
      entryLinkEnable={entryLinkEnable}
      titleLinkEnable={titleLinkEnable}
      individualLinkEnable={individualLinkEnable}
    />
  );
};

EditorManager.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  saveMode: PropTypes.oneOf(['blur', 'auto']),
  entryLinkEnable: PropTypes.bool,
  titleLinkEnable: PropTypes.bool,
  individualLinkEnable: PropTypes.bool,
  enableTlaDecorator: PropTypes.bool,
  enablePnmDecorator: PropTypes.bool,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyCommand: PropTypes.func,
};

EditorManager.defaultProps = {
  initialValue: null,
  readOnly: false,
  entryLinkEnable: false,
  titleLinkEnable: false,
  individualLinkEnable: false,
  enableTlaDecorator: false,
  enablePnmDecorator: false,
  saveMode: 'auto',
  placeholder: '',
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
  onValidate: noop,
  onKeyCommand: noop,
};

export default EditorManager;
