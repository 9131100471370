import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import useAutoSave from 'hooks/useAutoSave';
import './Input.scss';

export const AutoSaveInput = React.forwardRef(
  (
    {
      label,
      serif,
      italic,
      placeholder,
      name,
      size,
      layout,
      multiline,
      onChange,
      onBlur,
      onSave,
      ...props
    },
    ref
  ) => {
    const [autoSave] = useAutoSave(onSave);

    const handleChange = useCallback(
      (evt) => {
        onChange(evt);
        autoSave();
      },
      [onChange, autoSave]
    );

    const handleBlur = useCallback(
      (evt) => {
        onBlur(evt);
      },
      [onBlur]
    );

    return (
      <div
        className={classNames('input-container', {
          'is-serif': serif,
          'is-italic': italic,
          'is-read-only': !!props.readOnly,
          [`is-${size}`]: !!size,
        })}
        data-layout={layout}
      >
        {label && <label>{label}</label>}
        {!multiline && (
          <input
            type="text"
            ref={ref}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
          />
        )}
        {multiline && (
          <TextareaAutosize
            ref={ref}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
          />
        )}
      </div>
    );
  }
);

AutoSaveInput.defaultProps = {
  size: 'small',
  layout: 'above',
  label: null,
  placeholder: '',
  serif: false,
  multiline: false,
  italic: false,
};

AutoSaveInput.propTypes = {
  size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large']),
  layout: PropTypes.oneOf(['above', 'horizontal', 'below']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  serif: PropTypes.bool,
  italic: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const Input = ({
  label,
  name,
  placeholder,
  serif,
  italic,
  size,
  layout,
  multiline,
  register,
  required,
  ...props
}) => {
  return (
    <div
      className={classNames('input-container', {
        'is-serif': serif,
        'is-italic': italic,
        [`is-${size}`]: !!size,
      })}
      data-layout={layout}
    >
      {label && <label htmlFor={name}>{label}</label>}
      {!multiline && (
        <input type="text" placeholder={placeholder} {...register(name, { required })} {...props} />
      )}
      {multiline && (
        <TextareaAutosize placeholder={placeholder} {...register(name, { required })} {...props} />
      )}
    </div>
  );
};

Input.defaultProps = {
  size: 'small',
  layout: 'above',
  label: null,
  placeholder: '',
  serif: false,
  italic: false,
  multiline: false,
  required: false,
};

Input.propTypes = {
  size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large']),
  layout: PropTypes.oneOf(['above', 'horizontal', 'below']),
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  serif: PropTypes.bool,
  italic: PropTypes.bool,
  required: PropTypes.bool,
};

export default Input;
