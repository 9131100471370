import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, groupBy, isNil, uniq } from 'lodash';
import { isNotNil } from 'utils/util';
import NuancesDataService from 'services/NuancesDataService';
import { NUANCE_PUBLIC_STATUS, PROBLEMATIC_NUANCE_COMMENT } from 'utils/constants';
import {
  selectors as wordGraphiesSelectors,
  deleteGraphy,
  loadGraphies,
  resetGraphy,
  updateGraphy,
} from './wordGraphies';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getNuanceIdsByEntryId = (state, entryId) =>
  state.nuances.nuancesByEntryId[entryId] || emptyArray;
const getNuanceById = (state, nuanceId) => state.nuances.nuancesById[nuanceId];

const getNuanceByGraphyIdAndAttestationId = (state, graphyId, attestationId) => {
  const nuanceId = get(
    state,
    `nuances.nuancesByGraphyIdAndAttestationId.${graphyId}.${attestationId}`,
    null
  );
  if (nuanceId) {
    return getNuanceById(state, nuanceId);
  }
  return null;
};

const hasNuancesByEntryId = (state, entryId, publishableProp = 'published') => {
  const nuanceIds = getNuanceIdsByEntryId(state, entryId).filter((nuanceId) => {
    const nuance = getNuanceById(state, nuanceId);
    return nuance && !isNil(nuance[publishableProp]);
  });
  return nuanceIds.length > 0;
};

const getUniqueNuancesByEntryId = (state, entryId) => {
  const nuances = getNuanceIdsByEntryId(state, entryId)
    .map((nuanceId) => {
      const nuance = getNuanceById(state, nuanceId);
      return nuance && nuance.edited ? nuance.edited.description : null;
    })
    .filter((description) => isNotNil(description) && description !== '');
  return uniq(nuances);
};

const getAttestationIdsByUniqueNuances = (state, nuanceIds, publishableProp = 'published') => {
  if (!nuanceIds) {
    return null;
  }
  const nuances = nuanceIds
    .map((id) => state.nuances.nuancesById[id])
    .filter((nuance) => {
      return nuance[publishableProp];
    });
  const nuancesByUniqueDescription = groupBy(nuances, (nuance) => {
    if (nuance.publicStatus === NUANCE_PUBLIC_STATUS.problematic) {
      return nuance[publishableProp].description + PROBLEMATIC_NUANCE_COMMENT;
    }
    return nuance[publishableProp].description;
  });
  return Object.entries(nuancesByUniqueDescription).reduce((acc, [description, nuances]) => {
    acc[description] = nuances.map(({ attestationId, graphyId }) => ({ attestationId, graphyId }));
    return acc;
  }, {});
};

// ------------------------------------
// Async actions
// ------------------------------------

const publishEntryNuances = (entryId) => {
  return (dispatch, getState) => {
    getNuanceIdsByEntryId(getState(), entryId).forEach((nuanceId) => {
      dispatch(publishNuance(getNuanceById(getState(), nuanceId)));
    });
  };
};

const unpublishEntryNuances = (entryId) => {
  return (dispatch, getState) => {
    getNuanceIdsByEntryId(getState(), entryId).forEach((nuanceId) => {
      dispatch(unpublishNuance(getNuanceById(getState(), nuanceId)));
    });
  };
};

const createOrUpdateNuance = createAsyncThunk(
  'nuance/createOrUpdate',
  async (nuance, { getState }) => {
    const { entryId } = wordGraphiesSelectors.getById(getState(), nuance.graphyId);
    const res = await NuancesDataService.createOrUpdate(nuance);
    return { entryId, nuance: res.data };
  },
  {
    getPendingMeta: ({ arg }, { getState }) => {
      const { entryId } = wordGraphiesSelectors.getById(getState(), arg.graphyId);
      return { entryId };
    },
  }
);

const publishNuance = createAsyncThunk('nuance/publish', async (nuance) => {
  const res = await NuancesDataService.publish(nuance);
  return res.data;
});

const unpublishNuance = createAsyncThunk('nuance/unpublish', async (nuance) => {
  const res = await NuancesDataService.unpublish(nuance);
  return res.data;
});

const initialState = {
  nuancesByEntryId: {},
  nuancesByGraphyIdAndAttestationId: {},
  nuancesById: {},
};

const replaceNuance = (state, action) => {
  state.nuancesById[action.payload.id] = action.payload;
};

const removeNuancesOfGraphy = (state, action) => {
  const { graphyId, entryId } = action.payload;
  if (state.nuancesByGraphyIdAndAttestationId[graphyId]) {
    Object.values(state.nuancesByGraphyIdAndAttestationId[graphyId]).forEach((nuanceId) => {
      delete state.nuancesById[nuanceId];
      if (state.nuancesByEntryId[entryId]) {
        state.nuancesByEntryId[entryId] = state.nuancesByEntryId[entryId].filter(
          (id) => id !== nuanceId
        );
      }
    });
    delete state.nuancesByGraphyIdAndAttestationId[graphyId];
  }

  if (state.nuancesByEntryId[entryId]) {
    delete state.nuancesByEntryId[entryId];
  }
};

const nuancesSlice = createSlice({
  name: 'nuances',
  initialState,
  reducers: {
    resetNuancesOfEntry: (state, { payload: entryId }) => {
      const nuanceIdsToRemove = state.nuancesByEntryId[entryId] || [];
      nuanceIdsToRemove.forEach((id) => delete state.nuancesById[id]);
      delete state.nuancesByEntryId[entryId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGraphies.fulfilled, (state, action) => {
        const nuances = action.payload.data
          .flatMap((graphy) => [
            ...get(graphy, 'published.attestations', []).map((attestation) => ({
              ...attestation.nuance,
              attestationId: attestation.id,
              graphyId: graphy.id,
            })),
            ...get(graphy, 'edited.attestations', []).map((attestation) => ({
              ...attestation.nuance,
              attestationId: attestation.id,
              graphyId: graphy.id,
            })),
          ])
          .filter(isNotNil);

        const filteredNuances = nuances.filter(({ id }) => id);

        filteredNuances.forEach(({ id, graphyId, attestationId }) => {
          if (!state.nuancesByGraphyIdAndAttestationId[graphyId]) {
            state.nuancesByGraphyIdAndAttestationId[graphyId] = {};
          }
          state.nuancesByGraphyIdAndAttestationId[graphyId][attestationId] = id;
        });

        filteredNuances.forEach((nuance) => {
          state.nuancesById[nuance.id] = nuance;
        });

        state.nuancesByEntryId[action.payload.entryId] = uniq(filteredNuances.map(({ id }) => id));
      })
      .addCase(createOrUpdateNuance.fulfilled, (state, action) => {
        const { id, graphyId, attestationId } = action.payload.nuance;
        if (!state.nuancesByGraphyIdAndAttestationId[graphyId]) {
          state.nuancesByGraphyIdAndAttestationId[graphyId] = {};
        }
        state.nuancesByGraphyIdAndAttestationId[graphyId][attestationId] = id;
        state.nuancesById[id] = action.payload.nuance;
        if (
          !state.nuancesByEntryId[action.payload.entryId] ||
          !state.nuancesByEntryId[action.payload.entryId].includes(id)
        )
          state.nuancesByEntryId[action.payload.entryId] = [
            ...(state.nuancesByEntryId[action.payload.entryId] || []),
            id,
          ];
      })
      .addCase(publishNuance.fulfilled, replaceNuance)
      .addCase(unpublishNuance.fulfilled, replaceNuance)
      .addCase(updateGraphy.fulfilled, (state, action) => {
        const { id: graphyId, entryId, edited } = action.payload;
        if (state.nuancesByGraphyIdAndAttestationId[graphyId]) {
          Object.keys(state.nuancesByGraphyIdAndAttestationId[graphyId]).forEach(
            (attestationId) => {
              if (
                !edited.attestationIds ||
                !edited.attestationIds.includes(parseInt(attestationId, 10))
              ) {
                if (state.nuancesByEntryId[entryId]) {
                  const nuanceId = state.nuancesByGraphyIdAndAttestationId[graphyId][attestationId];
                  state.nuancesByEntryId[entryId] = state.nuancesByEntryId[entryId].filter(
                    (id) => id !== nuanceId
                  );
                }
                delete state.nuancesByGraphyIdAndAttestationId[graphyId][attestationId];
              }
            }
          );
        }
      })
      .addCase(resetGraphy, removeNuancesOfGraphy)
      .addCase(deleteGraphy.fulfilled, removeNuancesOfGraphy)
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { reducer, actions } = nuancesSlice;
const { resetNuancesOfEntry } = actions;

export {
  reducer,
  getNuanceById,
  getNuanceIdsByEntryId,
  getAttestationIdsByUniqueNuances,
  getNuanceByGraphyIdAndAttestationId,
  getUniqueNuancesByEntryId,
  hasNuancesByEntryId,
  resetNuancesOfEntry,
  createOrUpdateNuance,
  publishEntryNuances,
  unpublishEntryNuances,
};
