import PropTypes from 'prop-types';
import { PUBLIC_STATUS } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'components/Menu';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import EntryPublicStatus from './EntryPublicStatus';
import GraphyPublicStatus from './GraphyPublicStatus';
import NuancePublicStatus from './NuancePublicStatus';
import RelativePublicStatus from './RelativePublicStatus';
import TitlePublicStatus from 'components/PublicStatus/TitlePublicStatus';

const PublicStatusComponent = {
  entry: EntryPublicStatus,
  title: TitlePublicStatus,
  graphy: GraphyPublicStatus,
  nuance: NuancePublicStatus,
  individualRelatives: RelativePublicStatus,
};

const PublicStatusSelector = ({ statusList, onSelect, position, status, translationKey }) => {
  const { t } = useTranslation();
  const PublicStatus = PublicStatusComponent[translationKey];

  return (
    <DropdownMenu
      position={position}
      itemsCount={3}
      renderButton={(buttonProps) => (
        <DropdownButton {...buttonProps}>
          <PublicStatus status={status} />
        </DropdownButton>
      )}
    >
      {statusList.map((s) => (
        <MenuItem key={s} isSelectable isSelected={status === s} onClick={() => onSelect(s)}>
          {t(`${translationKey}.publicStatus.${s}`)}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};

PublicStatusSelector.defaultProps = {
  status: PUBLIC_STATUS.validated,
  position: 'left',
};

PublicStatusSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.oneOf(Object.values(PUBLIC_STATUS)),
  position: PropTypes.oneOf(['left', 'right']),
};

export default PublicStatusSelector;
