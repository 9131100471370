import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

function Icon({ children, size, isFilled, isInteractive }) {
  return (
    <div
      className="icon-layout"
      data-interactive={isInteractive}
      data-filled={isFilled}
      style={{ width: size, height: size }}
    >
      {children}
    </div>
  );
}

Icon.defaultProps = {
  size: null,
  isInteractive: false,
  isFilled: true,
};

Icon.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node.isRequired,
  isInteractive: PropTypes.bool,
  isFilled: PropTypes.bool,
};

export default Icon;
