import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import SequencesDataService from 'services/SequencesDataService';
import { isPublishable } from 'utils/util';
import { deleteIndividual } from './individuals';
import { logout } from './auth';
import { close } from 'slices/ui/thesaurus';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getIndividualSequenceIdsByIndividualId = (state, individualId) =>
  state.individualSequences.byIndividualId[individualId] || emptyArray;

const getOrderedIndividualSequenceIdsByIndividualId = createSelector(
  (state) => state.individualSequences.byIndividualId,
  (state) => state.individualSequences.byId,
  (_, individualId) => individualId,
  (_, __, publishableProp) => publishableProp,
  (byIndividualIdMap, byIdMap, individualId, publishableProp) => {
    const ids = byIndividualIdMap[individualId] || emptyArray;
    const getAttestationsCount = (sequence) =>
      get(sequence, `${publishableProp}.attestationIds.length`, 0);

    return [...ids].sort((id1, id2) => {
      const sequence1 = byIdMap[id1];
      const sequence2 = byIdMap[id2];
      return getAttestationsCount(sequence2) - getAttestationsCount(sequence1);
    });
  }
);

const getIndividualSequenceById = (state, sequenceId) => state.individualSequences.byId[sequenceId];

const doesIndividualHaveSequences = (state, individualId) => {
  return getIndividualSequenceIdsByIndividualId(state, individualId).length > 0;
};

const isIndividualSequencesPublishable = (state, individualId) => {
  const sequenceIds = getIndividualSequenceIdsByIndividualId(state, individualId);
  return sequenceIds.some((sequenceId) => isIndividualSequencePublishable(state, sequenceId));
};

const isIndividualSequencePublishable = (state, sequenceId) => {
  const sequence = getIndividualSequenceById(state, sequenceId);
  const hasUnpublishedTransliteration = isPublishable(sequence, 'transliteration');
  const hasUnpublishedMainNuanceEn = isPublishable(sequence, 'mainNuances.en');
  const hasUnpublishedMainNuanceFr = isPublishable(sequence, 'mainNuances.fr');
  const hasUnpublishedMainNuanceDe = isPublishable(sequence, 'mainNuances.de');
  const hasUnpublishedMainNuanceAr = isPublishable(sequence, 'mainNuances.ar');

  return (
    hasUnpublishedTransliteration ||
    hasUnpublishedMainNuanceEn ||
    hasUnpublishedMainNuanceFr ||
    hasUnpublishedMainNuanceDe ||
    hasUnpublishedMainNuanceAr
  );
};

// ------------------------------------
// Async actions
// ------------------------------------

const loadIndividualSequences = createAsyncThunk(
  'individualSequences/load',
  async ({ individualId, isEditVersion }) => {
    const res = await SequencesDataService.getAllOfIndividual(individualId, isEditVersion);
    return { individualId, data: res.data };
  }
);

const unpublishIndividualSequence = createAsyncThunk(
  'individualSequences/unpublish',
  async (sequence) => {
    const res = await SequencesDataService.unpublish(sequence);
    return res.data;
  }
);

const publishIndividualSequence = createAsyncThunk(
  'individualSequences/publish',
  async (sequence) => {
    const res = await SequencesDataService.publish(sequence);
    return res.data;
  }
);

const createIndividualSequence = createAsyncThunk(
  'individualSequences/create',
  async ({ individualId, data }) => {
    const res = await SequencesDataService.createOrUpdate({
      individualId,
      ...data,
    });
    return res.data;
  }
);

const updateIndividualSequence = createAsyncThunk(
  'individualSequences/update',
  async ({ id, data }, { getState }) => {
    const oldSequence = getIndividualSequenceById(getState(), id);
    const updatedSequence = {
      ...oldSequence,
      ...data,
      edited: {
        ...oldSequence.edited,
        ...data.edited,
        mainNuances: {
          ...oldSequence.edited.mainNuances,
          ...data.edited?.mainNuances,
        },
      },
    };
    const res = await SequencesDataService.createOrUpdate(updatedSequence);
    return res.data;
  }
);

const deleteIndividualSequence = createAsyncThunk(
  'individualSequences/delete',
  async ({ id, individualId }) => {
    await SequencesDataService.delete(id);
    return { individualId, id };
  }
);

const publishSequencesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const sequenceIds = getIndividualSequenceIdsByIndividualId(getState(), individualId);
    sequenceIds.forEach((sequenceId) => {
      dispatch(publishIndividualSequence(getIndividualSequenceById(getState(), sequenceId)));
    });
  };
};

const unpublishSequencesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const sequenceIds = getIndividualSequenceIdsByIndividualId(getState(), individualId);
    sequenceIds.forEach((sequenceId) => {
      dispatch(unpublishIndividualSequence(getIndividualSequenceById(getState(), sequenceId)));
    });
  };
};

const resetSequencesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const sequenceIds = getIndividualSequenceIdsByIndividualId(getState(), individualId);
    sequenceIds.forEach((sequenceId) => {
      dispatch(resetSequence({ individualId, sequenceId }));
    });
  };
};

const addAttestationToSequence = ({ sequenceId, attestationId }) => {
  return (dispatch, getState) => {
    dispatch(close());
    const sequence = getIndividualSequenceById(getState(), sequenceId);
    const data = {
      edited: {
        ...sequence.edited,
        attestationIds: sequence.edited.attestationIds
          ? [...sequence.edited.attestationIds, attestationId]
          : [attestationId],
      },
    };
    return dispatch(updateIndividualSequence({ id: sequenceId, data }));
  };
};

const removeAttestationFromSequence = ({ sequenceId, attestationId }) => {
  return (dispatch, getState) => {
    const sequence = getIndividualSequenceById(getState(), sequenceId);
    const data = {
      edited: {
        ...sequence.edited,
        attestationIds: sequence.edited.attestationIds.filter((id) => id !== attestationId),
      },
    };
    return dispatch(updateIndividualSequence({ id: sequenceId, data }));
  };
};

const initialState = {
  byIndividualId: {},
  byId: {},
};

const individualSequencesSlice = createSlice({
  name: 'individualSequences',
  initialState,
  reducers: {
    addSequenceToIndividual: (state, { payload }) => {
      state.byIndividualId[payload.individualId] = state.byIndividualId[payload.individualId]
        ? [...state.byIndividualId[payload.individualId], payload.sequence.id]
        : [payload.sequence.id];
      state.byId[payload.sequence.id] = payload.sequence;
    },
    resetSequence: (state, { payload }) => {
      state.byIndividualId[payload.individualId] = state.byIndividualId[
        payload.individualId
      ].filter((id) => id !== payload.sequenceId);
      if (state.byIndividualId[payload.individualId].length === 0) {
        delete state.byIndividualId[payload.individualId];
      }
      delete state.byId[payload.sequenceId];
    },
  },
  extraReducers: (builder) => {
    const replaceSequence = (state, action) => {
      state.byId[action.payload.id] = action.payload;
    };

    builder
      .addCase(loadIndividualSequences.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = action.payload.data.map(
          (sequence) => sequence.id
        );
        action.payload.data.forEach((sequence) => {
          const parsedSequence = { ...sequence };
          if (parsedSequence.edited?.attestations) {
            parsedSequence.edited = { ...parsedSequence.edited };
            delete parsedSequence.edited.attestations;
          }
          if (parsedSequence.published?.attestations) {
            parsedSequence.published = { ...parsedSequence.published };
            delete parsedSequence.published.attestations;
          }

          state.byId[parsedSequence.id] = parsedSequence;
        });
      })
      .addCase(createIndividualSequence.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ]
          ? [...state.byIndividualId[action.payload.individualId], action.payload.id]
          : [action.payload.id];
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(updateIndividualSequence.fulfilled, replaceSequence)
      .addCase(unpublishIndividualSequence.fulfilled, replaceSequence)
      .addCase(publishIndividualSequence.fulfilled, replaceSequence)
      .addCase(deleteIndividualSequence.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ].filter((sequenceId) => action.payload.id !== sequenceId);
        delete state.byId[action.payload.id];
      })
      .addCase(deleteIndividual.fulfilled, (state, action) => {
        const sequenceIdsToRemove = state.byIndividualId[action.payload.individualId] || [];
        sequenceIdsToRemove.forEach((id) => delete state.byId[id]);
        delete state.byIndividualId[action.payload.individualId];
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { resetSequence, addSequenceToIndividual } = individualSequencesSlice.actions;

const { reducer } = individualSequencesSlice;

export {
  reducer,
  loadIndividualSequences,
  addSequenceToIndividual,
  createIndividualSequence,
  publishSequencesOfIndividual,
  unpublishSequencesOfIndividual,
  updateIndividualSequence,
  deleteIndividualSequence,
  resetSequencesOfIndividual,
  addAttestationToSequence,
  removeAttestationFromSequence,
  getIndividualSequenceIdsByIndividualId,
  getOrderedIndividualSequenceIdsByIndividualId,
  getIndividualSequenceById,
  doesIndividualHaveSequences,
  isIndividualSequencesPublishable,
  isIndividualSequencePublishable,
};
