import axios from 'axios';

class SourcesDataService {
  get() {
    return axios.get('/sources');
  }

  search(search) {
    return axios.get(`/sources?search=${search}`);
  }

  createOrUpdate(source) {
    return axios.post('/sources', source);
  }

  delete(sourceId) {
    return axios.delete(`/sources/${sourceId}`);
  }
}

export default new SourcesDataService();
