import React from 'react';
import { useSelector } from 'react-redux';
import { getEntryPrinted } from 'slices/ui/printEntry';
import EntryPrintModal from 'components/Entry/EntryPrintModal';
import WordPrint from './WordPrint';

const WordPrintModal = () => {
  const printedEntryId = useSelector(getEntryPrinted);

  if (!printedEntryId) {
    return null;
  }

  return (
    <EntryPrintModal>
      <WordPrint id={printedEntryId} />
    </EntryPrintModal>
  );
};

export default WordPrintModal;
