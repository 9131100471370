import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cluster } from 'components/Layout';
import { getSourceById } from 'slices/sources';
import { ReactComponent as ForwardArrowIcon } from 'images/icon-arrow-forward.svg';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import SourcePeriods from './SourcePeriods';
import SourceIcon from './SourceIcon';
import './SourceSummary.scss';

const SourceSummary = ({ id, showArrow, onSelect }) => {
  const { t } = useTranslation();
  const source = useSelector((state) => getSourceById(state, id));

  const handleSelect = useCallback(() => {
    if (onSelect) {
      onSelect(id);
    }
  }, [onSelect, id]);

  return (
    <article className="source-summary" onClick={handleSelect}>
      <div style={{ flex: '1 1 auto' }}>
        <Cluster align="center" space="var(--s-2)">
          <SourceIcon type={source.type} />
          <h1 className="source-summary-title">{source.title}</h1>
        </Cluster>
        <h2 className="source-summary-id">
          <Cluster space="var(--s-2)">
            {source.inventoryNumber && <div>{source.inventoryNumber}</div>}
            {source.hieratic && <Tag theme="green">{t('source.hierat')}</Tag>}
          </Cluster>
        </h2>
        {source.periods && source.periods.length > 0 && (
          <div className="source-summary-periods">
            <SourcePeriods periods={source.periods} isUncertain={source.isPeriodUncertain} t={t} />
          </div>
        )}
        {onSelect && (
          <div className="source-summary-attestations-count">
            {t('source.attestationsCount', {
              count: source.attestationIds ? source.attestationIds.length : 0,
            })}
          </div>
        )}
      </div>
      {onSelect && showArrow && (
        <div className="source-summary-arrow">
          <Icon size={21} isFilled={false}>
            <ForwardArrowIcon />
          </Icon>
        </div>
      )}
    </article>
  );
};

SourceSummary.defaultProps = {
  onSelect: null,
  showArrow: false,
};

SourceSummary.propTypes = {
  id: PropTypes.number.isRequired,
  showArrow: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default SourceSummary;
