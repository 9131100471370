import { isNil, takeRightWhile, takeWhile } from 'lodash';

export const getEndPeriodValue = (values) => {
  if (isNil(values[values.length - 1])) {
    return null;
  }
  return values[values.length - 1].value;
};

export const getPeriodsBetweenIntervalBounds = (startPeriod, endPeriod, periods) => {
  const periodHierarchyTypes = ['king', 'dynasty', 'period'];
  const findByValue = (arr, v) => arr.find(({ value }) => v === value);
  const findByType = (arr, v) => arr.find(({ type }) => v === type);
  const findIndexByValue = (arr, v) => arr.findIndex(({ value }) => v === value);
  const startIndex = findIndexByValue(periods, startPeriod);
  const endIndex = findIndexByValue(periods, endPeriod);
  const fullIntervalPeriods = periods.slice(startIndex, endIndex + 1);

  const { type: startType } = findByValue(periods, startPeriod);
  const { type: endType } = findByValue(periods, endPeriod);
  const startTypeIndex = periodHierarchyTypes.indexOf(startType);
  const endTypeIndex = periodHierarchyTypes.indexOf(endType);
  const startPeriodParentType = periodHierarchyTypes[startTypeIndex + 1] || startType;
  const endPeriodParentType = periodHierarchyTypes[endTypeIndex + 1] || endType;
  const startPeriodNextParent = findByType(fullIntervalPeriods, startPeriodParentType);
  const endPeriodParent = findByType([...fullIntervalPeriods].reverse(), endPeriodParentType);

  const getTakePredicate =
    (period) =>
    ({ value }) =>
      value !== period.value;
  const getTypePredicate =
    (periodType) =>
    ({ type }) =>
      type === periodType;

  const getPeriodsFromStartUntil = (period) =>
    takeWhile(fullIntervalPeriods, getTakePredicate(period)).filter(getTypePredicate(startType));
  const getPeriodsFromEndUntil = (period) =>
    takeRightWhile(fullIntervalPeriods, getTakePredicate(period)).filter(getTypePredicate(endType));

  let returnPeriods;

  if (!startPeriodNextParent || (startTypeIndex === endTypeIndex && startType === 'period')) {
    returnPeriods = fullIntervalPeriods.filter(getTypePredicate(startType));
  } else if (startTypeIndex < endTypeIndex) {
    const s = getPeriodsFromStartUntil(startPeriodNextParent);
    const e = fullIntervalPeriods
      .slice(findIndexByValue(s, s[s.length - 1]) + 1)
      .filter(getTypePredicate(endType));
    returnPeriods = [...s, ...e];
  } else if (startTypeIndex > endTypeIndex) {
    returnPeriods = [
      ...getPeriodsFromStartUntil(endPeriodParent),
      ...getPeriodsFromEndUntil(endPeriodParent),
    ];
  } else {
    const s = getPeriodsFromStartUntil(startPeriodNextParent);
    const m = takeWhile(
      fullIntervalPeriods.slice(findIndexByValue(s, s[s.length - 1]) + 1),
      getTakePredicate(endPeriodParent)
    ).filter(({ type }) => type === startPeriodNextParent.type);
    returnPeriods = [...s, ...m, ...getPeriodsFromEndUntil(endPeriodParent)];
  }

  return returnPeriods.map(({ value }) => value);
};
