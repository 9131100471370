import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { canPublishEntry } from 'slices/auth';
import { isDeleting, isPublishing, isSaving } from 'slices/ui/entryLoading';
import { isSelectionModeTriggeredOnEntry } from 'slices/ui/thesaurus';
import EntryEditionToolBar from 'components/Entry/EntryEditionToolbar';
import './EntryEdition.scss';

const EntryEdition = ({
  id,
  children,
  isHighlighted,
  isPreviewed,
  isPublished,
  showUnpublishButton,
  onStopEdition,
  onPublish,
  onUnpublish,
  onClose,
  onDelete,
  onPreview,
}) => {
  const isEntryPublishing = useSelector((state) => isPublishing(state, id));
  const isEntrySaving = useSelector((state) => isSaving(state, id));
  const isEntryDeleting = useSelector((state) => isDeleting(state, id));
  const isInSelectionMode = useSelector((state) => isSelectionModeTriggeredOnEntry(state, id));
  const showPublishButton = useSelector(canPublishEntry);

  return (
    <div
      className={classNames('entry', 'entry-edition', {
        'is-highlighted': isHighlighted,
        'is-previewed': isPreviewed,
        'is-in-selection-mode': isInSelectionMode,
      })}
      data-loading={isEntryPublishing}
      data-deleting={isEntryDeleting}
      data-testid="entry-edition"
    >
      <EntryEditionToolBar
        isPreviewed={isPreviewed}
        isPublished={isPublished}
        isLoading={isEntryPublishing || isEntrySaving}
        showPublishButton={showPublishButton}
        showUnpublishButton={showUnpublishButton}
        onStopEdition={onStopEdition}
        onPublish={onPublish}
        onUnpublish={onUnpublish}
        onDelete={onDelete}
        onClose={onClose}
        onPreview={onPreview}
      />
      <div className="entry-content">{children}</div>
    </div>
  );
};

EntryEdition.defaultProps = {
  isHighlighted: false,
  isPreviewed: false,
};

EntryEdition.propTypes = {
  isHighlighted: PropTypes.bool,
  isPreviewed: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onStopEdition: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EntryEdition;
