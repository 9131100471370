import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  deleteIndividualSequence,
  getIndividualSequenceById,
  isIndividualSequencePublishable,
  removeAttestationFromSequence,
  updateIndividualSequence,
} from 'slices/individualSequences';
import { INTERACTION_MODE, setInteractionMode } from 'slices/ui/thesaurus';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { AutoSaveInput } from 'components/Input';
import MainNuancesEditor from 'components/MainNuancesEditor';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import GraphyAttestation from 'components/Graphy/GraphyAttestation';
import { Stack } from 'components/Layout';
import Accordion from 'components/Accordion';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Button from 'components/Button';
import './IndividualSequence.scss';

const mainNuanceLocales = ['fr'];

const IndividualSequenceEdition = ({ id, individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sequence = useSelector((state) => getIndividualSequenceById(state, id));
  const [isPublishable] = usePublishable(sequence);
  const isSequencePublishable = useSelector((state) => isIndividualSequencePublishable(state, id));

  const publishedAttestationIds = get(sequence, 'published.attestationIds', []);
  const getHasUnpublishedAddedAttestation = (attestationId) =>
    !publishedAttestationIds.includes(attestationId);

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      edited: {
        transliteration: get(sequence, 'edited.transliteration', ''),
      },
    },
  });

  const onSubmit = (data) => dispatch(updateIndividualSequence({ id, data }));
  const onError = (errors, e) => console.log(errors, e);

  const handleMainNuanceUpdate = (locale, newNuance) =>
    onSubmit({ edited: { mainNuances: { [locale]: newNuance } } });

  const handleRemove = () => {
    dispatch(deleteIndividualSequence({ id, individualId }));
  };

  const handleSelectAttestation = () => {
    dispatch(
      setInteractionMode({
        interactionMode: INTERACTION_MODE.sequenceAttestationSelection,
        interactionModeTriggeredBy: {
          id: individualId,
          sequenceId: id,
        },
      })
    );
  };

  const handleRemoveAttestation = (attestationId) => {
    dispatch(removeAttestationFromSequence({ sequenceId: id, attestationId }));
  };

  return (
    <div className="individual-sequence--edition" data-publishable={isSequencePublishable}>
      <Accordion
        summary={
          <>
            <div className="individual-sequence-actions">
              <IconButton
                onMouseDown={preventEventDefaultAndStopPropagation}
                onClick={handleRemove}
                theme="transparent"
                data-testid="remove-sequence-from-individual-button"
              >
                <Icon size={22}>
                  <TrashIcon />
                </Icon>
              </IconButton>
            </div>
            <div data-publishable={isPublishable('transliteration')}>
              <AutoSaveInput
                italic
                serif
                multiline
                minRows={1}
                size="small"
                placeholder={t('entry.transliteration')}
                {...register('edited.transliteration', { required: true })}
                onSave={handleSubmit(onSubmit, onError)}
              />
            </div>
          </>
        }
      >
        <MainNuancesEditor
          resource={sequence}
          onUpdate={handleMainNuanceUpdate}
          locales={mainNuanceLocales}
        />

        {sequence.edited.attestationIds && (
          <Stack space="var(--s0)">
            {sequence.edited.attestationIds.map((attestationId) => {
              return (
                <div
                  key={attestationId}
                  data-publishable={getHasUnpublishedAddedAttestation(attestationId)}
                >
                  <GraphyAttestation
                    id={attestationId}
                    hideAttestationDetails
                    onRemove={handleRemoveAttestation}
                  />
                </div>
              );
            })}
          </Stack>
        )}

        <div data-publishable="false">
          <Button
            size="full"
            onClick={handleSelectAttestation}
            data-testid="individual-sequence-add-attestation-button"
          >
            {t('graphy.selectAttestation')}
          </Button>
        </div>
      </Accordion>
    </div>
  );
};

IndividualSequenceEdition.propTypes = {
  id: PropTypes.number.isRequired,
  individualId: PropTypes.number.isRequired,
};

export default IndividualSequenceEdition;
