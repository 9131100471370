import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getPeriodByValue } from 'slices/periods';

const SourcePeriods = ({ periods, isUncertain, t }) => {
  const startPeriod = useSelector((state) => getPeriodByValue(state, periods ? periods[0] : null));
  const endPeriod = useSelector((state) =>
    getPeriodByValue(state, periods ? periods[periods.length - 1] : null)
  );

  if (!startPeriod) {
    return null;
  }

  return (
    <div>
      {startPeriod && (
        <>
          <span dangerouslySetInnerHTML={{ __html: t(`periods.longName.${startPeriod.value}`) }} />
          {startPeriod.type === 'king' && (
            <span
              dangerouslySetInnerHTML={{
                __html: ` (${t(`periods.longName.${startPeriod.parentKey}`)})`,
              }}
            />
          )}
          {isUncertain && ' ?'}
        </>
      )}
      {periods.length > 1 && endPeriod && (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: ` - ${t(`periods.longName.${endPeriod.value}`)}`,
            }}
          />
          {endPeriod.type === 'king' && (
            <span
              dangerouslySetInnerHTML={{
                __html: ` (${t(`periods.longName.${endPeriod.parentKey}`)})`,
              }}
            />
          )}
          {isUncertain && ' ?'}
        </>
      )}
    </div>
  );
};

SourcePeriods.propTypes = {
  periods: null,
  isUncertain: false,
};

SourcePeriods.propTypes = {
  periods: PropTypes.arrayOf(PropTypes.string),
  isUncertain: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default SourcePeriods;
