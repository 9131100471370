import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTitleHolder,
  getTitleHolderIdsByGraphyIdAndAttestationId,
} from 'slices/titleHolders';
import IndividualSelector from 'components/IndividualSelector';
import { Stack } from 'components/Layout';
import GraphyAttestationTitleHolder from './GraphyAttestationTitleHolder';
import './GraphyAttestationTitleHolders.scss';

const GraphyAttestationTitleHolders = ({ graphyId, attestationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleHolderIds = useSelector((state) =>
    getTitleHolderIdsByGraphyIdAndAttestationId(state, graphyId, attestationId)
  );

  const handleAddNewTitleHolder = useCallback(
    (individualId) => {
      dispatch(
        createTitleHolder({
          individualId,
          graphyId,
          attestationId,
          edited: { isDateDisplayed: false },
        })
      );
    },
    [dispatch, graphyId, attestationId]
  );

  return (
    <div className="graphy-attestation-title-holders">
      <div className="graphy-attestation-title-holders-title">{t('titleHolders.title')}</div>
      <IndividualSelector placeholder={t('titleHolders.add')} onSelect={handleAddNewTitleHolder} />
      <Stack space="var(--s-2)">
        {titleHolderIds.map((titleHolderId) => (
          <GraphyAttestationTitleHolder key={titleHolderId} titleHolderId={titleHolderId} />
        ))}
      </Stack>
    </div>
  );
};

GraphyAttestationTitleHolders.propTypes = {
  graphyId: PropTypes.number.isRequired,
  attestationId: PropTypes.number.isRequired,
};

export default GraphyAttestationTitleHolders;
