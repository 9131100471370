import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { useDispatch, useSelector } from 'react-redux';
import { cond, constant, debounce, stubTrue } from 'lodash';
import { eq } from 'lodash/fp';
import {
  fetchNextEntrySummariesPage,
  selectFilteredEntries,
  selectSearchStatus,
  selectTotalFilteredEntries,
  hasNextPage,
  selectSearchParameters,
  updateSearchPosition,
  updateSearchLang,
  updateSearchType,
} from 'slices/search';
import { asyncState } from 'utils/util';
import { Cluster } from 'components/Layout';
import { WordSummary } from 'components/Word';
import { TitleSummary } from 'components/Title';
import { IndividualSummary } from 'components/Individual';
import SearchPopupFilterLang from './SearchPopupFilterLang';
import SearchPopupFilterPosition from './SearchPopupFilterPosition';
import './SearchPopup.scss';

const useWindowResizeEnd = (callback, delay = 200) => {
  useEffect(() => {
    callback();

    const debouncedResize = debounce(callback, delay);

    window.addEventListener('resize', debouncedResize);

    return function cleanup() {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [callback, delay]);
};

const searchTypes = ['entry', 'title', 'individual'];

const getFilterLangExludeFilters = (type) => {
  return cond([
    [eq('title'), constant(['comment'])],
    [eq('individual'), constant(['en', 'de', 'ar', 'comment'])],
    [stubTrue, constant([])],
  ])(type);
};

const SearchPopup = ({ virtuosoState, onClose }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const virtuosoRef = useRef(null);
  const resultListRef = useRef();
  const [resultListHeight, setResultListHeight] = useState();

  const entries = useSelector(selectFilteredEntries);
  const totalFilteredEntries = useSelector(selectTotalFilteredEntries);
  const searchStatus = useSelector(selectSearchStatus);
  const existNextPage = useSelector(hasNextPage);
  const searchParams = useSelector(selectSearchParameters);
  const hasSearch = searchParams.search !== undefined && searchParams.search !== '';
  const searchIsPending = asyncState.pending === searchStatus;
  const noResult = totalFilteredEntries === 0 && asyncState.resolved === searchStatus;

  const handleUpdateSearchPosition = (position) => {
    virtuosoState.current = undefined;
    dispatch(updateSearchPosition(position, i18n.resolvedLanguage));
  };

  const handleUpdateSearchLang = (lang) => {
    virtuosoState.current = undefined;
    dispatch(updateSearchLang(lang, i18n.resolvedLanguage));
  };

  const handleSetSearchType = (type) => {
    virtuosoState.current = undefined;
    dispatch(updateSearchType(type, i18n.resolvedLanguage));
  };

  const loadMoreItems = useCallback(() => {
    if (existNextPage) {
      return dispatch(fetchNextEntrySummariesPage(i18n.resolvedLanguage));
    }
  }, [existNextPage, dispatch, i18n.resolvedLanguage]);

  const isFirstSearchPending = searchIsPending && totalFilteredEntries === 0;
  const isLoadingNextPage = searchIsPending && totalFilteredEntries > 0;

  const searchPopupTitle = (type) => {
    let title = '';
    if (isFirstSearchPending) {
      title = t('searchBar.results.searching');
    } else if (totalFilteredEntries === 0) {
      title = t(`searchBar.results.${type}.title0`);
    } else if (totalFilteredEntries === 1) {
      title = t(`searchBar.results.${type}.title1`);
    } else if (totalFilteredEntries > 1) {
      title = t(`searchBar.results.${type}.titleN`, {
        totalElements: totalFilteredEntries,
      });
    }
    return title;
  };

  const calculateResultListHeight = useCallback(() => {
    if (resultListRef.current) {
      const { height } = resultListRef.current.getBoundingClientRect();
      setResultListHeight(height);
    }
  }, []);

  useWindowResizeEnd(calculateResultListHeight);

  useEffect(() => {
    let getState;

    if (!!resultListHeight && virtuosoRef.current) {
      getState = virtuosoRef.current.getState;
    }

    return function cleanup() {
      if (getState) {
        getState((snapshot) => {
          virtuosoState.current = snapshot;
        });
      }
    };
  }, [resultListHeight, virtuosoState]);

  return (
    <>
      <div className="search-popup-header">
        <div className="search-tabs">
          <Cluster space="var(--s-1)">
            {searchTypes.map((type) => (
              <button
                key={type}
                className={classnames('search-tab-title', {
                  'is-active': searchParams.type === type,
                })}
                onClick={() => handleSetSearchType(type)}
              >
                {t(`searchBar.options.types.${type}`)}
              </button>
            ))}
          </Cluster>
        </div>
        <div className="search-popup-filters" data-testid="searchFilters">
          <SearchPopupFilterLang
            onUpdate={handleUpdateSearchLang}
            excludeFilters={getFilterLangExludeFilters(searchParams.type)}
          />
          {searchParams.type !== 'individual' && (
            <SearchPopupFilterPosition onUpdate={handleUpdateSearchPosition} />
          )}
        </div>
        {(!searchIsPending || isLoadingNextPage) && (
          <div className="search-popup-entry-count">{searchPopupTitle(searchParams.type)}</div>
        )}
        {searchIsPending && !isLoadingNextPage && (
          <div className=" search-popup-entry-count search-popup-searching">
            {t('searchBar.results.searching')}
          </div>
        )}
      </div>

      <div className="search-popup-list" ref={resultListRef}>
        {noResult && (
          <div className="search-popup-empty-message">{t('searchBar.results.noresult')}</div>
        )}
        {!!resultListHeight && (
          <Virtuoso
            ref={virtuosoRef}
            restoreStateFrom={virtuosoState.current}
            style={{ height: resultListHeight }}
            data={entries}
            endReached={loadMoreItems}
            overscan={600}
            itemContent={(_, entry) => {
              if (searchParams.type === 'entry') {
                return <WordSummary isHighlighted={hasSearch} entry={entry} onSelect={onClose} />;
              } else if (searchParams.type === 'title') {
                return <TitleSummary isHighlighted={hasSearch} entry={entry} onSelect={onClose} />;
              } else if (searchParams.type === 'individual') {
                return (
                  <IndividualSummary isHighlighted={hasSearch} entry={entry} onSelect={onClose} />
                );
              }
              return null;
            }}
          />
        )}

        {isLoadingNextPage && (
          <center>
            <div className="rotateWaiting" />
          </center>
        )}
      </div>
    </>
  );
};

SearchPopup.defaultProps = {
  virtuosoState: { current: undefined },
};

SearchPopup.propTypes = {
  virtuosoState: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default SearchPopup;
