import React from 'react';
import PropTypes from 'prop-types';
import './TlaLinkDecorator.scss';

const TlaLinkDecorator = ({ decoratedText, children }) => {
  return (
    <a
      className="tla-link-decorator"
      href={`https://thesaurus-linguae-aegyptiae.de/lemma/${decoratedText}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

TlaLinkDecorator.propTypes = {
  decoratedText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TlaLinkDecorator;
