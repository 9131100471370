import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import IndividualTitleEdition from './IndividualTitleEdition';

const SortableIndividualTitleEdition = ({ id, onRemove }) => {
  const {
    isDragging,
    isSorting,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <IndividualTitleEdition
      ref={setNodeRef}
      style={style}
      {...attributes}
      listeners={listeners}
      handleProps={{ ref: setActivatorNodeRef }}
      id={id}
      isDragging={isDragging}
      isSorting={isSorting}
      onRemove={onRemove}
    />
  );
};

export default SortableIndividualTitleEdition;
