import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  createIndividualTitulary,
  deleteIndividualTitulary,
  getMainTitularyIdsByIndividualId,
  getOtherTitularyIdsByIndividualId,
  updateMainTitulariesRanks,
  updateOtherTitulariesRanks,
} from 'slices/individualTitularies';
import { Stack } from 'components/Layout';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualTitlesSection from './IndividualTitlesSection';
import './IndividualTitles.scss';

const IndividualTitlesEdition = ({ individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mainTitularyIds = useSelector((state) =>
    getMainTitularyIdsByIndividualId(state, individualId)
  );
  const otherTitularyIds = useSelector((state) =>
    getOtherTitularyIdsByIndividualId(state, individualId)
  );

  const handleAddNewMainTitulary = useCallback(
    (newTitle) => {
      const { id: titleId } = newTitle;
      dispatch(
        createIndividualTitulary({
          individualId,
          data: { edited: { type: 'main' }, titleId },
          isMain: true,
        })
      );
    },
    [dispatch, individualId]
  );

  const handleAddNewOtherTitulary = useCallback(
    (newTitle) => {
      const { id: titleId } = newTitle;
      dispatch(
        createIndividualTitulary({
          individualId,
          data: { edited: { type: 'other' }, titleId },
          isMain: false,
        })
      );
    },
    [dispatch, individualId]
  );

  const handleRemoveTitulary = useCallback(
    (titularyId) => {
      dispatch(deleteIndividualTitulary({ individualId, id: titularyId }));
    },
    [dispatch, individualId]
  );

  const handleReorderMainTitularies = useCallback(
    ({ oldIndex, newIndex }) => {
      dispatch(updateMainTitulariesRanks(individualId, oldIndex, newIndex));
    },
    [dispatch, individualId]
  );

  const handleRorderOtherTitularies = useCallback(
    ({ oldIndex, newIndex }) => {
      dispatch(updateOtherTitulariesRanks(individualId, oldIndex, newIndex));
    },
    [dispatch, individualId]
  );

  return (
    <EntryTabContentLayout title={t('individualTitles.title')}>
      <Stack space="var(--s0)">
        <IndividualTitlesSection
          title={t('individualTitles.rankTitles')}
          titularyIds={mainTitularyIds}
          onAdd={handleAddNewMainTitulary}
          onRemove={handleRemoveTitulary}
          onReorder={handleReorderMainTitularies}
        />
        <IndividualTitlesSection
          title={t('individualTitles.otherTitles')}
          titularyIds={otherTitularyIds}
          onAdd={handleAddNewOtherTitulary}
          onRemove={handleRemoveTitulary}
          onReorder={handleRorderOtherTitularies}
        />
      </Stack>
    </EntryTabContentLayout>
  );
};

IndividualTitlesEdition.propTypes = {
  individualId: PropTypes.number.isRequired,
};

export default IndividualTitlesEdition;
