import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { highlightWord, isWordOpened, openAndLoadWord, resetWordHihglight } from 'slices/words';
import EntrySummary from 'components/Entry/EntrySummary';
import { EntryPublicStatus } from 'components/PublicStatus';

const WordSummary = forwardRef(({ entry, isHighlighted, onSelect }, ref) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isWordOpened(state, entry.id));

  const handleSelect = () => {
    dispatch(resetWordHihglight());
    if (!isOpen) {
      dispatch(openAndLoadWord(entry.id, !entry.published));
      onSelect();
    }
  };

  const handleEnter = () => {
    dispatch(highlightWord(entry.id));
  };

  const handleLeave = () => {
    dispatch(resetWordHihglight());
  };

  return (
    <EntrySummary
      ref={ref}
      entry={entry}
      isHighlighted={isHighlighted}
      isOpen={isOpen}
      publicStatusComponent={EntryPublicStatus}
      onSelect={handleSelect}
      onEnter={handleEnter}
      onLeave={handleLeave}
    />
  );
});

WordSummary.defaultProps = {
  isHighlighted: false,
};

WordSummary.propTypes = {
  entry: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
};

export default WordSummary;
