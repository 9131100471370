import PropTypes from 'prop-types';
import { Cluster, Stack } from 'components/Layout';

const EntryEditionMainInfoLayout = ({ children, actionsBarLeft, actionsBarRight }) => {
  return (
    <div style={{ padding: '0 var(--s-1)' }}>
      <Stack space="var(--s0)">
        <Cluster align="center" justify="space-between" data-testid="entry-informations">
          <Cluster align="center" space="var(--s-1)">
            {actionsBarLeft}
          </Cluster>
          {actionsBarRight}
        </Cluster>

        {children}
      </Stack>
    </div>
  );
};

EntryEditionMainInfoLayout.defaultProps = {
  actionsBarRight: null,
};

EntryEditionMainInfoLayout.propTypes = {
  actionsBarLeft: PropTypes.node.isRequired,
  actionsBarRight: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default EntryEditionMainInfoLayout;
