import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getIndividualTitularyById } from 'slices/individualTitularies';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { ReactComponent as DragHandleIcon } from 'images/icon-drag-handle.svg';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import './IndividualTitle.scss';

const IndividualTitleEdition = forwardRef(
  (
    { id, onRemove, isDragging, isDragOverlay, isSorting, handleProps, listeners, ...rest },
    ref
  ) => {
    const { t } = useTranslation();
    const titulary = useSelector((state) => getIndividualTitularyById(state, id));
    const { title } = titulary;
    const [isPublishable] = usePublishable(titulary);

    return (
      <div
        className={classNames('individual-title--edition', {
          'is-dragging': isDragging,
          'is-sorting': isSorting,
          'is-drag-overlay': isDragOverlay,
        })}
        data-publishable={isPublishable() || isPublishable('rank')}
        ref={ref}
        {...rest}
      >
        <div className="individual-title-handle" {...handleProps} {...listeners}>
          <DragHandleIcon />
        </div>
        <div>
          <span>{title.transliteration}</span>
          {!title.published && (
            <span className="individual-title-unpublished-label"> ({t('words.unpublished')})</span>
          )}
        </div>
        <div className="individual-title-action">
          <IconButton
            onMouseDown={preventEventDefaultAndStopPropagation}
            onClick={() => onRemove(id)}
            theme="transparent"
            data-testid="remove-name-from-individual-button"
          >
            <Icon size={22}>
              <TrashIcon />
            </Icon>
          </IconButton>
        </div>
      </div>
    );
  }
);

IndividualTitleEdition.propTypes = {
  id: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default IndividualTitleEdition;
