import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, upperFirst } from 'lodash';
import { ENTRY_STATE, resetEntryState, setEntryState } from 'slices/ui/entryLoading';
import { isNotEmptyHtml } from 'utils/util';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const getSelectors = (name, index, byIndexId) => {
  const sliceSelector = (state) => state[name];
  const indexBySliceSelector = (state) => sliceSelector(state)[byIndexId];

  const getCommentByEntryId = (state, entryId) => indexBySliceSelector(state)[entryId];

  const doesEntryHaveComment = (state, entryId, publishableProp) => {
    const comment = getCommentByEntryId(state, entryId);
    const version = get(comment, `${publishableProp}.text`, null);
    return isNotEmptyHtml(version);
  };

  return {
    [`get${upperFirst(byIndexId)}`]: getCommentByEntryId,
    [`does${upperFirst(index)}HaveOne`]: doesEntryHaveComment,
  };
};

// ------------------------------------
// Actions
// ------------------------------------

const getActions = (name, indexIdKey, byIndexId, selectors, sliceActions, commentsDataService) => {
  const { resetCommentOfEntry } = sliceActions;

  const actionName = (action) => `${name}/${action}`;
  const getIdsByIndexId = selectors[`get${upperFirst(byIndexId)}`];

  const loadComment = createAsyncThunk(actionName('load'), async (arg) => {
    const res = await commentsDataService.get(arg[indexIdKey], arg.isEditVersion);
    return res.data;
  });

  const unpublishEntryComment = createAsyncThunk(
    actionName('unpublish'),
    async (entryId, { getState }) => {
      const comment = getIdsByIndexId(getState(), entryId);
      const res = await commentsDataService.unpublish(comment);
      return res.data;
    }
  );

  const publishEntryComment = createAsyncThunk(
    actionName('publish'),
    async (entryId, { getState }) => {
      const comment = getIdsByIndexId(getState(), entryId);
      const res = await commentsDataService.publish(comment);
      return res.data;
    }
  );

  const createOrUpdateComment = createAsyncThunk(
    actionName('createOrUpdate'),
    async (comment, { dispatch }) => {
      dispatch(setEntryState({ entryId: comment.entryId, entryState: ENTRY_STATE.saving }));

      const res = await commentsDataService.createOrUpdate(comment);

      dispatch(resetEntryState({ entryId: comment.entryId }));

      return res.data;
    }
  );

  return {
    loadComment,
    unpublishEntryComment,
    publishEntryComment,
    createOrUpdateComment,
    resetCommentOfEntry,
  };
};

function commentsFactory(name, indexBy, extraReducers, commentsDataService) {
  const Index = upperFirst(indexBy);
  const byIndexId = `by${Index}Id`;
  const indexIdKey = `${indexBy}Id`;
  const selectors = getSelectors(name, indexBy, byIndexId);

  const initialState = {
    [byIndexId]: {},
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      resetCommentOfEntry: (state, { payload: entryId }) => {
        delete state[byIndexId][entryId];
      },
    },
    extraReducers: (builder) => {
      const replace = (state, action) => {
        state[byIndexId][action.payload[indexIdKey]] = action.payload;
      };

      builder
        .addCase(actions.loadComment.fulfilled, replace)
        .addCase(actions.createOrUpdateComment.fulfilled, replace)
        .addCase(actions.unpublishEntryComment.fulfilled, replace)
        .addCase(actions.publishEntryComment.fulfilled, replace)
        .addCase(logout.fulfilled, () => {
          return initialState;
        });

      Object.entries(extraReducers).forEach(([type, reducer]) => {
        builder.addCase(type, reducer);
      });
    },
  });

  const { reducer } = slice;

  const actions = getActions(
    name,
    indexIdKey,
    byIndexId,
    selectors,
    slice.actions,
    commentsDataService
  );

  return {
    reducer,
    selectors,
    actions,
  };
}

export default commentsFactory;
