import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { openPrintEntry } from 'slices/ui/printEntry';
import { ReactComponent as InfoIcon } from 'images/icon-info.svg';
import { ReactComponent as PermalinkIcon } from 'images/icon-link.svg';
import { ReactComponent as PrintIcon } from 'images/icon-print.svg';
import Menu, { MenuItem, MENU_THEMES } from 'components/Menu';
import Copy from 'components/Copy';
import Icon from 'components/Icon';
import { generateEntryPermalink } from './utils';
import './EntryInfoPopup.scss';

const EntryInfoPopup = ({ entryPrefix, entryId, saveDate, publishDate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getDateInfo = () => {
    let dateInfo = '';
    if (saveDate && publishDate && saveDate > publishDate) {
      dateInfo = t('entry.statusMessage.success', {
        date: new Date(saveDate),
      });
    } else if (publishDate) {
      dateInfo = t('entry.publishMessage', {
        date: new Date(publishDate),
      });
    }

    return dateInfo;
  };

  return (
    <div className="entry-info-popup" data-testid="entry-info-popup">
      <Icon size={22}>
        <InfoIcon />
      </Icon>
      <Menu theme={MENU_THEMES.light}>
        <div className="entry-info-popup-content">
          <div className="entry-id">
            <b>ID {entryId}</b>
          </div>
          <div className="entry-save-message">{getDateInfo()}</div>
        </div>
        <Copy
          text={generateEntryPermalink(`${entryPrefix}${entryId}`)}
          render={(isCopied, onCopy) => (
            <MenuItem onClick={onCopy} isActive={isCopied}>
              <Icon size={22}>
                <PermalinkIcon />
              </Icon>
              {isCopied ? t('entry.permalink.copied') : t('entry.permalink.toCopy')}
            </MenuItem>
          )}
        />
        <MenuItem onClick={() => dispatch(openPrintEntry(entryId))}>
          <Icon size={22}>
            <PrintIcon />
          </Icon>
          {t('actions.print')}
        </MenuItem>
      </Menu>
    </div>
  );
};

EntryInfoPopup.defaultProps = {
  publishDate: null,
  saveDate: null,
};

EntryInfoPopup.propTypes = {
  entryId: PropTypes.number.isRequired,
  publishDate: PropTypes.number,
  saveDate: PropTypes.number,
};

export default EntryInfoPopup;
