import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { SOURCE_TYPES } from 'utils/constants';
import { getSourceById, updateSource } from 'slices/sources';
import { Cluster, Stack } from 'components/Layout';
import { AutoSaveInput } from 'components/Input';
import Editor from 'components/Editor';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import PeriodsSelector from 'components/PeriodsSelector';
import DropdownMenu from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import SourceIcon from './SourceIcon';
import './SourceForm.scss';

const SourceEditForm = ({ id, canBeDeleted, onEnd, onDelete }) => {
  const { t } = useTranslation();
  const source = useSelector((state) => getSourceById(state, id));
  const dispatch = useDispatch();

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: get(source, 'title', ''),
      inventoryNumber: get(source, 'inventoryNumber', ''),
      isPeriodUncertain: get(source, 'isPeriodUncertain', false),
      hieratic: get(source, 'hieratic', false),
      origin: get(source, 'origin', ''),
      webography: get(source, 'webography', ''),
      webographyConsultedAt: get(source, 'webographyConsultedAt', ''),
      karnakId: get(source, 'karnakId', ''),
    },
  });

  const handleUpdate = (data) => {
    return dispatch(updateSource({ ...source, ...data, id }));
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <form className="source-form" dir="auto">
      <Stack space="var(--s0)">
        <Cluster align="center" space="var(--s-2)">
          <DropdownMenu
            itemsCount={Object.keys(SOURCE_TYPES).length}
            renderButton={(buttonProps) => (
              <Button {...buttonProps}>
                <SourceIcon type={source.type} />
              </Button>
            )}
          >
            {Object.values(SOURCE_TYPES).map((type) => (
              <MenuItem key={type} onClick={() => handleUpdate({ type })}>
                <SourceIcon type={type} />
                <span>{t(`source.types.${type}`)}</span>
              </MenuItem>
            ))}
          </DropdownMenu>
          <AutoSaveInput
            serif
            placeholder={t(`source.types.${source.type}`)}
            {...register('title', { required: true })}
            onSave={handleSubmit(handleUpdate, onError)}
          />
        </Cluster>
        <AutoSaveInput
          size="extra-small"
          placeholder={t('source.inventoryNumber')}
          {...register('inventoryNumber')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <PeriodsSelector
          values={source.periods}
          onChange={(periods) => handleUpdate({ periods })}
        />
        <Checkbox
          label={t(`source.isPeriodUncertain`)}
          size="small"
          {...register('isPeriodUncertain')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <Checkbox
          label={t(`source.hieratic`)}
          size="small"
          {...register('hieratic')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <AutoSaveInput
          serif
          size="extra-small"
          layout="horizontal"
          label={t(`source.origin`)}
          placeholder={t('source.originPlaceholder')}
          {...register('origin')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <div
          className="input-container input-container--borderless is-serif"
          data-size="extra-small"
        >
          <label>{t('source.bibliography')}</label>
          <Editor
            placeholder="…"
            initialValue={source.bibliography}
            onValidate={(value) => handleUpdate({ bibliography: value })}
          />
        </div>
        <AutoSaveInput
          size="extra-small"
          multiline
          label={t('source.webography')}
          {...register('webography')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <AutoSaveInput
          size="extra-small"
          layout="horizontal"
          label={t('source.webographyConsultedAt')}
          {...register('webographyConsultedAt')}
          onSave={handleSubmit(handleUpdate, onError)}
        />
        <AutoSaveInput
          size="extra-small"
          layout="horizontal"
          label="KIU"
          placeholder="123"
          {...register('karnakId')}
          onSave={handleSubmit(handleUpdate, onError)}
        />

        <Cluster justify="space-between" className="source-form-toolbar">
          <Button size="small" theme="red" onClick={() => onDelete(id)} disabled={!canBeDeleted}>
            {t('actions.remove')}
          </Button>
          {formState.isValid && (
            <Button size="small" theme="green" onClick={onEnd}>
              {t('actions.end')}
            </Button>
          )}
        </Cluster>
      </Stack>
    </form>
  );
};

SourceEditForm.defaultProps = {
  canBeDeleted: false,
};

SourceEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  canBeDeleted: PropTypes.bool,
  onEnd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SourceEditForm;
