import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu, { MenuItem } from 'components/Menu';
import FlagIcon from 'components/FlagIcon';
import './AppLangMenu.scss';

const LANGUAGES = {
  fr: 'Français',
  en: 'English',
  de: 'Deutsch',
  ar: 'العربية',
};

const AppLangMenu = () => {
  const { i18n } = useTranslation();

  const handleChangeLang = (selectedLang) => {
    return (evt) => {
      evt.preventDefault();
      i18n.changeLanguage(selectedLang);
    };
  };

  return (
    <div className="app-lang-menu" data-testid="app-lang-menu">
      <FlagIcon iso={i18n.resolvedLanguage} size="medium" />
      <Menu dir={i18n.dir()}>
        {Object.entries(LANGUAGES).map(([lang, label]) => (
          <MenuItem
            key={lang}
            data-testid={`app-lang-popup-${lang}`}
            onClick={handleChangeLang(lang)}
          >
            <span className="locale-name" dir="auto">
              {label}
            </span>
            <FlagIcon iso={lang} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AppLangMenu;
