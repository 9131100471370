import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIndividualRelativeById } from 'slices/individualRelatives';
import EntryNuance from 'components/Entry/EntryNuance';
import Transliteration from 'components/Transliteration';
import Tag from 'components/Tag';
import { Cluster } from 'components/Layout';
import { RelativePublicStatus } from 'components/PublicStatus';
import { openAndLoadIndividual } from 'slices/individuals';

const IndividualRelativeRead = ({ id, publishableProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const relative = useSelector((state) => getIndividualRelativeById(state, id));
  const isUnknown = get(relative, 'related.transliteration', '').toLowerCase() === 'inconnu';
  const isCollective = get(relative, 'related.transliteration', '').toLowerCase() === 'collectif';

  return (
    <div
      className="individual-relative"
      onClick={() => dispatch(openAndLoadIndividual(relative.related.id))}
    >
      <Cluster space="var(--s-1)">
        <Tag>{t(`individualRelatives.relationTypes.${relative[publishableProp].type}`)}</Tag>
        <RelativePublicStatus status={relative.publicStatus} hasTooltip />
      </Cluster>
      {!isUnknown && !isCollective && (
        <h2 className="individual-relative-transliteration">
          <Transliteration>{relative.related.transliteration}</Transliteration>
        </h2>
      )}
      <EntryNuance nuances={relative.related.mainNuances} />
    </div>
  );
};

IndividualRelativeRead.defaultProps = {
  publishableProp: 'published',
};

IndividualRelativeRead.propTypes = {
  id: PropTypes.number.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']),
};

export default IndividualRelativeRead;
