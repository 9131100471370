import { isNil } from 'lodash';

const areAttrEquals = (event, expected, attr) =>
  !isNil(event[attr]) && event[attr] === expected[attr];

export const isMacintosh = (platform = window.navigator.platform) =>
  platform.toUpperCase().indexOf('MAC') > -1 === true;

export function isKeyPressed(event, expected) {
  return (
    areAttrEquals(event, expected, 'key') ||
    areAttrEquals(event, expected, 'keyCode') ||
    areAttrEquals(event, expected, 'charCode') ||
    areAttrEquals(event, expected, 'code')
  );
}

export function isTabKeyPressed(event) {
  return isKeyPressed(event, { key: 'Tab', keyCode: 9 });
}

export function isShiftKeyPressed(event) {
  return event.shiftKey === true;
}

export function isCtrlPressed(event, platform) {
  return (
    ((event.metaKey && isMacintosh(platform)) || (event.ctrlKey && !isMacintosh(platform))) === true
  );
}

export function isEnterKeyPressed(event) {
  return isKeyPressed(event, { key: 'Enter', keyCode: 13 });
}

export function isEscapeKeyPressed(event) {
  return isKeyPressed(event, { key: 'Escape', keyCode: 27 });
}

export function stopEventPropagation(e) {
  e.stopPropagation();
}

export function preventEventDefault(e) {
  e.preventDefault();
}

export function preventEventDefaultAndStopPropagation(e) {
  preventEventDefault(e);
  stopEventPropagation(e);
}
