import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadTitle } from 'slices/titles';
import LinkPopover from './LinkPopover';

const TitleLinkPopover = ({ entityKey, titleId, onClose, onDelete }) => {
  const dispatch = useDispatch();

  const handleOpeningTitle = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadTitle(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkPopover
      entityKey={entityKey}
      type="title"
      id={titleId}
      onOpen={handleOpeningTitle}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

TitleLinkPopover.propTypes = {
  entityKey: PropTypes.string.isRequired,
  titleId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TitleLinkPopover;
