import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { isNotNil } from 'utils/util';
import { getPeriods, getPeriodsGrouped } from 'slices/periods';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import { components, getTheme } from './theme';
import { getPeriodsBetweenIntervalBounds, getEndPeriodValue } from './utils';
import { Cluster } from 'components/Layout';

const formatOptionLabel = (t) => (option) =>
  (
    <span
      dangerouslySetInnerHTML={{
        __html: t(`periods.longName.${option.label}`),
      }}
    />
  );

const formatGroupLabel = (t) => (groupOption) =>
  (
    <span
      dangerouslySetInnerHTML={{
        __html: t(
          `periods.longName.${groupOption.label === 'root-period' ? 'periods' : groupOption.label}`
        ),
      }}
    />
  );

const periodTypes = ['single', 'interval'];

const PeriodsSelector = ({ label, isSingle, values, onChange }) => {
  const { i18n, t } = useTranslation();
  const periods = useSelector(getPeriods);
  const groupedPeriods = useSelector(getPeriodsGrouped);
  const [selectedPeriodType, setSelectedPeriodType] = useState(
    values.length > 1 ? periodTypes[1] : periodTypes[0]
  );
  const isInterval = selectedPeriodType === 'interval';
  const [periodsOptions, setPeriodsOptions] = useState(() => {
    return isInterval
      ? groupedPeriods
      : [
          { label: 'other', options: [{ label: 'root-period', value: 'root-period' }] },
          ...groupedPeriods,
        ];
  });
  const [flatPeriodsOptions, setFlatPeriodsOptions] = useState(() => {
    return isInterval ? periods : [{ label: 'root-period', value: 'root-period' }, ...periods];
  });
  const [currentValue, setCurrentValue] = useState(
    flatPeriodsOptions.filter(({ value: periodValue }) => values.includes(periodValue))
  );

  const handleChange = useCallback(
    (newValue, actionMeta) => {
      let val;
      if (actionMeta.name === 'periodStart') {
        if (actionMeta.action === 'select-option') {
          if (isInterval && currentValue.filter(isNotNil).length > 1) {
            val = getPeriodsBetweenIntervalBounds(
              newValue.value,
              getEndPeriodValue(currentValue),
              flatPeriodsOptions
            );
          } else {
            val = [newValue.value];
          }
        } else if (actionMeta.action === 'remove-value' || actionMeta.action === 'clear') {
          val = [];
        }
        setCurrentValue(
          flatPeriodsOptions.filter(({ value: periodValue }) => val.includes(periodValue))
        );
        onChange(val);
      } else if (actionMeta.name === 'periodEnd') {
        if (actionMeta.action === 'select-option') {
          val = getPeriodsBetweenIntervalBounds(
            currentValue[0].value,
            newValue.value,
            flatPeriodsOptions
          );
        } else if (actionMeta.action === 'remove-value' || actionMeta.action === 'clear') {
          val = [currentValue[0].value];
        }
        setCurrentValue(
          flatPeriodsOptions.filter(({ value: periodValue }) => val.includes(periodValue))
        );
        onChange(val);
      }
    },
    [onChange, currentValue, isInterval, flatPeriodsOptions]
  );

  const handleSelectPeriodType = useCallback(
    (type) => {
      if (type === periodTypes[0]) {
        setPeriodsOptions([
          { label: 'other', options: [{ label: 'root-period', value: 'root-period' }] },
          ...groupedPeriods,
        ]);
        setFlatPeriodsOptions([{ label: 'root-period', value: 'root-period' }, ...periods]);
        if (currentValue[0]) {
          setCurrentValue([currentValue[0]]);
          onChange([currentValue[0].value]);
        } else {
          setCurrentValue([]);
          onChange([]);
        }
      } else {
        if (currentValue[0] && currentValue[0].value === 'root-period') {
          setCurrentValue([]);
          onChange([]);
        }
        setPeriodsOptions(groupedPeriods);
        setFlatPeriodsOptions(periods);
      }
      setSelectedPeriodType(type);
    },
    [onChange, currentValue, groupedPeriods, periods]
  );

  return (
    <div className="input-container input-container--borderless is-serif" data-size="extra-small">
      <Cluster space="var(--s-2)" align="center">
        {label && <label>{label}</label>}
        {!isSingle && (
          <DropdownMenu
            itemsCount={periodTypes.length}
            renderButton={(buttonProps) => (
              <DropdownButton {...buttonProps}>
                <label>{t(`periods.periodTypes.${selectedPeriodType}`)}</label>
              </DropdownButton>
            )}
          >
            {periodTypes.map((type) => (
              <MenuItem
                key={type}
                isSelectable
                isSelected={selectedPeriodType === type}
                onClick={() => handleSelectPeriodType(type)}
              >
                {t(`periods.periodTypes.${type}`)}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}
      </Cluster>
      {isInterval && <div className="source-form-period-selector-label">{t('periods.from')}</div>}
      <Select
        name="periodStart"
        aria-label={t('periods.from')}
        placeholder={t('periods.selectorPlaceholder')}
        options={periodsOptions}
        value={currentValue[0] || null}
        theme={getTheme}
        classNamePrefix="period-selector"
        isClearable={!isInterval || currentValue.length < 2}
        isRtl={i18n.dir() === 'rtl'}
        isSearchable
        styles={components}
        formatGroupLabel={formatGroupLabel(t)}
        formatOptionLabel={formatOptionLabel(t)}
        isOptionDisabled={(option) => {
          if (!isInterval || currentValue.length < 2 || isNil(getEndPeriodValue(currentValue))) {
            return false;
          }
          const endIndex = flatPeriodsOptions.findIndex(
            ({ value }) => getEndPeriodValue(currentValue) === value
          );
          const optionIndex = flatPeriodsOptions.findIndex(({ value }) => option.value === value);
          return optionIndex >= endIndex;
        }}
        onChange={handleChange}
      />

      {isInterval && (
        <>
          <div className="source-form-period-selector-label">{t('periods.to')}</div>
          <Select
            name="periodEnd"
            aria-label={t('periods.to')}
            placeholder={t('periods.selectorPlaceholder')}
            options={periodsOptions}
            value={currentValue.length >= 2 ? currentValue[currentValue.length - 1] : null}
            theme={getTheme}
            classNamePrefix="period-selector"
            isClearable
            isRtl={i18n.dir() === 'rtl'}
            isSearchable
            isDisabled={isNil(currentValue[0])}
            styles={components}
            formatGroupLabel={formatGroupLabel(t)}
            formatOptionLabel={formatOptionLabel(t)}
            isOptionDisabled={(option) => {
              const startIndex = flatPeriodsOptions.findIndex(
                ({ value }) => currentValue[0].value === value
              );
              const optionIndex = flatPeriodsOptions.findIndex(
                ({ value }) => option.value === value
              );
              return optionIndex <= startIndex || currentValue[0].value === option.parentKey;
            }}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
};

PeriodsSelector.defaultProps = {
  label: null,
  isSingle: false,
  values: [],
};

PeriodsSelector.propTypes = {
  label: PropTypes.string,
  isSingle: PropTypes.bool,
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default PeriodsSelector;
