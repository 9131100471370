import { NUANCE_PUBLIC_STATUS } from 'utils/constants';
import PublicStatusSelector from './PublicStatusSelector';

const statusList = Object.values(NUANCE_PUBLIC_STATUS);

const NuancePublicStatusSelector = (props) => {
  return <PublicStatusSelector statusList={statusList} {...props} translationKey="nuance" />;
};

export default NuancePublicStatusSelector;
