import { TITLE_PUBLIC_STATUS } from 'utils/constants';
import PublicStatusSelector from './PublicStatusSelector';

const statusList = Object.values(TITLE_PUBLIC_STATUS);

const TitlePublicStatusSelector = (props) => {
  return <PublicStatusSelector statusList={statusList} {...props} translationKey="title" />;
};

export default TitlePublicStatusSelector;
