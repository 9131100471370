import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Cluster, Stack } from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { updateTitle, TITLE_PREFIX } from 'slices/titles';
import { EDITION_STATUS } from 'utils/constants';
import { selectors as titleCommentsSelectors } from 'slices/titleComments';
import {
  doEntryHasPublishableTitleHolders,
  selectors as titleGraphiesSelectors,
} from 'slices/titleGraphies';
import { selectors as titleDictionaryReferencesSelectors } from 'slices/titleDictionaryReferences';
import usePublishable from 'hooks/usePublishable';
import useCapeaDictionaryReferencesPublishable from 'hooks/useCapeaDictionaryReferencesPublishable';
import { ReactComponent as GraphiesIcon } from 'images/icon-tab-graphies.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import { ReactComponent as DictionariesIcon } from 'images/icon-tab-dictionaries.svg';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import EntryEditionStatus from 'components/Entry/EntryEditionStatus';
import { TitlePublicStatusSelector } from 'components/PublicStatus';
import EntryInfoPopup from 'components/Entry/EntryInfoPopup';
import EntryTabs from 'components/Entry/EntryTabs';
import GraphiesEdition from 'components/TitleGraphies/GraphiesEdition';
import TitleDictionaryReferences from 'components/TitleDictionaryReferences';
import { AutoSaveInput } from 'components/Input';
import Tag from 'components/Tag';
import TitleComments from 'components/TitleComments';
import MainNuancesEditor from 'components/MainNuancesEditor';
import EntryEditionMainInfoLayout from 'components/Entry/EntryEditionMainInfoLayout';
import { get } from 'lodash';

const TitleEditionContent = ({ entry }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPublishable] = usePublishable(entry);
  const isGraphiesPublishable = useSelector((state) =>
    titleGraphiesSelectors.isAllOfTitlePublishable(state, entry.id)
  );
  const isGraphiesTitleHoldersPublishable = useSelector((state) =>
    doEntryHasPublishableTitleHolders(state, entry.id)
  );
  const comment = useSelector((state) => titleCommentsSelectors.getByTitleId(state, entry.id));
  const dictionaryRefs = useSelector((state) =>
    titleDictionaryReferencesSelectors.getByTitleId(state, entry.id)
  );
  const [isCommentPublishable] = usePublishable(comment);
  const isDictionaryRefsPublishable = useCapeaDictionaryReferencesPublishable(dictionaryRefs);
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      edited: {
        transliteration: get(entry, 'edited.transliteration', ''),
      },
    },
  });
  const onSubmit = (data) => dispatch(updateTitle({ id: entry.id, data }));
  const onError = (errors, e) => console.log(errors, e);

  const handleMainNuanceUpdate = (locale, newNuance) =>
    onSubmit({ edited: { mainNuances: { [locale]: newNuance } } });

  const handleEditionStatusUpdate = (newEditionStatus) => {
    dispatch(updateTitle({ id: entry.id, data: { editionStatus: newEditionStatus } }));
  };

  const handlePublicStatusUpdate = (newPublicStatus) => {
    dispatch(updateTitle({ id: entry.id, data: { publicStatus: newPublicStatus } }));
  };

  return (
    <Stack space="var(--s0)">
      <EntryEditionMainInfoLayout
        actionsBarLeft={
          <>
            <EntryInfoPopup
              entryPrefix={TITLE_PREFIX}
              entryId={entry.id}
              saveDate={entry.saveDate}
              publishDate={entry.publishDate}
            />
            <TitlePublicStatusSelector
              status={entry.publicStatus}
              onSelect={handlePublicStatusUpdate}
            />
            <DropdownMenu
              itemsCount={2}
              renderButton={(buttonProps) => (
                <DropdownButton {...buttonProps}>
                  <EntryEditionStatus status={entry.editionStatus} />
                </DropdownButton>
              )}
            >
              {Object.values(EDITION_STATUS).map((status) => (
                <MenuItem
                  key={status}
                  isSelectable
                  isSelected={entry.editionStatus === status}
                  onClick={() => handleEditionStatusUpdate(status)}
                >
                  {t(`entry.editionStatus.${status}`)}
                </MenuItem>
              ))}
            </DropdownMenu>
          </>
        }
      >
        <div data-publishable={isPublishable('transliteration')}>
          <AutoSaveInput
            italic
            serif
            size="large"
            placeholder={t('entry.transliteration')}
            {...register('edited.transliteration', { required: true })}
            onSave={handleSubmit(onSubmit, onError)}
          />
        </div>

        <div className="entry-natures">
          <Cluster space="var(--s-2)">
            <div>{t('entry.natures.label')}</div>
            <Tag>{t('entry.natures.types.title')}</Tag>
          </Cluster>
        </div>

        <MainNuancesEditor resource={entry} onUpdate={handleMainNuanceUpdate} />
      </EntryEditionMainInfoLayout>

      <EntryTabs
        selectors={[
          <GraphiesIcon
            id="graphies"
            data-publishable={isGraphiesPublishable || isGraphiesTitleHoldersPublishable}
          />,
          <DictionariesIcon id="dictionaries" data-publishable={isDictionaryRefsPublishable} />,
          <CommentsIcon id="comments" data-publishable={isCommentPublishable('text')} />,
        ]}
      >
        <div id="graphies">
          <GraphiesEdition titleId={entry.id} />
        </div>
        <div id="dictionaries">
          <TitleDictionaryReferences titleId={entry.id} isEditVersion />
        </div>
        <div id="comments">
          <TitleComments titleId={entry.id} isEditVersion />
        </div>
      </EntryTabs>
    </Stack>
  );
};

TitleEditionContent.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default TitleEditionContent;
