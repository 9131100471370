export const PUBLIC_STATUS = {
  validated: 'validated',
  wrong: 'wrong',
  wrongGraphy: 'wrongGraphy',
  problematic: 'problematic',
};

export const TITLE_PUBLIC_STATUS = {
  validated: 'validated',
  problematic: 'problematic',
  wrong: 'wrong',
};

export const GRAPHY_PUBLIC_STATUS = {
  validated: 'validated',
  problematic: 'problematic',
  wrong: 'wrong',
};

export const NUANCE_PUBLIC_STATUS = {
  validated: 'validated',
  problematic: 'problematic',
};

export const RELATIVE_PUBLIC_STATUS = {
  validated: 'validated',
  problematic: 'problematic',
};

export const PROBLEMATIC_NUANCE_COMMENT = '<!--PROBLEMATIC_NUANCE-->';

export const EDITION_STATUS = {
  processed: 'processed',
  done: 'done',
};

export const SOURCE_TYPES = {
  coffin: 'coffin',
  graffito: 'graffito',
  furniture: 'furniture',
  monument: 'monument',
  ostracon: 'ostracon',
  papyrus: 'papyrus',
  beetle: 'beetle',
  statue: 'statue',
  stele: 'stele',
  offeringTable: 'offeringTable',
  tomb: 'tomb',
};

export const KARNAK_URL = 'http://sith.huma-num.fr/karnak';
