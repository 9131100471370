import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Cluster.scss';

const Cluster = ({ children, space, justify, align, className, ...rest }) => {
  return (
    <div
      className={classNames('cluster', className)}
      style={{
        justifyContent: justify,
        alignItems: align,
        gap: space,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

Cluster.defaultProps = {
  className: null,
  space: null,
  justify: null,
  align: null,
};

Cluster.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  space: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
};

export default Cluster;
