import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EntryPreview from 'components/Entry/EntryPreview';
import { getTitleById } from 'slices/titles';
import TitleReadContent from './TitleReadContent';

const TitlePreview = ({ id, onClose }) => {
  const entry = useSelector((state) => getTitleById(state, id));

  return (
    <EntryPreview onClose={onClose}>
      {entry && entry.edited && <TitleReadContent publishableProp="edited" entry={entry} />}
    </EntryPreview>
  );
};

TitlePreview.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TitlePreview;
