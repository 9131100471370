import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { constant } from 'lodash';
import { isNotNil } from 'utils/util';
import IndividualsDataService from 'services/IndividualsDataService';
import {
  resetGraphiesOfIndividual,
  loadGraphies,
  unpublishIndividualGraphies,
  publishIndividualGraphies,
} from './individualGraphies';
import { logout } from './auth';
import {
  selectors as individualCommentsSelectors,
  loadComment,
  publishIndividualComment,
  resetCommentOfIndividual,
  unpublishIndividualComment,
} from './individualComments';
import {
  loadIndividualTitularies,
  publishTitulariesOfIndividual,
  resetTitulariesOfIndividual,
  unpublishTitulariesOfIndividual,
} from './individualTitularies';
import {
  loadIndividualNames,
  publishNamesOfIndividual,
  resetNamesOfIndividual,
  unpublishNamesOfIndividual,
} from './individualNames';
import {
  loadIndividualRelatives,
  publishRelativesOfIndividual,
  resetRelativesOfIndividual,
  unpublishRelativesOfIndividual,
} from './individualRelatives';
import {
  loadIndividualSequences,
  publishSequencesOfIndividual,
  resetSequencesOfIndividual,
  unpublishSequencesOfIndividual,
} from './individualSequences';
import { ENTRY_STATE, resetEntryState, setEntryState } from './ui/entryLoading';
import { registerOpenHandlers } from './openEntries';
import { registerCreation } from './ui/entryCreation';
import { registerHighlight } from './ui/entryHighlight';
import { registerLock } from './ui/entryLock';

const INDIVIDUAL_PREFIX = 'i';

// ------------------------------------
// Selectors
// ------------------------------------

const getIndividualById = (state, individualId) => state.individuals.byId[individualId];

const isIndividualPublished = (state, individualId) => {
  const individual = getIndividualById(state, individualId);
  return isNotNil(individual) && isNotNil(individual.published);
};

// ------------------------------------
// Actions
// ------------------------------------

const loadAllIndividualData = (individualId, isEditVersion) => {
  return async (dispatch) => {
    dispatch(setEntryState({ entryId: individualId, entryState: ENTRY_STATE.loading }));
    const [, loadResult] = await Promise.allSettled([
      dispatch(loadGraphies({ individualId, isEditVersion })),
      dispatch(loadIndividual({ individualId, isEditVersion })),
      dispatch(loadIndividualNames({ individualId, isEditVersion })),
      dispatch(loadIndividualRelatives({ individualId, isEditVersion })),
      dispatch(loadIndividualTitularies({ individualId, isEditVersion })),
      dispatch(loadIndividualSequences({ individualId, isEditVersion })),
      dispatch(loadComment({ individualId, isEditVersion })),
    ]);
    dispatch(resetEntryState({ entryId: individualId }));
    if (loadResult.value.type === loadIndividual.rejected.type) {
      return { error: true };
    }
    return { success: true };
  };
};

const loadIndividual = createAsyncThunk(
  'individual/load',
  async ({ individualId, isEditVersion }) => {
    const res = await IndividualsDataService.get(individualId, isEditVersion);
    return res.data;
  }
);

const reloadIndividual = (individualId) => {
  return (dispatch) => {
    dispatch(resetGraphiesOfIndividual(individualId));
    dispatch(resetNamesOfIndividual(individualId));
    dispatch(resetRelativesOfIndividual(individualId));
    dispatch(resetTitulariesOfIndividual(individualId));
    dispatch(resetSequencesOfIndividual(individualId));
    dispatch(resetCommentOfIndividual(individualId));
    return dispatch(loadAllIndividualData(individualId, false));
  };
};

const resetIndividualData = (individualId) => {
  return (dispatch) => {
    dispatch(resetIndividual(individualId));
    dispatch(resetGraphiesOfIndividual(individualId));
    dispatch(resetNamesOfIndividual(individualId));
    dispatch(resetRelativesOfIndividual(individualId));
    dispatch(resetTitulariesOfIndividual(individualId));
    dispatch(resetSequencesOfIndividual(individualId));
    dispatch(resetCommentOfIndividual(individualId));
  };
};

const createAndLockIndividual = (form) => {
  return (dispatch) => {
    if (form.data.edited?.transliteration) {
      return dispatch(createIndividual(form));
    }
  };
};

const lockIndividual = createAsyncThunk(
  'individual/lock',
  async (individualId, { rejectWithValue }) => {
    try {
      const res = await IndividualsDataService.lock(individualId);
      return { individualId, data: res.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const unlockIndividual = createAsyncThunk(
  'individual/unlock',
  async (individualId, { getState, dispatch }) => {
    if (isIndividualUILocked(getState(), individualId)) {
      dispatch(unlockIndividualUI());
    }
    const res = await IndividualsDataService.unlock(individualId);
    return { individualId, data: res.data };
  }
);

const unpublishAllIndividualData = (individualId) => {
  return (dispatch, getState) => {
    dispatch(unpublishIndividual(getIndividualById(getState(), individualId)));
    dispatch(unpublishIndividualGraphies(individualId));
    dispatch(unpublishNamesOfIndividual(individualId));
    dispatch(unpublishRelativesOfIndividual(individualId));
    dispatch(unpublishTitulariesOfIndividual(individualId));
    dispatch(unpublishSequencesOfIndividual(individualId));
    if (individualCommentsSelectors.getByIndividualId(getState(), individualId)) {
      dispatch(unpublishIndividualComment(individualId));
    }
  };
};

const publishAllIndividualData = (individualId) => {
  return async (dispatch, getState) => {
    dispatch(setEntryState({ entryId: individualId, entryState: ENTRY_STATE.publishing }));

    const publishActions = [
      dispatch(publishIndividual(getIndividualById(getState(), individualId))),
      dispatch(publishIndividualGraphies(individualId)),
      dispatch(publishNamesOfIndividual(individualId)),
      dispatch(publishRelativesOfIndividual(individualId)),
      dispatch(publishTitulariesOfIndividual(individualId)),
      dispatch(publishSequencesOfIndividual(individualId)),
      individualCommentsSelectors.getByIndividualId(getState(), individualId)
        ? dispatch(publishIndividualComment(individualId))
        : null,
    ].filter(isNotNil);

    await Promise.all(publishActions);

    dispatch(resetEntryState({ entryId: individualId }));
  };
};

const publishIndividual = createAsyncThunk('individual/publish', async (individual) => {
  const res = await IndividualsDataService.publish(individual);
  return res.data;
});

const unpublishIndividual = createAsyncThunk('individual/unpublish', async (individual) => {
  const res = await IndividualsDataService.unpublish(individual);
  return res.data;
});

const createIndividual = createAsyncThunk('individual/create', async ({ data }, { dispatch }) => {
  const res = await IndividualsDataService.createOrUpdate({
    edited: { ...data.edited, mainNuanceType: 'name' },
  });
  dispatch(stopIndividualCreation());
  dispatch(openAndLoadIndividual(res.data.id, true));
  return res.data;
});

const updateIndividual = createAsyncThunk(
  'individual/update',
  async ({ id, data }, { getState }) => {
    const oldIndividual = getIndividualById(getState(), id);
    const updatedIndividual = {
      ...oldIndividual,
      ...data,
      edited: {
        ...oldIndividual.edited,
        ...data.edited,
        mainNuances: {
          ...oldIndividual.edited.mainNuances,
          ...data.edited?.mainNuances,
        },
      },
    };
    const res = await IndividualsDataService.createOrUpdate(updatedIndividual);
    return res.data;
  }
);

const deleteIndividual = createAsyncThunk(
  'individual/delete',
  async (individualId, { dispatch }) => {
    const res = await IndividualsDataService.delete(individualId);
    dispatch(closeIndividual());
    return { individualId, data: res.data };
  }
);

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {
  byId: {},
};

const replace = (state, action) => {
  state.byId[action.payload.id] = action.payload;
};

const remove = (state, action) => {
  delete state.byId[action.payload.individualId];
};

const individualsSlice = createSlice({
  name: 'individuals',
  initialState,
  reducers: {
    resetIndividual: (state, action) => {
      delete state.byId[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadIndividual.fulfilled, replace)
      .addCase(publishIndividual.fulfilled, replace)
      .addCase(unpublishIndividual.fulfilled, replace)
      .addCase(updateIndividual.fulfilled, replace)
      .addCase(createIndividual.fulfilled, replace)
      .addCase(deleteIndividual.fulfilled, remove)
      .addCase(logout.fulfilled, constant(initialState));
  },
});

const { resetIndividual } = individualsSlice.actions;

const { reducer } = individualsSlice;

const {
  actions: openActions,
  utils: openUtils,
  selectors: openSelectors,
} = registerOpenHandlers(INDIVIDUAL_PREFIX, {
  load: loadAllIndividualData,
  reset: resetIndividualData,
  lock: lockIndividual,
  unlock: unlockIndividual,
  reload: reloadIndividual,
});
const {
  openAndLoad: openAndLoadIndividual,
  close: closeIndividual,
  startEdition: startIndividualEdition,
  stopEditionAndReload: stopIndividualEditionAndReload,
} = openActions;
const { isOfType: isIndividual } = openUtils;
const { isEdited: isIndividualEdited, isOpened: isIndividualOpened } = openSelectors;

const { actions: creationActions, selectors: creationSelectors } =
  registerCreation(INDIVIDUAL_PREFIX);
const { startCreation: startIndividualCreation, stopCreation: stopIndividualCreation } =
  creationActions;
const { isCreating: isCreatingIndividual } = creationSelectors;

const { actions: highlightActions, selectors: highlightSelectors } =
  registerHighlight(INDIVIDUAL_PREFIX);
const { highlight: highlightIndividual, reset: resetIndividualHihglight } = highlightActions;
const { isHighlighted: isIndividualHighlighted } = highlightSelectors;

const { actions: lockActions, selectors: lockSelectors } = registerLock(INDIVIDUAL_PREFIX);
const { lock: lockIndividualUI, unlock: unlockIndividualUI } = lockActions;
const { isLocked: isIndividualUILocked, getEditor: getIndividualLockUIEditor } = lockSelectors;

export {
  INDIVIDUAL_PREFIX,
  reducer,
  loadAllIndividualData,
  resetIndividualData,
  createAndLockIndividual,
  loadIndividual,
  reloadIndividual,
  lockIndividual,
  unlockIndividual,
  updateIndividual,
  deleteIndividual,
  openAndLoadIndividual,
  closeIndividual,
  startIndividualEdition,
  stopIndividualEditionAndReload,
  startIndividualCreation,
  stopIndividualCreation,
  unpublishAllIndividualData,
  publishAllIndividualData,
  getIndividualById,
  isCreatingIndividual,
  isIndividualPublished,
  isIndividual,
  isIndividualEdited,
  isIndividualOpened,
  highlightIndividual,
  resetIndividualHihglight,
  isIndividualHighlighted,
  lockIndividualUI,
  unlockIndividualUI,
  isIndividualUILocked,
  getIndividualLockUIEditor,
};
