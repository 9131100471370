import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadWord } from 'slices/words';
import LinkDecorator from './LinkDecorator';

const EntryLinkDecorator = ({ contentState, entityKey, children }) => {
  const dispatch = useDispatch();
  const { entryId } = contentState.getEntity(entityKey).getData();

  const handleOpeningEntry = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadWord(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkDecorator id={entryId} type="entry" onOpen={handleOpeningEntry}>
      {children}
    </LinkDecorator>
  );
};

EntryLinkDecorator.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default EntryLinkDecorator;
