import { RELATIVE_PUBLIC_STATUS } from 'utils/constants';
import PublicStatusSelector from './PublicStatusSelector';

const statusList = Object.values(RELATIVE_PUBLIC_STATUS);

const RelativePublicStatusSelector = (props) => {
  return (
    <PublicStatusSelector statusList={statusList} {...props} translationKey="individualRelatives" />
  );
};

export default RelativePublicStatusSelector;
