import { get } from 'lodash';
import { isPublishable } from 'utils/util';
import GraphiesDataService from 'services/GraphiesDataService';
import { getTitleHolderById, getTitleHolderIdsByGraphyIdAndAttestationId } from './titleHolders';
import graphiesFactory from './graphiesFactory';

const graphiesDataService = new GraphiesDataService('titleId');

const extraReducers = {
  'title/delete/fulfilled': (state, action) => {
    const graphyIdsToRemove = state.byTitleId[action.payload.titleId] || [];
    graphyIdsToRemove.forEach((id) => delete state.byId[id]);
    delete state.byTitleId[action.payload.titleId];
  },
};

const { reducer, actions, selectors } = graphiesFactory(
  'titleGraphies',
  'title',
  extraReducers,
  graphiesDataService
);

const isGraphyTitleHoldersPublishable = (state, graphyId) => {
  const graphy = selectors.getById(state, graphyId);
  const editedAttestationIds = get(graphy, 'edited.attestationIds', []);
  const titleHolderIds = editedAttestationIds.flatMap((attestationId) =>
    getTitleHolderIdsByGraphyIdAndAttestationId(state, graphyId, attestationId)
  );
  return titleHolderIds.some((titleHolderId) =>
    isPublishable(getTitleHolderById(state, titleHolderId), 'isDateDisplayed')
  );
};

const doEntryHasPublishableTitleHolders = (state, entryId) => {
  const graphyIds = selectors.getIdsByTitleId(state, entryId);
  return graphyIds.some((graphyId) => isGraphyTitleHoldersPublishable(state, graphyId));
};

const {
  unpublishEntryGraphies: unpublishTitleGraphies,
  publishEntryGraphies: publishTitleGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  udpateMainGraphies,
  addAttestationToGraphy,
  removeAttestationFromGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfEntry: resetGraphiesOfTitle,
  resetGraphy,
} = actions;

export {
  reducer,
  unpublishTitleGraphies,
  publishTitleGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  udpateMainGraphies,
  addAttestationToGraphy,
  removeAttestationFromGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfTitle,
  resetGraphy,
  selectors,
  isGraphyTitleHoldersPublishable,
  doEntryHasPublishableTitleHolders,
};
