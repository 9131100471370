import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  highlightTitle,
  isTitleOpened,
  openAndLoadTitle,
  resetTitleHihglight,
} from 'slices/titles';
import EntrySummary from 'components/Entry/EntrySummary';
import { TitlePublicStatus } from 'components/PublicStatus';

const TitleSummary = forwardRef(({ entry, isHighlighted, onSelect }, ref) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isTitleOpened(state, entry.id));

  const handleSelect = () => {
    dispatch(resetTitleHihglight());
    if (!isOpen) {
      dispatch(openAndLoadTitle(entry.id, !entry.published));
      onSelect();
    }
  };

  const handleEnter = () => {
    dispatch(highlightTitle(entry.id));
  };

  const handleLeave = () => {
    dispatch(resetTitleHihglight());
  };

  return (
    <EntrySummary
      ref={ref}
      entry={entry}
      isHighlighted={isHighlighted}
      isOpen={isOpen}
      publicStatusComponent={TitlePublicStatus}
      onSelect={handleSelect}
      onEnter={handleEnter}
      onLeave={handleLeave}
    />
  );
});

TitleSummary.defaultProps = {
  isHighlighted: false,
};

TitleSummary.propTypes = {
  entry: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
};

export default TitleSummary;
