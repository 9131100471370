import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { constant, uniq } from 'lodash';
import { isNotNil } from 'utils/util';
import TitlesDataService from 'services/TitlesDataService';
import {
  resetGraphiesOfTitle,
  loadGraphies,
  unpublishTitleGraphies,
  publishTitleGraphies,
  selectors as titleGraphiesSelectors,
} from './titleGraphies';
import { logout } from './auth';
import {
  selectors as titleCommentsSelectors,
  loadComment,
  publishTitleComment,
  resetCommentOfTitle,
  unpublishTitleComment,
} from './titleComments';
import {
  loadDictionaryReferences,
  publishTitleDictionaryReferences,
  resetDictionaryReferencesOfTitle,
  unpublishTitleDictionaryReferences,
  selectors as titleDictionaryReferencesSelectors,
} from './titleDictionaryReferences';
import { ENTRY_STATE, resetEntryState, setEntryState } from './ui/entryLoading';
import { registerOpenHandlers } from './openEntries';
import { registerCreation } from './ui/entryCreation';
import { registerHighlight } from './ui/entryHighlight';
import { registerLock } from './ui/entryLock';
import { getAttestationById } from 'slices/attestations';
import { getSourceById } from 'slices/sources';
import {
  getTitleHolderById,
  getTitleHolderIdsByTitleId,
  publishTitleHolders,
  unpublishTitleHolders,
} from 'slices/titleHolders';

const TITLE_PREFIX = 't';

// ------------------------------------
// Selectors
// ------------------------------------

const getTitleById = (state, titleId) => state.titles.byId[titleId];

const isTitlePublished = (state, titleId) => {
  const title = getTitleById(state, titleId);
  return isNotNil(title) && isNotNil(title.published);
};

const getPeriods = (state, titleId, publishableProp) => {
  const titleHoldersPeriods = getTitleHolderIdsByTitleId(state, titleId)
    .flatMap((id) => {
      const { published, individual } = getTitleHolderById(state, id);
      if (published && published.isDateDisplayed) {
        return individual.attestationPeriods;
      }
      return null;
    })
    .filter(isNotNil);

  const uniqueAttestationIds = uniq(
    titleGraphiesSelectors
      .getIdsByTitleId(state, titleId)
      .flatMap((id) => titleGraphiesSelectors.getById(state, id)[publishableProp].attestationIds)
      .filter(isNotNil)
  );
  const uniqueSourceIds = uniq(
    uniqueAttestationIds.map((id) => getAttestationById(state, id).sourceId).filter(isNotNil)
  );
  const uniquePeriods = uniq([
    ...uniqueSourceIds.flatMap((id) => getSourceById(state, id).periods),
    ...titleHoldersPeriods,
  ]).filter(isNotNil);

  return uniquePeriods;
};

// ------------------------------------
// Actions
// ------------------------------------

const loadAllTitleData = (titleId, isEditVersion) => {
  return async (dispatch) => {
    dispatch(setEntryState({ entryId: titleId, entryState: ENTRY_STATE.loading }));
    const [, loadResult] = await Promise.allSettled([
      dispatch(loadGraphies({ titleId, isEditVersion })),
      dispatch(loadTitle({ titleId, isEditVersion })),
      dispatch(loadDictionaryReferences({ titleId, isEditVersion })),
      dispatch(loadComment({ titleId, isEditVersion })),
    ]);
    dispatch(resetEntryState({ entryId: titleId }));
    if (loadResult.value.type === loadTitle.rejected.type) {
      return { error: true };
    }
    return { success: true };
  };
};

const loadTitle = createAsyncThunk('title/load', async ({ titleId, isEditVersion }) => {
  const res = await TitlesDataService.get(titleId, isEditVersion);
  return res.data;
});

const reloadTitle = (titleId) => {
  return (dispatch) => {
    dispatch(resetGraphiesOfTitle(titleId));
    dispatch(resetCommentOfTitle(titleId));
    dispatch(resetDictionaryReferencesOfTitle(titleId));
    return dispatch(loadAllTitleData(titleId, false));
  };
};

const resetTitleData = (titleId) => {
  return (dispatch) => {
    dispatch(resetTitle(titleId));
    dispatch(resetGraphiesOfTitle(titleId));
    dispatch(resetCommentOfTitle(titleId));
    dispatch(resetDictionaryReferencesOfTitle(titleId));
  };
};

const createAndLockTitle = (form) => {
  return (dispatch) => {
    if (form.data.edited?.transliteration) {
      return dispatch(createTitle(form));
    }
  };
};

const lockTitle = createAsyncThunk('title/lock', async (titleId, { rejectWithValue }) => {
  try {
    const res = await TitlesDataService.lock(titleId);
    return { titleId, data: res.data };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const unlockTitle = createAsyncThunk('title/unlock', async (titleId, { getState, dispatch }) => {
  if (isTitleUILocked(getState(), titleId)) {
    dispatch(unlockTitleUI());
  }
  const res = await TitlesDataService.unlock(titleId);
  return { titleId, data: res.data };
});

const unpublishAllTitleData = (titleId) => {
  return (dispatch, getState) => {
    dispatch(unpublishTitle(getTitleById(getState(), titleId)));
    dispatch(unpublishTitleGraphies(titleId));
    dispatch(unpublishTitleHolders(titleId));
    if (titleCommentsSelectors.getByTitleId(getState(), titleId)) {
      dispatch(unpublishTitleComment(titleId));
    }
    if (titleDictionaryReferencesSelectors.getByTitleId(getState(), titleId)) {
      dispatch(unpublishTitleDictionaryReferences(titleId));
    }
  };
};

const publishAllTitleData = (titleId) => {
  return async (dispatch, getState) => {
    dispatch(setEntryState({ entryId: titleId, entryState: ENTRY_STATE.publishing }));

    const publishActions = [
      dispatch(publishTitle(getTitleById(getState(), titleId))),
      dispatch(publishTitleGraphies(titleId)),
      dispatch(publishTitleHolders(titleId)),
      titleCommentsSelectors.getByTitleId(getState(), titleId)
        ? dispatch(publishTitleComment(titleId))
        : null,
      titleDictionaryReferencesSelectors.getByTitleId(getState(), titleId)
        ? dispatch(publishTitleDictionaryReferences(titleId))
        : null,
    ].filter(isNotNil);

    await Promise.all(publishActions);

    dispatch(resetEntryState({ entryId: titleId }));
  };
};

const publishTitle = createAsyncThunk('title/publish', async (title) => {
  const res = await TitlesDataService.publish(title);
  return res.data;
});

const unpublishTitle = createAsyncThunk('title/unpublish', async (title) => {
  const res = await TitlesDataService.unpublish(title);
  return res.data;
});

const createTitle = createAsyncThunk('title/create', async ({ data }, { dispatch }) => {
  const res = await TitlesDataService.createOrUpdate({
    edited: { ...data.edited },
  });
  dispatch(stopTitleCreation());
  dispatch(openAndLoadTitle(res.data.id, true));
  return res.data;
});

const updateTitle = createAsyncThunk('title/update', async ({ id, data }, { getState }) => {
  const oldTitle = getTitleById(getState(), id);
  const updatedTitle = {
    ...oldTitle,
    ...data,
    edited: {
      ...oldTitle.edited,
      ...data.edited,
      mainNuances: {
        ...oldTitle.edited.mainNuances,
        ...data.edited?.mainNuances,
      },
    },
  };
  const res = await TitlesDataService.createOrUpdate(updatedTitle);
  return res.data;
});

const deleteTitle = createAsyncThunk('title/delete', async (titleId, { dispatch }) => {
  const res = await TitlesDataService.delete(titleId);
  dispatch(closeTitle());
  return { titleId, data: res.data };
});

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {
  byId: {},
};

const replace = (state, action) => {
  state.byId[action.payload.id] = action.payload;
};

const remove = (state, action) => {
  delete state.byId[action.payload.titleId];
};

const titlesSlice = createSlice({
  name: 'titles',
  initialState,
  reducers: {
    resetTitle: (state, action) => {
      delete state.byId[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTitle.fulfilled, replace)
      .addCase(publishTitle.fulfilled, replace)
      .addCase(unpublishTitle.fulfilled, replace)
      .addCase(updateTitle.fulfilled, replace)
      .addCase(createTitle.fulfilled, replace)
      .addCase(deleteTitle.fulfilled, remove)
      .addCase(logout.fulfilled, constant(initialState));
  },
});

const { resetTitle } = titlesSlice.actions;

const { reducer } = titlesSlice;

const {
  actions: openActions,
  utils: openUtils,
  selectors: openSelectors,
} = registerOpenHandlers(TITLE_PREFIX, {
  load: loadAllTitleData,
  reset: resetTitleData,
  lock: lockTitle,
  unlock: unlockTitle,
  reload: reloadTitle,
});
const {
  openAndLoad: openAndLoadTitle,
  close: closeTitle,
  startEdition: startTitleEdition,
  stopEditionAndReload: stopTitleEditionAndReload,
} = openActions;
const { isOfType: isTitle } = openUtils;
const { isEdited: isTitleEdited, isOpened: isTitleOpened } = openSelectors;

const { actions: creationActions, selectors: creationSelectors } = registerCreation(TITLE_PREFIX);
const { startCreation: startTitleCreation, stopCreation: stopTitleCreation } = creationActions;
const { isCreating: isCreatingTitle } = creationSelectors;

const { actions: highlightActions, selectors: highlightSelectors } =
  registerHighlight(TITLE_PREFIX);
const { highlight: highlightTitle, reset: resetTitleHihglight } = highlightActions;
const { isHighlighted: isTitleHighlighted } = highlightSelectors;

const { actions: lockActions, selectors: lockSelectors } = registerLock(TITLE_PREFIX);
const { lock: lockTitleUI, unlock: unlockTitleUI } = lockActions;
const { isLocked: isTitleUILocked, getEditor: getTitleLockUIEditor } = lockSelectors;

export {
  TITLE_PREFIX,
  reducer,
  loadAllTitleData,
  resetTitleData,
  createAndLockTitle,
  loadTitle,
  reloadTitle,
  lockTitle,
  unlockTitle,
  updateTitle,
  deleteTitle,
  openAndLoadTitle,
  closeTitle,
  startTitleEdition,
  stopTitleEditionAndReload,
  startTitleCreation,
  stopTitleCreation,
  unpublishAllTitleData,
  publishAllTitleData,
  getTitleById,
  isCreatingTitle,
  isTitlePublished,
  isTitle,
  isTitleEdited,
  isTitleOpened,
  highlightTitle,
  resetTitleHihglight,
  isTitleHighlighted,
  lockTitleUI,
  unlockTitleUI,
  isTitleUILocked,
  getTitleLockUIEditor,
  getPeriods,
};
