import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useDefaultNuanceLang from 'hooks/useDefaultNuanceLang';
import DropdownMenu from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import FlagIcon from 'components/FlagIcon';
import { ReactComponent as ArrowDownIcon } from 'images/icon-arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'images/icon-arrow-up.svg';
import { isEmptyHtml, isNotEmptyHtml } from 'utils/util';

const EntryNuance = ({ nuances }) => {
  const [nuanceLang, setNuanceLang] = useDefaultNuanceLang(nuances);
  const languages = Object.keys(nuances).filter(
    (key) => isNotEmptyHtml(nuances[key]) && key !== nuanceLang
  );

  if (languages.length === 0 && isEmptyHtml(nuances[nuanceLang])) {
    return null;
  }

  return (
    <div className="entry-nuance-container" data-testid="entry-shown-nuance">
      <div
        className="entry-nuance"
        dangerouslySetInnerHTML={{
          __html: nuances[nuanceLang] || nuances.fr || nuances.en,
        }}
        data-testid="entry-shown-nuance-text"
      />
      {languages.length > 0 && (
        <div className="entry-nuance-lang-menu" data-testid="entry-nuance-lang-menu">
          <DropdownMenu
            itemsCount={languages.length}
            renderButton={(buttonProps) => (
              <button
                className={classNames('nuance-flag-selector', {
                  'is-menu-open': buttonProps['aria-expanded'],
                })}
                data-testid="nuance-lang-selector"
                {...buttonProps}
              >
                <FlagIcon iso={nuanceLang} size="medium" />
                {buttonProps['aria-expanded'] && <ArrowUpIcon className="arrow" />}
                {!buttonProps['aria-expanded'] && <ArrowDownIcon className="arrow" />}
              </button>
            )}
          >
            {languages.map((lang) => (
              <MenuItem key={lang} onClick={() => setNuanceLang(lang)}>
                <FlagIcon iso={lang} size="medium" />
              </MenuItem>
            ))}
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};

EntryNuance.propTypes = {
  nuances: PropTypes.object.isRequired,
};

export default EntryNuance;
