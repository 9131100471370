import { useEffect, useCallback } from 'react';
import { isEscapeKeyPressed } from 'components/Editor/eventUtils';

const useOnCancel = (onCancel) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (isEscapeKeyPressed(e)) {
        onCancel();
      }
    },
    [onCancel]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);
};

export default useOnCancel;
