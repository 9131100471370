import React from 'react';
import PropTypes from 'prop-types';
import { get, identity } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors as individualGraphiesSelectors } from 'slices/individualGraphies';
import { doesIndividualHaveNames } from 'slices/individualNames';
import { doesIndividualHaveRelatives } from 'slices/individualRelatives';
import { doesIndividualHaveSequences } from 'slices/individualSequences';
import { doesIndividualHaveTitularies } from 'slices/individualTitularies';
import { selectors as individualCommentsSelectors } from 'slices/individualComments';
import { isLoading } from 'slices/ui/entryLoading';
import { ReactComponent as NamesIcon } from 'images/icon-tab-names.svg';
import { ReactComponent as RelativesIcon } from 'images/icon-tab-relatives.svg';
import { ReactComponent as TitlesIcon } from 'images/icon-tab-titles.svg';
import { ReactComponent as SequencesIcon } from 'images/icon-tab-sequences.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import Transliteration from 'components/Transliteration';
import Graphy from 'components/Graphy';
import Tag from 'components/Tag';
import EntryTabs from 'components/Entry/EntryTabs';
import EntryNuance from 'components/Entry/EntryNuance';
import { Cluster } from 'components/Layout';
import IndividualComments from 'components/IndividualComments';
import { IndividualTitlesRead } from 'components/IndividualTitles';
import { IndividualNamesRead } from 'components/IndividualNames';
import { IndividualSequencesRead } from 'components/IndividualSequences';
import { IndividualRelativesRead } from 'components/IndividualRelatives';
import { PeriodsLiteral } from 'components/Periods';

const IndividualReadContent = ({ entry, publishableProp }) => {
  const { t, i18n } = useTranslation();
  const main1Graphy = useSelector((state) =>
    individualGraphiesSelectors.getMain1GraphyByIndividualId(state, entry.id)
  );
  const main2Graphy = useSelector((state) =>
    individualGraphiesSelectors.getMain2GraphyByIndividualId(state, entry.id, publishableProp)
  );
  const hasNames = useSelector((state) => doesIndividualHaveNames(state, entry.id));
  const hasRelatives = useSelector((state) => doesIndividualHaveRelatives(state, entry.id));
  const hasSequences = useSelector((state) => doesIndividualHaveSequences(state, entry.id));
  const hasTitles = useSelector((state) =>
    doesIndividualHaveTitularies(state, entry.id, publishableProp)
  );
  const hasComment = useSelector((state) =>
    individualCommentsSelectors.doesIndividualHaveOne(state, entry.id, publishableProp)
  );
  const isLoadingEntry = useSelector((state) => isLoading(state, entry.id));
  const isUnknown =
    get(entry, `${publishableProp}.transliteration`, '').toLowerCase() === 'inconnu';
  const isCollective =
    get(entry, `${publishableProp}.transliteration`, '').toLowerCase() === 'collectif';

  const selectCommentTab =
    (entry.publicStatus === 'problematic' ||
      entry.publicStatus === 'wrong' ||
      entry.publicStatus === 'wrongGraphy') &&
    hasComment;
  const commentTabIndex = [hasNames, hasRelatives, hasSequences, hasTitles].filter(identity).length;

  return (
    <>
      <div className="entry-natures">
        <Cluster space="var(--s-3)">
          <Tag>{t('entry.natures.types.individual')}</Tag>
          {!isUnknown && !isCollective && (
            <Tag>
              {t(
                `individual.nuanceTypes.${get(entry, `${publishableProp}.mainNuanceType`, 'name')}`
              )}
            </Tag>
          )}
        </Cluster>
      </div>

      {!isUnknown && !isCollective && (
        <h2 className="entry-transliteration">
          <Transliteration>{entry[publishableProp].transliteration}</Transliteration>
        </h2>
      )}

      <div className="entry-main-graphies" data-testid="entry-main-graphies">
        {main1Graphy && main1Graphy[publishableProp] && (
          <Graphy
            url={main1Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main1Graphy.publicStatus}
          />
        )}
        {main2Graphy && main2Graphy[publishableProp] && (
          <Graphy
            url={main2Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main2Graphy.publicStatus}
          />
        )}
      </div>

      <EntryNuance nuances={entry[publishableProp].mainNuances} />

      <div className="entry-periods">
        <PeriodsLiteral periods={get(entry, `${publishableProp}.attestationPeriods`, [])} />
      </div>

      {!isLoadingEntry && (
        <EntryTabs
          defaultIndex={selectCommentTab ? commentTabIndex : 0}
          selectors={[
            hasNames ? <NamesIcon id="individualNames" /> : null,
            hasRelatives ? <RelativesIcon id="individualRelatives" /> : null,
            hasTitles ? <TitlesIcon id="individualTitles" /> : null,
            hasSequences ? <SequencesIcon id="individualSequences" /> : null,
            hasComment ? <CommentsIcon id="comments" /> : null,
          ]}
        >
          {hasNames ? (
            <div id="individualNames">
              <IndividualNamesRead individualId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          {hasRelatives ? (
            <div id="individualRelatives">
              <IndividualRelativesRead individualId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          {hasTitles ? (
            <div id="individualTitles">
              <IndividualTitlesRead individualId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          {hasSequences ? (
            <div id="individualSequences">
              <IndividualSequencesRead individualId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          {hasComment ? (
            <div id="comments">
              <IndividualComments
                individualId={entry.id}
                isEditVersion={false}
                publishableProp={publishableProp}
              />
            </div>
          ) : null}
        </EntryTabs>
      )}
    </>
  );
};

IndividualReadContent.propTypes = {
  entry: PropTypes.object.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
};

export default IndividualReadContent;
