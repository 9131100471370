import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openAndLoadTitle } from 'slices/titles';
import {
  getIndividualTitularyById,
  getMainTitularyIdsByIndividualId,
  getOtherTitularyIdsByIndividualId,
} from 'slices/individualTitularies';
import { Stack } from 'components/Layout';
import EntryNuance from 'components/Entry/EntryNuance';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import './IndividualTitles.scss';

const IndividualTitle = ({ id }) => {
  const dispatch = useDispatch();
  const { title } = useSelector((state) => getIndividualTitularyById(state, id));

  if (!title.published) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={() => dispatch(openAndLoadTitle(title.id))}
      className="individual-titles-read-title"
    >
      <div
        className="individual-titles-read-title-transliteration"
        dangerouslySetInnerHTML={{ __html: title.transliteration }}
      />
      <EntryNuance nuances={title.mainNuances} />
    </button>
  );
};

const IndividualTitlesRead = ({ individualId }) => {
  const { t } = useTranslation();
  const mainTitularyIds = useSelector((state) =>
    getMainTitularyIdsByIndividualId(state, individualId)
  );
  const otherTitularyIds = useSelector((state) =>
    getOtherTitularyIdsByIndividualId(state, individualId)
  );

  return (
    <EntryTabContentLayout title={t('individualTitles.title')} noTitleBorder noBodyTopSpace>
      <Stack space="var(--s0)">
        {mainTitularyIds.length > 0 && (
          <div>
            <div className="individual-titles-section-label">
              {t('individualTitles.rankTitles')}
            </div>
            <div>
              {mainTitularyIds.map((titularyId) => (
                <IndividualTitle key={titularyId} id={titularyId} />
              ))}
            </div>
          </div>
        )}
        {otherTitularyIds.length > 0 && (
          <div>
            <div className="individual-titles-section-label">
              {t('individualTitles.otherTitles')}
            </div>
            <div>
              {otherTitularyIds.map((titularyId) => (
                <IndividualTitle key={titularyId} id={titularyId} />
              ))}
            </div>
          </div>
        )}
      </Stack>
    </EntryTabContentLayout>
  );
};

IndividualTitlesRead.propTypes = {
  individualId: PropTypes.number.isRequired,
};

export default IndividualTitlesRead;
