import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Section from 'components/Section';
import { ReactComponent as EyeOpenedLogo } from 'images/icon-eye-opened.svg';
import { ReactComponent as CrossLogo } from 'images/icon-cross.svg';
import ToolbarButton from './ToolbarButton';
import './EntryPreview.scss';

const EntryPreview = ({ children, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="entry entry-preview" data-testid="entry-preview">
      <div className="arrow">
        <svg width="11" height="19" viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
          <path d="M-3.74959e-07 9.5L10 1L10 18L-3.74959e-07 9.5Z" fill="var(--color-bg-depth-2)" />
          <path d="M10 1L0.75 9.5L10 18" stroke="var(--color-bg-depth-4)" />
        </svg>
      </div>

      <Section space="var(--s-2)">
        <div className="entry-header">
          <div className="entry-header-left">
            <EyeOpenedLogo className="icon-eye-open-svg" />
            <span className="entry-preview-title">{t('entry.preview')}</span>
          </div>
          <div className="entry-header-right">
            <ToolbarButton onClick={onClose} data-testid="entry-close-button">
              <CrossLogo />
            </ToolbarButton>
          </div>
        </div>
      </Section>

      <div className="entry-content">{children}</div>
    </div>
  );
};

EntryPreview.defaultProps = {
  children: null,
};

EntryPreview.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default EntryPreview;
