import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WordsDataService from 'services/WordsDataService';
import { getAuthenticatedUser, logout } from './auth';

const isEntryEditable = (state, entryId) => {
  const user = getAuthenticatedUser(state);
  if (!user) {
    return false;
  }

  const { id: userId, group: userGroup } = user;

  if (userGroup === 'committee-director' || userGroup === 'committee-publisher') {
    return true;
  }

  if (userGroup === 'contributor' && isEntryAttributedToUser(state, entryId, userId)) {
    return true;
  }

  return false;
};

const isEntryAttributedToUser = (state, userId, entryId) =>
  state.attributions.entryAttributions[entryId] === userId;

//-- actions
/**
 * Retrieve the last attribution of an entry.
 */
const retrieveLastAttribution = createAsyncThunk('attribution/lastForEntry', async (entryId) => {
  const res = await WordsDataService.getLastAttribution(entryId);
  return res.data;
});

const initialState = {
  entryAttributions: {},
};

const attributionsSlice = createSlice({
  name: 'attributions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLastAttribution.fulfilled, (state, action) => {
        state.entryAttributions[action.payload.entryId] = action.payload.userId;
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { reducer } = attributionsSlice;

export { reducer, retrieveLastAttribution, isEntryEditable };
