import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Cluster, Stack } from 'components/Layout';
import { createAttestation } from 'slices/attestations';
import { isNotEmptyHtml } from 'utils/util';
import PeriodsSelector from 'components/PeriodsSelector';
import Editor from 'components/Editor';
import Input from 'components/Input';
import Button from 'components/Button';
import './AttestationForm.scss';

let newAttestation = {};

const AttestationNewForm = ({ onCancel, sourceId }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const handleUpdate = (value) => {
    newAttestation = { ...newAttestation, ...value };
    setIsValid(isNotEmptyHtml(newAttestation.reference));
  };

  const onSubmit = useCallback(
    (data) => {
      dispatch(createAttestation({ ...data, ...newAttestation, sourceId }));
      onCancel();
    },
    [dispatch, sourceId, onCancel]
  );

  const onError = (errors, e) => console.log(errors, e);

  return (
    <form className="attestation-form" onSubmit={handleSubmit(onSubmit, onError)} dir="auto">
      <Stack space="var(--s0)">
        <div
          className="input-container attestation-form-reference"
          data-layout="horizontal"
          data-size="extra-small"
        >
          <label>{t('attestation.reference')}</label>
          <Editor
            saveMode="blur"
            placeholder={t('attestation.reference')}
            onValidate={(value) => handleUpdate({ reference: value })}
          />
        </div>
        <Editor
          saveMode="blur"
          placeholder={t('attestation.transliteration')}
          entryLinkEnable
          titleLinkEnable
          individualLinkEnable
          onValidate={(value) => handleUpdate({ transliteration: value })}
        />
        <Editor
          saveMode="blur"
          placeholder={t('attestation.traduction')}
          onValidate={(value) => handleUpdate({ traduction: value })}
        />
        <PeriodsSelector
          isSingle
          label={t('source.periods')}
          onChange={(periods) => handleUpdate({ attestationPeriod: periods[0] })}
        />
        <div className="input-container input-container--borderless" data-size="extra-small">
          <label>{t('attestation.bibliography')}</label>
          <Editor
            saveMode="blur"
            placeholder={t('attestation.bibliography')}
            onValidate={(value) => handleUpdate({ bibliography: value })}
          />
        </div>
        <Input
          size="extra-small"
          name="karnakId"
          layout="horizontal"
          label="KIU"
          placeholder="123"
          register={register}
        />

        <Cluster justify="space-between" className="attestation-form-toolbar">
          <Button size="small" onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          {isValid && (
            <Button type="submit" size="small" theme="green">
              {t('actions.create')}
            </Button>
          )}
        </Cluster>
      </Stack>
    </form>
  );
};

AttestationNewForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  sourceId: PropTypes.number.isRequired,
};

export default AttestationNewForm;
