import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { makeCancelable } from 'utils/util';
import SearchDataService from 'services/SearchDataService';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import Select from 'components/Select';

const renderIndividual = (individual) => {
  return (
    <div
      className="link-editor-autocomplete-item select-individuals-item"
      data-published={individual.published}
      onMouseDown={preventEventDefaultAndStopPropagation}
    >
      <div
        style={{
          fontSize: '11px',
          fontFamily: 'var(--ff-sans)',
          fontStyle: 'normal',
          color: 'var(--color-text-inactive)',
        }}
      >
        ID {individual.id}
      </div>
      <div dangerouslySetInnerHTML={{ __html: individual.transliteration }} />
      <div dangerouslySetInnerHTML={{ __html: individual.mainNuance }} />
    </div>
  );
};

let cancelablePromise;

const IndividualSelector = ({ placeholder, onSelect }) => {
  const { i18n } = useTranslation();
  const [searchedIndividuals, setSearchedIndividuals] = useState([]);

  const handleChange = useCallback(
    (search) => {
      cancelablePromise = makeCancelable(
        SearchDataService.searchByTransliteration({
          locale: i18n.resolvedLanguage,
          search,
          type: 'individual',
        })
      );
      cancelablePromise.promise
        .then(({ data }) => {
          setSearchedIndividuals(data.content);
        })
        .catch((reason) => {
          if (!reason.isCanceled) {
            setSearchedIndividuals([]);
          }
        });
    },
    [i18n.resolvedLanguage]
  );

  const handleSelect = useCallback(
    (individual) => {
      onSelect(individual.id);
    },
    [onSelect]
  );

  useEffect(() => {
    return function cleanup() {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, []);

  return (
    <Select
      placeholder={placeholder}
      items={searchedIndividuals}
      onChange={handleChange}
      onSelect={handleSelect}
      renderItem={renderIndividual}
      idGetter={get('id')}
    />
  );
};

IndividualSelector.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default IndividualSelector;
