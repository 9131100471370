import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEntryEditable } from 'slices/attributions';
import {
  getWordById,
  unlockWord,
  WORD_PREFIX,
  getWordLockUIEditor,
  isWordUILocked,
  unlockWordUI,
} from 'slices/words';
import EntryRead from 'components/Entry/EntryRead';
import WordReadContent from './WordReadContent';

const WordRead = ({ id, isHighlighted, onStartEdition, onClose }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) => getWordById(state, id));
  const isEditable = useSelector((state) => isEntryEditable(state, id));
  const showLockModal = useSelector((state) => isWordUILocked(state, id));
  const entryEditor = useSelector(getWordLockUIEditor);

  const handleUnlockWordUI = () => {
    dispatch(unlockWordUI());
  };

  const handleUnlockWord = () => {
    dispatch(unlockWord(id));
  };

  return (
    <EntryRead
      id={id}
      entry={entry}
      entryPrefix={WORD_PREFIX}
      isEditable={isEditable}
      isHighlighted={isHighlighted}
      showLockModal={showLockModal}
      entryEditor={entryEditor}
      onStartEdition={onStartEdition}
      onUnlock={handleUnlockWord}
      onUnlockUI={handleUnlockWordUI}
      onClose={onClose}
    >
      {entry && entry.published && <WordReadContent publishableProp="published" entry={entry} />}
    </EntryRead>
  );
};

WordRead.defaultProps = {
  isHighlighted: false,
};

WordRead.propTypes = {
  id: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool,
  onStartEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WordRead;
