import { GRAPHY_PUBLIC_STATUS } from 'utils/constants';
import PublicStatusSelector from './PublicStatusSelector';

const statusList = Object.values(GRAPHY_PUBLIC_STATUS);

const GraphyPublicStatusSelector = (props) => {
  return <PublicStatusSelector statusList={statusList} {...props} translationKey="graphy" />;
};

export default GraphyPublicStatusSelector;
