import { Cluster } from 'components/Layout';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SelectionBanner.scss';

const SelectionBanner = ({ children, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="selection-banner">
      <Cluster justify="space-between" align="center">
        {children}
        <Button size="small" onClick={onCancel}>
          {t('actions.cancel')}
        </Button>
      </Cluster>
    </div>
  );
};

SelectionBanner.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SelectionBanner;
