import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIndividualNameById } from 'slices/individualNames';
import { selectors as individualNameGraphiesSelectors } from 'slices/individualNameGraphies';
import EntryNuance from 'components/Entry/EntryNuance';
import Transliteration from 'components/Transliteration';
import Graphy from 'components/Graphy';
import { Cluster } from 'components/Layout';
import Tag from 'components/Tag';

const IndividualNameRead = ({ id, publishableProp }) => {
  const { i18n, t } = useTranslation();
  const name = useSelector((state) => getIndividualNameById(state, id));
  const graphyIds = useSelector((state) =>
    individualNameGraphiesSelectors.getIdsByNameId(state, name.id)
  );
  const graphy = useSelector((state) => {
    return graphyIds.length > 0
      ? individualNameGraphiesSelectors.getById(state, graphyIds[0])
      : null;
  });

  return (
    <div className="individual-name">
      <Cluster>
        <Tag>{t(`individual.nuanceTypes.${name[publishableProp].mainNuanceType}`)}</Tag>
      </Cluster>
      <h2 className="individual-name-transliteration">
        <Transliteration>{name[publishableProp].transliteration}</Transliteration>
      </h2>
      <div className="individual-name-graphy" data-testid="individual-name-graphy">
        {graphy && graphy[publishableProp] && (
          <Graphy
            height={35}
            url={graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={graphy.publicStatus}
          />
        )}
      </div>
      <EntryNuance nuances={name[publishableProp].mainNuances} />
    </div>
  );
};

IndividualNameRead.defaultProps = {
  publishableProp: 'published',
};

IndividualNameRead.propTypes = {
  id: PropTypes.number.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']),
};

export default IndividualNameRead;
