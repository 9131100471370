import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { safePolygon } from '@floating-ui/react';
import { makeCancelable } from 'utils/util';
import SearchDataService from 'services/SearchDataService';
import Spinner from 'components/Spinner';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import Transliteration from 'components/Transliteration';
import { preventEventDefaultAndStopPropagation } from './eventUtils';
import './LinkDecorator.scss';

const LinkDecorator = ({ id, type, children, onOpen }) => {
  const linkRef = useRef();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [showUnpublishedEntryMessage, setShowUnpublishedEntryMessage] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [disableTooltip, setDisableTooltip] = useState(false);

  const handleOpen = useCallback(() => {
    onOpen(id, !data.published);
  }, [id, onOpen, data]);

  useEffect(() => {
    if (linkRef.current) {
      const contentEditable = linkRef.current.closest('[contenteditable]');
      if (contentEditable && contentEditable.getAttribute('contenteditable') === 'true') {
        setDisableTooltip(true);
      }
    }
  }, [linkRef]);

  useEffect(() => {
    let cancelablePromise;

    if (isHover) {
      cancelablePromise = makeCancelable(
        SearchDataService.getSummary(id, i18n.resolvedLanguage, type)
      );
      cancelablePromise.promise
        .then(({ data: d }) => {
          setData(d);
        })
        .catch((reason) => {
          if (!reason.isCanceled) {
            setData(null);
            setShowUnpublishedEntryMessage(true);
          }
        });
    }

    return function cleanup() {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, [i18n.resolvedLanguage, id, type, isHover]);

  return (
    <TooltipManager handleClose={safePolygon()} open={disableTooltip ? false : null}>
      <TooltipTrigger>
        <span
          data-id={id}
          className="link-decorator"
          onMouseOver={() => setIsHover(true)}
          onClick={disableTooltip ? noop : handleOpen}
          ref={linkRef}
        >
          {children}
        </span>
      </TooltipTrigger>
      <TooltipContent
        data-theme={(data && data.published) || showUnpublishedEntryMessage ? 'light' : 'blue'}
        dir={i18n.dir()}
      >
        <div className="link-decorator-tooltip">
          {!data && !showUnpublishedEntryMessage && <Spinner size={24} />}
          {data && !showUnpublishedEntryMessage && (
            <div
              data-id={id}
              onMouseDown={preventEventDefaultAndStopPropagation}
              onClick={handleOpen}
            >
              <Transliteration>{data.transliteration}</Transliteration>
              <div dangerouslySetInnerHTML={{ __html: data.mainNuance }}></div>
            </div>
          )}
          {showUnpublishedEntryMessage && (
            <div onMouseDown={preventEventDefaultAndStopPropagation}>
              {t('entry.notPublishedMessage')}
            </div>
          )}
        </div>
      </TooltipContent>
    </TooltipManager>
  );
};

LinkDecorator.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['entry', 'title', 'individual']).isRequired,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default LinkDecorator;
