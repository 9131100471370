import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Editor as DraftEditor,
  EditorState,
  getVisibleSelectionRect,
  getDefaultKeyBinding,
  RichUtils,
  SelectionState,
  Modifier,
} from 'draft-js';
import { filterEditorState } from 'draftjs-filters';
import {
  onDraftEditorCopy,
  onDraftEditorCut,
  handleDraftEditorPastedText,
} from 'draftjs-conductor';
import { noop } from 'lodash';
import {
  isCtrlPressed,
  isEnterKeyPressed,
  isEscapeKeyPressed,
  isKeyPressed,
  isShiftKeyPressed,
  isTabKeyPressed,
} from './eventUtils';
import {
  setCursorAtTheEnd,
  isSelectionCollapsed,
  getSelectionInlineStyle,
  getSelectionEntryId,
  removeEntity,
  clearInlineStyles,
  isFocusOnEntryLink,
  getFocusedEntryId,
  getFocusedEntityRange,
  getFocusedEntityKey,
  getSelectionAsText,
  getPreviousCharactersAtSelection,
  isNotEmpty,
  getSelectionTitleId,
  isFocusOnTitleLink,
  getFocusedTitleId,
  getSelectionIndividualId,
  isFocusOnIndividualLink,
  getFocusedIndividualId,
} from './editorUtils';
import EditorContextMenu from './EditorContextMenu';
import EditorPopover from './EditorPopover';
import LinkEditor from './LinkEditor';
import EntryLinkPopover from './EntryLinkPopover';
import TitleLinkPopover from './TitleLinkPopover';
import withPopoverManager from './withPopoverManager';
import './Editor.scss';
import IndividualLinkPopover from 'components/Editor/IndividualLinkPopover';

const POPOVERS = {
  CONTEXT_MENU: 'CONTEXT_MENU',
  ENTRY_LINK_EDITOR: 'ENTRY_LINK_EDITOR',
  TITLE_LINK_EDITOR: 'TITLE_LINK_EDITOR',
  INDIVIDUAL_LINK_EDITOR: 'INDIVIDUAL_LINK_EDITOR',
  ENTRY_LINK_POPOVER: 'ENTRY_LINK_POPOVER',
  TITLE_LINK_POPOVER: 'TITLE_LINK_POPOVER',
  INDIVIDUAL_LINK_POPOVER: 'INDIVIDUAL_LINK_POPOVER',
};

function keyBindingFn(evt) {
  if (isShiftKeyPressed(evt) && isEnterKeyPressed(evt)) {
    return 'validate';
  } else if (isEscapeKeyPressed(evt)) {
    return 'cancel';
  } else if (isTabKeyPressed(evt)) {
    return 'tab';
  } else if (isCtrlPressed(evt) && !isShiftKeyPressed(evt) && isKeyPressed(evt, { key: 'i' })) {
    return 'italic';
  } else if (isCtrlPressed(evt) && isKeyPressed(evt, { key: 'b' })) {
    return 'bold';
  } else if (isCtrlPressed(evt) && isKeyPressed(evt, { key: 'u' })) {
    return 'underline';
  } else if (isCtrlPressed(evt) && isKeyPressed(evt, { key: 'k' })) {
    return 'link';
  } else if (isCtrlPressed(evt) && isShiftKeyPressed(evt) && isKeyPressed(evt, { key: 'I' })) {
    return 'transliteration';
  }
  return getDefaultKeyBinding(evt);
}

const getStyleMap = (readOnly) => ({
  SELECTED: {
    backgroundColor: 'rgba(78, 78, 68, 0.2)',
  },
  TRANSLITERATION: {
    color: readOnly ? 'inherit' : 'var(--orange-1)',
    fontStyle: 'italic',
  },
});

class Editor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedEntryId: '',
      focusedTitleId: '',
      focusedIndividualId: '',
      focusedEntityKey: null,
      currentSelectionInlineStylesMap: {
        BOLD: false,
        ITALIC: false,
        UNDERLINE: false,
        TRANSLITERATION: false,
      },
    };

    this.setEditorRef = this.setEditorRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleBeforeInput = this.handleBeforeInput.bind(this);
    this.formatPastedText = this.formatPastedText.bind(this);
    this.handlePastedText = this.handlePastedText.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleActions = this.handleActions.bind(this);
    this.openEntryLinkEditor = this.openEntryLinkEditor.bind(this);
    this.openTitleLinkEditor = this.openTitleLinkEditor.bind(this);
    this.openIndividualLinkEditor = this.openIndividualLinkEditor.bind(this);
    this.handleConfirmEntryLink = this.handleConfirmEntryLink.bind(this);
    this.handleConfirmTitleLink = this.handleConfirmTitleLink.bind(this);
    this.handleConfirmIndividualLink = this.handleConfirmIndividualLink.bind(this);
    this.handleRemoveEntryLink = this.handleRemoveEntryLink.bind(this);
  }

  setEditorRef(domNode) {
    this.editorDomNode = domNode;
  }

  handleKeyCommand(command) {
    if (
      command === 'italic' ||
      command === 'bold' ||
      command === 'underline' ||
      command === 'transliteration'
    ) {
      // Update selection state to force the component to rerender
      const newEditorState = RichUtils.toggleInlineStyle(
        this.props.editorState,
        command.toUpperCase()
      );
      this.setState({
        currentSelectionInlineStylesMap: getSelectionInlineStyle(newEditorState),
      });
      this.props.onChange(newEditorState);
      return 'handled';
    }
    if (command === 'link') {
      this.openEntryLinkEditor();
      return 'handled';
    }
    if (
      this.props.onKeyCommand &&
      (command === 'cancel' || command === 'validate' || command === 'tab')
    ) {
      // Update selection state to force the component to rerender
      const newState = EditorState.acceptSelection(
        this.props.editorState,
        setCursorAtTheEnd(this.props.editorState)
      );
      this.props.onChange(newState);
      this.props.onKeyCommand(command, newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleChange(nextEditorState) {
    const { editorState, readOnly, onSetPopover, onSetPopoverPosition, popover } = this.props;
    let filteredEditorState = nextEditorState;

    const shouldFilterPaste =
      nextEditorState.getCurrentContent() !== editorState.getCurrentContent() &&
      nextEditorState.getLastChangeType() === 'insert-fragment';

    if (shouldFilterPaste) {
      filteredEditorState = filterEditorState(
        {
          blocks: [],
          styles: ['BOLD', 'ITALIC', 'UNDERLINE'],
          entities: [
            {
              type: 'ENTRY_LINK',
              attributes: ['entryId'],
            },
            {
              type: 'TITLE_LINK',
              attributes: ['titleId'],
            },
            {
              type: 'INDIVIDUAL_LINK',
              attributes: ['individualId'],
            },
          ],
          maxNesting: 0,
          whitespacedCharacters: ['\t', '\u00A0'],
        },
        filteredEditorState
      );
    }

    this.lastSelectionState = nextEditorState.getSelection();

    const selectionHasChanged = Object.is(
      nextEditorState.getCurrentContent(),
      editorState.getCurrentContent()
    );
    const shouldShowContextMenu = !readOnly && !popover && !isSelectionCollapsed(nextEditorState);

    if (nextEditorState.getSelection().getHasFocus()) {
      if (
        selectionHasChanged &&
        ((this.shouldShowEntryLinkPopover(nextEditorState) &&
          popover !== POPOVERS.ENTRY_LINK_POPOVER) ||
          (this.shouldShowTitleLinkPopover(nextEditorState) &&
            popover !== POPOVERS.TITLE_LINK_POPOVER) ||
          (this.shouldShowIndividualLinkPopover(nextEditorState) &&
            popover !== POPOVERS.INDIVIDUAL_LINK_POPOVER))
      ) {
        /*
         * LINK EDITOR
         * The cursor is inside an hyperlink
         * –› show the hyperlink editor
         * –› put a “SELECTED” style on the selection
         */
        const focusedEntityKey = getFocusedEntityKey(nextEditorState);
        this.setState({
          focusedEntryId: getFocusedEntryId(nextEditorState),
          focusedTitleId: getFocusedTitleId(nextEditorState),
          focusedIndividualId: getFocusedIndividualId(nextEditorState),
          focusedEntityKey,
        });

        if (document.getSelection().rangeCount > 0) {
          onSetPopoverPosition(document.getSelection().getRangeAt(0).getClientRects()[0]);
          if (this.shouldShowEntryLinkPopover(nextEditorState)) {
            onSetPopover(POPOVERS.ENTRY_LINK_POPOVER);
          } else if (this.shouldShowTitleLinkPopover(nextEditorState)) {
            onSetPopover(POPOVERS.TITLE_LINK_POPOVER);
          } else if (this.shouldShowIndividualLinkPopover(nextEditorState)) {
            onSetPopover(POPOVERS.INDIVIDUAL_LINK_POPOVER);
          }
        }

        if (nextEditorState.getSelection().isCollapsed()) {
          // Put the “SELECTED” style on the entity the cursor is in
          const range = getFocusedEntityRange(nextEditorState, focusedEntityKey);
          const entitySelection = nextEditorState.getSelection().merge({
            anchorOffset: range[0],
            focusOffset: range[1],
          });
          filteredEditorState = EditorState.acceptSelection(
            clearInlineStyles(nextEditorState),
            entitySelection
          );
          filteredEditorState = RichUtils.toggleInlineStyle(filteredEditorState, 'SELECTED');
          filteredEditorState = EditorState.acceptSelection(
            filteredEditorState,
            nextEditorState.getSelection()
          );
        } else {
          // Put the “SELECTED” style on the selection
          filteredEditorState = RichUtils.toggleInlineStyle(
            clearInlineStyles(filteredEditorState),
            'SELECTED'
          );
        }
      } else if (selectionHasChanged && shouldShowContextMenu) {
        /*
         * CONTEXT MENU
         * Some text has been selected
         * –› show the context menu
         */
        this.setState({
          currentSelectionInlineStylesMap: getSelectionInlineStyle(nextEditorState),
        });
        onSetPopover(POPOVERS.CONTEXT_MENU);
        onSetPopoverPosition(getVisibleSelectionRect(window));
      } else if (
        (popover === POPOVERS.ENTRY_LINK_POPOVER &&
          !this.shouldShowEntryLinkPopover(nextEditorState)) ||
        (popover === POPOVERS.TITLE_LINK_POPOVER &&
          !this.shouldShowTitleLinkPopover(nextEditorState)) ||
        (popover === POPOVERS.INDIVIDUAL_LINK_POPOVER &&
          !this.shouldShowIndividualLinkPopover(nextEditorState))
      ) {
        /*
         * CLOSE POPOVER
         * The hyperlink editor is not open or it is open
         * but the selection is not on an hyperlink
         * –› remove the “SELECTED” style
         * –› close the popover
         */
        this.props.onResetPopoverState();
        filteredEditorState = clearInlineStyles(filteredEditorState);
      } else if (popover === POPOVERS.CONTEXT_MENU && isSelectionCollapsed(nextEditorState)) {
        this.props.onResetPopoverState();
      } else if (
        selectionHasChanged &&
        isNotEmpty(filteredEditorState) &&
        popover !== POPOVERS.ENTRY_LINK_POPOVER &&
        popover !== POPOVERS.TITLE_LINK_POPOVER &&
        popover !== POPOVERS.INDIVIDUAL_LINK_POPOVER
      ) {
        filteredEditorState = clearInlineStyles(filteredEditorState);
      }
    } else if (
      selectionHasChanged &&
      popover !== POPOVERS.ENTRY_LINK_EDITOR &&
      popover !== POPOVERS.TITLE_LINK_EDITOR &&
      popover !== POPOVERS.INDIVIDUAL_LINK_EDITOR
    ) {
      filteredEditorState = EditorState.acceptSelection(
        clearInlineStyles(filteredEditorState),
        setCursorAtTheEnd(filteredEditorState)
      );
      this.setState({
        focusedEntryId: null,
        focusedTitleId: null,
        focusedIndividualId: null,
        focusedEntityKey: null,
      });
    }

    if (!readOnly) {
      this.props.onChange(filteredEditorState, !selectionHasChanged);
    }
  }

  handleBeforeInput(chars, editorState) {
    const { readOnly, onChange } = this.props;

    if (readOnly) {
      return 'handled';
    }

    const replaceChar = (newChar) => {
      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const contentReplaced = Modifier.replaceText(content, selection, newChar);
      return EditorState.push(editorState, contentReplaced, 'replace-text');
    };
    const QUOTE = '"';
    const PUNCTUATIONS = ['?', '!', ';', ':'];
    const CHARS_TO_REPLACE = [QUOTE, ...PUNCTUATIONS];
    const SPACE_CHARS = [' ', '\u00A0'];

    if (CHARS_TO_REPLACE.includes(chars)) {
      const isPreviousCharSpace = SPACE_CHARS.includes(
        getPreviousCharactersAtSelection(editorState)
      );

      if (chars === QUOTE) {
        if (isPreviousCharSpace) {
          onChange(replaceChar('«\u00A0'));
        } else {
          onChange(replaceChar('\u00A0»'));
        }
      } else if (PUNCTUATIONS.includes(chars)) {
        if (!isPreviousCharSpace) {
          onChange(replaceChar('\u00A0' + chars));
        } else {
          return 'not-handled';
        }
      }
      return 'handled';
    } else if (/\d/.test(chars) && getPreviousCharactersAtSelection(editorState, 2) === 'p.') {
      onChange(replaceChar('\u00A0' + chars));
      return 'handled';
    }

    return 'not-handled';
  }

  formatPastedText(text, html) {
    return {
      text: text.replaceAll(' ', '\u00A0'),
      html: html ? html.replaceAll(' ', '\u00A0') : null,
    };
  }

  handlePastedText(pastedText, html, editorState) {
    const { readOnly } = this.props;

    if (!pastedText) {
      return 'not-handled';
    }

    if (readOnly) {
      return 'handled';
    }

    const newEditorState = handleDraftEditorPastedText(html, editorState);

    if (newEditorState) {
      this.props.onChange(newEditorState);
      return 'handled';
    }

    if (!html && pastedText.indexOf(' ') !== -1) {
      console.log('replace', pastedText.indexOf(' '), pastedText.indexOf('\u00A0'));
      const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        pastedText.replaceAll(' ', '\u00A0')
      );
      this.props.onChange(EditorState.push(editorState, newContentState, 'insert-fragment'));
      return 'handled';
    }

    return 'not-handled';
  }

  shouldShowEntryLinkPopover(editorState) {
    const { readOnly } = this.props;
    return !readOnly && isFocusOnEntryLink(editorState) && getFocusedEntryId(editorState) !== '';
  }

  shouldShowTitleLinkPopover(editorState) {
    const { readOnly } = this.props;
    return !readOnly && isFocusOnTitleLink(editorState) && getFocusedTitleId(editorState) !== '';
  }

  shouldShowIndividualLinkPopover(editorState) {
    const { readOnly } = this.props;
    return (
      !readOnly &&
      isFocusOnIndividualLink(editorState) &&
      getFocusedIndividualId(editorState) !== ''
    );
  }

  handleActions(action) {
    const { onChange, editorState } = this.props;
    const newEditorState = RichUtils.toggleInlineStyle(editorState, action);
    this.setState({
      currentSelectionInlineStylesMap: getSelectionInlineStyle(newEditorState),
    });
    onChange(newEditorState);
  }

  openEntryLinkEditor() {
    const { editorState, onSetPopover, onChange } = this.props;

    if (!isSelectionCollapsed(editorState)) {
      onChange(RichUtils.toggleInlineStyle(editorState, 'SELECTED'), false);
      onSetPopover(POPOVERS.ENTRY_LINK_EDITOR);

      this.setState({
        focusedEntryId: getSelectionEntryId(editorState),
      });
    }
  }

  openTitleLinkEditor() {
    const { editorState, onSetPopover, onChange } = this.props;

    if (!isSelectionCollapsed(editorState)) {
      onChange(RichUtils.toggleInlineStyle(editorState, 'SELECTED'), false);
      onSetPopover(POPOVERS.TITLE_LINK_EDITOR);

      this.setState({
        focusedTitleId: getSelectionTitleId(editorState),
      });
    }
  }

  openIndividualLinkEditor() {
    const { editorState, onSetPopover, onChange } = this.props;

    if (!isSelectionCollapsed(editorState)) {
      onChange(RichUtils.toggleInlineStyle(editorState, 'SELECTED'), false);
      onSetPopover(POPOVERS.INDIVIDUAL_LINK_EDITOR);

      this.setState({
        focusedIndividualId: getSelectionIndividualId(editorState),
      });
    }
  }

  handleConfirmEntryLink(entryId, editedEntityKey) {
    const { editorState, onChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const isSelectionExpanded = !editorState.getSelection().isCollapsed();
    let entityKey = editedEntityKey;
    let contentStateWithEntity;
    if (!entityKey || isSelectionExpanded) {
      const contentStateWithoutEntity = removeEntity(editorState).getCurrentContent();
      contentStateWithEntity = contentStateWithoutEntity.createEntity('ENTRY_LINK', 'IMMUTABLE', {
        entryId,
      });
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    } else {
      contentStateWithEntity = contentState.replaceEntityData(entityKey, { entryId });
    }

    const inlineStyles = getSelectionInlineStyle(editorState);
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    const editorStateWithoutStyles = inlineStyles.TRANSLITERATION
      ? RichUtils.toggleInlineStyle(newEditorState, 'TRANSLITERATION')
      : newEditorState;
    const editorStateWithLinkToggled = RichUtils.toggleLink(
      editorStateWithoutStyles,
      editorStateWithoutStyles.getSelection(),
      entityKey
    );

    this.props.onFocus();
    this.editorDomNode.focus();
    this.props.onResetPopoverState();
    const cleanedEditorState = clearInlineStyles(editorStateWithLinkToggled);
    onChange(EditorState.forceSelection(cleanedEditorState, this.lastSelectionState));
  }

  handleConfirmTitleLink(titleId, editedEntityKey) {
    const { editorState, onChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const isSelectionExpanded = !editorState.getSelection().isCollapsed();
    let entityKey = editedEntityKey;
    let contentStateWithEntity;
    if (!entityKey || isSelectionExpanded) {
      const contentStateWithoutEntity = removeEntity(editorState).getCurrentContent();
      contentStateWithEntity = contentStateWithoutEntity.createEntity('TITLE_LINK', 'IMMUTABLE', {
        titleId,
      });
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    } else {
      contentStateWithEntity = contentState.replaceEntityData(entityKey, { titleId });
    }

    const inlineStyles = getSelectionInlineStyle(editorState);
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    const editorStateWithoutStyles = inlineStyles.TRANSLITERATION
      ? RichUtils.toggleInlineStyle(newEditorState, 'TRANSLITERATION')
      : newEditorState;
    const editorStateWithLinkToggled = RichUtils.toggleLink(
      editorStateWithoutStyles,
      editorStateWithoutStyles.getSelection(),
      entityKey
    );

    this.props.onFocus();
    this.editorDomNode.focus();
    this.props.onResetPopoverState();
    const cleanedEditorState = clearInlineStyles(editorStateWithLinkToggled);
    onChange(EditorState.forceSelection(cleanedEditorState, this.lastSelectionState));
  }

  handleConfirmIndividualLink(individualId, editedEntityKey) {
    const { editorState, onChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const isSelectionExpanded = !editorState.getSelection().isCollapsed();
    let entityKey = editedEntityKey;
    let contentStateWithEntity;
    if (!entityKey || isSelectionExpanded) {
      const contentStateWithoutEntity = removeEntity(editorState).getCurrentContent();
      contentStateWithEntity = contentStateWithoutEntity.createEntity(
        'INDIVIDUAL_LINK',
        'IMMUTABLE',
        {
          individualId,
        }
      );
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    } else {
      contentStateWithEntity = contentState.replaceEntityData(entityKey, { individualId });
    }

    const inlineStyles = getSelectionInlineStyle(editorState);
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    const editorStateWithoutStyles = inlineStyles.TRANSLITERATION
      ? RichUtils.toggleInlineStyle(newEditorState, 'TRANSLITERATION')
      : newEditorState;
    const editorStateWithLinkToggled = RichUtils.toggleLink(
      editorStateWithoutStyles,
      editorStateWithoutStyles.getSelection(),
      entityKey
    );

    this.props.onFocus();
    this.editorDomNode.focus();
    this.props.onResetPopoverState();
    const cleanedEditorState = clearInlineStyles(editorStateWithLinkToggled);
    onChange(EditorState.forceSelection(cleanedEditorState, this.lastSelectionState));
  }

  handleRemoveEntryLink(entityKey) {
    const { onResetPopoverState, editorState, onChange } = this.props;
    onResetPopoverState();
    const cleanedEditorState = clearInlineStyles(editorState);

    if (entityKey) {
      const currentSelection = cleanedEditorState.getSelection();
      const currentSelectionBlockKey = currentSelection.getEndKey();
      const currentSelectionOffset = currentSelection.getEndOffset();
      const selectionState = SelectionState.createEmpty(currentSelectionBlockKey);
      const updatedSelection = selectionState.merge({
        focusOffset: currentSelectionOffset,
        anchorOffset: currentSelectionOffset,
      });
      onChange(EditorState.forceSelection(removeEntity(cleanedEditorState), updatedSelection));
    } else {
      onChange(cleanedEditorState);
    }
  }

  handleClosePopover() {
    const { onChange, editorState, onResetPopoverState } = this.props;
    onResetPopoverState();
    if (!editorState.getSelection().getHasFocus()) {
      onChange(clearInlineStyles(editorState), false);
    }
  }

  renderPopovers() {
    const {
      popover,
      popoverPosition,
      onResetPopoverState,
      entryLinkEnable,
      titleLinkEnable,
      individualLinkEnable,
      editorState,
    } = this.props;
    const {
      currentSelectionInlineStylesMap,
      focusedEntityKey,
      focusedEntryId,
      focusedTitleId,
      focusedIndividualId,
    } = this.state;

    if (popover === POPOVERS.CONTEXT_MENU) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <EditorContextMenu
            onAction={this.handleActions}
            onAddEntryLink={this.openEntryLinkEditor}
            onAddTitleLink={this.openTitleLinkEditor}
            onAddIndividualLink={this.openIndividualLinkEditor}
            onCancel={onResetPopoverState}
            stylesMap={currentSelectionInlineStylesMap}
            entryLinkEnable={entryLinkEnable}
            titleLinkEnable={titleLinkEnable}
            individualLinkEnable={individualLinkEnable}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.ENTRY_LINK_EDITOR) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <LinkEditor
            type="entry"
            value={getSelectionAsText(editorState)}
            onConfirm={this.handleConfirmEntryLink}
            onCancel={this.handleClosePopover}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.TITLE_LINK_EDITOR) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <LinkEditor
            type="title"
            value={getSelectionAsText(editorState)}
            onConfirm={this.handleConfirmTitleLink}
            onCancel={this.handleClosePopover}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.INDIVIDUAL_LINK_EDITOR) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <LinkEditor
            type="individual"
            value={getSelectionAsText(editorState)}
            onConfirm={this.handleConfirmIndividualLink}
            onCancel={this.handleClosePopover}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.ENTRY_LINK_POPOVER) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <EntryLinkPopover
            entityKey={focusedEntityKey}
            entryId={focusedEntryId}
            onDelete={this.handleRemoveEntryLink}
            onClose={this.handleClosePopover}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.TITLE_LINK_POPOVER) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <TitleLinkPopover
            entityKey={focusedEntityKey}
            titleId={focusedTitleId}
            onDelete={this.handleRemoveEntryLink}
            onClose={this.handleClosePopover}
          />
        </EditorPopover>
      );
    } else if (popover === POPOVERS.INDIVIDUAL_LINK_POPOVER) {
      return (
        <EditorPopover position={popoverPosition} withOverlay={false}>
          <IndividualLinkPopover
            entityKey={focusedEntityKey}
            individualId={focusedIndividualId}
            onDelete={this.handleRemoveEntryLink}
            onClose={this.handleClosePopover}
          />
        </EditorPopover>
      );
    }
    return null;
  }

  render() {
    const { editorState, placeholder, popover, onFocus, onBlur, readOnly } = this.props;

    return (
      <div className="editor" dir="auto">
        <DraftEditor
          readOnly={readOnly}
          editorState={editorState}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={this.handleChange}
          onCopy={onDraftEditorCopy}
          onCut={onDraftEditorCut}
          handleBeforeInput={this.handleBeforeInput}
          formatPastedText={this.formatPastedText}
          handlePastedText={this.handlePastedText}
          handleKeyCommand={this.handleKeyCommand}
          keyBindingFn={keyBindingFn}
          customStyleMap={getStyleMap(readOnly)}
          placeholder={placeholder}
          ref={this.setEditorRef}
        />
        {popover && this.renderPopovers()}
      </div>
    );
  }
}

Editor.defaultProps = {
  readOnly: false,
  placeholder: '',
  popover: null,
  popoverPosition: null,
  entryLinkEnable: false,
  titleLinkEnable: false,
  individualLinkEnable: false,
  onFocus: noop,
  onBlur: noop,
  onKeyCommand: null,
};

Editor.propTypes = {
  readOnly: PropTypes.bool,
  popover: PropTypes.oneOf(Object.values(POPOVERS)),
  popoverPosition: PropTypes.object,
  placeholder: PropTypes.string,
  entryLinkEnable: PropTypes.bool,
  titleLinkEnable: PropTypes.bool,
  individualLinkEnable: PropTypes.bool,
  editorState: PropTypes.object.isRequired,
  onSetPopover: PropTypes.func.isRequired,
  onSetPopoverPosition: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onResetPopoverState: PropTypes.func.isRequired,
  onKeyCommand: PropTypes.func,
};

export const UnWrappedEditor = Editor;
export default withPopoverManager()(Editor);
