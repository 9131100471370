import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { constant, uniq } from 'lodash';
import { get } from 'lodash/fp';
import { isNotNil } from 'utils/util';
import WordsDataService from 'services/WordsDataService';
import {
  resetGraphiesOfEntry,
  loadGraphies,
  unpublishEntryGraphies,
  publishEntryGraphies,
  selectors as wordGraphiesSelectors,
} from './wordGraphies';
import { logout } from './auth';
import {
  selectors as wordCommentsSelectors,
  loadComment,
  publishEntryComment,
  resetCommentOfEntry,
  unpublishEntryComment,
} from './wordComments';
import {
  loadDictionaryReferences,
  publishEntryDictionaryReferences,
  resetDictionaryReferencesOfEntry,
  unpublishEntryDictionaryReferences,
  selectors as wordDictionaryReferencesSelectors,
} from './wordDictionaryReferences';
import {
  getNuanceIdsByEntryId,
  publishEntryNuances,
  resetNuancesOfEntry,
  unpublishEntryNuances,
} from './nuances';
import { ENTRY_STATE, resetEntryState, setEntryState } from './ui/entryLoading';
import { registerOpenHandlers } from './openEntries';
import { registerCreation } from './ui/entryCreation';
import { registerHighlight } from './ui/entryHighlight';
import { registerLock } from './ui/entryLock';
import { getAttestationById } from 'slices/attestations';
import { getSourceById } from 'slices/sources';

const WORD_PREFIX = 'w';

// ------------------------------------
// Selectors
// ------------------------------------

const getWordById = (state, entryId) => state.words.byId[entryId];

const isWordPublished = (state, entryId) => {
  const entry = getWordById(state, entryId);
  return isNotNil(entry) && isNotNil(entry.published);
};

const getPeriods = (state, entryId, publishableProp) => {
  const uniqueAttestationIds = uniq(
    wordGraphiesSelectors
      .getIdsByEntryId(state, entryId)
      .flatMap(
        (graphyId) => wordGraphiesSelectors.getById(state, graphyId)[publishableProp].attestationIds
      )
      .filter(isNotNil)
  );

  const uniqueAttestationPeriods = uniqueAttestationIds
    .map((id) => getAttestationById(state, id))
    .filter(({ attestationPeriod }) => attestationPeriod)
    .map(get('attestationPeriod'));

  const uniqueSourcePeriods = uniqueAttestationIds
    .map((id) => getAttestationById(state, id))
    .filter(({ attestationPeriod }) => !attestationPeriod)
    .map(get('sourceId'))
    .filter(isNotNil)
    .flatMap((id) => getSourceById(state, id).periods)
    .filter(isNotNil);

  return uniq([...uniqueAttestationPeriods, ...uniqueSourcePeriods]);
};

// ------------------------------------
// Actions
// ------------------------------------

const loadAllWordData = (entryId, isEditVersion) => {
  return async (dispatch) => {
    dispatch(setEntryState({ entryId, entryState: ENTRY_STATE.loading }));
    const [, loadResult] = await Promise.allSettled([
      dispatch(loadGraphies({ entryId, isEditVersion })),
      dispatch(loadWord({ entryId, isEditVersion })),
      dispatch(loadDictionaryReferences({ entryId, isEditVersion })),
      dispatch(loadComment({ entryId, isEditVersion })),
    ]);
    dispatch(resetEntryState({ entryId }));
    if (loadResult.value.type === loadWord.rejected.type) {
      return { error: true };
    }
    return { success: true };
  };
};

const loadWord = createAsyncThunk('entry/load', async ({ entryId, isEditVersion }) => {
  const res = await WordsDataService.get(entryId, isEditVersion);
  return res.data;
});

const reloadWord = (entryId) => {
  return (dispatch) => {
    dispatch(resetGraphiesOfEntry(entryId));
    dispatch(resetCommentOfEntry(entryId));
    dispatch(resetDictionaryReferencesOfEntry(entryId));
    dispatch(resetNuancesOfEntry(entryId));
    return dispatch(loadAllWordData(entryId, false));
  };
};

const resetWordData = (entryId) => {
  return (dispatch) => {
    dispatch(resetWord(entryId));
    dispatch(resetGraphiesOfEntry(entryId));
    dispatch(resetCommentOfEntry(entryId));
    dispatch(resetDictionaryReferencesOfEntry(entryId));
    dispatch(resetNuancesOfEntry(entryId));
  };
};

const createAndLockWord = (form) => {
  return (dispatch) => {
    if (form.data.edited?.transliteration) {
      return dispatch(createWord(form));
    }
  };
};

const lockWord = createAsyncThunk('entry/lock', async (entryId, { rejectWithValue }) => {
  try {
    const res = await WordsDataService.lock(entryId);
    return { entryId, data: res.data };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const unlockWord = createAsyncThunk('entry/unlock', async (entryId, { getState, dispatch }) => {
  if (isWordUILocked(getState(), entryId)) {
    dispatch(unlockWordUI());
  }
  const res = await WordsDataService.unlock(entryId);
  return { entryId, data: res.data };
});

const unpublishAllWordData = (entryId) => {
  return (dispatch, getState) => {
    dispatch(unpublishWord(getWordById(getState(), entryId)));
    dispatch(unpublishEntryGraphies(entryId));
    dispatch(unpublishEntryNuances(entryId));
    if (wordCommentsSelectors.getByEntryId(getState(), entryId)) {
      dispatch(unpublishEntryComment(entryId));
    }
    if (wordDictionaryReferencesSelectors.getByEntryId(getState(), entryId)) {
      dispatch(unpublishEntryDictionaryReferences(entryId));
    }
    if (getNuanceIdsByEntryId(getState(), entryId)) {
      dispatch(unpublishEntryNuances(entryId));
    }
  };
};

const publishAllWordData = (entryId) => {
  return async (dispatch, getState) => {
    dispatch(setEntryState({ entryId, entryState: ENTRY_STATE.publishing }));

    const publishActions = [
      dispatch(publishWord(getWordById(getState(), entryId))),
      dispatch(publishEntryGraphies(entryId)),
      dispatch(publishEntryNuances(entryId)),
      wordCommentsSelectors.getByEntryId(getState(), entryId)
        ? dispatch(publishEntryComment(entryId))
        : null,
      wordDictionaryReferencesSelectors.getByEntryId(getState(), entryId)
        ? dispatch(publishEntryDictionaryReferences(entryId))
        : null,
      getNuanceIdsByEntryId(getState(), entryId) ? dispatch(publishEntryNuances(entryId)) : null,
    ].filter(isNotNil);

    await Promise.all(publishActions);

    dispatch(resetEntryState({ entryId }));
  };
};

const publishWord = createAsyncThunk('word/publish', async (word) => {
  const res = await WordsDataService.publish(word);
  return res.data;
});

const unpublishWord = createAsyncThunk('word/unpublish', async (word) => {
  const res = await WordsDataService.unpublish(word);
  return res.data;
});

const createWord = createAsyncThunk('word/create', async ({ data }, { dispatch }) => {
  const res = await WordsDataService.createOrUpdate({
    isCheckedForV4: true,
    edited: { ...data.edited },
  });
  dispatch(stopWordCreation());
  dispatch(openAndLoadWord(res.data.id, true));
  return res.data;
});

const updateWord = createAsyncThunk('word/update', async ({ id, data }, { dispatch, getState }) => {
  dispatch(setEntryState({ entryId: id, entryState: ENTRY_STATE.saving }));

  const oldWord = getWordById(getState(), id);
  const updatedWord = {
    ...oldWord,
    ...data,
    edited: {
      ...oldWord.edited,
      ...data.edited,
      mainNuances: {
        ...oldWord.edited.mainNuances,
        ...data.edited?.mainNuances,
      },
    },
  };

  const res = await WordsDataService.createOrUpdate(updatedWord);

  dispatch(resetEntryState({ entryId: id }));

  return res.data;
});

const deleteWord = createAsyncThunk('word/delete', async (entryId, { dispatch }) => {
  dispatch(setEntryState({ entryId, entryState: ENTRY_STATE.deleting }));
  dispatch(resetNuancesOfEntry(entryId));
  dispatch(resetDictionaryReferencesOfEntry(entryId));

  const res = await WordsDataService.delete(entryId);

  dispatch(resetEntryState({ entryId }));
  dispatch(closeWord());

  return { entryId, data: res.data };
});

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {
  byId: {},
};

const replace = (state, action) => {
  state.byId[action.payload.id] = action.payload;
};

const remove = (state, action) => {
  delete state.byId[action.payload.entryId];
};

const wordsSlice = createSlice({
  name: 'words',
  initialState,
  reducers: {
    resetWord: (state, action) => {
      delete state.byId[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWord.fulfilled, replace)
      .addCase(publishWord.fulfilled, replace)
      .addCase(unpublishWord.fulfilled, replace)
      .addCase(updateWord.fulfilled, replace)
      .addCase(createWord.fulfilled, replace)
      .addCase(deleteWord.fulfilled, remove)
      .addCase(logout.fulfilled, constant(initialState));
  },
});

const { resetWord } = wordsSlice.actions;

const { reducer } = wordsSlice;

const {
  actions: openActions,
  utils: openUtils,
  selectors: openSelectors,
} = registerOpenHandlers(WORD_PREFIX, {
  load: loadAllWordData,
  reset: resetWordData,
  lock: lockWord,
  unlock: unlockWord,
  reload: reloadWord,
});
const {
  openAndLoad: openAndLoadWord,
  close: closeWord,
  startEdition: startWordEdition,
  stopEditionAndReload: stopWordEditionAndReload,
} = openActions;
const { isOfType: isWord } = openUtils;
const { isEdited: isWordEdited, isOpened: isWordOpened } = openSelectors;

const { actions: creationActions, selectors: creationSelectors } = registerCreation(WORD_PREFIX);
const { startCreation: startWordCreation, stopCreation: stopWordCreation } = creationActions;
const { isCreating: isCreatingWord } = creationSelectors;

const { actions: highlightActions, selectors: highlightSelectors } = registerHighlight(WORD_PREFIX);
const { highlight: highlightWord, reset: resetWordHihglight } = highlightActions;
const { isHighlighted: isWordHighlighted } = highlightSelectors;

const { actions: lockActions, selectors: lockSelectors } = registerLock(WORD_PREFIX);
const { lock: lockWordUI, unlock: unlockWordUI } = lockActions;
const { isLocked: isWordUILocked, getEditor: getWordLockUIEditor } = lockSelectors;

export {
  WORD_PREFIX,
  reducer,
  loadAllWordData,
  resetWordData,
  createAndLockWord,
  loadWord,
  reloadWord,
  lockWord,
  unlockWord,
  updateWord,
  deleteWord,
  openAndLoadWord,
  closeWord,
  startWordEdition,
  stopWordEditionAndReload,
  startWordCreation,
  stopWordCreation,
  unpublishAllWordData,
  publishAllWordData,
  getWordById,
  isCreatingWord,
  isWordPublished,
  isWord,
  isWordEdited,
  isWordOpened,
  highlightWord,
  resetWordHihglight,
  isWordHighlighted,
  lockWordUI,
  unlockWordUI,
  isWordUILocked,
  getWordLockUIEditor,
  getPeriods,
};
