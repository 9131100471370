import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './IconButton.scss';

const IconButton = ({ children, theme, className, ...rest }) => {
  return (
    <button
      type="button"
      className={classNames('icon-button', className)}
      data-theme={theme}
      {...rest}
    >
      {children}
    </button>
  );
};

IconButton.defaultProps = {
  className: '',
  theme: '',
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
};

export default IconButton;
