import React, { forwardRef, useCallback } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { ReactComponent as DragHandleIcon } from 'images/icon-drag-handle.svg';
import { getEntryNumericId, isEntryEdited } from 'slices/openEntries';

const MiniMapEntry = forwardRef(
  (
    {
      id,
      rank,
      isDragging,
      isSorting,
      isDragOverlay,
      handleProps,
      listeners,
      entrySelector,
      onMouseEnter,
      onMouseLeave,
      ...rest
    },
    ref
  ) => {
    const entry = useSelector((state) => entrySelector(state, getEntryNumericId(id)));
    const isEdited = useSelector((state) => isEntryEdited(state, id));
    const transliteration = isEdited
      ? get(entry, 'edited.transliteration', null)
      : get(entry, 'published.transliteration', null);

    const handleScrollEntryIntoView = () => {
      const entries = document.querySelectorAll('.entry');
      if (entries && entries[rank]) {
        entries[rank].scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    };

    const handleMouseEnter = useCallback(() => {
      onMouseEnter(getEntryNumericId(id));
    }, [onMouseEnter, id]);

    return (
      <div
        ref={ref}
        {...rest}
        className={classNames('mini-map-entry', {
          'is-dragging': isDragging,
          'is-sorting': isSorting,
          'is-drag-overlay': isDragOverlay,
        })}
        data-theme={isEdited ? 'blue' : 'default'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleScrollEntryIntoView}
      >
        <div className="mini-map-entry-handle" {...handleProps} {...listeners}>
          <DragHandleIcon />
        </div>
        <div className="mini-map-entry-content">{transliteration}</div>
      </div>
    );
  }
);

export default MiniMapEntry;
