import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Copy = ({ text, render }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  return <CopyToClipboard text={text}>{render(copied, onCopy)}</CopyToClipboard>;
};

Copy.propTypes = {
  text: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

export default Copy;
