import { cond, constant, stubTrue } from 'lodash';
import { eq } from 'lodash/fp';
import { EDITION_STATUS } from 'utils/constants';

const generateEntryPermalink = (entryId) =>
  window.location.origin + window.location.pathname + '?entries=' + entryId;

const getEditionStatus = (entry) => {
  if (!entry) {
    return null;
  }
  return cond([
    [eq('handleable'), constant(EDITION_STATUS.processed)],
    [eq(EDITION_STATUS.processed), constant(EDITION_STATUS.processed)],
    [eq(EDITION_STATUS.done), constant(EDITION_STATUS.done)],
    [stubTrue, constant(null)],
  ])(entry.editionStatus);
};

export { getEditionStatus, generateEntryPermalink };
