import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EntryPreview from 'components/Entry/EntryPreview';
import { getIndividualById } from 'slices/individuals';
import TitleReadContent from './IndividualReadContent';

const IndividualPreview = ({ id, onClose }) => {
  const entry = useSelector((state) => getIndividualById(state, id));

  return (
    <EntryPreview onClose={onClose}>
      {entry && entry.edited && <TitleReadContent publishableProp="edited" entry={entry} />}
    </EntryPreview>
  );
};

IndividualPreview.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IndividualPreview;
