import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadIndividual } from 'slices/individuals';
import LinkDecorator from './LinkDecorator';

const IndividualLinkDecorator = ({ contentState, entityKey, children }) => {
  const dispatch = useDispatch();
  const { individualId } = contentState.getEntity(entityKey).getData();

  const handleOpeningIndividual = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadIndividual(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkDecorator id={individualId} type="individual" onOpen={handleOpeningIndividual}>
      {children}
    </LinkDecorator>
  );
};

IndividualLinkDecorator.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default IndividualLinkDecorator;
