import commentsFactory from './commentsFactory';
import CommentsDataService from 'services/CommentsDataService';

const commentsDataService = new CommentsDataService('titleId');

const extraReducers = {
  'title/delete/fulfilled': (state, action) => {
    delete state.byTitleId[action.payload.titleId];
  },
};

const { reducer, actions, selectors } = commentsFactory(
  'titleComments',
  'title',
  extraReducers,
  commentsDataService
);

const {
  loadComment,
  unpublishEntryComment: unpublishTitleComment,
  publishEntryComment: publishTitleComment,
  createOrUpdateComment,
  resetCommentOfEntry: resetCommentOfTitle,
} = actions;

export {
  reducer,
  loadComment,
  unpublishTitleComment,
  publishTitleComment,
  createOrUpdateComment,
  resetCommentOfTitle,
  selectors,
};
