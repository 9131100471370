import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { get, uniq } from 'lodash';
import { get as getFp } from 'lodash/fp';
import { isNotNil } from 'utils/util';
import AttestationsDataService from 'services/AttestationsDataService';
import { loadGraphies as loadTitleGraphies } from 'slices/titleGraphies';
import { loadGraphies as loadWordGraphies } from 'slices/wordGraphies';
import { loadIndividualSequences } from 'slices/individualSequences';
import { getSourceById } from 'slices/sources';
import { close } from 'slices/ui/thesaurus';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getAttestationById = (state, attestationId) =>
  state.attestations.attestationsById[attestationId];
const getAttestationIdsBySourceId = (state, sourceId) =>
  state.attestations.attestationsBySourceId[sourceId] || emptyArray;

const getAllPeriodsFromAttestationIds = (state, attestationIds) => {
  const attestationPeriods = attestationIds
    .map((id) => getAttestationById(state, id))
    .filter(({ attestationPeriod }) => attestationPeriod)
    .map(getFp('attestationPeriod'));

  const sourcePeriods = attestationIds
    .map((id) => getAttestationById(state, id))
    .filter(({ attestationPeriod }) => !attestationPeriod)
    .map(getFp('sourceId'))
    .filter(isNotNil)
    .flatMap((id) => getSourceById(state, id).periods)
    .filter(isNotNil);

  return uniq([...attestationPeriods, ...sourcePeriods]);
};

// ------------------------------------
// Async actions
// ------------------------------------
const collator = new Intl.Collator('fr', { numeric: true, sensitivity: 'base' });

const compareAttestations = (a1, a2) => collator.compare(a1.reference, a2.reference);

const loadAttestations = createAsyncThunk('attestations/load', async (sourceId) => {
  const res = await AttestationsDataService.get(sourceId);
  return { sourceId, data: res.data.sort(compareAttestations) };
});

const createAttestation = createAsyncThunk('attestations/create', async (attestation) => {
  const res = await AttestationsDataService.createOrUpdate(attestation);
  return res.data;
});

const updateAttestation = createAsyncThunk('attestations/update', async (attestation) => {
  const res = await AttestationsDataService.createOrUpdate(attestation);
  return res.data;
});

const deleteAttestation = createAsyncThunk(
  'attestations/delete',
  async ({ attestationId, sourceId }, { rejectWithValue }) => {
    try {
      await AttestationsDataService.delete(attestationId);
      return { id: attestationId, sourceId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addSourceToAttestation = ({ attestationId, sourceId }) => {
  return (dispatch, getState) => {
    dispatch(close());
    const attestation = getAttestationById(getState(), attestationId);
    return dispatch(updateAttestation({ ...attestation, sourceId }));
  };
};

const initialState = {
  attestationsBySourceId: {},
  attestationsById: {},
};

const attestationsSlice = createSlice({
  name: 'attestations',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadAttestations.fulfilled, (state, action) => {
        state.attestationsBySourceId[action.payload.sourceId] = action.payload.data.map(
          (attestation) => attestation.id
        );
        action.payload.data.forEach(
          (attestation) => (state.attestationsById[attestation.id] = attestation)
        );
      })
      .addCase(createAttestation.fulfilled, (state, action) => {
        state.attestationsBySourceId[action.payload.sourceId] = [
          action.payload.id,
          ...(state.attestationsBySourceId[action.payload.sourceId] || []),
        ];
        state.attestationsById[action.payload.id] = action.payload;
      })
      .addCase(updateAttestation.pending, (state, action) => {
        state.attestationsById[action.meta.arg.id] = action.meta.arg;
      })
      .addCase(updateAttestation.fulfilled, (state, action) => {
        state.attestationsById[action.payload.id] = action.payload;
      })
      .addCase(deleteAttestation.fulfilled, (state, action) => {
        delete state.attestationsById[action.payload.id];
        state.attestationsBySourceId[action.payload.sourceId] = state.attestationsBySourceId[
          action.payload.sourceId
        ].filter((id) => id !== action.payload.id);
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(
          loadWordGraphies.fulfilled,
          loadTitleGraphies.fulfilled,
          loadIndividualSequences.fulfilled
        ),
        (state, action) => {
          action.payload.data
            .flatMap((graphy) => [
              ...get(graphy, 'published.attestations', []),
              ...get(graphy, 'edited.attestations', []),
            ])
            .forEach((attestation) => {
              const parsedAttestation = { ...attestation };
              if (parsedAttestation.source) {
                parsedAttestation.sourceId = parsedAttestation.source.id;
                delete parsedAttestation.source;
              }
              if (parsedAttestation.nuance) {
                delete parsedAttestation.nuance;
              }
              state.attestationsById[parsedAttestation.id] = parsedAttestation;
            });
        }
      );
  },
});

const { reducer } = attestationsSlice;

export {
  reducer,
  getAttestationById,
  getAttestationIdsBySourceId,
  getAllPeriodsFromAttestationIds,
  loadAttestations,
  createAttestation,
  updateAttestation,
  deleteAttestation,
  addSourceToAttestation,
};
