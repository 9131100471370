import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openAndLoadIndividual } from 'slices/individuals';
import LinkPopover from './LinkPopover';

const IndividualLinkPopover = ({ entityKey, individualId, onClose, onDelete }) => {
  const dispatch = useDispatch();

  const handleOpeningIndividual = useCallback(
    (id, isEdited) => {
      dispatch(openAndLoadIndividual(id, isEdited));
    },
    [dispatch]
  );

  return (
    <LinkPopover
      entityKey={entityKey}
      type="individual"
      id={individualId}
      onOpen={handleOpeningIndividual}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

IndividualLinkPopover.propTypes = {
  entityKey: PropTypes.string.isRequired,
  individualId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IndividualLinkPopover;
