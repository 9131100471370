import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'slices/auth';
import { createOrUpdateNuance } from 'slices/nuances';

const ENTRY_STATE = {
  idle: 'idle',
  loading: 'loading',
  saving: 'saving',
  publishing: 'publishing',
  deleting: 'deleting',
  error: 'error',
};

// ------------------------------------
// Selectors
// ------------------------------------

const isIdle = (state, entryId) => state.entryLoading[entryId] === ENTRY_STATE.idle;
const isLoading = (state, entryId) => state.entryLoading[entryId] === ENTRY_STATE.loading;
const isSaving = (state, entryId) => state.entryLoading[entryId] === ENTRY_STATE.saving;
const isPublishing = (state, entryId) => state.entryLoading[entryId] === ENTRY_STATE.publishing;
const isDeleting = (state, entryId) => state.entryLoading[entryId] === ENTRY_STATE.deleting;

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {};

const reset = () => {
  return initialState;
};

const entryLoadingSlice = createSlice({
  name: 'entryLoading',
  initialState,
  reducers: {
    setEntryState: (state, action) => {
      state[action.payload.entryId] = action.payload.entryState;
    },
    resetEntryState: (state, action) => {
      delete state[action.payload.entryId];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrUpdateNuance.pending, (state, action) => {
      state[action.meta.arg.entryId] = ENTRY_STATE.saving;
    });
    builder.addCase(createOrUpdateNuance.fulfilled, reset);
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

const { setEntryState, resetEntryState } = entryLoadingSlice.actions;

const { reducer } = entryLoadingSlice;

export {
  reducer,
  setEntryState,
  resetEntryState,
  isLoading,
  isSaving,
  isIdle,
  isPublishing,
  isDeleting,
  ENTRY_STATE,
};
