import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cond, constant, stubTrue, isEqual } from 'lodash';
import { eq } from 'lodash/fp';
import SearchDataService from 'services/SearchDataService';
import { asyncState } from '../utils/util';
import { logout } from './auth';

// ------------------------------------
// Constants
// ------------------------------------

const DEFAULT_PAGE_SIZE = 30;

// ------------------------------------
// Utils
// ------------------------------------

// Compare search parameters without the locale since its not stored.
const equalSearchParameters = (searchParams, requestParams) => {
  const { locale, ...testedRequestParams } = requestParams;
  return isEqual(searchParams, testedRequestParams);
};

// ------------------------------------
// Selectors
// ------------------------------------

const selectFilteredEntries = (state) => state.search.entries;
const selectTotalFilteredEntries = (state) => state.search.totalFilteredEntries;
const selectSearchStatus = (state) => state.search.status;

const hasNextPage = (state) => state.search.searchParameters.hasNextPage;
const selectPageNumber = (state) => state.search.searchParameters.pageNumber;
const selectSearchParameters = (state) => state.search.searchParameters;

const getSearchLang = (state) => {
  const searchParams = selectSearchParameters(state);
  const isTrue = (prop) => (params) => params[prop];
  const isAllTrue = (props) => (params) => props.every((prop) => isTrue(prop)(params));
  const getLangFromSearchParams = cond([
    [
      isAllTrue(['isArabic', 'isDeutsch', 'isEnglish', 'isFrench', 'isTransliteration']),
      constant(null),
    ],
    [isTrue('isArabic'), constant('ar')],
    [isTrue('isDeutsch'), constant('de')],
    [isTrue('isEnglish'), constant('en')],
    [isTrue('isFrench'), constant('fr')],
    [isTrue('isTransliteration'), constant('trans')],
    [isTrue('isComment'), constant('comment')],
    [stubTrue, constant(null)],
  ]);
  return getLangFromSearchParams(searchParams);
};

const getSearchPosition = (state) => {
  const searchParams = selectSearchParameters(state);
  const isTrue = (prop) => (params) => params[prop];
  const getPositionFromSearchParams = cond([
    [isTrue('startWord'), constant('start')],
    [isTrue('middleWord'), constant('middle')],
    [isTrue('endWord'), constant('end')],
    [stubTrue, constant('start')],
  ]);
  return getPositionFromSearchParams(searchParams);
};

const buildLocaleSearchParameters = (state, locale) => {
  return {
    ...state.search.searchParameters,
    locale,
  };
};

// ------------------------------------
// Actions
// ------------------------------------

const fetchFirstEntrySummariesPage = (locale) => {
  return (dispatch, getState) => {
    dispatch(clearFilteredEntries());
    return dispatch(loadEntrySummaries(buildLocaleSearchParameters(getState(), locale)));
  };
};

const fetchNextEntrySummariesPage = (locale) => {
  return (dispatch, getState) => {
    dispatch(setPageNumber(selectPageNumber(getState()) + 1));
    return dispatch(loadEntrySummaries(buildLocaleSearchParameters(getState(), locale)));
  };
};

const updateSearchPosition = (position, locale) => {
  return (dispatch) => {
    const defaultParams = { startWord: false, middleWord: false, endWord: false };
    const getSearchParamsFromPosition = cond([
      [eq('start'), constant({ ...defaultParams, startWord: true })],
      [eq('middle'), constant({ ...defaultParams, middleWord: true })],
      [eq('end'), constant({ ...defaultParams, endWord: true })],
      [stubTrue, constant({ ...defaultParams, startWord: true })],
    ]);
    dispatch(updateSearchParams(getSearchParamsFromPosition(position)));
    dispatch(fetchFirstEntrySummariesPage(locale));
  };
};

const updateSearchLang = (lang, locale) => {
  return (dispatch) => {
    const defaultParams = {
      isArabic: false,
      isDeutsch: false,
      isEnglish: false,
      isFrench: false,
      isTransliteration: false,
      isComment: false,
    };
    const getSearchParamsFromLang = cond([
      [eq('ar'), constant({ ...defaultParams, isArabic: true })],
      [eq('de'), constant({ ...defaultParams, isDeutsch: true })],
      [eq('en'), constant({ ...defaultParams, isEnglish: true })],
      [eq('fr'), constant({ ...defaultParams, isFrench: true })],
      [eq('trans'), constant({ ...defaultParams, isTransliteration: true })],
      [eq('comment'), constant({ ...defaultParams, isComment: true })],
      [
        stubTrue,
        constant({
          isArabic: true,
          isDeutsch: true,
          isEnglish: true,
          isFrench: true,
          isTransliteration: true,
          isComment: false,
        }),
      ],
    ]);
    dispatch(updateSearchParams(getSearchParamsFromLang(lang)));
    dispatch(fetchFirstEntrySummariesPage(locale));
  };
};

const updateSearchType = (type, locale) => {
  return (dispatch) => {
    dispatch(updateSearchParams({ type }));
    dispatch(fetchFirstEntrySummariesPage(locale));
  };
};

// ------------------------------------
// Async actions
// ------------------------------------

const loadEntrySummaries = createAsyncThunk('search/loadEntrySummaries', async (searchParams) => {
  const res = await SearchDataService.getAll(searchParams);
  return res.data;
});

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {
  searchParameters: {
    search: '',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    hasNextPage: true,
    isTransliteration: true,
    isEnglish: true,
    isFrench: true,
    isDeutsch: true,
    isArabic: true,
    isComment: false,
    startWord: true,
    middleWord: false,
    endWord: false,
    type: 'entry',
  },
  entries: [],
  status: asyncState.pending,
  totalFilteredEntries: 0,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.searchParameters.pageNumber = action.payload;
    },
    updateSearchParams: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        ...action.payload,
      };
    },
    clearFilteredEntries: (state) => {
      state.entries = [];
      state.totalFilteredEntries = 0;
      state.status = asyncState.pending;
      state.searchParameters.pageNumber = 1;
      state.searchParameters.pageSize = DEFAULT_PAGE_SIZE;
      state.searchParameters.hasNextPage = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEntrySummaries.pending, (state) => {
        state.status = asyncState.pending;
      })
      .addCase(loadEntrySummaries.fulfilled, (state, action) => {
        // Check required to avoid race condition issues with request responses
        // arriving in a different order that they were sent
        if (equalSearchParameters(state.searchParameters, action.meta.arg)) {
          state.searchParameters.hasNextPage = action.payload.content.length !== 0;
          state.totalFilteredEntries = action.payload.totalElements;
          state.entries = [...state.entries, ...action.payload.content];
          state.status = asyncState.resolved;
        }
      })
      .addCase(loadEntrySummaries.rejected, (state) => {
        state.status = asyncState.error;
        state.searchParameters.hasNextPage = false;
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { clearFilteredEntries, setPageNumber, updateSearchParams } = searchSlice.actions;

const { reducer } = searchSlice;

export {
  reducer,
  fetchFirstEntrySummariesPage,
  fetchNextEntrySummariesPage,
  selectSearchStatus,
  selectFilteredEntries,
  selectTotalFilteredEntries,
  selectSearchParameters,
  selectPageNumber,
  hasNextPage,
  clearFilteredEntries,
  setPageNumber,
  getSearchLang,
  getSearchPosition,
  updateSearchParams,
  updateSearchPosition,
  updateSearchLang,
  updateSearchType,
};
