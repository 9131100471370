import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Cluster, Stack } from 'components/Layout';
import { getAttestationById, updateAttestation } from 'slices/attestations';
import SourceSummary from 'components/Thesaurus/SourceSummary';
import PeriodsSelector from 'components/PeriodsSelector';
import Editor from 'components/Editor';
import { AutoSaveInput } from 'components/Input';
import Button from 'components/Button';
import './AttestationForm.scss';

const AttestationEditForm = ({ id, showSourceHeader, onEnd, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const attestation = useSelector((state) => getAttestationById(state, id));

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      karnakId: get(attestation, 'karnakId', ''),
    },
  });

  const handleUpdate = (data) => {
    return dispatch(updateAttestation({ ...attestation, ...data, id }));
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <form className="attestation-form" dir="auto">
      <Stack space="var(--s-1)">
        {showSourceHeader && !isNil(attestation.sourceId) && (
          <SourceSummary id={attestation.sourceId} />
        )}
        <Stack space="var(--s0)">
          <div
            className="input-container attestation-form-reference"
            data-layout="horizontal"
            data-size="extra-small"
          >
            <label>{t('attestation.reference')}</label>
            <Editor
              placeholder={t('attestation.reference')}
              initialValue={attestation.reference}
              onValidate={(value) => handleUpdate({ reference: value })}
            />
          </div>
          <Editor
            placeholder={t('attestation.transliteration')}
            initialValue={attestation.transliteration}
            entryLinkEnable
            titleLinkEnable
            individualLinkEnable
            onValidate={(value) => handleUpdate({ transliteration: value })}
          />
          <Editor
            placeholder={t('attestation.traduction')}
            initialValue={attestation.traduction}
            onValidate={(value) => handleUpdate({ traduction: value })}
          />
          <PeriodsSelector
            isSingle
            values={[attestation.attestationPeriod]}
            label={t('source.periods')}
            onChange={(periods) => handleUpdate({ attestationPeriod: periods[0] })}
          />
          <div className="input-container input-container--borderless" data-size="extra-small">
            <label>{t('attestation.bibliography')}</label>
            <Editor
              placeholder={t('attestation.bibliography')}
              initialValue={attestation.bibliography}
              onValidate={(value) => handleUpdate({ bibliography: value })}
            />
          </div>
          <AutoSaveInput
            size="extra-small"
            layout="horizontal"
            label="KIU"
            placeholder="123"
            {...register('karnakId')}
            onSave={handleSubmit(handleUpdate, onError)}
          />

          <Cluster justify="space-between" className="attestation-form-toolbar">
            {onDelete && (
              <Button
                size="small"
                theme="red"
                onClick={() => onDelete(id)}
                disabled={!attestation.reference}
              >
                {t('actions.remove')}
              </Button>
            )}
            {!!attestation.reference && (
              <Button size="small" theme="green" onClick={onEnd}>
                {t('actions.end')}
              </Button>
            )}
          </Cluster>
        </Stack>
      </Stack>
    </form>
  );
};

AttestationEditForm.defaultProps = {
  showSourceHeader: false,
  onDelete: null,
};

AttestationEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  onEnd: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  showSourceHeader: PropTypes.bool,
};

export default AttestationEditForm;
