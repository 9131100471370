import React from 'react';
import PropTypes from 'prop-types';
import EntryButton from './EntryButton';

const ToolbarButton = ({ children, isActive, onClick, ...rest }) => {
  return (
    <EntryButton {...rest} isSquare isActive={isActive} onClick={onClick}>
      {children}
    </EntryButton>
  );
};

ToolbarButton.defaultProps = {
  isActive: false,
};

ToolbarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

export default ToolbarButton;
