import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPeriods } from 'slices/titles';
import { selectors as titleGraphiesSelectors } from 'slices/titleGraphies';
import { selectors as titleCommentsSelectors } from 'slices/titleComments';
import { selectors as titleDictionaryReferencesSelectors } from 'slices/titleDictionaryReferences';
import { isLoading } from 'slices/ui/entryLoading';
import { ReactComponent as GraphiesIcon } from 'images/icon-tab-graphies.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import { ReactComponent as DictionariesIcon } from 'images/icon-tab-dictionaries.svg';
import { ReactComponent as TitleHoldersIcon } from 'images/icon-tab-title-holders.svg';
import Transliteration from 'components/Transliteration';
import Graphies from 'components/TitleGraphies/Graphies';
import Graphy from 'components/Graphy';
import Tag from 'components/Tag';
import TitleDictionaryReferences from 'components/TitleDictionaryReferences';
import { PeriodsOverview } from 'components/Periods';
import EntryTabs from 'components/Entry/EntryTabs';
import EntryNuance from 'components/Entry/EntryNuance';
import { Cluster } from 'components/Layout';
import TitleComments from 'components/TitleComments';
import TitleHolders from 'components/TitleHolders';
import { hasTitleHoldersByTitleId } from 'slices/titleHolders';

const TitleReadContent = ({ entry, publishableProp }) => {
  const { t, i18n } = useTranslation();
  const graphies = useSelector((state) => titleGraphiesSelectors.getIdsByTitleId(state, entry.id));
  const main1Graphy = useSelector((state) =>
    titleGraphiesSelectors.getMain1GraphyByTitleId(state, entry.id)
  );
  const main2Graphy = useSelector((state) =>
    titleGraphiesSelectors.getMain2GraphyByTitleId(state, entry.id, publishableProp)
  );
  const hasComment = useSelector((state) =>
    titleCommentsSelectors.doesTitleHaveOne(state, entry.id, publishableProp)
  );
  const hasDictionaryRefs = useSelector((state) =>
    titleDictionaryReferencesSelectors.doesTitleHaveOne(state, entry.id, publishableProp)
  );
  const hasTitleHolders = useSelector((state) =>
    hasTitleHoldersByTitleId(state, entry.id, publishableProp)
  );
  const entryPeriods = useSelector((state) => getPeriods(state, entry.id, publishableProp));
  const isLoadingEntry = useSelector((state) => isLoading(state, entry.id));

  const selectCommentTab =
    (entry.publicStatus === 'problematic' ||
      entry.publicStatus === 'wrong' ||
      entry.publicStatus === 'wrongGraphy') &&
    hasComment;
  const commentTabIndex = [true, hasDictionaryRefs, hasTitleHolders].filter(identity).length;

  return (
    <>
      <div className="entry-natures">
        <Cluster>
          <Tag>{t('entry.natures.types.title')}</Tag>
        </Cluster>
      </div>

      <h2 className="entry-transliteration">
        <Transliteration>{entry[publishableProp].transliteration}</Transliteration>
      </h2>

      <div className="entry-main-graphies" data-testid="entry-main-graphies">
        {main1Graphy && main1Graphy[publishableProp] && (
          <Graphy
            url={main1Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main1Graphy.publicStatus}
          />
        )}
        {main2Graphy && main2Graphy[publishableProp] && (
          <Graphy
            url={main2Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main2Graphy.publicStatus}
          />
        )}
      </div>

      <EntryNuance nuances={entry[publishableProp].mainNuances} />

      <div className="entry-periods">
        <PeriodsOverview selectedPeriods={entryPeriods} />
      </div>

      {!isLoadingEntry && (
        <EntryTabs
          defaultIndex={selectCommentTab ? commentTabIndex : 0}
          selectors={[
            <GraphiesIcon id="graphies" disabled={graphies.length === 0} />,
            hasDictionaryRefs ? <DictionariesIcon id="dictionaries" /> : null,
            hasTitleHolders ? <TitleHoldersIcon id="titleHolders" /> : null,
            hasComment ? <CommentsIcon id="comments" /> : null,
          ]}
        >
          <div id="graphies">
            {graphies.length > 0 ? (
              <Graphies titleId={entry.id} publishableProp={publishableProp} />
            ) : null}
          </div>
          {hasDictionaryRefs ? (
            <div id="dictionaries">
              <TitleDictionaryReferences
                titleId={entry.id}
                isEditVersion={false}
                publishableProp={publishableProp}
              />
            </div>
          ) : null}
          {hasTitleHolders ? (
            <div id="titleHolders">
              <TitleHolders titleId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          {hasComment ? (
            <div id="comments">
              <TitleComments
                titleId={entry.id}
                isEditVersion={false}
                publishableProp={publishableProp}
              />
            </div>
          ) : null}
        </EntryTabs>
      )}
    </>
  );
};

TitleReadContent.propTypes = {
  entry: PropTypes.object.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
};

export default TitleReadContent;
