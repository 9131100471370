import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import usePublishable from 'hooks/usePublishable';
import useDefaultNuanceLang from 'hooks/useDefaultNuanceLang';
import Editor from 'components/Editor';
import FlagIcon from 'components/FlagIcon';
import './MainNuancesEditor.scss';

const LOCALES = ['fr', 'en', 'de', 'ar'];

const MainNuancesEditor = ({ resource, onUpdate, locales }) => {
  const { i18n, t } = useTranslation();
  const [nuanceLang] = useDefaultNuanceLang();
  const [isPublishable] = usePublishable(resource);

  return (
    <Tabs
      className="main-nuances-tabs"
      disabledTabClassName="is-disabled"
      selectedTabClassName="is-selected"
      defaultIndex={locales.indexOf(nuanceLang) >= 0 ? locales.indexOf(nuanceLang) : 0}
      direction={i18n.dir()}
    >
      {locales.map((locale) => (
        <TabPanel key={locale}>
          <div
            className="main-nuances-editor"
            data-publishable={isPublishable(`mainNuances.${locale}`)}
          >
            {resource.edited && (
              <Editor
                initialValue={get(resource, `edited.mainNuances.${locale}`, null)}
                placeholder={t(`entry.translation.${locale}`)}
                readOnly={false}
                onValidate={(v) => onUpdate(locale, v)}
              />
            )}
          </div>
        </TabPanel>
      ))}
      <TabList className="main-nuances-tabs-selectors">
        {locales.map((locale) => (
          <Tab
            key={locale}
            className="main-nuances-tabs-selector"
            disabledClassName="is-disabled"
            selectedClassName="is-selected"
            data-publishable={isPublishable(`mainNuances.${locale}`)}
          >
            <FlagIcon
              iso={locale}
              size="medium"
              tint={isPublishable(`mainNuances.${locale}`) ? '#29B8FF' : null}
            />
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

MainNuancesEditor.defaultProps = {
  locales: LOCALES,
};

MainNuancesEditor.propTypes = {
  resource: PropTypes.object.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func.isRequired,
};

export default MainNuancesEditor;
