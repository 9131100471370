import { Cluster } from 'components/Layout';
import './V4Tag.scss';

const V4Tag = () => {
  return (
    <Cluster space="var(--s-4)" className="v4-tag" align="center">
      <span>V4</span>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 4.875L4.07692 8L9 3" />
      </svg>
    </Cluster>
  );
};

export default V4Tag;
