import graphiesFactory from './graphiesFactory';
import GraphiesDataService from 'services/GraphiesDataService';

const graphiesDataService = new GraphiesDataService('nameId');

const extraReducers = {
  'individualNames/delete/fulfilled': (state, action) => {
    const graphyIdsToRemove = state.byNameId[action.payload.nameId] || [];
    graphyIdsToRemove.forEach((id) => delete state.byId[id]);
    delete state.byNameId[action.payload.nameId];
  },
};

const { reducer, actions, selectors } = graphiesFactory(
  'individualNameGraphies',
  'name',
  extraReducers,
  graphiesDataService
);

const {
  unpublishEntryGraphies: unpublishIndividualNameGraphies,
  publishEntryGraphies: publishIndividualNameGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfEntry: resetGraphiesOfIndividualName,
  resetGraphy,
} = actions;

export {
  reducer,
  unpublishIndividualNameGraphies,
  publishIndividualNameGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfIndividualName,
  resetGraphy,
  selectors,
};
