import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { constant, groupBy } from 'lodash';
import { get } from 'lodash/fp';
import DictionariesDataService from 'services/DictionariesDataService';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const getVegaDictionaries = (state) => state.dictionaries.vega;

const getCapeaDictionaries = (state) => state.dictionaries.capea;

// ------------------------------------
// Async actions
// ------------------------------------

const loadAllDictionaries = createAsyncThunk('dictionaries/loadAll', async () => {
  const res = await DictionariesDataService.getAllDictionaries();
  return res.data;
});

const initialState = {
  vega: {},
  capea: {},
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAllDictionaries.fulfilled, (state, action) => {
        state.vega = groupBy(
          action.payload.filter((d) => d.isVega),
          get('groupKey')
        );
        state.capea = groupBy(
          action.payload.filter((d) => d.isCapea),
          get('groupKey')
        );
      })
      .addCase(logout.fulfilled, constant(initialState));
  },
});

const { reducer } = dictionariesSlice;

export { reducer, loadAllDictionaries, getVegaDictionaries, getCapeaDictionaries };
