import axios from 'axios';

class NuancesDataService {
  createOrUpdate(nuance) {
    return axios.post('/nuances', nuance);
  }

  delete(nuanceId) {
    return axios.delete(`/nuances/${nuanceId}`);
  }

  unpublish(nuance) {
    return axios.post('/nuances/unpublish', nuance);
  }

  publish(nuance) {
    return axios.post('/nuances/publish', nuance);
  }
}

export default new NuancesDataService();
