import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { selectors as titleCommentsSelectors, createOrUpdateComment } from 'slices/titleComments';
import usePublishable from 'hooks/usePublishable';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import CommentsEditor from 'components/CommentsEditor';

const TitleComments = ({ titleId, isEditVersion, publishableProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comment = useSelector((state) => titleCommentsSelectors.getByTitleId(state, titleId));
  const [isPublishable] = usePublishable(comment);

  const handleCommentUpdate = useCallback(
    (newCommentText) => {
      return dispatch(
        createOrUpdateComment({ ...comment, titleId, edited: { text: newCommentText } })
      );
    },
    [dispatch, comment, titleId]
  );

  return (
    <EntryTabContentLayout
      title={t('comments.title')}
      isEditVersion={isEditVersion}
      isPublishable={isEditVersion && isPublishable('text')}
    >
      {(comment || isEditVersion) && (
        <CommentsEditor
          value={get(comment, `${publishableProp}.text`, null)}
          isEditVersion={isEditVersion}
          titleLinkEnable
          entryLinkEnable
          individualLinkEnable
          onUpdate={handleCommentUpdate}
        />
      )}
    </EntryTabContentLayout>
  );
};

TitleComments.defaultProps = {
  publishableProp: 'edited',
};

TitleComments.propTypes = {
  titleId: PropTypes.number.isRequired,
  isEditVersion: PropTypes.bool.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']),
};

export default TitleComments;
