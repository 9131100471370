import React, { useCallback } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import './Checkbox.scss';

const Checkbox = React.forwardRef(
  ({ label, name, size, labelPlacement, onChange, onSave, ...props }, ref) => {
    const handleChange = useCallback(
      (evt) => {
        onChange(evt);
        onSave();
      },
      [onChange, onSave]
    );

    return (
      <div className="checkbox-container" data-size={size} data-label-placement={labelPlacement}>
        {label && <label htmlFor={name}>{label}</label>}
        <input type="checkbox" ref={ref} name={name} onChange={handleChange} {...props} />
      </div>
    );
  }
);

Checkbox.defaultProps = {
  label: null,
  size: 'regular',
  labelPlacement: 'left',
  onSave: noop,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'regular']),
  labelPlacement: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default Checkbox;
