import React from 'react';
import PropTypes from 'prop-types';
import { get, identity } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Cluster } from 'components/Layout';
import { getPeriods } from 'slices/words';
import { selectors as wordGraphiesSelectors } from 'slices/wordGraphies';
import { hasNuancesByEntryId } from 'slices/nuances';
import { selectors as wordCommentsSelectors } from 'slices/wordComments';
import { selectors as wordDictionaryReferencesSelectors } from 'slices/wordDictionaryReferences';
import { isLoading } from 'slices/ui/entryLoading';
import { ReactComponent as NuancesIcon } from 'images/icon-tab-nuances.svg';
import { ReactComponent as GraphiesIcon } from 'images/icon-tab-graphies.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import { ReactComponent as DictionariesIcon } from 'images/icon-tab-dictionaries.svg';
import Transliteration from 'components/Transliteration';
import Nuances from 'components/Nuances';
import Graphies from 'components/WordGraphies/Graphies';
import Graphy from 'components/Graphy';
import Tag from 'components/Tag';
import WordDictionaryReferences from 'components/WordDictionaryReferences';
import { PeriodsOverview } from 'components/Periods';
import EntryTabs from 'components/Entry/EntryTabs';
import EntryNuance from 'components/Entry/EntryNuance';
import WordComments from 'components/WordComments';

const WordReadContent = ({ entry, publishableProp }) => {
  const { t, i18n } = useTranslation();
  const graphies = useSelector((state) => wordGraphiesSelectors.getIdsByEntryId(state, entry.id));
  const main1Graphy = useSelector((state) =>
    wordGraphiesSelectors.getMain1GraphyByEntryId(state, entry.id)
  );
  const main2Graphy = useSelector((state) =>
    wordGraphiesSelectors.getMain2GraphyByEntryId(state, entry.id, publishableProp)
  );
  const showNuancesTab = useSelector((state) =>
    hasNuancesByEntryId(state, entry.id, publishableProp)
  );
  const hasComment = useSelector((state) =>
    wordCommentsSelectors.doesEntryHaveOne(state, entry.id, publishableProp)
  );
  const hasDictionaryRefs = useSelector((state) =>
    wordDictionaryReferencesSelectors.doesEntryHaveOne(state, entry.id, publishableProp)
  );
  const entryPeriods = useSelector((state) => getPeriods(state, entry.id, publishableProp));
  const isLoadingEntry = useSelector((state) => isLoading(state, entry.id));

  const selectCommentTab =
    (entry.publicStatus === 'problematic' ||
      entry.publicStatus === 'wrong' ||
      entry.publicStatus === 'wrongGraphy') &&
    hasComment;
  const commentTabIndex = [true, hasDictionaryRefs, showNuancesTab].filter(identity).length;

  return (
    <>
      {entry[publishableProp].nature && (
        <div className="entry-natures">
          <Cluster space="var(--s-3)">
            {entry[publishableProp].nature.map((nature) => (
              <Tag key={nature}>{t(`entry.natures.types.${nature}`)}</Tag>
            ))}
          </Cluster>
        </div>
      )}
      <h2 className="entry-transliteration">
        <Transliteration>{entry[publishableProp].transliteration}</Transliteration>
      </h2>
      <div className="entry-main-graphies" data-testid="entry-main-graphies">
        {main1Graphy && main1Graphy[publishableProp] && (
          <Graphy
            url={main1Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main1Graphy.publicStatus}
          />
        )}
        {main2Graphy && main2Graphy[publishableProp] && (
          <Graphy
            url={main2Graphy[publishableProp].url}
            dir={i18n.dir()}
            publicStatus={main2Graphy.publicStatus}
          />
        )}
      </div>
      <EntryNuance nuances={entry[publishableProp].mainNuances} />
      <div className="entry-periods">
        <PeriodsOverview
          selectedPeriods={
            entryPeriods.length > 0
              ? entryPeriods
              : get(entry, `${publishableProp}.attestationPeriods`, [])
          }
        />
      </div>
      {!isLoadingEntry && (
        <EntryTabs
          defaultIndex={selectCommentTab ? commentTabIndex : 0}
          selectors={[
            showNuancesTab ? <NuancesIcon id="nuances" /> : null,
            <GraphiesIcon id="graphies" disabled={graphies.length === 0} />,
            hasDictionaryRefs ? <DictionariesIcon id="dictionaries" /> : null,
            hasComment ? <CommentsIcon id="comments" /> : null,
          ]}
        >
          {showNuancesTab ? (
            <div id="nuances">
              <Nuances entryId={entry.id} publishableProp={publishableProp} />
            </div>
          ) : null}
          <div id="graphies">
            {graphies.length > 0 ? (
              <Graphies entryId={entry.id} publishableProp={publishableProp} />
            ) : null}
          </div>
          {hasDictionaryRefs ? (
            <div id="dictionaries">
              <WordDictionaryReferences
                entryId={entry.id}
                isEditVersion={false}
                publishableProp={publishableProp}
              />
            </div>
          ) : null}
          {hasComment ? (
            <div id="comments">
              <WordComments
                entryId={entry.id}
                isEditVersion={false}
                publishableProp={publishableProp}
              />
            </div>
          ) : null}
        </EntryTabs>
      )}
      {entry[publishableProp].authors && (
        <div className="entry-authors">
          <div className="input-container input-container--borderless" data-layout="horizontal">
            <label>{t('entry.authorsLabel')}</label>
            <span className="entry-authors-value">{entry[publishableProp].authors}</span>
          </div>
        </div>
      )}
    </>
  );
};

WordReadContent.propTypes = {
  entry: PropTypes.object.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
};

export default WordReadContent;
