import PropTypes from 'prop-types';
import './SourceInfo.scss';

const SourceInfo = ({ label, children, layout }) => {
  if (!children) {
    return null;
  }
  return (
    <div className="source-info" data-layout={layout}>
      {label && <div className="source-info-label">{label}</div>}
      <div className="source-info-content" dir="auto">
        {children}
      </div>
    </div>
  );
};

SourceInfo.defaultProps = {
  label: null,
  layout: 'above',
  children: null,
};

SourceInfo.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  layout: PropTypes.oneOf(['above', 'horizontal', 'below']),
};

export default SourceInfo;
