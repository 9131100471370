import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSearchPosition } from 'slices/search';
import { MenuItem, MENU_THEMES } from 'components/Menu';
import DropdownMenu from 'components/DropdownMenu';
import { ReactComponent as ArrowDownIcon } from 'images/icon-arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'images/icon-arrow-up.svg';
import './SearchPopupFilterPosition.scss';

const POSITIONS = ['start', 'middle', 'end'];

const SearchPopupFilterPosition = ({ onUpdate }) => {
  const { t, i18n } = useTranslation();
  const searchPosition = useSelector(getSearchPosition);

  const handleChangePosition = (e, position) => {
    e.preventDefault();
    onUpdate(position);
  };

  return (
    <div
      className="search-filter-position-menu"
      data-testid="search-filter-position-menu"
      dir={i18n.dir()}
    >
      <DropdownMenu
        theme={MENU_THEMES.dark}
        itemsCount={POSITIONS.length}
        renderButton={(buttonProps) => (
          <button
            className="search-filter-position-button"
            data-testid="search-filter-position-button"
            {...buttonProps}
          >
            {t(`searchBar.options.position.${searchPosition}`)}
            {buttonProps['aria-expanded'] && <ArrowUpIcon className="arrow" />}
            {!buttonProps['aria-expanded'] && <ArrowDownIcon className="arrow" />}
          </button>
        )}
      >
        {POSITIONS.map((position) => (
          <MenuItem
            key={position}
            isSelectable
            isSelected={searchPosition === position}
            onClick={(e) => handleChangePosition(e, position)}
            data-testid={`search-filter-position-item-${position}`}
          >
            {t(`searchBar.options.position.${position}`)}
          </MenuItem>
        ))}
      </DropdownMenu>
    </div>
  );
};

SearchPopupFilterPosition.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default SearchPopupFilterPosition;
