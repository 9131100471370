import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  publishAllTitleData,
  closeTitle,
  stopTitleEditionAndReload,
  unpublishAllTitleData,
  deleteTitle,
  isTitleEdited,
  isTitleHighlighted,
  startTitleEdition,
} from 'slices/titles';
import TitleEdition from './TitleEdition';
import TitleRead from './TitleRead';
import TitlePreview from './TitlePreview';

const Title = ({ id }) => {
  const dispatch = useDispatch();
  const [isPreviewed, setIsPreviewed] = useState(false);
  const isEdited = useSelector((state) => isTitleEdited(state, id));
  const isHighlighted = useSelector((state) => isTitleHighlighted(state, id));

  const handleStartTitleEdition = (evt) => {
    evt.preventDefault();
    dispatch(startTitleEdition(id));
  };

  const handleStopTitleEdition = (evt) => {
    evt.preventDefault();
    setIsPreviewed(false);
    dispatch(stopTitleEditionAndReload(id));
  };

  const handlePublishTitle = (evt) => {
    evt.preventDefault();
    dispatch(publishAllTitleData(id));
  };

  const handleUnpublish = (e) => {
    e.preventDefault();
    dispatch(unpublishAllTitleData(id));
  };

  const togglePreview = () => {
    setIsPreviewed(!isPreviewed);
  };

  const handleCloseTitle = () => {
    dispatch(closeTitle(id));
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteTitle(id));
  };

  return (
    <>
      {!isEdited && (
        <TitleRead
          id={id}
          isHighlighted={isHighlighted}
          onStartEdition={handleStartTitleEdition}
          onClose={handleCloseTitle}
        />
      )}
      {isEdited && (
        <TitleEdition
          id={id}
          isHighlighted={isHighlighted}
          isPreviewed={isPreviewed}
          onStopEdition={handleStopTitleEdition}
          onPublish={handlePublishTitle}
          onUnpublish={handleUnpublish}
          onPreview={togglePreview}
          onClose={handleCloseTitle}
          onDelete={handleDelete}
        />
      )}
      {isPreviewed && <TitlePreview id={id} onClose={togglePreview} />}
    </>
  );
};

Title.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Title;
