export const getTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  spacing: {
    ...theme.spacing,
    controlHeight: 34,
    menuGutter: -6,
  },
  colors: {
    primary: 'var(--blue-8)',
    primary75: 'var(--blue-6)',
    primary50: 'var(--blue-4)',
    primary25: 'var(--blue-2)',
    dangerLight: 'var(--red-1)',
    danger: 'var(--red-2)',
    neutral0: 'var(--white)',
    neutral5: 'var(--grey-1)',
    neutral10: 'var(--grey-2)',
    neutral20: 'var(--grey-3)',
    neutral30: 'var(--grey-4)',
    neutral40: 'var(--grey-5)',
    neutral50: 'var(--grey-6)',
    neutral60: 'var(--grey-7)',
    neutral70: 'var(--grey-8)',
    neutral80: '#1e1e1d',
    neutral90: 'var(--black)',
  },
});

export const components = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontFamily: 'var(--ff-serif)',
    border: 0,
    backgroundColor: 'var(--color-bg-depth-1)',
    borderBottom: '1px solid var(--input-color)',
    boxShadow: 'none',
    opacity: state.isDisabled ? 0.25 : 1,
    '&:hover': {
      borderColor: 'var(--input-color-hover)',
    },
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: 'var(--color-text-inactive)',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '0',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    span: {
      backgroundColor: 'var(--color-bg-depth-2)',
      borderRadius: 3,
      padding: '0 var(--s-1)',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    padding: 'var(--s-2) var(--s-4)',
    borderRadius: 6,
    width: 'auto',
    zIndex: 1000,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: '5px 10px',
    fontFamily: 'var(--ff-serif)',
    borderRadius: 3,
    color: 'var(--color-text)',
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
    fontSize: 15,
    opacity: state.isDisabled ? 0.2 : 1,
    '&:hover': {
      backgroundColor: state.isDisabled ? 'transparent' : 'rgba(0, 0, 0, 0.05)',
    },
  }),
};
