import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RelativesDataService from 'services/RelativesDataService';
import { isPublishable } from 'utils/util';
import { deleteIndividual } from './individuals';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const emptyArray = [];

const getIndividualRelativeIdsByIndividualId = (state, individualId) =>
  state.individualRelatives.byIndividualId[individualId] || emptyArray;

const getIndividualRelativeById = (state, relativeId) => state.individualRelatives.byId[relativeId];

const doesIndividualHaveRelatives = (state, individualId) => {
  return getIndividualRelativeIdsByIndividualId(state, individualId).length > 0;
};

const isIndividualRelativesPublishable = (state, individualId) => {
  const relativeIds = getIndividualRelativeIdsByIndividualId(state, individualId);
  return relativeIds.some((relativeId) => isIndividualRelativePublishable(state, relativeId));
};

const isIndividualRelativePublishable = (state, relativeId) => {
  const relative = getIndividualRelativeById(state, relativeId);
  const hasUnpublishedType = isPublishable(relative, 'type');
  return hasUnpublishedType;
};

// ------------------------------------
// Async actions
// ------------------------------------

const loadIndividualRelatives = createAsyncThunk(
  'individualRelatives/load',
  async ({ individualId, isEditVersion }) => {
    const res = await RelativesDataService.getAllOfIndividual(individualId, isEditVersion);
    return { individualId, data: res.data };
  }
);

const unpublishIndividualRelative = createAsyncThunk(
  'individualRelatives/unpublish',
  async (relative) => {
    const res = await RelativesDataService.unpublish(relative);
    return res.data;
  }
);

const publishIndividualRelative = createAsyncThunk(
  'individualRelatives/publish',
  async (relative) => {
    const res = await RelativesDataService.publish(relative);
    return res.data;
  }
);

const createIndividualRelative = createAsyncThunk(
  'individualRelatives/create',
  async ({ individualId, relatedId }) => {
    const res = await RelativesDataService.createOrUpdate({ individualId, relatedId, edited: {} });
    return res.data;
  }
);

const updateIndividualRelative = createAsyncThunk(
  'individualRelatives/update',
  async ({ id, data }, { getState }) => {
    const oldRelative = getIndividualRelativeById(getState(), id);
    const updatedRelative = {
      ...oldRelative,
      ...data,
      edited: {
        ...oldRelative.edited,
        ...(data.edited || {}),
      },
    };
    const res = await RelativesDataService.createOrUpdate(updatedRelative);
    return res.data;
  }
);

const deleteIndividualRelative = createAsyncThunk(
  'individualRelatives/delete',
  async ({ id, individualId }) => {
    await RelativesDataService.delete(id);
    return { individualId, id };
  }
);

const publishRelativesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const relativeIds = getIndividualRelativeIdsByIndividualId(getState(), individualId);
    relativeIds.forEach((relativeId) => {
      dispatch(publishIndividualRelative(getIndividualRelativeById(getState(), relativeId)));
    });
  };
};

const unpublishRelativesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const relativeIds = getIndividualRelativeIdsByIndividualId(getState(), individualId);
    relativeIds.forEach((relativeId) => {
      dispatch(unpublishIndividualRelative(getIndividualRelativeById(getState(), relativeId)));
    });
  };
};

const resetRelativesOfIndividual = (individualId) => {
  return (dispatch, getState) => {
    const relativeIds = getIndividualRelativeIdsByIndividualId(getState(), individualId);
    relativeIds.forEach((relativeId) => {
      dispatch(resetIndividualRelative({ individualId, relativeId }));
    });
  };
};

const initialState = {
  byIndividualId: {},
  byId: {},
};

const individualRelativesSlice = createSlice({
  name: 'individualRelatives',
  initialState,
  reducers: {
    resetIndividualRelative: (state, { payload }) => {
      state.byIndividualId[payload.individualId] = state.byIndividualId[
        payload.individualId
      ].filter((id) => id !== payload.relativeId);
      if (state.byIndividualId[payload.individualId].length === 0) {
        delete state.byIndividualId[payload.individualId];
      }
      delete state.byId[payload.relativeId];
    },
  },
  extraReducers: (builder) => {
    const replaceIndividualRelative = (state, action) => {
      state.byId[action.payload.id] = action.payload;
    };

    builder
      .addCase(loadIndividualRelatives.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = action.payload.data.map(
          (relative) => relative.id
        );
        action.payload.data.forEach((relative) => {
          state.byId[relative.id] = relative;
        });
      })
      .addCase(createIndividualRelative.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ]
          ? [...state.byIndividualId[action.payload.individualId], action.payload.id]
          : [action.payload.id];
        state.byId[action.payload.id] = action.payload;
      })
      .addCase(updateIndividualRelative.fulfilled, replaceIndividualRelative)
      .addCase(unpublishIndividualRelative.fulfilled, replaceIndividualRelative)
      .addCase(publishIndividualRelative.fulfilled, replaceIndividualRelative)
      .addCase(deleteIndividualRelative.fulfilled, (state, action) => {
        state.byIndividualId[action.payload.individualId] = state.byIndividualId[
          action.payload.individualId
        ].filter((relativeId) => action.payload.id !== relativeId);
        delete state.byId[action.payload.id];
      })
      .addCase(deleteIndividual.fulfilled, (state, action) => {
        const relativeIdsToRemove = state.byIndividualId[action.payload.individualId] || [];
        relativeIdsToRemove.forEach((id) => delete state.byId[id]);
        delete state.byIndividualId[action.payload.individualId];
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

const { resetIndividualRelative } = individualRelativesSlice.actions;

const { reducer } = individualRelativesSlice;

export {
  reducer,
  loadIndividualRelatives,
  createIndividualRelative,
  publishRelativesOfIndividual,
  unpublishRelativesOfIndividual,
  updateIndividualRelative,
  deleteIndividualRelative,
  resetRelativesOfIndividual,
  getIndividualRelativeIdsByIndividualId,
  getIndividualRelativeById,
  doesIndividualHaveRelatives,
  isIndividualRelativesPublishable,
  isIndividualRelativePublishable,
};
