import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import Icon from 'components/Icon';
import { ReactComponent as ValidatedIcon } from 'images/icon-public-status-validated.svg';
import { ReactComponent as WrongIcon } from 'images/icon-public-status-wrong.svg';
import { ReactComponent as WrongGraphyIcon } from 'images/icon-public-status-wrong-graphy.svg';
import { GRAPHY_PUBLIC_STATUS } from 'utils/constants';
import './PublicStatus.scss';

const statusIcons = {
  validated: <ValidatedIcon />,
  problematic: <WrongIcon />,
  wrong: <WrongGraphyIcon />,
};

function GraphyPublicStatus({ status, hasTooltip }) {
  const { i18n, t } = useTranslation();

  if (!status) {
    return null;
  }

  if (!hasTooltip) {
    return (
      <div className="public-status" data-status={status} data-testid="public-status">
        <Icon>{statusIcons[status]}</Icon>
      </div>
    );
  }

  return (
    <TooltipManager>
      <TooltipTrigger>
        <div className="public-status" data-status={status} data-testid="public-status">
          <Icon isInteractive>{statusIcons[status]}</Icon>
        </div>
      </TooltipTrigger>
      <TooltipContent dir={i18n.dir()}>{t(`graphy.publicStatus.tooltip.${status}`)}</TooltipContent>
    </TooltipManager>
  );
}

GraphyPublicStatus.defaultProps = {
  status: GRAPHY_PUBLIC_STATUS.validated,
  hasTooltip: false,
};

GraphyPublicStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(GRAPHY_PUBLIC_STATUS)),
  hasTooltip: PropTypes.bool,
};

export default GraphyPublicStatus;
