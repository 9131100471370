import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossLogo } from 'images/icon-cross.svg';
import { ReactComponent as EyeOpenedLogo } from 'images/icon-eye-opened.svg';
import { ReactComponent as SandwichIcon } from 'images/icon-sandwich.svg';
import Section from 'components/Section';
import DropdownMenu from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Spinner from 'components/Spinner';
import ToolbarButton from './ToolbarButton';
import EntryButton from './EntryButton';
import './EntryEditionToolbar.scss';

const EntryEditionToolBar = ({
  isPreviewed,
  isPublished,
  isLoading,
  showPublishButton,
  showUnpublishButton,
  onStopEdition,
  onPublish,
  onUnpublish,
  onDelete,
  onClose,
  onPreview,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Section space="var(--s-2)">
      <div className="entry-header entry-edition-header">
        <div className="entry-header-left">
          <div className="entry-edition-menu" data-testid="entry-edition-menu" dir={i18n.dir()}>
            <DropdownMenu
              itemsCount={showUnpublishButton ? 2 : 1}
              renderButton={(buttonProps) => (
                <EntryButton
                  isSquare
                  isActive={buttonProps['aria-expanded']}
                  data-testid="entry-edition-sandwich-button"
                  {...buttonProps}
                >
                  <SandwichIcon />
                </EntryButton>
              )}
            >
              {showUnpublishButton && (
                <MenuItem onClick={onUnpublish}>{t('actions.unpublish')}</MenuItem>
              )}
              <MenuItem onClick={onDelete}>{t('actions.remove')}</MenuItem>
            </DropdownMenu>
          </div>
        </div>
        <div className="entry-header-right">
          {showPublishButton && (
            <div style={{ position: 'relative' }}>
              <EntryButton
                data-testid="entry-publish-button"
                onClick={onPublish}
                disabled={isLoading}
              >
                {t('actions.publish')}
              </EntryButton>
              {isLoading && (
                <div className="entry-publish-button-spinner">
                  <Spinner size={16} thickness={5} />
                </div>
              )}
            </div>
          )}
          {isPublished && (
            <EntryButton data-testid="entry-consult-button" onClick={onStopEdition}>
              {t('actions.end')}
            </EntryButton>
          )}
          <ToolbarButton
            isActive={isPreviewed}
            onClick={onPreview}
            data-testid="entry-preview-button"
          >
            <EyeOpenedLogo />
          </ToolbarButton>
          <ToolbarButton onClick={onClose} data-testid="entry-close-button">
            <CrossLogo />
          </ToolbarButton>
        </div>
      </div>
    </Section>
  );
};

EntryEditionToolBar.propTypes = {
  isPreviewed: false,
  isPublished: false,
  isLoading: false,
  showPublishButton: true,
  showUnpublishButton: false,
};

EntryEditionToolBar.propTypes = {
  isPreviewed: PropTypes.bool,
  isPublished: PropTypes.bool,
  isLoading: PropTypes.bool,
  showPublishButton: PropTypes.bool,
  showUnpublishButton: PropTypes.bool,
  onStopEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EntryEditionToolBar;
