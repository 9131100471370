import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as FrenchFlag } from 'images/icon-flag-fr.svg';
import { ReactComponent as EnglishFlag } from 'images/icon-flag-en.svg';
import { ReactComponent as GermanFlag } from 'images/icon-flag-de.svg';
import { ReactComponent as EgyptianFlag } from 'images/icon-flag-ar.svg';
import './FlagIcon.scss';

const Icon = {
  fr: FrenchFlag,
  en: EnglishFlag,
  de: GermanFlag,
  ar: EgyptianFlag,
};

const FlagIcon = ({ iso, size, tint }) => {
  const IconComponent = Icon[iso];
  return (
    <span
      className={classNames('flag-icon', {
        [`flag-icon--${size}`]: !!size,
        [`flag-icon--${iso}`]: !!iso,
      })}
      data-testid={`flag-icon-${iso}`}
    >
      <IconComponent />
      {!!tint && <span className="flag-icon-tint-overlay" style={{ backgroundColor: tint }} />}
    </span>
  );
};

FlagIcon.defaultProps = {
  size: 'small',
  tint: null,
};

FlagIcon.propTypes = {
  iso: PropTypes.oneOf(['fr', 'en', 'de', 'ar']).isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  tint: PropTypes.string,
};

export default FlagIcon;
