import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotEmptyHtml } from 'utils/util';

const useDefaultNuanceLang = (nuances) => {
  const { i18n } = useTranslation();
  const [nuanceLang, setNuanceLang] = useState(() => {
    const resolvedLanguage = i18n.resolvedLanguage;
    if (!nuances || isNotEmptyHtml(nuances[resolvedLanguage])) {
      return resolvedLanguage;
    } else {
      return 'fr';
    }
  });

  useEffect(() => {
    i18n.on('languageChanged', () => {
      const resolvedLanguage = i18n.resolvedLanguage;
      if (!nuances || isNotEmptyHtml(nuances[resolvedLanguage])) {
        setNuanceLang(resolvedLanguage);
      } else {
        setNuanceLang('fr');
      }
    });
  }, [i18n, nuances]);

  return [nuanceLang, setNuanceLang];
};

export default useDefaultNuanceLang;
