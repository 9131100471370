import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Cluster, Stack } from 'components/Layout';
import { getWordById } from 'slices/words';
import { selectors as wordGraphiesSelectors } from 'slices/wordGraphies';
import { hasNuancesByEntryId } from 'slices/nuances';
import { selectors as wordCommentsSelectors } from 'slices/wordComments';
import { selectors as wordDictionaryReferencesSelectors } from 'slices/wordDictionaryReferences';
import Tag from 'components/Tag';
import Transliteration from 'components/Transliteration';
import Graphy from 'components/Graphy';
import WordDictionaryReferences from 'components/WordDictionaryReferences';
import Graphies from 'components/WordGraphies/Graphies';
import WordComments from 'components/WordComments';
import Nuances from 'components/Nuances';
import './WordPrint.scss';

const WordPrint = ({ id }) => {
  const { t, i18n } = useTranslation();
  const entry = useSelector((state) => getWordById(state, id));
  const graphies = useSelector((state) => wordGraphiesSelectors.getIdsByEntryId(state, id));
  const main1Graphy = useSelector((state) =>
    wordGraphiesSelectors.getMain1GraphyByEntryId(state, entry.id)
  );
  const main2Graphy = useSelector((state) =>
    wordGraphiesSelectors.getMain2GraphyByEntryId(state, entry.id, 'published')
  );
  const showNuancesTab = useSelector((state) => hasNuancesByEntryId(state, id, 'published'));
  const hasComment = useSelector((state) =>
    wordCommentsSelectors.doesEntryHaveOne(state, id, 'published')
  );
  const hasDictionaryRefs = useSelector((state) =>
    wordDictionaryReferencesSelectors.doesEntryHaveOne(state, id, 'published')
  );

  return (
    <div className="entry-print">
      <div className="entry-content">
        {entry && entry.published && (
          <div className="entry-data">
            <div className="entry-id">
              {t('pageTitle')} | ID {id}
            </div>

            <Stack space="var(--s2)">
              <Stack space="var(--s-1)">
                {entry.published.nature && (
                  <div className="entry-natures">
                    <Cluster space="var(--s-3)">
                      {entry.published.nature.map((nature) => (
                        <Tag key={nature}>{t(`entry.natures.types.${nature}`)}</Tag>
                      ))}
                    </Cluster>
                  </div>
                )}
                <h2 className="entry-transliteration">
                  <Transliteration>{entry.published.transliteration}</Transliteration>
                </h2>
                <div className="entry-main-graphies" data-testid="entry-main-graphies">
                  {main1Graphy && main1Graphy.published && (
                    <Graphy
                      url={main1Graphy.published.url}
                      dir={i18n.dir()}
                      publicStatus={main1Graphy.publicStatus}
                    />
                  )}
                  {main2Graphy && main2Graphy.published && (
                    <Graphy
                      url={main2Graphy.published.url}
                      dir={i18n.dir()}
                      publicStatus={main2Graphy.publicStatus}
                    />
                  )}
                </div>
              </Stack>

              <Stack space="var(--s0)">
                {Object.entries(entry.published.mainNuances).map(([lang, nuance]) => (
                  <Stack space="var(--s-3)" key={lang}>
                    <div>{lang.toUpperCase()}</div>
                    <div className="entry-nuance" dangerouslySetInnerHTML={{ __html: nuance }} />
                  </Stack>
                ))}
              </Stack>

              {showNuancesTab ? (
                <div id="nuances">
                  <Nuances entryId={id} publishableProp="published" open />
                </div>
              ) : null}
              <div id="graphies">
                {graphies.length > 0 ? (
                  <Graphies entryId={id} publishableProp="published" open />
                ) : null}
              </div>
              {hasDictionaryRefs ? (
                <div id="dictionaries">
                  <WordDictionaryReferences
                    entryId={id}
                    isEditVersion={false}
                    publishableProp="published"
                  />
                </div>
              ) : null}
              {hasComment ? (
                <div id="comments">
                  <WordComments entryId={id} isEditVersion={false} publishableProp="published" />
                </div>
              ) : null}
              {entry.published.authors && (
                <div className="entry-authors">
                  <div
                    className="input-container input-container--borderless"
                    data-layout="horizontal"
                  >
                    <label>{t('entry.authorsLabel')}</label>
                    <span className="entry-authors-value">{entry.published.authors}</span>
                  </div>
                </div>
              )}
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

WordPrint.propTypes = {
  id: PropTypes.number.isRequired,
};

export default WordPrint;
