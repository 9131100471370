import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  publishAllWordData,
  isWordEdited,
  closeWord,
  startWordEdition,
  stopWordEditionAndReload,
  unpublishAllWordData,
  deleteWord,
  isWordHighlighted,
} from 'slices/words';
import WordEdition from './WordEdition';
import WordRead from './WordRead';
import WordPreview from './WordPreview';

const Word = ({ id }) => {
  const dispatch = useDispatch();
  const [isPreviewed, setIsPreviewed] = useState(false);
  const isEdited = useSelector((state) => isWordEdited(state, id));
  const isHighlighted = useSelector((state) => isWordHighlighted(state, id));

  const handleStartEntryEdition = (evt) => {
    evt.preventDefault();
    dispatch(startWordEdition(id));
  };

  const handleStopEntryEdition = (evt) => {
    evt.preventDefault();
    setIsPreviewed(false);
    dispatch(stopWordEditionAndReload(id));
  };

  const handlePublishEntry = (evt) => {
    evt.preventDefault();
    dispatch(publishAllWordData(id));
  };

  const handleUnpublish = (e) => {
    e.preventDefault();
    dispatch(unpublishAllWordData(id));
  };

  const togglePreview = () => {
    setIsPreviewed(!isPreviewed);
  };

  const handleCloseWord = () => {
    dispatch(closeWord(id));
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteWord(id));
  };

  return (
    <>
      {!isEdited && (
        <WordRead
          id={id}
          isHighlighted={isHighlighted}
          onStartEdition={handleStartEntryEdition}
          onClose={handleCloseWord}
        />
      )}
      {isEdited && (
        <WordEdition
          id={id}
          isHighlighted={isHighlighted}
          isPreviewed={isPreviewed}
          onStopEdition={handleStopEntryEdition}
          onPublish={handlePublishEntry}
          onUnpublish={handleUnpublish}
          onPreview={togglePreview}
          onClose={handleCloseWord}
          onDelete={handleDelete}
        />
      )}
      {isPreviewed && <WordPreview id={id} onClose={togglePreview} />}
    </>
  );
};

Word.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Word;
