import PropTypes from 'prop-types';
import './ThesaurusModal.scss';

const ThesaurusModal = ({ children }) => {
  return (
    <div className="thesaurus-overlay">
      <div className="thesaurus-modal">{children}</div>
    </div>
  );
};

ThesaurusModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThesaurusModal;
