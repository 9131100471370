import axios from 'axios';

class AttestationsDataService {
  get(sourceId) {
    return axios.get(`/attestations?sourceId=${sourceId}`);
  }

  createOrUpdate({ nuanceId, ...attestation }) {
    return axios.post('/attestations', attestation);
  }

  delete(attestationId) {
    return axios.delete(`/attestations/${attestationId}`);
  }
}

export default new AttestationsDataService();
