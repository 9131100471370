import axios from 'axios';

class TitlesDataService {
  getAllOfIndividual(individualId) {
    return axios.get(`/titles?individualId=${individualId}`);
  }

  get(id, isEditVersion) {
    return axios.get(`/titles/${id}?edited=${isEditVersion}`);
  }

  createOrUpdate(title) {
    return axios.post('/titles', title);
  }

  lock(titleId) {
    return axios.put(`/titles/${titleId}/lock`);
  }

  unlock(titleId) {
    return axios.put(`/titles/${titleId}/unlock`);
  }

  publish(title) {
    return axios.post('/titles/publish', title);
  }

  unpublish(title) {
    return axios.post('/titles/unpublish', title);
  }

  delete(titleId) {
    return axios.delete(`/titles/${titleId}`);
  }
}

export default new TitlesDataService();
