import { createSlice } from '@reduxjs/toolkit';
import { isNotNil } from 'utils/util';

// ------------------------------------
// Generic selectors
// ------------------------------------

const isCreating = (state) => isNotNil(state.entryCreation);

// ------------------------------------
// Register
// ------------------------------------

function registerCreation(prefix) {
  return {
    actions: {
      startCreation: () => startCreation(prefix),
      stopCreation,
    },
    selectors: {
      isCreating: (state) => state.entryCreation === prefix,
    },
  };
}

// ------------------------------------
// Slice
// ------------------------------------

const initialState = null;

const entryCreationSlice = createSlice({
  name: 'entryCreation',
  initialState,
  reducers: {
    startCreation: (state, action) => {
      return action.payload;
    },
    stopCreation: () => {
      return initialState;
    },
  },
});

const { startCreation, stopCreation } = entryCreationSlice.actions;

const { reducer } = entryCreationSlice;

export { reducer, registerCreation, isCreating };
