import React from 'react';
import PropTypes from 'prop-types';
import { GRAPHY_PUBLIC_STATUS } from 'utils/constants';
import { GraphyPublicStatus } from 'components/PublicStatus';
import CounterFlag from 'components/CounterFlag';
import './Graphy.scss';

const Graphy = React.forwardRef(
  ({ url, publicStatus, height, dir, attestationsCount, isPublishable }, ref) => {
    return (
      <div className="graphy">
        <div className="graphy-image" ref={ref}>
          <div
            className="graphy-image-bg"
            style={{ backgroundImage: `url(${url})`, height }}
            dir={dir}
            data-testid="graphy"
            data-publishable={isPublishable}
          />
        </div>
        <div className="graphy-flags">
          {attestationsCount > 0 && <CounterFlag>{attestationsCount}</CounterFlag>}
          {(publicStatus === GRAPHY_PUBLIC_STATUS.problematic ||
            publicStatus === GRAPHY_PUBLIC_STATUS.wrong) && (
            <GraphyPublicStatus status={publicStatus} hasTooltip />
          )}
        </div>
      </div>
    );
  }
);

Graphy.defaultProps = {
  publicStatus: null,
  dir: 'ltr',
  height: 50,
  attestationsCount: 0,
  isPublishable: false,
};

Graphy.propTypes = {
  url: PropTypes.string.isRequired,
  publicStatus: PropTypes.oneOf(Object.values(GRAPHY_PUBLIC_STATUS)),
  dir: PropTypes.string,
  height: PropTypes.number,
  attestationsCount: PropTypes.number,
  isPublishable: PropTypes.bool,
};

export default Graphy;
