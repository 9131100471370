import axios from 'axios';

class RelativesDataService {
  getAllOfIndividual(individualId, isEdited) {
    return axios.get(`/relatives?individualId=${individualId}&edited=${isEdited}`);
  }

  createOrUpdate(relative) {
    return axios.post('/relatives', relative);
  }

  publish(relative) {
    return axios.post('/relatives/publish', relative);
  }

  unpublish(relative) {
    return axios.post('/relatives/unpublish', relative);
  }

  delete(relativeId) {
    return axios.delete(`/relatives/${relativeId}`);
  }
}

export default new RelativesDataService();
