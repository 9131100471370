import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { isNotNil } from 'utils/util';
import SourcesDataService from 'services/SourcesDataService';
import { loadGraphies as loadTitleGraphies } from 'slices/titleGraphies';
import { loadGraphies as loadWordGraphies } from 'slices/wordGraphies';
import { loadIndividualSequences } from 'slices/individualSequences';
import { logout } from './auth';

// ------------------------------------
// Selectors
// ------------------------------------

const getAllSourceIds = (state) => state.sources.sourceIds;

const getSourceById = (state, sourceId) => state.sources.sourcesById[sourceId];

// ------------------------------------
// Async actions
// ------------------------------------

const loadSources = createAsyncThunk('sources/load', async () => {
  const res = await SourcesDataService.get();
  return res.data;
});

const searchSources = createAsyncThunk('sources/search', async (searchQuery) => {
  const res = await SourcesDataService.search(searchQuery);
  return res.data;
});

const createSource = createAsyncThunk('sources/create', async (source) => {
  const res = await SourcesDataService.createOrUpdate(source);
  return res.data;
});

const updateSource = createAsyncThunk('sources/update', async (source) => {
  const res = await SourcesDataService.createOrUpdate(source);
  return res.data;
});

const deleteSource = createAsyncThunk('sources/delete', async (sourceId) => {
  await SourcesDataService.delete(sourceId);
  return { sourceId };
});

const initialState = {
  sourceIds: [],
  sourcesById: {},
};

const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loadSources.fulfilled, (state, action) => {
        state.sourceIds = action.payload.map((source) => source.id);
        action.payload.forEach((source) => (state.sourcesById[source.id] = source));
      })
      .addCase(searchSources.fulfilled, (state, action) => {
        state.sourceIds = action.payload.map((source) => source.id);
        action.payload.forEach((source) => (state.sourcesById[source.id] = source));
      })
      .addCase(createSource.fulfilled, (state, action) => {
        state.sourceIds = [action.payload.id, ...state.sourceIds];
        state.sourcesById[action.payload.id] = action.payload;
      })
      .addCase(updateSource.fulfilled, (state, action) => {
        state.sourcesById[action.payload.id] = action.payload;
      })
      .addCase(deleteSource.fulfilled, (state, action) => {
        delete state.sourcesById[action.payload.sourceId];
        state.sourceIds = state.sourceIds.filter((id) => id !== action.payload.sourceId);
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(
          loadWordGraphies.fulfilled,
          loadTitleGraphies.fulfilled,
          loadIndividualSequences.fulfilled
        ),
        (state, action) => {
          action.payload.data
            .flatMap((graphy) => [
              ...get(graphy, 'published.attestations', [])
                .map((attestation) => attestation.source)
                .filter(isNotNil),
              ...get(graphy, 'edited.attestations', [])
                .map((attestation) => attestation.source)
                .filter(isNotNil),
            ])
            .forEach((source) => (state.sourcesById[source.id] = source));
        }
      );
  },
});

const { reducer } = sourcesSlice;

export {
  reducer,
  getAllSourceIds,
  getSourceById,
  loadSources,
  searchSources,
  createSource,
  updateSource,
  deleteSource,
};
