import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { ReactComponent as SearchIcon } from 'images/icon-search.svg';
import TransliterationKeyboard from 'components/TransliterationKeyboard';
import {
  fetchFirstEntrySummariesPage,
  selectTotalFilteredEntries,
  updateSearchParams,
} from 'slices/search';
import useOutsideClick from 'hooks/useOutsideClick';
import SearchPopup from './SearchPopup';
import './Search.scss';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import classNames from 'classnames';

const Search = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const searchBarRef = useRef();
  const searchInputMobileRef = useRef();
  const searchBarMobileRef = useRef();
  const totalFilteredEntries = useSelector(selectTotalFilteredEntries);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const virtuosoState = useRef(undefined);

  const openSearchPopup = useCallback(
    (e) => {
      e.preventDefault();
      setShowSearchPopup(true);
      if (totalFilteredEntries === 0) {
        dispatch(fetchFirstEntrySummariesPage(i18n.resolvedLanguage));
      }
    },
    [dispatch, i18n.resolvedLanguage, totalFilteredEntries]
  );

  const closeSearchPopup = useCallback(() => {
    setShowSearchPopup(false);
  }, []);

  const containerRef = useOutsideClick(closeSearchPopup);

  const debouncedNewSearch = useMemo(
    () =>
      debounce((searchParam) => {
        dispatch(updateSearchParams(searchParam));
        dispatch(fetchFirstEntrySummariesPage(i18n.resolvedLanguage));
      }, 250),
    [dispatch, i18n.resolvedLanguage]
  );

  const handleSearchChanged = (e) => {
    virtuosoState.current = undefined;
    debouncedNewSearch({ search: e.target.value });
  };

  useEffect(() => {
    return () => {
      debouncedNewSearch.cancel();
    };
  }, [debouncedNewSearch]);

  const handleTranliterationKey = useCallback(
    (key) => {
      const appendKey = (searchBar, input) => {
        let newInputValue;
        let newSelectionRange;
        const text = input.value;

        if (window.getSelection().anchorNode === searchBar) {
          const start = text.slice(0, input.selectionStart);
          const end =
            input.selectionStart === input.selectionEnd
              ? text.slice(input.selectionStart)
              : text.slice(input.selectionEnd);
          newInputValue = start + key + end;
          newSelectionRange = [input.selectionStart + 1, input.selectionStart + 1];
        } else {
          newInputValue = text.concat(key);
        }

        input.value = newInputValue;
        if (newSelectionRange) {
          input.setSelectionRange(...newSelectionRange);
        }
        debouncedNewSearch({ search: newInputValue });
      };

      if (searchInputRef.current && searchBarRef.current && window.innerWidth > 520) {
        appendKey(searchBarRef.current, searchInputRef.current);
      } else if (searchInputMobileRef.current && searchBarMobileRef.current) {
        appendKey(searchBarMobileRef.current, searchInputMobileRef.current);
      }
    },
    [searchInputRef, debouncedNewSearch, searchBarRef]
  );

  return (
    <>
      <div
        className={classNames('search-bar-container', { 'is-popup-open': showSearchPopup })}
        ref={containerRef}
        dir={i18n.dir()}
      >
        <div className="search-bar" ref={searchBarRef}>
          <input
            type="text"
            spellCheck="false"
            autoComplete="off"
            id="searchInput"
            className="search-bar-field"
            onClick={openSearchPopup}
            onChange={handleSearchChanged}
            placeholder={t('searchBar.search.text')}
            ref={searchInputRef}
          />
          <SearchIcon className="search-bar-icon" />
        </div>

        {showSearchPopup && (
          <div className="search-bar-popup-container">
            <div className="search-bar-mobile-container">
              <div className="search-bar-mobile" ref={searchBarMobileRef}>
                <input
                  type="text"
                  spellCheck="false"
                  autoComplete="off"
                  id="searchInput"
                  className="search-bar-field"
                  onClick={openSearchPopup}
                  onChange={handleSearchChanged}
                  placeholder={t('searchBar.search.text')}
                  ref={searchInputMobileRef}
                />
                <SearchIcon className="search-bar-icon" />
              </div>
              <button
                className="search-bar-mobile-cancel-button"
                type="button"
                onClick={closeSearchPopup}
              >
                {t('actions.cancel')}
              </button>
            </div>
            <TransliterationKeyboard onKeyClick={handleTranliterationKey} />
            <SearchPopup virtuosoState={virtuosoState} onClose={closeSearchPopup} />
          </div>
        )}
      </div>
      <div className="search-bar-mobile-button">
        <IconButton theme="white" size="large" onClick={openSearchPopup}>
          <Icon size={24}>
            <SearchIcon />
          </Icon>
        </IconButton>
      </div>
    </>
  );
};

export default Search;
