import { get } from 'lodash';
import { isPublishable } from 'utils/util';
import GraphiesDataService from 'services/GraphiesDataService';
import { getNuanceByGraphyIdAndAttestationId } from './nuances';
import graphiesFactory from './graphiesFactory';

const graphiesDataService = new GraphiesDataService('entryId');

const extraReducers = {
  'word/delete/fulfilled': (state, action) => {
    const graphyIdsToRemove = state.byEntryId[action.payload.entryId] || [];
    graphyIdsToRemove.forEach((id) => delete state.byId[id]);
    delete state.byEntryId[action.payload.entryId];
  },
};

const { reducer, actions, selectors } = graphiesFactory(
  'wordGraphies',
  'entry',
  extraReducers,
  graphiesDataService
);

const isGraphyNuancesPublishable = (state, graphyId) => {
  const graphy = selectors.getById(state, graphyId);
  const editedAttestationIds = get(graphy, 'edited.attestationIds', []);
  const nuances = editedAttestationIds.map((attestationId) =>
    getNuanceByGraphyIdAndAttestationId(state, graphyId, attestationId)
  );
  return nuances.some((nuance) => isPublishable(nuance, 'description'));
};

const doEntryHasPublishableNuances = (state, entryId) => {
  const graphyIds = selectors.getIdsByEntryId(state, entryId);
  return graphyIds.some((graphyId) => isGraphyNuancesPublishable(state, graphyId));
};

const {
  unpublishEntryGraphies,
  publishEntryGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  udpateMainGraphies,
  addAttestationToGraphy,
  removeAttestationFromGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfEntry,
  resetGraphy,
} = actions;

export {
  reducer,
  unpublishEntryGraphies,
  publishEntryGraphies,
  loadGraphies,
  unpublishGraphy,
  publishGraphy,
  udpateMainGraphies,
  addAttestationToGraphy,
  removeAttestationFromGraphy,
  uploadFileAndCreateGraphy,
  uploadFileAndUpdateGraphy,
  createGraphy,
  updateGraphy,
  uploadGraphy,
  deleteGraphy,
  resetGraphiesOfEntry,
  resetGraphy,
  selectors,
  isGraphyNuancesPublishable,
  doEntryHasPublishableNuances,
};
