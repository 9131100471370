import axios from 'axios';

class SequencesDataService {
  getAllOfIndividual(individualId, isEdited) {
    return axios.get(`/sequences?individualId=${individualId}&edited=${isEdited}`);
  }

  createOrUpdate(sequence) {
    return axios.post('/sequences', sequence);
  }

  publish(sequence) {
    return axios.post('/sequences/publish', sequence);
  }

  unpublish(sequence) {
    return axios.post('/sequences/unpublish', sequence);
  }

  delete(sequenceId) {
    return axios.delete(`/sequences/${sequenceId}`);
  }
}

export default new SequencesDataService();
