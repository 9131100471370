import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Cluster } from 'components/Layout';
import { canCreateEntry } from 'slices/auth';
import { WORD_PREFIX } from 'slices/words';
import { TITLE_PREFIX } from 'slices/titles';
import { INDIVIDUAL_PREFIX } from 'slices/individuals';
import { ReactComponent as PenLogo } from 'images/icon-pen.svg';
import { ReactComponent as CrossIcon } from 'images/icon-cross.svg';
import { EntryPublicStatus, TitlePublicStatus } from 'components/PublicStatus';
import V4Tag from 'components/V4Tag';
import EntryEditionStatus from './EntryEditionStatus';
import EntryInfoPopup from './EntryInfoPopup';
import ToolbarButton from './ToolbarButton';
import './EntryReadToolbar.scss';

const EntryReadToolBar = ({ entry, entryPrefix, isEditable, onStartEdition, onClose }) => {
  const userIsAnEditor = useSelector(canCreateEntry);

  return (
    <div className="entry-header">
      {entry && (
        <Cluster align="center" space="var(--s-1)" data-testid="entry-informations">
          <EntryInfoPopup
            entryPrefix={entryPrefix}
            entryId={entry.id}
            publishDate={entry.publishDate}
          />
          <div className="vertical-separator" />
          {entryPrefix === WORD_PREFIX && (
            <EntryPublicStatus status={entry.publicStatus} hasTooltip />
          )}
          {entryPrefix === TITLE_PREFIX && (
            <TitlePublicStatus status={entry.publicStatus} hasTooltip />
          )}
          <EntryEditionStatus status={entry.editionStatus} hasTooltip />
          {userIsAnEditor && entry.isCheckedForV4 && <V4Tag />}
        </Cluster>
      )}
      {!entry && <div style={{ flex: '1 1 auto' }} />}

      <Cluster align="center" space="var(--s-3)">
        {isEditable && (
          <ToolbarButton onClick={onStartEdition} data-testid="entry-edit-button">
            <PenLogo />
          </ToolbarButton>
        )}
        <ToolbarButton onClick={onClose} data-testid="entry-close-button">
          <CrossIcon />
        </ToolbarButton>
      </Cluster>
    </div>
  );
};

EntryReadToolBar.defaultProps = {
  entry: null,
  isEditable: false,
};

EntryReadToolBar.propTypes = {
  entry: PropTypes.object,
  entryPrefix: PropTypes.oneOf([WORD_PREFIX, TITLE_PREFIX, INDIVIDUAL_PREFIX]).isRequired,
  isEditable: PropTypes.bool,
  onStartEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EntryReadToolBar;
