import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import './EntryTabs.scss';

const EntryTabs = ({ children, selectors, defaultIndex = 0 }) => {
  const { t, i18n } = useTranslation();

  return (
    <Tabs
      className="entry-tabs"
      disabledTabClassName="is-disabled"
      selectedTabClassName="is-selected"
      direction={i18n.dir()}
      defaultIndex={defaultIndex}
    >
      <TabList className="entry-tabs-selectors">
        {React.Children.map(selectors, (child) => {
          if (child !== null) {
            return (
              <Tab
                key={child.props.id}
                className="entry-tabs-selector"
                disabledClassName="is-disabled"
                selectedClassName="is-selected"
                disabled={child.props.disabled}
                data-publishable={child.props['data-publishable']}
              >
                <TooltipManager>
                  <TooltipTrigger>
                    <div className="entry-tabs-selector-icon">{child}</div>
                  </TooltipTrigger>
                  <TooltipContent>{t(`${child.props.id}.title`)}</TooltipContent>
                </TooltipManager>
              </Tab>
            );
          }
        })}
      </TabList>

      {React.Children.map(children, (child) => {
        if (child !== null) {
          return (
            <TabPanel key={child.props.id} className="entry-tabs-panel">
              <div data-testid={`entry-tab-${child.props.id}`}>{child}</div>
            </TabPanel>
          );
        }
      })}
    </Tabs>
  );
};

export default EntryTabs;
