import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { updateWord, WORD_PREFIX } from 'slices/words';
import { EDITION_STATUS } from 'utils/constants';
import { selectors as wordCommentsSelectors } from 'slices/wordComments';
import {
  doEntryHasPublishableNuances,
  selectors as wordGraphiesSelectors,
} from 'slices/wordGraphies';
import { selectors as wordDictionaryReferencesSelectors } from 'slices/wordDictionaryReferences';
import usePublishable from 'hooks/usePublishable';
import useVegaDictionaryReferencesPublishable from 'hooks/useVegaDictionaryReferencesPublishable';
import { ReactComponent as GraphiesIcon } from 'images/icon-tab-graphies.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import { ReactComponent as DictionariesIcon } from 'images/icon-tab-dictionaries.svg';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import EntryEditionStatus from 'components/Entry/EntryEditionStatus';
import { EntryPublicStatusSelector } from 'components/PublicStatus';
import EntryInfoPopup from 'components/Entry/EntryInfoPopup';
import NatureSelector from 'components/NatureSelector';
import EntryTabs from 'components/Entry/EntryTabs';
import GraphiesEdition from 'components/WordGraphies/GraphiesEdition';
import WordDictionaryReferences from 'components/WordDictionaryReferences';
import { AutoSaveInput } from 'components/Input';
import Checkbox from 'components/Checkbox';
import WordComments from 'components/WordComments';
import MainNuancesEditor from 'components/MainNuancesEditor';
import EntryEditionMainInfoLayout from 'components/Entry/EntryEditionMainInfoLayout';

const WordEditionContent = ({ entry }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPublishable] = usePublishable(entry);
  const isGraphiesPublishable = useSelector((state) =>
    wordGraphiesSelectors.isAllOfEntryPublishable(state, entry.id)
  );
  const isGraphiesNuancesPublishable = useSelector((state) =>
    doEntryHasPublishableNuances(state, entry.id)
  );
  const comment = useSelector((state) => wordCommentsSelectors.getByEntryId(state, entry.id));
  const dictionaryRefs = useSelector((state) =>
    wordDictionaryReferencesSelectors.getByEntryId(state, entry.id)
  );
  const [isCommentPublishable] = usePublishable(comment);
  const isDictionaryRefsPublishable = useVegaDictionaryReferencesPublishable(dictionaryRefs);
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      edited: {
        transliteration: get(entry, 'edited.transliteration', ''),
        authors: get(entry, 'edited.authors', ''),
      },
    },
  });
  const onSubmit = (data) => dispatch(updateWord({ id: entry.id, data }));
  const onError = (errors, e) => console.log(errors, e);

  const handleMainNuanceUpdate = (locale, newNuance) =>
    onSubmit({ edited: { mainNuances: { [locale]: newNuance } } });

  const handleEditionStatusUpdate = (newEditionStatus) => {
    dispatch(updateWord({ id: entry.id, data: { editionStatus: newEditionStatus } }));
  };

  const handlePublicStatusUpdate = (newPublicStatus) => {
    dispatch(updateWord({ id: entry.id, data: { publicStatus: newPublicStatus } }));
  };

  const handleAddNature = (natureToAdd) => {
    onSubmit({ edited: { nature: [...(entry.edited.nature || []), natureToAdd] } });
  };

  const handleRemoveNature = (natureToRemove) => {
    onSubmit({
      edited: { nature: entry.edited.nature.filter((nature) => nature !== natureToRemove) },
    });
  };

  const handleUpdateCheckedForV4 = (evt) => {
    onSubmit({ isCheckedForV4: evt.target.checked });
  };

  return (
    <Stack space="var(--s0)">
      <EntryEditionMainInfoLayout
        actionsBarLeft={
          <>
            <EntryInfoPopup
              entryPrefix={WORD_PREFIX}
              entryId={entry.id}
              saveDate={entry.saveDate}
              publishDate={entry.publishDate}
            />

            <EntryPublicStatusSelector
              status={entry.publicStatus}
              onSelect={handlePublicStatusUpdate}
            />

            <DropdownMenu
              itemsCount={2}
              renderButton={(buttonProps) => (
                <DropdownButton {...buttonProps}>
                  <EntryEditionStatus status={entry.editionStatus} />
                </DropdownButton>
              )}
            >
              {Object.values(EDITION_STATUS).map((status) => (
                <MenuItem
                  key={status}
                  isSelectable
                  isSelected={entry.editionStatus === status}
                  onClick={() => handleEditionStatusUpdate(status)}
                >
                  {t(`entry.editionStatus.${status}`)}
                </MenuItem>
              ))}
            </DropdownMenu>
          </>
        }
        actionsBarRight={
          <Checkbox
            name="checkedForV4"
            label={t('entry.checkedForV4')}
            checked={entry.isCheckedForV4}
            onChange={handleUpdateCheckedForV4}
          />
        }
      >
        <div data-publishable={isPublishable('transliteration')}>
          <AutoSaveInput
            italic
            serif
            size="large"
            placeholder={t('entry.transliteration')}
            {...register('edited.transliteration', { required: true })}
            onSave={handleSubmit(onSubmit, onError)}
          />
        </div>
        <div data-publishable={isPublishable('nature')}>
          <NatureSelector
            natures={entry.edited.nature}
            onSelect={handleAddNature}
            onDeselect={handleRemoveNature}
          />
        </div>
        <MainNuancesEditor resource={entry} onUpdate={handleMainNuanceUpdate} />
      </EntryEditionMainInfoLayout>

      <EntryTabs
        selectors={[
          <GraphiesIcon
            id="graphies"
            data-publishable={isGraphiesPublishable || isGraphiesNuancesPublishable}
          />,
          <DictionariesIcon id="dictionaries" data-publishable={isDictionaryRefsPublishable} />,
          <CommentsIcon id="comments" data-publishable={isCommentPublishable('text')} />,
        ]}
      >
        <div id="graphies">
          <GraphiesEdition entryId={entry.id} />
        </div>
        <div id="dictionaries">
          <WordDictionaryReferences entryId={entry.id} isEditVersion />
        </div>
        <div id="comments">
          <WordComments entryId={entry.id} isEditVersion />
        </div>
      </EntryTabs>

      <div className="entry-authors" data-publishable={isPublishable('authors')}>
        <AutoSaveInput
          size="extra-small"
          layout="horizontal"
          serif
          placeholder={t('entry.authors')}
          label={t('entry.authorsLabel')}
          {...register('edited.authors')}
          onSave={handleSubmit(onSubmit, onError)}
        />
      </div>
    </Stack>
  );
};

WordEditionContent.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default WordEditionContent;
