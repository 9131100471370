import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Cluster } from 'components/Layout';
import { ReactComponent as AddIcon } from 'images/icon-add.svg';
import { SOURCE_TYPES } from 'utils/constants';
import { getAllSourceIds, loadSources, searchSources } from 'slices/sources';
import { addSourceToAttestation } from 'slices/attestations';
import {
  isSelectionModeTriggeredForSource,
  getInteractionModeTriggeredBy,
} from 'slices/ui/thesaurus';
import DropdownMenu from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Button from 'components/Button';
import ThesaurusLayout from './ThesaurusLayout';
import SourceIcon from './SourceIcon';
import SourceNewForm from './SourceNewForm';
import SourceSummary from './SourceSummary';
import SearchField from './SearchField';
import './Sources.scss';

const Sources = ({ canEdit, onNavigateToSource }) => {
  const { t } = useTranslation();
  const sourcesListRef = useRef(null);
  const dispatch = useDispatch();
  const [newSourceType, setNewSourceType] = useState(null);
  const sourceIds = useSelector(getAllSourceIds);
  const shouldSelectSource = useSelector(isSelectionModeTriggeredForSource);
  const interactionModeTrigger = useSelector(getInteractionModeTriggeredBy);

  const handleCreateSource = useCallback(
    (type) => {
      if (sourcesListRef.current) {
        sourcesListRef.current.parentElement.scrollTop = 0;
      }
      setNewSourceType(type);
    },
    [sourcesListRef]
  );

  const handleSelectSource = useCallback(
    (id) => {
      if (shouldSelectSource) {
        const { attestationId } = interactionModeTrigger;
        dispatch(addSourceToAttestation({ attestationId, sourceId: id }));
      } else {
        onNavigateToSource(id);
      }
    },
    [dispatch, onNavigateToSource, shouldSelectSource, interactionModeTrigger]
  );

  const handleSearchSources = useCallback(
    (searchQuery) => {
      dispatch(searchSources(searchQuery));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadSources());
  }, [dispatch]);

  return (
    <ThesaurusLayout
      backgroundColor="var(--green-2)"
      header={
        <div>
          <SearchField onChange={handleSearchSources} />
          <Cluster justify="space-between" align="center" className="sources-header">
            <h1 className="thesaurus-title">{t('source.title', { count: sourceIds.length })}</h1>
            {canEdit && (
              <DropdownMenu
                position="right"
                itemsCount={Object.keys(SOURCE_TYPES).length}
                renderButton={(buttonProps) => (
                  <Button {...buttonProps} theme="white" disabled={!!newSourceType}>
                    <AddIcon />
                  </Button>
                )}
              >
                {Object.values(SOURCE_TYPES).map((type) => (
                  <MenuItem key={type} onClick={() => handleCreateSource(type)}>
                    <SourceIcon type={type} />
                    <span>{t(`source.types.${type}`)}</span>
                  </MenuItem>
                ))}
              </DropdownMenu>
            )}
          </Cluster>
        </div>
      }
    >
      <div className="sources-list" ref={sourcesListRef}>
        {!!newSourceType && (
          <SourceNewForm type={newSourceType} onCancel={() => setNewSourceType(null)} />
        )}
        {sourceIds.map((id) => (
          <SourceSummary
            key={id}
            id={id}
            onSelect={handleSelectSource}
            showArrow={!shouldSelectSource}
          />
        ))}
      </div>
    </ThesaurusLayout>
  );
};

Sources.defaultProps = {
  canEdit: false,
};

Sources.propTypes = {
  canEdit: PropTypes.bool,
  onNavigateToSource: PropTypes.func.isRequired,
};

export default Sources;
