import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate } from 'date-fns';
import { fr, enGB, de, ar } from 'date-fns/locale';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const locales = { fr, en: enGB, de, ar }; // used to look up the required locale

export const getISO2Locale = (locale) => {
  if (locale) {
    return locale.slice(0, 2);
  }
  return 'en';
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      cookieMinutes: 7 * 24 * 60, // 7 days
      cookieOptions: {
        samesite: 'Lax',
        path: '/',
      },
    },
    fallbackLng: 'en',
    debug: false,
    cleanCode: true,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          const locale = locales[getISO2Locale(lng)];
          return formatDate(value, format, { locale });
        }
      },
    },
  });

export default i18n;
