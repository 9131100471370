import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTitleHolderIdsByTitleId } from 'slices/titleHolders';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import TitleHolder from './TitleHolder';

const TitleHolders = ({ titleId }) => {
  const { t } = useTranslation();
  const titleHolderIds = useSelector((state) => getTitleHolderIdsByTitleId(state, titleId));

  return (
    <EntryTabContentLayout title={t('titleHolders.title')} noBodyTopSpace>
      {titleHolderIds.map((id) => (
        <TitleHolder id={id} key={id} />
      ))}
    </EntryTabContentLayout>
  );
};

TitleHolders.propTypes = {
  titleId: PropTypes.number.isRequired,
};

export default TitleHolders;
