import { useSelector } from 'react-redux';
import { getCapeaDictionaries } from 'slices/dictionaries';
import useDictionaryReferencesPublishable from './useDictionaryReferencesPublishable';

const useCapeaDictionaryReferencesPublishable = (references) => {
  const dictionaries = useSelector(getCapeaDictionaries);
  return useDictionaryReferencesPublishable(references, dictionaries);
};

export default useCapeaDictionaryReferencesPublishable;
