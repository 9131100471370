import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Stack } from 'components/Layout';
import { selectors as titleGraphiesSelectors } from 'slices/titleGraphies';
import { getAllPeriodsFromAttestationIds } from 'slices/attestations';
import Accordion from 'components/Accordion';
import { PeriodsOverview } from 'components/Periods';
import GraphyAttestation from 'components/Graphy/GraphyAttestation';
import Graphy from 'components/Graphy';

const GraphyRead = ({ graphyId, publishableProp, dir, ...rest }) => {
  const graphy = useSelector((state) => titleGraphiesSelectors.getById(state, graphyId));
  const graphyPeriods = useSelector((state) =>
    getAllPeriodsFromAttestationIds(state, get(graphy, `${publishableProp}.attestationIds`, []))
  );

  if (!graphy[publishableProp]) {
    return null;
  }

  return (
    <div className="graphy-container">
      <Accordion
        summary={
          <Graphy
            url={graphy[publishableProp].url}
            publicStatus={graphy.publicStatus}
            dir={dir}
            attestationsCount={get(graphy, `${publishableProp}.attestationIds.length`, 0)}
          />
        }
        {...rest}
      >
        {graphy[publishableProp].attestationIds && (
          <div className="graphy-attestation-container">
            <Stack space="var(--s0)">
              <PeriodsOverview selectedPeriods={graphyPeriods} />
              {graphy[publishableProp].attestationIds.map((attestationId) => (
                <GraphyAttestation key={attestationId} id={attestationId} hideAttestationDetails />
              ))}
            </Stack>
          </div>
        )}
      </Accordion>
    </div>
  );
};

GraphyRead.propTypes = {
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
  graphyId: PropTypes.number.isRequired,
  dir: PropTypes.string,
};

export default GraphyRead;
