import React, { useEffect, useCallback } from 'react';
import ReactModal from 'react-modal';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Outlet, Link } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import axios from 'axios';
import AppHeader from 'components/AppHeader';
import EntriesManager from 'components/Entries';
import { loadAuthUser } from 'slices/auth';
import { loadAllDictionaries } from 'slices/dictionaries';
import { loadAllPeriods } from 'slices/periods';
import store from './utils/configureStore';
import './utils/i18n';
import './styles/style.scss';

//-- Allows to mock axios for tests.
//-- The configuration of axios through an instance created with axios.create ({ config })
//-- doesn't allow to mock it for tests.
axios.defaults.baseURL = '/api';
axios.defaults.headers['Content-Type'] = 'application/json';

ReactModal.setAppElement('#root');

const NoMatch = () => {
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '65ch', textAlign: 'center' }}>
      <h1>Oops!</h1>
      <p>Sorry, this page doesn’t exist.</p>
      <p>
        <Link to="/">Go to the home page ›</Link>
      </p>
    </div>
  );
};

const App = () => {
  const dispatch = useDispatch();

  const handleCheckAuth = useCallback(() => {
    if (!document.hidden) {
      dispatch(loadAuthUser());
    }
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleCheckAuth, true);

    return function cleanup() {
      document.removeEventListener('visibilitychange', handleCheckAuth, true);
    };
  }, [handleCheckAuth]);

  return (
    <>
      <AppHeader />
      <Outlet />
    </>
  );
};

const Root = () => {
  useEffect(() => {
    store.dispatch(loadAuthUser());
    store.dispatch(loadAllDictionaries());
    store.dispatch(loadAllPeriods());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<EntriesManager />} />
            </Route>
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </QueryParamProvider>
      </Router>
    </Provider>
  );
};

export default Root;
