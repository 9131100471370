import axios from 'axios';

class TitleHoldersDataService {
  createOrUpdate(titleHolder) {
    return axios.post('/title-holders', titleHolder);
  }

  delete(titleHolderId) {
    return axios.delete(`/title-holders/${titleHolderId}`);
  }

  unpublish(titleHolder) {
    return axios.post('/title-holders/unpublish', titleHolder);
  }

  publish(titleHolder) {
    return axios.post('/title-holders/publish', titleHolder);
  }
}

export default new TitleHoldersDataService();
