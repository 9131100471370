import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTitleHolderById } from 'slices/titleHolders';
import { openAndLoadIndividual } from 'slices/individuals';
import EntryNuance from 'components/Entry/EntryNuance';
import Transliteration from 'components/Transliteration';
import { Cluster } from 'components/Layout';
import Tag from 'components/Tag';
import { PeriodsLiteral } from 'components/Periods';
import './TitleHolder.scss';

const TitleHolder = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { individual } = useSelector((state) => getTitleHolderById(state, id));
  const isUnknown = get(individual, 'transliteration', '').toLowerCase() === 'inconnu';
  const isCollective = get(individual, 'transliteration', '').toLowerCase() === 'collectif';

  if (!individual.published) {
    return null;
  }

  return (
    <div className="title-holder" onClick={() => dispatch(openAndLoadIndividual(individual.id))}>
      <Cluster space="var(--s-1)" justify="space-between">
        <Tag>{t(`individual.nuanceTypes.${individual.mainNuanceType}`)}</Tag>
      </Cluster>
      {!isUnknown && !isCollective && (
        <h2 className="title-holder-transliteration">
          <Transliteration>{individual.transliteration}</Transliteration>
        </h2>
      )}
      <EntryNuance nuances={individual.mainNuances} />
      <PeriodsLiteral periods={individual.attestationPeriods} />
    </div>
  );
};

TitleHolder.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TitleHolder;
