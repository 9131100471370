import { useSelector } from 'react-redux';
import { getVegaDictionaries } from 'slices/dictionaries';
import useDictionaryReferencesPublishable from './useDictionaryReferencesPublishable';

const useVegaDictionaryReferencesPublishable = (references) => {
  const dictionaries = useSelector(getVegaDictionaries);
  return useDictionaryReferencesPublishable(references, dictionaries);
};

export default useVegaDictionaryReferencesPublishable;
