import React from 'react';
import PropTypes from 'prop-types';
import './Menu.scss';

export const MENU_THEMES = {
  light: 'light',
  medium: 'medium',
  dark: 'dark',
};

const Menu = ({ children, theme, dir }) => {
  return (
    <div className="menu" data-theme={theme} data-testid="menu" role="menu" dir={dir}>
      {children}
    </div>
  );
};

Menu.defaultProps = {
  theme: MENU_THEMES.light,
  dir: null,
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(Object.values(MENU_THEMES)),
  dir: PropTypes.oneOf(['ltr', 'rtl']),
};

export default Menu;
