import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  deleteIndividualName,
  getIndividualNameById,
  isIndividualNamePublishable,
  updateIndividualName,
} from 'slices/individualNames';
import {
  uploadFileAndCreateGraphy,
  selectors as individualNameGraphiesSelectors,
} from 'slices/individualNameGraphies';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { AutoSaveInput } from 'components/Input';
import MainNuancesEditor from 'components/MainNuancesEditor';
import IndividualNuanceTypeSelector from 'components/Individual/IndividualNuanceTypeSelector';
import GraphyDropzone from 'components/Graphy/GraphyDropzone';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import IndividualNameGraphyEdition from './IndividualNameGraphyEdition';
import './IndividualName.scss';

const mainNuanceLocales = ['fr'];

const IndividualNameEdition = ({ id, individualId }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const name = useSelector((state) => getIndividualNameById(state, id));
  const graphyIds = useSelector((state) =>
    individualNameGraphiesSelectors.getIdsByNameId(state, id)
  );
  const [isPublishable] = usePublishable(name);
  const hasDataToPublished = useSelector((state) => isIndividualNamePublishable(state, id));

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      edited: {
        transliteration: get(name, 'edited.transliteration', ''),
      },
    },
  });

  const onSubmit = (data) => dispatch(updateIndividualName({ id, data }));
  const onError = (errors, e) => console.log(errors, e);

  const handleMainNuanceUpdate = (locale, newNuance) =>
    onSubmit({ edited: { mainNuances: { [locale]: newNuance } } });

  const handleMainNuanceTypeUpdate = (newMainNuanceType) => {
    dispatch(updateIndividualName({ id, data: { edited: { mainNuanceType: newMainNuanceType } } }));
  };

  const handleGraphySelection = (file) => {
    dispatch(uploadFileAndCreateGraphy(id, file));
  };

  const handleRemove = () => {
    dispatch(deleteIndividualName({ id, individualId }));
  };

  return (
    <div className="individual-name--edition" data-publishable={hasDataToPublished}>
      <div data-publishable={isPublishable('mainNuanceType')}>
        <IndividualNuanceTypeSelector
          type={get(name, 'edited.mainNuanceType', undefined)}
          onSelect={handleMainNuanceTypeUpdate}
        />
      </div>

      <div data-publishable={isPublishable('transliteration')}>
        <AutoSaveInput
          italic
          serif
          size="large"
          placeholder={t('entry.transliteration')}
          {...register('edited.transliteration', { required: true })}
          onSave={handleSubmit(onSubmit, onError)}
        />
      </div>

      {graphyIds.length > 0 && (
        <IndividualNameGraphyEdition
          key={graphyIds[0]}
          graphyId={graphyIds[0]}
          nameId={id}
          dir={i18n.dir()}
        />
      )}

      {graphyIds.length === 0 && (
        <div className="individual-name-graphy-dropzone">
          <GraphyDropzone
            id={`individual-name-graphy-dropzone-${id}`}
            onSelect={handleGraphySelection}
          />
        </div>
      )}

      <MainNuancesEditor
        resource={name}
        onUpdate={handleMainNuanceUpdate}
        locales={mainNuanceLocales}
      />

      <div className="individual-name-actions">
        <IconButton
          onMouseDown={preventEventDefaultAndStopPropagation}
          onClick={handleRemove}
          theme="transparent"
          data-testid="remove-name-from-individual-button"
        >
          <Icon size={22}>
            <TrashIcon />
          </Icon>
        </IconButton>
      </div>
    </div>
  );
};

IndividualNameEdition.propTypes = {
  id: PropTypes.number.isRequired,
  individualId: PropTypes.number.isRequired,
};

export default IndividualNameEdition;
