import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Cluster } from 'components/Layout';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';

const NUANCE_TYPES = ['name', 'nickname', 'goodname'];

const IndividualNuanceTypeSelector = ({ type, onSelect }) => {
  const { t } = useTranslation();

  return (
    <div className="nature-selector">
      <Cluster align="center" space="var(--s0)">
        <DropdownMenu
          itemsCount={NUANCE_TYPES.length}
          renderButton={(buttonProps) => (
            <DropdownButton {...buttonProps}> {t(`individual.nuanceTypes.${type}`)}</DropdownButton>
          )}
        >
          {NUANCE_TYPES.map((nuanceType) => (
            <MenuItem
              key={nuanceType}
              isSelectable
              isSelected={type === nuanceType}
              onClick={() => onSelect(nuanceType)}
            >
              {t(`individual.nuanceTypes.${nuanceType}`)}
            </MenuItem>
          ))}
        </DropdownMenu>
      </Cluster>
    </div>
  );
};

IndividualNuanceTypeSelector.defaultProps = {
  type: NUANCE_TYPES[0],
};

IndividualNuanceTypeSelector.propTypes = {
  type: PropTypes.oneOf(NUANCE_TYPES),
  onSelect: PropTypes.func.isRequired,
};

export default IndividualNuanceTypeSelector;
