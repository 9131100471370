import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Cluster, Stack } from 'components/Layout';
import {
  selectors as titleGraphiesSelectors,
  updateGraphy,
  udpateMainGraphies,
  removeAttestationFromGraphy,
  isGraphyTitleHoldersPublishable,
} from 'slices/titleGraphies';
import usePublishable from 'hooks/usePublishable';
import { INTERACTION_MODE, setInteractionMode } from 'slices/ui/thesaurus';
import { ReactComponent as StarIcon } from 'images/icon-star.svg';
import { ReactComponent as SandwichIcon } from 'images/icon-sandwich.svg';
import { GraphyPublicStatusSelector } from 'components/PublicStatus';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import Graphy from 'components/Graphy';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import AttestationEditForm from 'components/Thesaurus/AttestationEditForm';
import GraphyAttestation from 'components/Graphy/GraphyAttestation';
import GraphyAttestationTitleHolders from 'components/TitleGraphies/GraphyAttestationTitleHolders';

const GraphyEdition = ({ titleId, graphyId, dir, onDelete, onReplace }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const graphyRef = useRef();
  const graphy = useSelector((state) => titleGraphiesSelectors.getById(state, graphyId));
  const [editedAttestationId, setEditedAttestationId] = useState(null);
  const hasDataToPublished = useSelector((state) =>
    titleGraphiesSelectors.isPublishable(state, graphyId)
  );
  const hasTitleHoldersToPublished = useSelector((state) =>
    isGraphyTitleHoldersPublishable(state, graphyId)
  );
  const [isPublishable] = usePublishable(graphy);

  const handlePublicStatusUpdate = (newPublicStatus) => {
    dispatch(updateGraphy({ ...graphy, publicStatus: newPublicStatus }));
  };

  const handleReplaceImage = (evt) => {
    if (graphyRef.current) {
      // We trigger a click here to close the graphy menu
      // that needed to stay open when selecting the new image
      graphyRef.current.click();
    }
    onReplace(graphy.id, evt.target.files[0]);
  };

  const handleSelectAttestation = () => {
    dispatch(
      setInteractionMode({
        interactionMode: INTERACTION_MODE.titleGraphyAttestationSelection,
        interactionModeTriggeredBy: {
          id: titleId,
          graphyId,
        },
      })
    );
  };

  const handleEditAttestation = (attestationId) => {
    setEditedAttestationId(attestationId);
  };

  const handleRemoveAttestation = (attestationId) => {
    dispatch(removeAttestationFromGraphy({ graphyId, attestationId }));
  };

  const handleUpdateMain = (mainProp, value) => {
    const newMainProp = { [mainProp]: value };
    dispatch(udpateMainGraphies(graphyId, newMainProp));
  };

  if (!graphy.edited) {
    return null;
  }

  const publishedAttestationIds = get(graphy, 'published.attestationIds', []);
  const hasUnpublishedMain = isPublishable('main1') || isPublishable('main2');
  const hasUnpublishedUrl = isPublishable('url');
  const getHasUnpublishedAddedAttestation = (attestationId) =>
    !publishedAttestationIds.includes(attestationId);

  return (
    <div
      className="graphy-container"
      ref={graphyRef}
      data-publishable={hasDataToPublished || hasTitleHoldersToPublished}
    >
      <Cluster
        space="var(--s-1)"
        justify="flex-end"
        className="entry-graphy-toolbar"
        data-publishable="false"
      >
        <DropdownMenu
          position="right"
          itemsCount={2}
          renderButton={(buttonProps) => (
            <DropdownButton {...buttonProps}>
              <Cluster space="var(--s-4)" align="center">
                <Icon isFilled={graphy.edited.main1 || graphy.edited.main2} size={20}>
                  <StarIcon />
                </Icon>
                <div>
                  {graphy.edited.main1 && 1}
                  {graphy.edited.main2 && 2}
                </div>
              </Cluster>
            </DropdownButton>
          )}
          data-publishable={hasUnpublishedMain}
        >
          <MenuItem
            key="main1"
            isSelectable
            isSelected={graphy.edited.main1}
            onClick={() => handleUpdateMain('main1', !graphy.edited.main1)}
          >
            {t('graphy.main1')}
          </MenuItem>
          <MenuItem
            key="main2"
            isSelectable
            isSelected={graphy.edited.main2}
            onClick={() => handleUpdateMain('main2', !graphy.edited.main2)}
          >
            {t('graphy.main2')}
          </MenuItem>
        </DropdownMenu>

        <GraphyPublicStatusSelector
          status={graphy.publicStatus}
          position="right"
          onSelect={handlePublicStatusUpdate}
        />

        <DropdownMenu
          position="right"
          itemsCount={3}
          renderButton={(buttonProps) => (
            <button className="graphy-menu-button" type="button" {...buttonProps}>
              <Icon size={16}>
                <SandwichIcon />
              </Icon>
            </button>
          )}
          ignoreSelectors=".graphy-menu-file-input"
        >
          <MenuItem onClick={() => {}}>
            <label className="graphy-menu-file-input">
              {t('actions.replaceImage')}
              <input
                onChange={handleReplaceImage}
                id="replaceGraphy"
                name="replaceGraphy"
                type="file"
                accept=".svg"
                multiple={false}
              />
            </label>
          </MenuItem>
          <MenuItem onClick={() => onDelete(graphy.id)}>{t('actions.remove')}</MenuItem>
        </DropdownMenu>
      </Cluster>

      <Accordion
        summary={<Graphy url={graphy.edited.url} dir={dir} isPublishable={hasUnpublishedUrl} />}
      >
        {graphy.edited.attestationIds && (
          <Stack space="var(--s0)">
            {graphy.edited.attestationIds.map((attestationId) => {
              if (editedAttestationId === attestationId) {
                return (
                  <div key={`${attestationId}-edited`}>
                    <div className="entry-graphy-attestation-edit-container">
                      <AttestationEditForm
                        id={attestationId}
                        onEnd={() => setEditedAttestationId(null)}
                        showSourceHeader
                      />
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={attestationId}
                  data-publishable={getHasUnpublishedAddedAttestation(attestationId)}
                >
                  <GraphyAttestation
                    id={attestationId}
                    onEdit={handleEditAttestation}
                    onRemove={handleRemoveAttestation}
                    hideAttestationDetails
                  />
                  <GraphyAttestationTitleHolders
                    attestationId={attestationId}
                    graphyId={graphyId}
                  />
                </div>
              );
            })}
          </Stack>
        )}
        <div data-publishable="false">
          <Button
            size="full"
            onClick={handleSelectAttestation}
            data-testid="entry-graphy-add-attestation-button"
          >
            {t('graphy.selectAttestation')}
          </Button>
        </div>
      </Accordion>
    </div>
  );
};

GraphyEdition.propTypes = {
  titleId: PropTypes.number.isRequired,
  graphyId: PropTypes.number.isRequired,
  dir: PropTypes.string,
};

export default GraphyEdition;
