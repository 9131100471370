import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getOrderedIndividualSequenceIdsByIndividualId } from 'slices/individualSequences';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualSequenceRead from './IndividualSequenceRead';
import './IndividualSequences.scss';

const IndividualSequencesRead = ({ individualId, publishableProp }) => {
  const { t } = useTranslation();
  const sequenceIds = useSelector((state) =>
    getOrderedIndividualSequenceIdsByIndividualId(state, individualId, publishableProp)
  );

  return (
    <EntryTabContentLayout title={t('individualSequences.title')} noBodyTopSpace>
      {sequenceIds.map((sequenceId) => (
        <IndividualSequenceRead
          key={sequenceId}
          id={sequenceId}
          publishableProp={publishableProp}
        />
      ))}
    </EntryTabContentLayout>
  );
};

IndividualSequencesRead.defaultProps = {
  publishableProp: 'published',
};

IndividualSequencesRead.propTypes = {
  publishableProp: PropTypes.oneOf(['edited', 'published']),
  individualId: PropTypes.number.isRequired,
};

export default IndividualSequencesRead;
