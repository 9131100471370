import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GraphyDropzone from 'components/Graphy/GraphyDropzone';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';

const GraphiesLayout = ({ htmlFor, children, onSelect }) => {
  const { t } = useTranslation();

  return (
    <EntryTabContentLayout title={t('graphies.title')} noBodySpace>
      <div style={{ borderBottom: '1px solid var(--input-color)' }}>{children}</div>
      {onSelect && <GraphyDropzone id={`${htmlFor}-graphy-dropzone`} onSelect={onSelect} />}
    </EntryTabContentLayout>
  );
};

GraphiesLayout.defaultProps = {
  htmlFor: '',
  children: null,
  onSelect: null,
};

GraphiesLayout.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  onSelect: PropTypes.func,
};

export default GraphiesLayout;
