import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  highlightIndividual,
  isIndividualOpened,
  openAndLoadIndividual,
  resetIndividualHihglight,
} from 'slices/individuals';
import EntrySummary from 'components/Entry/EntrySummary';

const IndividualSummary = forwardRef(({ entry, isHighlighted, onSelect }, ref) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isIndividualOpened(state, entry.id));

  const handleSelect = () => {
    dispatch(resetIndividualHihglight());
    if (!isOpen) {
      dispatch(openAndLoadIndividual(entry.id, !entry.published));
      onSelect();
    }
  };

  const handleEnter = () => {
    dispatch(highlightIndividual(entry.id));
  };

  const handleLeave = () => {
    dispatch(resetIndividualHihglight());
  };

  return (
    <EntrySummary
      ref={ref}
      entry={entry}
      isHighlighted={isHighlighted}
      isOpen={isOpen}
      onSelect={handleSelect}
      onEnter={handleEnter}
      onLeave={handleLeave}
    />
  );
});

IndividualSummary.defaultProps = {
  isHighlighted: false,
};

IndividualSummary.propTypes = {
  entry: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
};

export default IndividualSummary;
