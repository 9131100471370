import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { Stack } from 'components/Layout';
import { ReactComponent as EditIcon } from 'images/icon-edit.svg';
import { KARNAK_URL } from 'utils/constants';
import { isNotEmptyHtml } from 'utils/util';
import { getAttestationById } from 'slices/attestations';
import IconButton from 'components/Button/IconButton';
import Editor from 'components/Editor';
import Icon from 'components/Icon';
import SourcePeriods from './SourcePeriods';
import SourceInfo from './SourceInfo';
import './AttestationSummary.scss';

const AttestationSummary = ({ id, canEdit, onSelect, onEdit }) => {
  const { t } = useTranslation();
  const attestation = useSelector((state) => getAttestationById(state, id));

  const handleSelect = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  const handleEdit = useCallback(() => {
    onEdit(id);
  }, [onEdit, id]);

  if (!attestation) {
    return null;
  }

  return (
    <article className="attestation-summary" onClick={handleSelect}>
      {canEdit && (
        <div className="attestation-summary-actions">
          <IconButton theme="transparent" onClick={handleEdit}>
            <Icon size={22}>
              <EditIcon />
            </Icon>
          </IconButton>
        </div>
      )}
      <Stack space="var(--s-1)">
        <h1 className="attestation-summary-reference">
          <Editor readOnly initialValue={attestation.reference} onValidate={noop} />
        </h1>
        {isNotEmptyHtml(attestation.transliteration) && (
          <div className="attestation-summary-transliteration">
            <Editor readOnly initialValue={attestation.transliteration} onValidate={noop} />
          </div>
        )}
        {isNotEmptyHtml(attestation.traduction) && (
          <div className="attestation-summary-traduction">
            <Editor readOnly initialValue={attestation.traduction} onValidate={noop} />
          </div>
        )}
        <SourcePeriods periods={[attestation.attestationPeriod]} t={t} />
        <SourceInfo label={t('attestation.bibliography')}>
          {isNotEmptyHtml(attestation.bibliography) && (
            <Editor readOnly initialValue={attestation.bibliography} onValidate={noop} />
          )}
        </SourceInfo>
        <SourceInfo label="KIU">
          {attestation.karnakId && (
            <a
              href={`${KARNAK_URL}/${attestation.karnakId}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              {attestation.karnakId}
            </a>
          )}
        </SourceInfo>
      </Stack>
    </article>
  );
};

AttestationSummary.defaultProps = {
  canEdit: false,
};

AttestationSummary.propTypes = {
  id: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AttestationSummary;
