import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EntryButton.scss';

const EntryButton = React.forwardRef(({ isActive, isSquare, children, ...rest }, propRef) => {
  return (
    <button
      type="button"
      className={classNames('entry-button', { 'is-active': isActive, 'is-square': isSquare })}
      {...rest}
      ref={propRef}
    >
      {children}
    </button>
  );
});

EntryButton.defaultProps = {
  isActive: false,
  isSquare: false,
};

EntryButton.propTypes = {
  isActive: PropTypes.bool,
  isSquare: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default EntryButton;
