import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EntryPreview from 'components/Entry/EntryPreview';
import { getWordById } from 'slices/words';
import WordReadContent from './WordReadContent';

const WordPreview = ({ id, onClose }) => {
  const entry = useSelector((state) => getWordById(state, id));

  return (
    <EntryPreview onClose={onClose}>
      {entry && entry.edited && <WordReadContent publishableProp="edited" entry={entry} />}
    </EntryPreview>
  );
};

WordPreview.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WordPreview;
