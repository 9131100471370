import Modal from 'components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPeriods, getPeriodValuesWithoutKings } from 'slices/periods';
import './PeriodsOverview.scss';

const Periods = ({ allPeriods, selectedPeriods, t, showLabel = false }) => {
  const minWidth = showLabel ? 38 : 0;
  return (
    <div className="periods-overview" data-layout={showLabel ? 'expand' : 'shrink'}>
      <div
        key="root-period"
        className="periods-overview-period"
        style={{
          width: 'auto',
          minWidth: showLabel ? 'content' : '2.94%',
        }}
      >
        <div
          title={t(`periods.longName.root-period`)}
          className="periods-overview-item"
          data-selected={selectedPeriods.includes('root-period')}
        >
          {showLabel && t(`periods.abbr.root-period`)}
        </div>
      </div>
      {allPeriods &&
        allPeriods
          .filter(({ type }) => type === 'period')
          .map((period) => {
            const children = allPeriods.filter(({ parentKey }) => parentKey === period.value);
            return (
              <div
                key={period.value}
                className="periods-overview-period"
                style={{
                  width: children.length === 0 ? 'auto' : `${2.94 * children.length}%`,
                  minWidth:
                    children.length === 0
                      ? showLabel
                        ? 'content'
                        : '2.94%'
                      : `max(2.94%, ${minWidth * children.length}px)`,
                }}
              >
                <div
                  title={t(`periods.longName.${period.label}`)}
                  className="periods-overview-item"
                  data-selected={selectedPeriods.includes(period.value)}
                >
                  {showLabel && t(`periods.abbr.${period.label}`)}
                </div>
                {children.length > 0 && (
                  <div className="periods-overview-sub">
                    {children.map((subPeriod) => (
                      <div
                        key={subPeriod.value}
                        title={t(`periods.longName.${subPeriod.label}`)
                          .replace('<sup>', '')
                          .replace('</sup>', '')}
                        className="periods-overview-sub-item"
                        data-selected={selectedPeriods.includes(subPeriod.value)}
                        style={{ width: `max(${100 / children.length}%, ${minWidth}px)` }}
                      >
                        {showLabel && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(`periods.abbr.${subPeriod.label}`),
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
    </div>
  );
};

const PeriodsOverview = ({ selectedPeriods }) => {
  const { t } = useTranslation();
  const allPeriods = useSelector(getPeriods);
  const parsedSelectedPeriods = useSelector((state) =>
    getPeriodValuesWithoutKings(state, selectedPeriods)
  );
  const [showModal, setShowModal] = useState(false);

  if (!selectedPeriods || selectedPeriods.length === 0) {
    return null;
  }

  return (
    <div
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setShowModal(true);
      }}
      className="periods-overview-container"
    >
      <Periods allPeriods={allPeriods} selectedPeriods={parsedSelectedPeriods} t={t} />
      {showModal && (
        <Modal
          className="periods-overview-modal"
          onClose={(evt) => {
            evt.stopPropagation();
            setShowModal(false);
          }}
        >
          <Periods
            allPeriods={allPeriods}
            selectedPeriods={parsedSelectedPeriods}
            showLabel
            t={t}
          />
        </Modal>
      )}
    </div>
  );
};

export default PeriodsOverview;
