import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'images/icon-search.svg';
import './SearchField.scss';

const SearchField = ({ onChange }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce(onChange, 500, {
      leading: false,
      trailing: true,
    }),
    [onChange]
  );

  const handleChange = useCallback(
    (evt) => {
      debounceOnChange(evt.target.value);
      setSearch(evt.target.value);
    },
    [debounceOnChange]
  );

  return (
    <div className="thesaurus-search-field-container">
      <input
        className="thesaurus-search-field"
        placeholder={t('actions.search')}
        value={search}
        onChange={handleChange}
      />
      <SearchIcon className="thesaurus-search-field-icon" />
    </div>
  );
};

SearchField.defaultProps = {};

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SearchField;
