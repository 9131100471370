import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './EntryTabContentLayout.scss';

const EntryTabContentLayout = ({
  title,
  children,
  noTitleBorder,
  noBodyTopSpace,
  noBodySpace,
  isPublishable,
  isEditVersion,
}) => {
  const { i18n } = useTranslation();

  return (
    <div
      className="entry-tab-content-layout"
      dir={i18n.dir()}
      data-publishable={isPublishable}
      data-edited={isEditVersion}
    >
      <div className={classNames('entry-tab-content-layout-title', { 'no-border': noTitleBorder })}>
        {title}
      </div>
      <div
        className={classNames('entry-tab-content-layout-body', {
          'no-top-space': noBodyTopSpace,
          'no-space': noBodySpace,
        })}
        dir="auto"
      >
        {children}
      </div>
    </div>
  );
};

EntryTabContentLayout.defaultProps = {
  children: null,
  noTitleBorder: false,
  noBodyTopSpace: false,
  noBodySpace: false,
  isEditVersion: false,
  isPublishable: false,
};

EntryTabContentLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  noTitleBorder: PropTypes.bool,
  noBodyTopSpace: PropTypes.bool,
  noBodySpace: PropTypes.bool,
  isPublishable: PropTypes.bool,
  isEditVersion: PropTypes.bool,
};

export default EntryTabContentLayout;
