import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Cluster, Stack } from 'components/Layout';
import Button from 'components/Button';
import MainNuancesEditor from 'components/MainNuancesEditor';
import IndividualNuanceTypeSelector from 'components/Individual/IndividualNuanceTypeSelector';
import GraphyDropzone from 'components/Graphy/GraphyDropzone';
import './IndividualName.scss';

const mainNuanceLocales = ['fr'];

const IndividualNameCreation = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [tmpGraphySrc, setTmpGraphySrc] = useState(null);
  const [graphyFile, setGraphyFile] = useState(null);
  const [transliteration, setTransliteration] = useState(null);
  const [mainNuanceType, setMainNuanceType] = useState('name');
  const [newName, setNewName] = useState({ edited: { mainNuances: {} } });

  const handleSubmit = () => {
    URL.revokeObjectURL(tmpGraphySrc);
    const mainNuances = newName.edited.mainNuances.fr
      ? { mainNuances: { fr: newName.edited.mainNuances.fr } }
      : {};
    onSubmit({ edited: { transliteration, mainNuanceType, ...mainNuances } }, graphyFile);
  };

  const handleTransliterationUpdate = (evt) => {
    setTransliteration(evt.target.value);
  };

  const handleMainNuanceUpdate = (locale, newNuance) => {
    setNewName({
      ...newName,
      edited: {
        ...newName.edited,
        mainNuances: {
          ...newName.edited.mainNuances,
          [locale]: newNuance,
        },
      },
    });
  };

  const handleGraphySelection = (file) => {
    setGraphyFile(file);
    setTmpGraphySrc(URL.createObjectURL(file));
  };

  return (
    <div className="individual-name--edition individual-name--creation" data-publishable="true">
      <Stack space="var(--s0)">
        <IndividualNuanceTypeSelector type={mainNuanceType} onSelect={setMainNuanceType} />

        <div className="input-container is-serif is-italic is-large">
          <input
            type="text"
            defaultValue=""
            name="individual-name-transliteration"
            placeholder={t('entry.transliteration')}
            onChange={handleTransliterationUpdate}
            onBlur={handleTransliterationUpdate}
          />
        </div>

        <div className="individual-name-graphy-dropzone">
          {tmpGraphySrc && graphyFile && (
            <div
              className="individual-name-graphy-dropzone-img"
              style={{ backgroundImage: `url(${tmpGraphySrc})` }}
              height="50"
              alt=""
            />
          )}

          {(!tmpGraphySrc || !graphyFile) && (
            <GraphyDropzone
              id={`individual-name-graphy-dropzone-new`}
              onSelect={handleGraphySelection}
            />
          )}
        </div>

        <MainNuancesEditor
          resource={newName}
          onUpdate={handleMainNuanceUpdate}
          locales={mainNuanceLocales}
        />

        <Cluster justify="space-between" className="individual-name--creation-toolbar">
          <Button size="small" onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          {transliteration && (
            <Button type="submit" size="small" onClick={handleSubmit}>
              {t('actions.create')}
            </Button>
          )}
        </Cluster>
      </Stack>
    </div>
  );
};

IndividualNameCreation.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default IndividualNameCreation;
