import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getEntryNumericId, getOpenEntryIds } from 'slices/openEntries';
import { createAndLockWord, stopWordCreation, isCreatingWord, isWord } from 'slices/words';
import { createAndLockTitle, isCreatingTitle, isTitle, stopTitleCreation } from 'slices/titles';
import {
  createAndLockIndividual,
  isCreatingIndividual,
  isIndividual,
  stopIndividualCreation,
} from 'slices/individuals';
import { isInSelectionMode, isThesaurusOpen } from 'slices/ui/thesaurus';
import Overlay from 'components/Overlay';
import Thesaurus from 'components/Thesaurus/Thesaurus';
import EntryCreation from 'components/Entry/EntryCreation';
import Word from 'components/Word';
import Title from 'components/Title';
import Individual from 'components/Individual';
import './Entries.scss';

const Entries = () => {
  const { i18n } = useTranslation();
  const entriesRef = useRef(null);
  const openEntryIds = useSelector(getOpenEntryIds);
  const showThesaurus = useSelector(isThesaurusOpen);
  const [currentEntryCount, setCurrentEntryCount] = useState(openEntryIds.length);
  const showWordCreation = useSelector(isCreatingWord);
  const showTitleCreation = useSelector(isCreatingTitle);
  const showIndividualCreation = useSelector(isCreatingIndividual);
  const showSelectionModeOverlay = useSelector(isInSelectionMode);

  useLayoutEffect(() => {
    if (showWordCreation && entriesRef.current) {
      entriesRef.current.scrollLeft = 0;
    }
  }, [showWordCreation, openEntryIds]);

  useLayoutEffect(() => {
    if (currentEntryCount < openEntryIds.length && entriesRef.current) {
      entriesRef.current.scrollLeft = 0;
    }
    setCurrentEntryCount(openEntryIds.length);
  }, [openEntryIds, currentEntryCount]);

  return (
    <div className="workspace">
      <div
        className="entries"
        style={{ overflow: showSelectionModeOverlay ? 'hidden' : 'auto' }}
        data-testid="entries"
        dir={i18n.dir()}
        ref={entriesRef}
      >
        {showWordCreation && (
          <EntryCreation onSave={createAndLockWord} onClose={stopWordCreation} />
        )}
        {showTitleCreation && (
          <EntryCreation onSave={createAndLockTitle} onClose={stopTitleCreation} />
        )}
        {showIndividualCreation && (
          <EntryCreation onSave={createAndLockIndividual} onClose={stopIndividualCreation} />
        )}
        {openEntryIds.map((id) => {
          const numericId = getEntryNumericId(id);
          if (isWord(id)) {
            return <Word key={id} id={numericId} />;
          } else if (isTitle(id)) {
            return <Title key={id} id={numericId} />;
          } else if (isIndividual(id)) {
            return <Individual key={id} id={numericId} />;
          }
          return null;
        })}
        <div className="entries-last-spacer" />
        {showSelectionModeOverlay && <Overlay zIndex="calc(var(--z-index-entries) + 1)" />}
      </div>
      {showThesaurus && <Thesaurus />}
    </div>
  );
};

export default Entries;
