import React from 'react';
import { useSelector } from 'react-redux';
import { getIndividualSequenceById } from 'slices/individualSequences';
import EntryNuance from 'components/Entry/EntryNuance';
import Transliteration from 'components/Transliteration';
import Accordion from 'components/Accordion';
import './IndividualSequences.scss';
import { Stack } from 'components/Layout';
import GraphyAttestation from 'components/Graphy/GraphyAttestation';
import CounterFlag from 'components/CounterFlag';

const IndividualSequenceRead = ({ id, publishableProp }) => {
  const sequence = useSelector((state) => getIndividualSequenceById(state, id));

  return (
    <div className="individual-sequence">
      <Accordion
        summary={
          <div>
            <h2 className="individual-sequence-transliteration">
              <Transliteration>{sequence[publishableProp].transliteration}</Transliteration>
            </h2>
            {sequence[publishableProp].attestationIds &&
              sequence[publishableProp].attestationIds.length > 0 && (
                <div className="individual-sequence-counter">
                  <CounterFlag>{sequence[publishableProp].attestationIds.length}</CounterFlag>
                </div>
              )}
          </div>
        }
      >
        <EntryNuance nuances={sequence[publishableProp].mainNuances} />

        {sequence[publishableProp].attestationIds && (
          <Stack space="var(--s0)">
            {sequence[publishableProp].attestationIds.map((attestationId) => {
              return (
                <div key={attestationId}>
                  <GraphyAttestation id={attestationId} hideAttestationDetails />
                </div>
              );
            })}
          </Stack>
        )}
      </Accordion>
    </div>
  );
};

export default IndividualSequenceRead;
