import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { identity, isNil } from 'lodash';
import {
  autoUpdate,
  size,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
  flip,
} from '@floating-ui/react';
import {
  isEnterKeyPressed,
  preventEventDefaultAndStopPropagation,
} from 'components/Editor/eventUtils';

const Item = forwardRef(({ children, active, ...rest }, ref) => {
  const id = useId();

  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      style={{
        background: active ? 'rgba(0, 0, 0, 0.05)' : 'none',
        ...rest.style,
      }}
    >
      {children}
    </div>
  );
});

function AutoComplete({
  items,
  initialInputValue,
  labelGetter,
  idGetter,
  renderItem,
  onSelect,
  onChange,
}) {
  const [open, setOpen] = useState(!!initialInputValue);
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [activeIndex, setActiveIndex] = useState(null);
  const listRef = useRef([]);

  const { x, y, reference, floating, strategy, context, refs } = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${Math.min(availableHeight, 260)}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  useLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this effect runs when
    // the styles have **not yet** been applied to the element. A rAF ensures
    // we wait until the position is ready, and also runs before paint.
    // https://floating-ui.com/docs/react-dom#effects
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
      }
    });
  }, [activeIndex]);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
    }),
  ]);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onChange(value);
    if (value) {
      setOpen(true);
    }
    setActiveIndex(0);
  };

  useEffect(() => {
    if (refs.domReference.current) {
      refs.domReference.current.focus();
    }
  }, [refs]);

  return (
    <>
      <input
        {...getReferenceProps({
          ref: reference,
          onChange: handleChange,
          value: inputValue,
          placeholder: '',
          'aria-autocomplete': 'list',
          onKeyDown(event) {
            if (isEnterKeyPressed(event) && activeIndex != null && items[activeIndex]) {
              setInputValue(items[activeIndex]);
              setActiveIndex(null);
              setOpen(false);
              onSelect(items[activeIndex]);
            }
          },
        })}
      />
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <div
              className="autocomplete-container"
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
            >
              {items.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: idGetter(item),
                    ref(node) {
                      listRef.current[index] = node;
                    },
                    onMouseDown(evt) {
                      preventEventDefaultAndStopPropagation(evt);
                    },
                    onClick(evt) {
                      evt.stopPropagation();
                      evt.preventDefault();
                      setInputValue(labelGetter(item));
                      setOpen(false);
                      refs.reference.current?.focus();
                      onSelect(item);
                    },
                  })}
                  active={activeIndex === index}
                >
                  {renderItem && renderItem(item)}
                  {isNil(renderItem) && item}
                </Item>
              ))}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}

AutoComplete.defaultProps = {
  items: [],
  initialInputValue: '',
  renderItem: null,
  labelGetter: identity,
  idGetter: identity,
};

AutoComplete.propTypes = {
  items: PropTypes.array,
  initialInputValue: PropTypes.string,
  renderItem: PropTypes.func,
  labelGetter: PropTypes.func,
  idGetter: PropTypes.func,
};

export default AutoComplete;
