import PropTypes from 'prop-types';
import './Overlay.scss';

const Overlay = ({ zIndex }) => {
  return <div className="overlay" style={{ zIndex }} />;
};

Overlay.defaultProps = {
  zIndex: null,
};

Overlay.propTypes = {
  zIndex: PropTypes.string,
};

export default Overlay;
