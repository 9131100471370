import axios from 'axios';

class SearchDataService {
  getAll({
    locale = 'fr',
    pageNumber = 1,
    pageSize = 30,
    search = '',
    isTransliteration = true,
    isEnglish = true,
    isFrench = true,
    isDeutsch = true,
    isArabic = true,
    isComment = false,
    startWord = true,
    middleWord = false,
    endWord = false,
    type = 'entry',
  }) {
    const params = {
      locale,
      pageNumber,
      pageSize,
      search,
      isTransliteration,
      isEnglish,
      isFrench,
      isDeutsch,
      isArabic,
      isComment,
      startWord,
      middleWord,
      endWord,
      type,
    };
    return axios.get('/entry-summaries', { params: params });
  }

  searchByTransliteration({ locale = 'fr', search = '', type = 'entry' } = {}) {
    return axios.get('/entry-summaries', {
      params: {
        locale,
        pageNumber: 1,
        pageSize: 50,
        search,
        isTransliteration: true,
        isEnglish: false,
        isFrench: false,
        isDeutsch: false,
        isArabic: false,
        isComment: false,
        startWord: true,
        middleWord: false,
        endWord: false,
        type,
      },
    });
  }

  getSummary(entryId, locale = 'fr', type = 'entry') {
    return axios.get(`/entry-summaries/${entryId}?locale=${locale}&type=${type}`);
  }
}

export default new SearchDataService();
