import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { mergeRefs } from 'react-merge-refs';
import {
  autoUpdate,
  size,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
  flip,
} from '@floating-ui/react';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';

const Item = forwardRef(({ children, active, ...rest }, ref) => {
  const id = useId();

  return (
    <div
      ref={ref}
      role="option"
      id={id}
      className="menu-item"
      aria-selected={active}
      {...rest}
      style={{
        background: active ? 'rgba(0, 0, 0, 0.05)' : 'none',
        ...rest.style,
      }}
    >
      {children}
    </div>
  );
});

const NuanceAutocomplete = forwardRef(
  ({ isOpen, items, renderItem, children, onSelect }, propRef) => {
    const [open, setOpen] = useState(isOpen);
    const [activeIndex, setActiveIndex] = useState(null);
    const listRef = useRef([]);

    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    const { x, y, reference, floating, strategy, placement, context, refs } = useFloating({
      whileElementsMounted: autoUpdate,
      open,
      onOpenChange: setOpen,
      middleware: [
        flip({ padding: 10 }),
        size({
          apply({ rects, availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
              maxHeight: `${Math.min(availableHeight, 160)}px`,
            });
          },
          padding: 10,
        }),
      ],
    });

    useLayoutEffect(() => {
      // IMPORTANT: When the floating element first opens, this effect runs when
      // the styles have **not yet** been applied to the element. A rAF ensures
      // we wait until the position is ready, and also runs before paint.
      // https://floating-ui.com/docs/react-dom#effects
      requestAnimationFrame(() => {
        if (activeIndex != null) {
          listRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
        }
      });
    }, [activeIndex]);

    const ref = React.useMemo(() => mergeRefs([reference, propRef]), [reference, propRef]);

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true,
      }),
    ]);

    return (
      <>
        {React.cloneElement(
          children,
          getReferenceProps({
            ref,
            ...children.props,
            'data-state': open ? 'open' : 'closed',
          })
        )}
        <FloatingPortal>
          {open && (
            <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
              <div
                className="nuance-autocomplete-container"
                {...getFloatingProps({
                  ref: floating,
                  style: {
                    position: strategy,
                    left: x ?? 0,
                    top: y ?? 0,
                  },
                  'data-placement': placement,
                })}
              >
                {items.map((item, index) => (
                  <Item
                    {...getItemProps({
                      key: item,
                      ref(node) {
                        listRef.current[index] = node;
                      },
                      onMouseDown(evt) {
                        preventEventDefaultAndStopPropagation(evt);
                      },
                      onClick(evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        setOpen(false);
                        refs.reference.current?.focus();
                        onSelect(item);
                      },
                    })}
                    active={activeIndex === index}
                  >
                    {renderItem && renderItem(item)}
                    {isNil(renderItem) && item}
                  </Item>
                ))}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </>
    );
  }
);

NuanceAutocomplete.defaultProps = {
  items: [],
  isOpen: false,
  renderItem: null,
};

NuanceAutocomplete.propTypes = {
  items: PropTypes.array,
  isOpen: PropTypes.bool,
  renderItem: PropTypes.func,
};

export default NuanceAutocomplete;
