import PropTypes from 'prop-types';
import './Spinner.scss';

const Spinner = ({ size, thickness }) => {
  return (
    <svg className="spinner" viewBox="0 0 50 50" width={size} height={size}>
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth={thickness} />
    </svg>
  );
};

Spinner.defaultProps = {
  size: 50,
  thickness: 5,
};

Spinner.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
};

export default Spinner;
