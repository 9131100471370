import { noop } from 'lodash';
import { arrayMove } from '@dnd-kit/sortable';
import { createSlice } from '@reduxjs/toolkit';
import { canEditEntry, loadAuthUser, logout } from 'slices/auth';
import { lock as lockEntryUI } from 'slices/ui/entryLock';
import { isSelectionModeTriggeredOnEntry, close as closeThesaurus } from 'slices/ui/thesaurus';
import { setUnfetchableEntry } from 'slices/unfetchableEntries';

const HANDLERS = {};

const getEntryIdRegex = () => new RegExp(`^(${Object.keys(HANDLERS).join('|')})\\d+$`);
const isEntryId = (id) => getEntryIdRegex().test(id);
const getEntryIdPrefix = (id) => id.substring(0, 1);
const getEntryNumericId = (id) => parseInt(id.substring(1), 10);
const getEntryHandlers = (id) => {
  const idPrefix = getEntryIdPrefix(id);
  const numericId = getEntryNumericId(id);
  return [HANDLERS[idPrefix](numericId), numericId];
};

// ------------------------------------
// Selectors
// ------------------------------------

const getOpenEntryIds = (state) => state.openEntries.ids;

const isInitialEntriesLoaded = (state) => state.openEntries.isInitialEntriesLoaded;

const isEntryOpened = (state, id) => state.openEntries.ids.includes(id);

const isEntryEdited = (state, id) => state.openEntries.editedEntriesById[id];

// ------------------------------------
// Actions
// ------------------------------------

const openEntries = (ids) => {
  return async (dispatch) => {
    if (ids && ids.length > 0) {
      const reverseIds = [...ids].filter((id) => isEntryId(id)).reverse();
      await dispatch(loadAuthUser());
      await Promise.allSettled(reverseIds.map((id) => dispatch(openAndLoadEntry(id))));
    }
    return dispatch(finishLoadingInitialEntries());
  };
};

const openAndLoadEntry = (id, isEditVersion = false) => {
  return async (dispatch, getState) => {
    if (!isEntryOpened(getState(), id)) {
      const [handlers, numericId] = getEntryHandlers(id);

      dispatch(openEntry(id));

      if (isEditVersion) {
        return dispatch(genericStartEntryEdition(id, true));
      } else {
        const { error } = await dispatch(handlers.load(isEditVersion));
        if (error) {
          dispatch(closeEntry(id));
          if (!isEditVersion) {
            if (canEditEntry(getState())) {
              return dispatch(openAndLoadEntry(id, true));
            } else {
              return dispatch(setUnfetchableEntry(numericId));
            }
          }
        }
        return true;
      }
    }
  };
};

const genericStopEntryEditionAndReloadEntry = (id) => {
  return async (dispatch, getState) => {
    const [handlers, numericId] = getEntryHandlers(id);

    dispatch(genericStopEntryEdition(id));

    const { error } = await dispatch(handlers.reload());

    if (error) {
      dispatch(closeEntry(id));

      if (canEditEntry(getState())) {
        dispatch(openAndLoadEntry(id, true));
      } else {
        dispatch(setUnfetchableEntry(numericId));
      }
    }
    return true;
  };
};

const genericStartEntryEdition = (id, autoEditionStart = false) => {
  return async (dispatch) => {
    const [handlers] = getEntryHandlers(id);

    dispatch(handlers.reset());
    dispatch(startEntryEdition(id));

    const res = await dispatch(handlers.lock());

    if (res.type === handlers.lock.originalAction.rejected.type) {
      const { firstName, lastName } = res.payload;
      dispatch(lockEntryUI({ entryId: id, editor: `${firstName} ${lastName}` }));
      if (autoEditionStart) {
        dispatch(closeEntry(id, true, false));
      } else {
        dispatch(stopEntryEdition(id));
        dispatch(handlers.load());
      }
    } else {
      return dispatch(handlers.load(true));
    }
  };
};

const genericStopEntryEdition = (id, shouldUnlockEntry = true) => {
  return (dispatch, getState) => {
    const [handlers] = getEntryHandlers(id);

    if (shouldUnlockEntry) {
      dispatch(handlers.unlock());
    }
    if (isSelectionModeTriggeredOnEntry(getState(), id)) {
      dispatch(closeThesaurus());
    }
    dispatch(stopEntryEdition(id));
  };
};

const genericCloseEntry = (id, isEditVersion, shouldUnlockEntry) => {
  return (dispatch, getState) => {
    const [handlers] = getEntryHandlers(id);

    if (isEditVersion || isEntryEdited(getState(), id)) {
      dispatch(genericStopEntryEdition(id, shouldUnlockEntry));
    }
    if (isSelectionModeTriggeredOnEntry(getState(), id)) {
      dispatch(closeThesaurus());
    }
    dispatch(closeEntry(id));
    dispatch(handlers.reset());
  };
};

// ------------------------------------
// Register handlers
// ------------------------------------

function registerOpenHandlers(prefix, handlers) {
  const defaultHandlers = {
    load: noop,
    lock: noop,
    unlock: noop,
    reset: noop,
    reload: noop,
  };

  HANDLERS[prefix] = (numericId) =>
    Object.entries(handlers).reduce((acc, [key, handler]) => {
      const callback = (...args) => handler(numericId, ...args);
      callback.originalAction = handler;
      acc[key] = callback;
      return acc;
    }, defaultHandlers);

  return {
    actions: {
      openAndLoad: (id, isEditVersion) => openAndLoadEntry(`${prefix}${id}`, isEditVersion),
      close: (id) => genericCloseEntry(`${prefix}${id}`),
      startEdition: (id, autoEditionStart) =>
        genericStartEntryEdition(`${prefix}${id}`, autoEditionStart),
      stopEditionAndReload: (id) => genericStopEntryEditionAndReloadEntry(`${prefix}${id}`),
    },
    selectors: {
      isEdited: (state, id) => isEntryEdited(state, `${prefix}${id}`),
      isOpened: (state, id) => isEntryOpened(state, `${prefix}${id}`),
    },
    utils: {
      isOfType: (id) => getEntryIdPrefix(id) === prefix,
    },
  };
}

// ------------------------------------
// Slice
// ------------------------------------

const initialState = {
  ids: [],
  editedEntriesById: {},
  isInitialEntriesLoaded: false,
};

const reset = () => {
  return initialState;
};

const openEntriesSlice = createSlice({
  name: 'openEntries',
  initialState,
  reducers: {
    openEntry: (state, action) => {
      if (state.ids.includes(action.payload)) {
        return state;
      }
      state.ids = [action.payload, ...state.ids];
    },
    closeEntry: (state, action) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    startEntryEdition: (state, action) => {
      state.editedEntriesById[action.payload] = true;
    },
    stopEntryEdition: (state, action) => {
      delete state.editedEntriesById[action.payload];
    },
    finishLoadingInitialEntries: (state) => {
      state.isInitialEntriesLoaded = true;
    },
    reorderOpenEntries: (state, action) => {
      state.ids = arrayMove(state.ids, action.payload.oldIndex, action.payload.newIndex);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, reset);
  },
});

const {
  openEntry,
  closeEntry,
  startEntryEdition,
  stopEntryEdition,
  finishLoadingInitialEntries,
  reorderOpenEntries,
} = openEntriesSlice.actions;

const { reducer } = openEntriesSlice;

export {
  reducer,
  registerOpenHandlers,
  openEntries,
  reorderOpenEntries,
  getOpenEntryIds,
  isEntryOpened,
  isEntryEdited,
  getEntryNumericId,
  isInitialEntriesLoaded,
};
