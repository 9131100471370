import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEntryEditable } from 'slices/attributions';
import {
  getIndividualById,
  getIndividualLockUIEditor,
  INDIVIDUAL_PREFIX,
  isIndividualUILocked,
  unlockIndividual,
  unlockIndividualUI,
} from 'slices/individuals';
import EntryRead from 'components/Entry/EntryRead';
import IndividualReadContent from './IndividualReadContent';

const IndividualRead = ({ id, isHighlighted, onStartEdition, onClose }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) => getIndividualById(state, id));
  const isEditable = useSelector((state) => isEntryEditable(state, id));
  const showLockModal = useSelector((state) => isIndividualUILocked(state, id));
  const entryEditor = useSelector(getIndividualLockUIEditor);

  const handleUnlockIndividualUI = () => {
    dispatch(unlockIndividualUI());
  };

  const handleUnlockIndividual = () => {
    dispatch(unlockIndividual(id));
  };

  return (
    <EntryRead
      id={id}
      entry={entry}
      entryPrefix={INDIVIDUAL_PREFIX}
      isEditable={isEditable}
      isHighlighted={isHighlighted}
      showLockModal={showLockModal}
      entryEditor={entryEditor}
      onStartEdition={onStartEdition}
      onUnlock={handleUnlockIndividual}
      onUnlockUI={handleUnlockIndividualUI}
      onClose={onClose}
    >
      {entry && entry.published && (
        <IndividualReadContent publishableProp="published" entry={entry} />
      )}
    </EntryRead>
  );
};

IndividualRead.defaultProps = {
  isHighlighted: false,
};

IndividualRead.propTypes = {
  id: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool,
  onStartEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IndividualRead;
