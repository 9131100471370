import { createSlice } from '@reduxjs/toolkit';
import { constant } from 'lodash';

// ------------------------------------
// Register
// ------------------------------------

function registerHighlight(prefix) {
  return {
    actions: {
      highlight: (id) => highlight(`${prefix}${id}`),
      reset,
    },
    selectors: {
      isHighlighted: (state, id) => state.entryHighlight === `${prefix}${id}`,
    },
  };
}

// ------------------------------------
// Slice
// ------------------------------------

const initialState = null;

const entryHighlightSlice = createSlice({
  name: 'entryHighlight',
  initialState,
  reducers: {
    highlight: (state, action) => {
      return action.payload;
    },
    reset: constant(initialState),
  },
});

const { highlight, reset } = entryHighlightSlice.actions;

const { reducer } = entryHighlightSlice;

export { reducer, registerHighlight };
