import { Stack } from 'components/Layout';
import './Accordion.scss';

const Accordion = ({ children, summary, ...rest }) => {
  return (
    <div className="accordion" data-has-content={!!children}>
      <details {...rest}>
        <summary>{summary}</summary>
        {children && <Stack space="var(--s-1)">{children}</Stack>}
      </details>
    </div>
  );
};

export default Accordion;
