import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = React.forwardRef(({ children, type, theme, size, ...rest }, ref) => {
  return (
    <button type={type} className="button" data-theme={theme} data-size={size} {...rest} ref={ref}>
      {children}
    </button>
  );
});

Button.defaultProps = {
  theme: '',
  type: 'button',
  size: 'regular',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  size: PropTypes.oneOf(['small', 'regular', 'medium', 'large', 'full']),
  type: PropTypes.string,
};

export default Button;
