import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import VegaLogo from 'images/logo-vega.svg';
import { ReactComponent as AddIcon } from 'images/icon-add.svg';
import { canCreateEntry, getAuthenticatedUser } from 'slices/auth';
import { startWordCreation, WORD_PREFIX } from 'slices/words';
import { startTitleCreation, TITLE_PREFIX } from 'slices/titles';
import { INDIVIDUAL_PREFIX, startIndividualCreation } from 'slices/individuals';
import { isInSelectionMode, toggle as toggleThesaurus } from 'slices/ui/thesaurus';
import { isCreating } from 'slices/ui/entryCreation';
import { MenuItem } from 'components/Menu';
import DropdownMenu from 'components/DropdownMenu';
import Overlay from 'components/Overlay';
import Search from 'components/Search';
import Button from 'components/Button';
import AppLangMenu from './AppLangMenu';
import LoggedUser from './LoggedUser';
import './AppHeader.scss';

const vegaUrl = 'http://www.vega-vocabulaire-egyptien-ancien.fr/';

const AppHeader = () => {
  const { i18n, t } = useTranslation();
  const loggedUser = useSelector(getAuthenticatedUser);
  const showAddEntryButton = useSelector(canCreateEntry);
  const isCreateEntryButtonDisabled = useSelector(isCreating);
  const showSelectionModeOverlay = useSelector(isInSelectionMode);
  const dispatch = useDispatch();

  const handleCreateEntry = (entryType) => {
    if (entryType === WORD_PREFIX) {
      dispatch(startWordCreation());
    } else if (entryType === TITLE_PREFIX) {
      dispatch(startTitleCreation());
    } else if (entryType === INDIVIDUAL_PREFIX) {
      dispatch(startIndividualCreation());
    }
  };

  const handleToggleThesaurus = () => {
    dispatch(toggleThesaurus());
  };

  return (
    <div className="app-header" data-testid="app-header" dir={i18n.dir()}>
      <div className="app-header-logo">
        <a href={vegaUrl} target="_blank" rel="noreferrer">
          <img
            className="app-header-logo-image"
            title={t('pageTitle')}
            alt={t('pageTitle')}
            src={VegaLogo}
          />
          <div className="app-header-logo-baseline">{t('baseline')}</div>
        </a>
      </div>
      <div className="app-header-middle">
        <Search />
        {showAddEntryButton && (
          <DropdownMenu
            itemsCount={2}
            renderButton={(buttonProps) => (
              <button
                type="button"
                className="app-header-create-entry-button"
                disabled={isCreateEntryButtonDisabled}
                {...buttonProps}
              >
                <AddIcon />
              </button>
            )}
          >
            {[WORD_PREFIX, TITLE_PREFIX, INDIVIDUAL_PREFIX].map((entryTypePrefix) => (
              <MenuItem key={entryTypePrefix} onClick={() => handleCreateEntry(entryTypePrefix)}>
                {t(`createEntry.${entryTypePrefix}`)}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}
      </div>
      <div className="app-header-right">
        <Button theme="green" size="large" onClick={handleToggleThesaurus}>
          {t('thesaurus.label')}
        </Button>
        <AppLangMenu />
        <div className="app-header-separator" />
        {loggedUser && <LoggedUser user={loggedUser} />}
        {!loggedUser && (
          <div className="user-menu">
            <a
              className="link link--clear"
              href={`${window.location.origin.replace('app', 'accounts')}/login?redirectTo=${
                window.location.href
              }`}
              data-testid="login-button"
            >
              {t('user.login')}
            </a>
          </div>
        )}
      </div>
      {showSelectionModeOverlay && <Overlay zIndex="calc(var(--z-index-header) + 1)" />}
    </div>
  );
};

export default AppHeader;
