import { createSlice } from '@reduxjs/toolkit';
import { constant, get } from 'lodash';

// ------------------------------------
// Register
// ------------------------------------

function registerLock(prefix) {
  return {
    actions: {
      lock: ({ entryId, editor }) => lock({ entryId: `${prefix}${entryId}`, editor }),
      unlock,
    },
    selectors: {
      isLocked: (state, entryId) => get(state, 'entryLock.entryId', null) === `${prefix}${entryId}`,
      getEditor: (state) => get(state, 'entryLock.editor', ''),
    },
  };
}

// ------------------------------------
// Slice
// ------------------------------------

const initialState = null;

const entryLockSlice = createSlice({
  name: 'entryLock',
  initialState,
  reducers: {
    lock: (state, action) => {
      return action.payload;
    },
    unlock: constant(initialState),
  },
});

const { lock, unlock } = entryLockSlice.actions;

const { reducer } = entryLockSlice;

export { reducer, registerLock, lock };
