import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createIndividualName, getIndividualNameIdsByIndividualId } from 'slices/individualNames';
import { ReactComponent as AddIcon } from 'images/icon-add.svg';
import { Cluster, Stack } from 'components/Layout';
import Button from 'components/Button';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualNameEdition from './IndividualNameEdition';
import IndividualNameCreation from './IndividualNameCreation';

const IndividualNamesEdition = ({ individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const individualNameIds = useSelector((state) =>
    getIndividualNameIdsByIndividualId(state, individualId)
  );

  const handleSubmit = (data, graphyFile) => {
    dispatch(createIndividualName({ individualId, data, graphyFile }));
    setIsCreating(false);
  };

  return (
    <EntryTabContentLayout title={t('individualNames.title')}>
      <Stack space="var(--s-1)">
        <Cluster justify="flex-end">
          <Button
            disabled={isCreating}
            onClick={() => setIsCreating(true)}
            data-testid="add-name-to-individual-button"
          >
            <AddIcon />
          </Button>
        </Cluster>

        {isCreating && (
          <IndividualNameCreation onSubmit={handleSubmit} onCancel={() => setIsCreating(false)} />
        )}

        {individualNameIds.map((titleId) => (
          <IndividualNameEdition key={titleId} id={titleId} individualId={individualId} />
        ))}
      </Stack>
    </EntryTabContentLayout>
  );
};

IndividualNamesEdition.propTypes = {
  individualId: PropTypes.number.isRequired,
};

export default IndividualNamesEdition;
