import { EditorState, ContentState, convertFromHTML, SelectionState, Modifier } from 'draft-js';
import { isNil } from 'lodash';

export function removeEntity(editorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const startKey = selectionState.getStartKey();
  const contentBlock = contentState.getBlockForKey(startKey);
  const startOffset = selectionState.getStartOffset();
  const entity = contentBlock.getEntityAt(startOffset);

  if (!entity) {
    return editorState;
  }

  let entitySelection = null;

  contentBlock.findEntityRanges(
    (character) => character.getEntity() === entity,
    (start, end) => {
      entitySelection = selectionState.merge({
        anchorOffset: start,
        focusOffset: end,
        isBackward: false,
      });
    }
  );

  const newContentState = Modifier.applyEntity(contentState, entitySelection, null);

  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');

  return newEditorState;
}

export const getBlockAtSelectionStart = (editorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  return contentState.getBlockForKey(startKey);
};

export const getPreviousCharactersAtSelection = (editorState, numberOfChars = 1) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  return contentState
    .getBlockForKey(startKey)
    .getText()
    .slice(startOffset - numberOfChars, startOffset);
};

export const isSelectionCollapsed = (editorState) => {
  return editorState.getSelection().isCollapsed();
};

export const hasChanged = (editorState, newEditorState) => {
  return (
    !isNil(newEditorState.getLastChangeType()) &&
    !Object.is(editorState.getCurrentContent(), newEditorState.getCurrentContent())
  );
};

export const isNotEmpty = (editorState) => {
  return (
    editorState.getCurrentContent().hasText() &&
    editorState.getCurrentContent().getPlainText().trim() !== ''
  );
};

export const isEmpty = (editorState) => !isNotEmpty(editorState);

export const createEditorStateFromString = (text, customDecorators) => {
  const blocksFromHTML = convertFromHTML(text);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(state, customDecorators);
};

export const setCursorAtTheEnd = (editorState, hasFocus = false) => {
  const newSelectionState = SelectionState.createEmpty();
  const keys = editorState.getCurrentContent().getBlocksAsArray();

  return newSelectionState.merge({
    anchorOffset: keys[keys.length - 1].getText().length,
    focusOffset: keys[keys.length - 1].getText().length,
    anchorKey: keys[keys.length - 1].getKey(),
    focusKey: keys[keys.length - 1].getKey(),
    hasFocus,
  });
};

export const getAllSelection = (editorState) => {
  const newSelectionState = SelectionState.createEmpty();
  const keys = editorState.getCurrentContent().getBlocksAsArray();

  return newSelectionState.merge({
    anchorOffset: 0,
    focusOffset: keys[keys.length - 1].getText().length,
    anchorKey: keys[0].getKey(),
    focusKey: keys[keys.length - 1].getKey(),
    hasFocus: true,
  });
};

export const getSelectedBlocksMap = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
};

export const getSelectedBlocksList = (editorState) => {
  return getSelectedBlocksMap(editorState).toList();
};

export const getSelectionInlineStyle = (editorState) => {
  const styles = ['BOLD', 'ITALIC', 'UNDERLINE', 'TRANSLITERATION'];
  const currentSelection = editorState.getSelection();
  if (currentSelection.isCollapsed()) {
    const inlineStyles = {};
    const styleList = editorState.getCurrentInlineStyle().toList().toJS();
    if (styleList) {
      styles.forEach((style) => {
        inlineStyles[style] = styleList.indexOf(style) >= 0;
      });
      return inlineStyles;
    }
  }
  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();
  const selectedBlocks = getSelectedBlocksList(editorState);
  if (selectedBlocks.size > 0) {
    const inlineStyles = {
      BOLD: true,
      ITALIC: true,
      UNDERLINE: true,
      TRANSLITERATION: true,
    };
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      let blockStart = i === 0 ? start : 0;
      let blockEnd = i === selectedBlocks.size - 1 ? end : selectedBlocks.get(i).getText().length;
      if (blockStart === blockEnd && blockStart === 0) {
        blockStart = 1;
        blockEnd = 2;
      } else if (blockStart === blockEnd) {
        blockStart -= 1;
      }
      for (let j = blockStart; j < blockEnd; j += 1) {
        const inlineStylesAtOffset = selectedBlocks.get(i).getInlineStyleAt(j);
        styles.forEach((style) => {
          inlineStyles[style] = inlineStyles[style] && inlineStylesAtOffset.get(style) === style;
        });
      }
    }
    return inlineStyles;
  }
  return {};
};

const getSelectionEntityProperty = (property) => (editorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startOffset = selection.getStartOffset();
  const blockWithLinkAtBeginning = getBlockAtSelectionStart(editorState);
  const entryLinkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

  if (entryLinkKey) {
    const entryLinkInstance = contentState.getEntity(entryLinkKey);
    return entryLinkInstance.getData()[property];
  }
  return undefined;
};

export const getSelectionEntryId = getSelectionEntityProperty('entryId');
export const getSelectionTitleId = getSelectionEntityProperty('titleId');
export const getSelectionIndividualId = getSelectionEntityProperty('individualId');

export const clearInlineStyles = (editorState) => {
  const styles = ['SELECTED'];
  const currentselection = editorState.getSelection();
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(newContentState, getAllSelection(editorState), style),
    editorState.getCurrentContent()
  );

  return EditorState.forceSelection(
    EditorState.push(editorState, contentWithoutStyles, 'change-inline-style'),
    currentselection
  );
};

export const normalizeEditorState = (editorState) => {
  return EditorState.acceptSelection(
    clearInlineStyles(editorState),
    setCursorAtTheEnd(editorState)
  );
};

const findFocusedCharacters = (editorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const endOffset = selection.isCollapsed()
    ? selection.getEndOffset() + 1
    : selection.getEndOffset();

  return contentState
    .getBlockForKey(selection.getFocusKey())
    .getCharacterList()
    .slice(selection.getStartOffset(), endOffset);
};

const findFocusedEntity = (editorState) => {
  const characterMetaData = findFocusedCharacters(editorState).first();

  if (characterMetaData) {
    return characterMetaData.getEntity();
  }
  return null;
};

const isFocusOnEntityType = (entityType) => (editorState) => {
  const contentState = editorState.getCurrentContent();
  const entityMap = contentState.getEntityMap();

  return findFocusedCharacters(editorState).every((v) => {
    const entity = v.getEntity();
    return !!entity && entityMap.__get(entity).getType() === entityType;
  });
};

export const isFocusOnEntryLink = isFocusOnEntityType('ENTRY_LINK');
export const isFocusOnTitleLink = isFocusOnEntityType('TITLE_LINK');
export const isFocusOnIndividualLink = isFocusOnEntityType('INDIVIDUAL_LINK');

export const getFocusedEntityKey = (editorState) => {
  const entity = findFocusedEntity(editorState);

  if (entity) {
    return entity;
  }

  return null;
};

const getFocusedEntityPropery = (property) => (editorState) => {
  const contentState = editorState.getCurrentContent();
  const entityMap = contentState.getEntityMap();
  const entity = findFocusedEntity(editorState);

  if (entity) {
    return entityMap.__get(entity).getData()[property];
  }

  return '';
};

export const getFocusedEntryId = getFocusedEntityPropery('entryId');
export const getFocusedTitleId = getFocusedEntityPropery('titleId');
export const getFocusedIndividualId = getFocusedEntityPropery('individualId');

export const getFocusedEntityRange = (editorState, entityKey) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  let range;

  contentState.getBlockForKey(selection.getFocusKey()).findEntityRanges(
    (value) => value.getEntity() === entityKey,
    (start, end) => {
      range = [start, end];
    }
  );

  return range;
};

export function getSelectionAsText(editorState, blockDelimiter = '\n') {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blocks = currentContent.getBlockMap();
  let lastWasEnd = false;

  const selectedBlock = blocks
    .skipUntil((block) => block.getKey() === startKey)
    .takeUntil((block) => {
      const result = lastWasEnd;
      if (block.getKey() === endKey) {
        lastWasEnd = true;
      }
      return result;
    });

  return selectedBlock
    .map((block) => {
      const key = block.getKey();
      const text = block.getText();
      const start = key === startKey ? selection.getStartOffset() : 0;
      const end = key === endKey ? selection.getEndOffset() : text.length;
      return text.slice(start, end);
    })
    .join(blockDelimiter);
}
