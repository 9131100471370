import { CompositeDecorator } from 'draft-js';
import TlaLinkDecorator from './TlaLinkDecorator';
import PnmLinkDecorator from './PnmLinkDecorator';
import EntryLinkDecorator from './EntryLinkDecorator';
import TitleLinkDecorator from './TitleLinkDecorator';
import IndividualLinkDecorator from './IndividualLinkDecorator';

const findLinkEntities = (entryType) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === entryType;
  }, callback);
};

const findEntryLinkEntities = findLinkEntities('ENTRY_LINK');
const findTitleLinkEntities = findLinkEntities('TITLE_LINK');
const findIndividualLinkEntities = findLinkEntities('INDIVIDUAL_LINK');

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    const start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const numberRegex = /\d+/gm;

function findNumericId(enable) {
  return (contentBlock, callback) => {
    if (enable) {
      findWithRegex(numberRegex, contentBlock, callback);
    }
  };
}

export const generateDecorators = ({
  enableTlaDecorator = false,
  enablePnmDecorator = false,
} = {}) => {
  return new CompositeDecorator([
    {
      strategy: findEntryLinkEntities,
      component: EntryLinkDecorator,
    },
    {
      strategy: findTitleLinkEntities,
      component: TitleLinkDecorator,
    },
    {
      strategy: findIndividualLinkEntities,
      component: IndividualLinkDecorator,
    },
    {
      strategy: findNumericId(enableTlaDecorator),
      component: TlaLinkDecorator,
    },
    {
      strategy: findNumericId(enablePnmDecorator),
      component: PnmLinkDecorator,
    },
  ]);
};
