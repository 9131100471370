import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from 'images/icon-cross.svg';
import './Tag.scss';

const Tag = ({ children, theme, onDelete }) => {
  return (
    <div className="tag" data-deleteable={!!onDelete} data-theme={theme}>
      {children}
      {onDelete && (
        <button type="button" onClick={onDelete}>
          <CrossIcon />
        </button>
      )}
    </div>
  );
};

Tag.defaultProps = {
  onDelete: null,
  theme: 'default',
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['default', 'green']),
  onDelete: PropTypes.func,
};

export default Tag;
