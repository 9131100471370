import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEntryEditable } from 'slices/attributions';
import {
  getTitleById,
  unlockTitle,
  TITLE_PREFIX,
  isTitleUILocked,
  unlockTitleUI,
  getTitleLockUIEditor,
} from 'slices/titles';
import EntryRead from 'components/Entry/EntryRead';
import TitleReadContent from './TitleReadContent';

const TitleRead = ({ id, isHighlighted, onStartEdition, onClose }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) => getTitleById(state, id));
  const isEditable = useSelector((state) => isEntryEditable(state, id));
  const showLockModal = useSelector((state) => isTitleUILocked(state, id));
  const entryEditor = useSelector(getTitleLockUIEditor);

  const handleUnlockTitleUI = () => {
    dispatch(unlockTitleUI());
  };

  const handleUnlockTitle = () => {
    dispatch(unlockTitle(id));
  };

  return (
    <EntryRead
      id={id}
      entry={entry}
      entryPrefix={TITLE_PREFIX}
      isEditable={isEditable}
      isHighlighted={isHighlighted}
      showLockModal={showLockModal}
      entryEditor={entryEditor}
      onStartEdition={onStartEdition}
      onUnlock={handleUnlockTitle}
      onUnlockUI={handleUnlockTitleUI}
      onClose={onClose}
    >
      {entry && entry.published && <TitleReadContent publishableProp="published" entry={entry} />}
    </EntryRead>
  );
};

TitleRead.defaultProps = {
  isHighlighted: false,
};

TitleRead.propTypes = {
  id: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool,
  onStartEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TitleRead;
