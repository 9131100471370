import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAttestationIdsByUniqueNuances, getNuanceIdsByEntryId } from 'slices/nuances';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import Nuance from './Nuance';
import './Nuances.scss';

const Nuances = ({ entryId, publishableProp, ...rest }) => {
  const { t, i18n } = useTranslation();
  const nuanceIds = useSelector((state) => getNuanceIdsByEntryId(state, entryId));
  const uniqueNuances = useSelector((state) =>
    getAttestationIdsByUniqueNuances(state, nuanceIds, publishableProp)
  );

  return (
    <EntryTabContentLayout title={t('nuances.title')} noBodyTopSpace>
      {uniqueNuances &&
        Object.entries(uniqueNuances).map(([description, attestations]) => (
          <Nuance
            key={description}
            description={description}
            attestations={attestations}
            publishableProp={publishableProp}
            dir={i18n.dir()}
            {...rest}
          />
        ))}
    </EntryTabContentLayout>
  );
};

Nuances.propTypes = {
  entryId: PropTypes.number.isRequired,
  publishableProp: PropTypes.oneOf(['edited', 'published']).isRequired,
};

export default Nuances;
