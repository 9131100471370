import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash/fp';
import { noop } from 'lodash';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SearchDataService from 'services/SearchDataService';
import { makeCancelable } from 'utils/util';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import Select from 'components/Select';
import { Stack } from 'components/Layout';
import IndividualTitleEdition from './IndividualTitleEdition';
import SortableIndividualTitleEdition from './SortableIndividualTitleEdition';

const renderTitle = (title) => {
  return (
    <div
      className="link-editor-autocomplete-item select-titles-item"
      data-published={title.published}
      onMouseDown={preventEventDefaultAndStopPropagation}
    >
      <div dangerouslySetInnerHTML={{ __html: title.transliteration }} />
      <div dangerouslySetInnerHTML={{ __html: title.mainNuance }} />
    </div>
  );
};

let cancelablePromise;

const IndividualTitlesSection = ({ title, titularyIds, onAdd, onRemove, onReorder }) => {
  const { i18n, t } = useTranslation();
  const [searchedTitles, setSearchedTitles] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (evt) => {
    const { active } = evt;
    setActiveId(active.id);
  };

  const handleDragEnd = (evt) => {
    const { active, over } = evt;

    if (active.id !== over.id) {
      const oldIndex = titularyIds.indexOf(active.id);
      const newIndex = titularyIds.indexOf(over.id);
      onReorder({ oldIndex, newIndex });
    }

    setActiveId(null);
  };

  const handleSelectChange = useCallback(
    (search) => {
      cancelablePromise = makeCancelable(
        SearchDataService.searchByTransliteration({
          locale: i18n.resolvedLanguage,
          search,
          type: 'title',
        })
      );
      cancelablePromise.promise
        .then(({ data }) => {
          setSearchedTitles(data.content);
        })
        .catch((reason) => {
          if (!reason.isCanceled) {
            setSearchedTitles([]);
          }
        });
    },
    [i18n.resolvedLanguage]
  );

  useEffect(() => {
    return function cleanup() {
      if (cancelablePromise) {
        cancelablePromise.cancel();
      }
    };
  }, []);

  return (
    <div className="individual-titles-section">
      <Stack space="var(--s-3)">
        <div className="individual-titles-section-label">{title}</div>
        <div data-publishable="false">
          <Select
            placeholder={t('individualTitles.addTitle')}
            items={searchedTitles}
            onChange={handleSelectChange}
            onSelect={onAdd}
            renderItem={renderTitle}
            idGetter={get('id')}
          />
        </div>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={titularyIds} strategy={verticalListSortingStrategy}>
              {titularyIds.map((titularyId) => (
                <SortableIndividualTitleEdition
                  key={titularyId}
                  id={titularyId}
                  onRemove={onRemove}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeId && <IndividualTitleEdition id={activeId} isDragOverlay onRemove={noop} />}
            </DragOverlay>
          </DndContext>
        </div>
      </Stack>
    </div>
  );
};

IndividualTitlesSection.propTypes = {
  title: PropTypes.string.isRequired,
  titularyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
};

export default IndividualTitlesSection;
