import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTitleHolder,
  getTitleHolderById,
  isTitleHolderPublishable,
  updateTitleHolder,
} from 'slices/titleHolders';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import Transliteration from 'components/Transliteration';
import EntryNuance from 'components/Entry/EntryNuance';
import { Cluster, Stack } from 'components/Layout';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Checkbox from 'components/Checkbox';
import './GraphyAttestationTitleHolder.scss';
import usePublishable from 'hooks/usePublishable';

const GraphyAttestationTitleHolder = ({ titleHolderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleHolder = useSelector((state) => getTitleHolderById(state, titleHolderId));
  const isNew = useSelector((state) => isTitleHolderPublishable(state, titleHolderId));
  const [isPublishable] = usePublishable(titleHolder);

  const handleUpdateIsDateDisplayed = useCallback(
    (evt) => {
      dispatch(
        updateTitleHolder({
          id: titleHolderId,
          data: { edited: { isDateDisplayed: evt.target.checked } },
        })
      );
    },
    [dispatch, titleHolderId]
  );

  const handleRemoveTitleHolder = useCallback(() => {
    dispatch(deleteTitleHolder(titleHolderId));
  }, [dispatch, titleHolderId]);

  return (
    <div
      className="graphy-attestation-title-holder"
      data-publishable={isNew || isPublishable('isDateDisplayed')}
    >
      <Stack space="var(--s-3)">
        <Cluster space="var(--s-1)" justify="space-between">
          <Tag>{t(`individual.nuanceTypes.${titleHolder.individual.mainNuanceType}`)}</Tag>
          <IconButton
            onMouseDown={preventEventDefaultAndStopPropagation}
            onClick={handleRemoveTitleHolder}
            theme="transparent"
            data-testid="remove-title-holder-from-attestation-button"
          >
            <Icon size={22}>
              <TrashIcon />
            </Icon>
          </IconButton>
        </Cluster>
        <h2 className="graphy-attestation-title-holder-transliteration">
          <Transliteration>
            {titleHolder.individual.transliteration}
            {!titleHolder.individual.published && (
              <span className="graphy-attestation-title-holder-unpublished-label">
                {' '}
                ({t('words.unpublished')})
              </span>
            )}
          </Transliteration>
        </h2>
        <EntryNuance nuances={titleHolder.individual.mainNuances} />
        <Checkbox
          name="isDateDisplayed"
          size="small"
          labelPlacement="right"
          label={t('titleHolders.displayDate')}
          checked={titleHolder.edited.isDateDisplayed}
          onChange={handleUpdateIsDateDisplayed}
        />
      </Stack>
    </div>
  );
};

GraphyAttestationTitleHolder.propTypes = {
  titleHolderId: PropTypes.number.isRequired,
};

export default GraphyAttestationTitleHolder;
