import commentsFactory from './commentsFactory';
import CommentsDataService from 'services/CommentsDataService';

const commentsDataService = new CommentsDataService('entryId');

const extraReducers = {
  'word/delete/fulfilled': (state, action) => {
    delete state.byEntryId[action.payload.entryId];
  },
};

const { reducer, actions, selectors } = commentsFactory(
  'wordComments',
  'entry',
  extraReducers,
  commentsDataService
);

const {
  loadComment,
  unpublishEntryComment,
  publishEntryComment,
  createOrUpdateComment,
  resetCommentOfEntry,
} = actions;

export {
  reducer,
  loadComment,
  unpublishEntryComment,
  publishEntryComment,
  createOrUpdateComment,
  resetCommentOfEntry,
  selectors,
};
