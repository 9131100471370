import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TooltipContent, TooltipManager, TooltipTrigger } from 'components/Tooltip';
import Icon from 'components/Icon';
import { ReactComponent as NullIcon } from 'images/icon-edition-status-null.svg';
import { ReactComponent as ProcessedIcon } from 'images/icon-edition-status-processed.svg';
import { ReactComponent as DoneIcon } from 'images/icon-edition-status-done.svg';
import './EntryEditionStatus.scss';

const statusIcons = {
  null: <NullIcon />,
  processed: <ProcessedIcon />,
  done: <DoneIcon />,
};

function EntryEditionStatus({ status, hasTooltip }) {
  const { i18n, t } = useTranslation();

  if (!hasTooltip) {
    return (
      <div className="entry-edition-status" data-status={status} data-testid="entry-edition-status">
        <Icon>{statusIcons[status]}</Icon>
      </div>
    );
  }

  return (
    <TooltipManager>
      <TooltipTrigger>
        <div
          className="entry-edition-status"
          data-status={status}
          data-testid="entry-edition-status"
        >
          <Icon isInteractive>{statusIcons[status]}</Icon>
        </div>
      </TooltipTrigger>
      <TooltipContent dir={i18n.dir()}>{t(`entry.editionStatus.tooltip.${status}`)}</TooltipContent>
    </TooltipManager>
  );
}

EntryEditionStatus.defaultProps = {
  hasTooltip: false,
  status: null,
};

EntryEditionStatus.propTypes = {
  status: PropTypes.oneOf([null, 'processed', 'done']),
  hasTooltip: PropTypes.bool,
};

export default EntryEditionStatus;
