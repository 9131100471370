import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  createIndividualSequence,
  getIndividualSequenceIdsByIndividualId,
} from 'slices/individualSequences';
import Button from 'components/Button';
import EntryTabContentLayout from 'components/Entry/EntryTabContentLayout';
import IndividualSequenceEdition from './IndividualSequenceEdition';
import IndividualSequenceCreation from './IndividualSequenceCreation';
import './IndividualSequences.scss';

const IndividualSequencesEdition = ({ individualId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const individualSequenceIds = useSelector((state) =>
    getIndividualSequenceIdsByIndividualId(state, individualId)
  );

  const handleSubmit = (data) => {
    dispatch(createIndividualSequence({ individualId, data }));
    setIsCreating(false);
  };

  return (
    <EntryTabContentLayout title={t('individualSequences.title')} noBodySpace>
      {individualSequenceIds.length > 0 && (
        <div className="individual-sequences">
          {individualSequenceIds.map((titleId) => (
            <IndividualSequenceEdition key={titleId} id={titleId} individualId={individualId} />
          ))}
        </div>
      )}

      {isCreating && (
        <IndividualSequenceCreation onSubmit={handleSubmit} onCancel={() => setIsCreating(false)} />
      )}

      <Button
        disabled={isCreating}
        size="full"
        onClick={() => setIsCreating(true)}
        data-testid="add-sequence-to-individual-button"
      >
        + {t('individualSequences.addSequence')}
      </Button>
    </EntryTabContentLayout>
  );
};

IndividualSequencesEdition.propTypes = {
  individualId: PropTypes.number.isRequired,
};

export default IndividualSequencesEdition;
