import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_SAVE_DELAY_MS = 400;

const useAutoSave = (onSave, saveDelay = DEBOUNCE_SAVE_DELAY_MS) => {
  const [isSaving, setIsSaving] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      (newData) => {
        if (!isSaving) {
          setIsSaving(true);
          const saveFunc = onSave(newData);
          if (saveFunc && saveFunc.finally) {
            saveFunc.finally(() => setIsSaving(false));
          } else {
            setIsSaving(false);
          }
        }
      },
      saveDelay,
      { leading: false, trailing: true }
    ),
    [onSave, isSaving]
  );

  useEffect(() => {
    return function cleanup() {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return [debouncedSave, isSaving];
};

export default useAutoSave;
