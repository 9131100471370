import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import MiniMapEntry from './MiniMapEntry';

const SortableMiniMapEntry = ({ id, rank, entrySelector, onMouseEnter, onMouseLeave }) => {
  const {
    isDragging,
    isSorting,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <MiniMapEntry
      ref={setNodeRef}
      style={style}
      {...attributes}
      listeners={listeners}
      handleProps={{ ref: setActivatorNodeRef }}
      id={id}
      rank={rank}
      isDragging={isDragging}
      isSorting={isSorting}
      entrySelector={entrySelector}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default SortableMiniMapEntry;
