import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { canUnlockEntry } from 'slices/auth';
import { WORD_PREFIX } from 'slices/words';
import { TITLE_PREFIX } from 'slices/titles';
import { INDIVIDUAL_PREFIX } from 'slices/individuals';
import { ReactComponent as LockIcon } from 'images/icon-lock.svg';
import { Cluster, Stack } from 'components/Layout';
import Button from 'components/Button';
import Overlay from 'components/Overlay';
import EntryReadToolbar from './EntryReadToolbar';
import './EntryRead.scss';

const EntryRead = ({
  id,
  children,
  entry,
  entryPrefix,
  isEditable,
  isHighlighted,
  showLockModal,
  entryEditor,
  onStartEdition,
  onUnlock,
  onUnlockUI,
  onClose,
}) => {
  const { t } = useTranslation();
  const showUnlockEntryButton = useSelector(canUnlockEntry);

  return (
    <div
      className={classNames('entry', 'entry-read', {
        'is-highlighted': isHighlighted,
      })}
      data-testid="entry-read"
    >
      <EntryReadToolbar
        entry={entry}
        entryPrefix={entryPrefix}
        isEditable={isEditable}
        onStartEdition={onStartEdition}
        onClose={onClose}
      />
      <div className="entry-content">{children}</div>
      {showLockModal && (
        <>
          <Overlay />
          <div className="entry-locked-modal">
            <Stack space="var(--s0)">
              <Cluster
                space="var(--s-2)"
                align="center"
                justify="center"
                className="entry-locked-modal-title"
              >
                <LockIcon />
                <span>ID {id}</span>
              </Cluster>
              <div>{t('entry.lockMessage', { editor: entryEditor })}</div>
              <Button onClick={onUnlockUI}>OK</Button>
              {showUnlockEntryButton && (
                <button
                  type="button"
                  className="entry-locked-modal-unlock-button"
                  onClick={onUnlock}
                >
                  {t('entry.unlockEntry')}
                </button>
              )}
            </Stack>
          </div>
        </>
      )}
    </div>
  );
};

EntryRead.defaultProps = {
  children: null,
  entry: null,
  isHighlighted: false,
  isEditable: false,
  showLockModal: false,
  entryEditor: '?',
};

EntryRead.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node,
  entry: PropTypes.object,
  entryPrefix: PropTypes.oneOf([WORD_PREFIX, TITLE_PREFIX, INDIVIDUAL_PREFIX]).isRequired,
  isEditable: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  showLockModal: PropTypes.bool,
  entryEditor: PropTypes.string,
  onStartEdition: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
  onUnlockUI: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EntryRead;
