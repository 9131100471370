import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { getOpenEntryIds, reorderOpenEntries } from 'slices/openEntries';
import { getWordById, highlightWord, isWord, resetWordHihglight } from 'slices/words';
import { getTitleById, highlightTitle, isTitle, resetTitleHihglight } from 'slices/titles';
import {
  getIndividualById,
  highlightIndividual,
  isIndividual,
  resetIndividualHihglight,
} from 'slices/individuals';
import SortableMiniMapEntry from './SortableMiniMapEntry';
import MiniMapEntry from './MiniMapEntry';
import './MiniMap.scss';

const MiniMap = () => {
  const dispatch = useDispatch();
  const entryIds = useSelector(getOpenEntryIds);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleMouseEnterOnWord = (id) => {
    dispatch(highlightWord(id));
  };

  const handleMouseLeaveOnWord = () => {
    dispatch(resetWordHihglight());
  };

  const handleMouseEnterOnTitle = (id) => {
    dispatch(highlightTitle(id));
  };

  const handleMouseLeaveOnTitle = () => {
    dispatch(resetTitleHihglight());
  };

  const handleMouseEnterOnIndividual = (id) => {
    dispatch(highlightIndividual(id));
  };

  const handleMouseLeaveOnIndividual = () => {
    dispatch(resetIndividualHihglight());
  };

  const handleDragStart = (evt) => {
    const { active } = evt;
    setActiveId(active.id);
  };

  const handleDragEnd = (evt) => {
    const { active, over } = evt;

    if (active.id !== over.id) {
      const oldIndex = entryIds.indexOf(active.id);
      const newIndex = entryIds.indexOf(over.id);
      dispatch(reorderOpenEntries({ oldIndex, newIndex }));
    }

    setActiveId(null);
  };

  if (entryIds.length === 0) {
    return null;
  }

  return (
    <div className="mini-map-container">
      <div className="mini-map">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={entryIds} strategy={horizontalListSortingStrategy}>
            {entryIds.map((id, index) => {
              if (isWord(id)) {
                return (
                  <SortableMiniMapEntry
                    key={id}
                    id={id}
                    rank={index}
                    entrySelector={getWordById}
                    onMouseEnter={handleMouseEnterOnWord}
                    onMouseLeave={handleMouseLeaveOnWord}
                  />
                );
              } else if (isTitle(id)) {
                return (
                  <SortableMiniMapEntry
                    key={id}
                    id={id}
                    rank={index}
                    entrySelector={getTitleById}
                    onMouseEnter={handleMouseEnterOnTitle}
                    onMouseLeave={handleMouseLeaveOnTitle}
                  />
                );
              } else if (isIndividual(id)) {
                return (
                  <SortableMiniMapEntry
                    key={id}
                    id={id}
                    rank={index}
                    entrySelector={getIndividualById}
                    onMouseEnter={handleMouseEnterOnIndividual}
                    onMouseLeave={handleMouseLeaveOnIndividual}
                  />
                );
              }
              return null;
            })}
          </SortableContext>
          <DragOverlay>
            {activeId && isWord(activeId) ? (
              <MiniMapEntry
                id={activeId}
                isDragOverlay
                entrySelector={getWordById}
                onMouseEnter={handleMouseEnterOnWord}
                onMouseLeave={handleMouseLeaveOnWord}
              />
            ) : null}
            {activeId && isTitle(activeId) ? (
              <MiniMapEntry
                id={activeId}
                isDragOverlay
                entrySelector={getTitleById}
                onMouseEnter={handleMouseEnterOnTitle}
                onMouseLeave={handleMouseLeaveOnTitle}
              />
            ) : null}
            {activeId && isIndividual(activeId) ? (
              <MiniMapEntry
                id={activeId}
                isDragOverlay
                entrySelector={getIndividualById}
                onMouseEnter={handleMouseEnterOnIndividual}
                onMouseLeave={handleMouseLeaveOnIndividual}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
};

export default MiniMap;
