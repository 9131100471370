import { useCallback } from 'react';

const useEvent = (callback, { preventDefault = false, stopPropagation = false } = {}) =>
  useCallback(
    (evt, ...args) => {
      if (preventDefault) {
        evt.preventDefault();
      }
      if (stopPropagation) {
        evt.stopPropagation();
      }
      callback(...args);
    },
    [callback, preventDefault, stopPropagation]
  );

export default useEvent;
