import React from 'react';
import PropTypes from 'prop-types';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import Menu, { MENU_THEMES } from 'components/Menu';
import './DropdownMenu.scss';

function DropdownMenu({
  itemsCount,
  theme,
  position,
  renderButton,
  separatorIndexes,
  ignoreSelectors,
  children,
  ...props
}) {
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(itemsCount);

  return (
    <div className="dropdown-menu" data-open={isOpen} data-position={position} {...props}>
      {renderButton(buttonProps)}
      {isOpen && (
        <div
          onClick={(evt) => {
            if (!ignoreSelectors || !evt.target.closest(ignoreSelectors)) {
              setIsOpen(false);
            }
          }}
        >
          <Menu theme={theme}>
            {React.Children.map(children, (child, index) => (
              <div {...itemProps[index]} data-separator={separatorIndexes.includes(index)}>
                {child}
              </div>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
}

DropdownMenu.defaultProps = {
  theme: MENU_THEMES.light,
  position: 'left',
  separatorIndexes: [],
  ignoreSelectors: '',
};

DropdownMenu.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  ignoreSelectors: PropTypes.string,
  separatorIndexes: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.oneOf(Object.values(MENU_THEMES)),
  position: PropTypes.oneOf(['left', 'right']),
  renderButton: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DropdownMenu;
