import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Stack.scss';

const Stack = ({ children, space, className, ...rest }) => {
  return (
    <div className={classNames('stack', className)} style={{ '--space': space }} {...rest}>
      {children}
    </div>
  );
};

Stack.defaultProps = {
  className: null,
  space: null,
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  space: PropTypes.string,
};

export default Stack;
