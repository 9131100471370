import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cluster, Stack } from 'components/Layout';
import { ReactComponent as BackArrowIcon } from 'images/icon-arrow-back.svg';
import { ReactComponent as AddIcon } from 'images/icon-add.svg';
import { ReactComponent as EditIcon } from 'images/icon-edit.svg';
import { isNotEmptyHtml } from 'utils/util';
import { KARNAK_URL } from 'utils/constants';
import {
  getInteractionModeTriggeredGraphyId,
  getInteractionModeTriggeredSequenceId,
  isInSelectionMode,
  isSelectionModeTriggeredForSequenceAttestation,
  isSelectionModeTriggeredForTitleGraphyAttestation,
  isSelectionModeTriggeredForWordGraphyAttestation,
} from 'slices/ui/thesaurus';
import { openAndLoadWord } from 'slices/words';
import {
  deleteAttestation,
  getAttestationIdsBySourceId,
  loadAttestations,
} from 'slices/attestations';
import { deleteSource, getSourceById } from 'slices/sources';
import { addAttestationToSequence } from 'slices/individualSequences';
import { openAndLoadTitle } from 'slices/titles';
import { openAndLoadIndividual } from 'slices/individuals';
import { addAttestationToGraphy as addAttestationToWordGraphy } from 'slices/wordGraphies';
import { addAttestationToGraphy as addAttestationToTitleGraphy } from 'slices/titleGraphies';
import IconButton from 'components/Button/IconButton';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Editor from 'components/Editor';
import Modal from 'components/Modal';
import ThesaurusLayout from './ThesaurusLayout';
import ThesaurusModal from './ThesaurusModal';
import SourcePeriods from './SourcePeriods';
import SourceIcon from './SourceIcon';
import SourceInfo from './SourceInfo';
import SourceEditForm from './SourceEditForm';
import AttestationSummary from './AttestationSummary';
import AttestationNewForm from './AttestationNewForm';
import AttestationEditForm from './AttestationEditForm';
import './Source.scss';

const Source = ({ id, canEdit, onNavigateBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const attestationListRef = useRef(null);
  const source = useSelector((state) => getSourceById(state, id));
  const attestationIds = useSelector((state) => getAttestationIdsBySourceId(state, id));
  const couldSelectAttestation = useSelector(isInSelectionMode);
  const shouldSelectWordGraphyAttestation = useSelector(
    isSelectionModeTriggeredForWordGraphyAttestation
  );
  const shouldSelectTitleGraphyAttestation = useSelector(
    isSelectionModeTriggeredForTitleGraphyAttestation
  );
  const shouldSelectSequenceAttestation = useSelector(
    isSelectionModeTriggeredForSequenceAttestation
  );
  const graphyId = useSelector(getInteractionModeTriggeredGraphyId);
  const sequenceId = useSelector(getInteractionModeTriggeredSequenceId);
  const [isEditingSource, setIsEditingSource] = useState(false);
  const [editedAttestationId, setEditedAttestationId] = useState(null);
  const [isCreatingAttestation, setIsCreatingAttestation] = useState(false);
  const [entriesInvolvedInAttestationDeletion, setEntriesInvolvedInAttestationDeletion] =
    useState(null);

  const handleEditSource = useCallback(() => {
    setIsEditingSource(true);
  }, []);

  const handleEditAttestation = useCallback((id) => {
    setEditedAttestationId(id);
  }, []);

  const handleAttestationSelection = useCallback(
    (attestationId) => {
      if (couldSelectAttestation) {
        if (shouldSelectWordGraphyAttestation) {
          dispatch(addAttestationToWordGraphy({ graphyId, attestationId }));
        } else if (shouldSelectTitleGraphyAttestation) {
          dispatch(addAttestationToTitleGraphy({ graphyId, attestationId }));
        } else if (shouldSelectSequenceAttestation) {
          dispatch(addAttestationToSequence({ sequenceId, attestationId }));
        }
      }
    },
    [
      dispatch,
      graphyId,
      sequenceId,
      couldSelectAttestation,
      shouldSelectWordGraphyAttestation,
      shouldSelectTitleGraphyAttestation,
      shouldSelectSequenceAttestation,
    ]
  );

  const handleDeleteAttestation = useCallback(
    (attestationId) => {
      setEditedAttestationId(null);
      dispatch(deleteAttestation({ attestationId, sourceId: source.id }))
        .unwrap()
        .catch((data) => {
          setEntriesInvolvedInAttestationDeletion(data);
        });
    },
    [dispatch, source]
  );

  const closeDeleteAttestationForbiddenModal = useCallback(() => {
    setEntriesInvolvedInAttestationDeletion(null);
  }, []);

  const handleDeleteSource = useCallback(
    (sourceId) => {
      onNavigateBack();
      dispatch(deleteSource(sourceId));
    },
    [dispatch, onNavigateBack]
  );

  const handleCreateAttestation = useCallback(() => {
    if (attestationListRef.current) {
      attestationListRef.current.parentElement.scrollTop = 0;
    }
    setIsCreatingAttestation(true);
  }, [attestationListRef]);

  const handleOpenEntry = useCallback(
    (id, type) => {
      if (type === 'entry') {
        dispatch(openAndLoadWord(id, false));
      } else if (type === 'title') {
        dispatch(openAndLoadTitle(id, false));
      } else if (type === 'individual') {
        dispatch(openAndLoadIndividual(id, false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadAttestations(id));
  }, [dispatch, id]);

  const openDeleteAttestationForbiddenModal =
    entriesInvolvedInAttestationDeletion &&
    (entriesInvolvedInAttestationDeletion.entryIds.length > 0 ||
      entriesInvolvedInAttestationDeletion.titleIds.length > 0 ||
      entriesInvolvedInAttestationDeletion.individualIds.length > 0);

  const formatEntriesMessage = (idsMap, separator, lastSeparator) => {
    const linkEntry = ({ id, type }, hasSeparator = true) => (
      <span key={id}>
        <span className="delete-attestation-entity-link" onClick={() => handleOpenEntry(id, type)}>
          {id}
        </span>
        {hasSeparator && separator}
      </span>
    );
    const entries = idsMap.entryIds.map((id) => ({ type: 'entry', id }));
    const titles = idsMap.titleIds.map((id) => ({ type: 'title', id }));
    const individuals = idsMap.individualIds.map((id) => ({ type: 'individual', id }));
    const objects = [...entries, ...titles, ...individuals];
    const last = linkEntry(objects.pop(), false);

    return (
      <>
        {objects.map((id, index) => linkEntry(id, index < objects.length - 1))}
        {objects.length > 0 && ` ${lastSeparator} `}
        {last}
      </>
    );
  };

  return (
    <>
      <ThesaurusLayout
        header={
          <>
            <div className="source-header">
              <div>
                <IconButton
                  theme="green"
                  onClick={onNavigateBack}
                  className="source-back-nav-button"
                  data-testid="thesaurus-back-nav-button"
                >
                  <Icon size={21}>
                    <BackArrowIcon />
                  </Icon>
                </IconButton>
                <Cluster align="center" space="var(--s-2)">
                  <SourceIcon type={source.type} />
                  <h1 className="source-title">{source.title}</h1>
                  {canEdit && (
                    <IconButton theme="green" onClick={handleEditSource}>
                      <Icon size={22}>
                        <EditIcon />
                      </Icon>
                    </IconButton>
                  )}
                </Cluster>
                <h2 className="source-id">
                  <Cluster space="var(--s-2)">
                    {source.inventoryNumber && <div>{source.inventoryNumber}</div>}
                    {source.hieratic && <Tag theme="green">{t('source.hierat')}</Tag>}
                  </Cluster>
                </h2>
                {source.periods && source.periods.length > 0 && (
                  <div className="source-periods">
                    <SourcePeriods
                      periods={source.periods}
                      isUncertain={source.isPeriodUncertain}
                      t={t}
                    />
                  </div>
                )}
              </div>
              {(source.origin || isNotEmptyHtml(source.bibliography) || source.webography) && (
                <details className="source-details">
                  <summary>
                    <div>{t('source.moreInfo')}</div>
                  </summary>
                  <Stack space="var(--s-1)">
                    <SourceInfo label={t(`source.origin`)}>{source.origin}</SourceInfo>
                    {/* <SourceInfo label={t('source.owners')}>{source.owners}</SourceInfo> */}
                    <SourceInfo label={t('source.bibliography')}>
                      {isNotEmptyHtml(source.bibliography) && (
                        <Editor initialValue={source.bibliography} readOnly onValidate={noop} />
                      )}
                    </SourceInfo>
                    <SourceInfo label={t('source.webography')}>
                      {(source.webography || source.webographyConsultedAt) && (
                        <div>
                          {source.webography &&
                            source.webography.split(';').map((url) => (
                              <div key={url}>
                                <a
                                  href={url.trim()}
                                  target="_blank"
                                  rel="noreferrer"
                                  title={url.trim()}
                                >
                                  {url.trim()}
                                </a>
                              </div>
                            ))}
                          {source.webographyConsultedAt && (
                            <div>
                              <span className="source-consulted-at">
                                {t('source.webographyConsultedAt')} {source.webographyConsultedAt}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </SourceInfo>
                    <SourceInfo label="KIU">
                      {source.karnakId && (
                        <a
                          href={`${KARNAK_URL}/${source.karnakId}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'underline' }}
                        >
                          {source.karnakId}
                        </a>
                      )}
                    </SourceInfo>
                  </Stack>
                </details>
              )}
            </div>
            <div className="source-list-header">
              <Cluster justify="space-between" align="center">
                <div className="source-list-title">
                  {t('attestation.title')} {attestationIds && `(${attestationIds.length})`}
                </div>
                {canEdit && (
                  <Button
                    theme="green"
                    disabled={isCreatingAttestation}
                    onClick={handleCreateAttestation}
                  >
                    <AddIcon />
                  </Button>
                )}
              </Cluster>
            </div>
          </>
        }
      >
        <div className="attestations-list" ref={attestationListRef} dir="auto">
          {isCreatingAttestation && (
            <AttestationNewForm onCancel={() => setIsCreatingAttestation(false)} sourceId={id} />
          )}
          {attestationIds &&
            attestationIds.map((attestationId) => {
              if (attestationId === editedAttestationId) {
                return (
                  <AttestationEditForm
                    key={attestationId}
                    id={attestationId}
                    onEnd={() => setEditedAttestationId(null)}
                    onDelete={handleDeleteAttestation}
                  />
                );
              }
              return (
                <AttestationSummary
                  key={attestationId}
                  id={attestationId}
                  canEdit={canEdit}
                  onSelect={handleAttestationSelection}
                  onEdit={handleEditAttestation}
                />
              );
            })}
        </div>
        {isEditingSource && (
          <ThesaurusModal>
            <SourceEditForm
              id={id}
              canBeDeleted={!attestationIds || attestationIds.length === 0}
              onEnd={() => setIsEditingSource(false)}
              onDelete={handleDeleteSource}
            />
          </ThesaurusModal>
        )}
      </ThesaurusLayout>
      {openDeleteAttestationForbiddenModal && (
        <Modal
          title={t('attestation.deletionModal.title')}
          actions={
            <Button onClick={closeDeleteAttestationForbiddenModal}>{t('actions.ok')}</Button>
          }
        >
          {t('attestation.deletionModal.content', {
            count:
              entriesInvolvedInAttestationDeletion.entryIds.length +
              entriesInvolvedInAttestationDeletion.titleIds.length +
              entriesInvolvedInAttestationDeletion.individualIds.length,
          })}
          <b>
            {formatEntriesMessage(
              entriesInvolvedInAttestationDeletion,
              t('words.comma'),
              t('words.and')
            )}
          </b>
        </Modal>
      )}
    </>
  );
};

Source.defaultProps = {
  canEdit: false,
};

Source.propTypes = {
  id: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  onNavigateBack: PropTypes.func.isRequired,
};

export default Source;
