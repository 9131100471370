import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { updateIndividual, INDIVIDUAL_PREFIX } from 'slices/individuals';
import { EDITION_STATUS } from 'utils/constants';
import { selectors as individualCommentsSelectors } from 'slices/individualComments';
import {
  selectors as individualGraphiesSelectors,
  uploadFileAndCreateGraphy,
} from 'slices/individualGraphies';
import { isIndividualNamesPublishable } from 'slices/individualNames';
import { isIndividualRelativesPublishable } from 'slices/individualRelatives';
import { isIndividualTitulariesPublishable } from 'slices/individualTitularies';
import { isIndividualSequencesPublishable } from 'slices/individualSequences';
import usePublishable from 'hooks/usePublishable';
import { ReactComponent as NamesIcon } from 'images/icon-tab-names.svg';
import { ReactComponent as TitlesIcon } from 'images/icon-tab-titles.svg';
import { ReactComponent as SequencesIcon } from 'images/icon-tab-sequences.svg';
import { ReactComponent as RelativesIcon } from 'images/icon-tab-relatives.svg';
import { ReactComponent as CommentsIcon } from 'images/icon-tab-comments.svg';
import DropdownMenu, { DropdownButton } from 'components/DropdownMenu';
import { MenuItem } from 'components/Menu';
import EntryEditionStatus from 'components/Entry/EntryEditionStatus';
import EntryInfoPopup from 'components/Entry/EntryInfoPopup';
import EntryTabs from 'components/Entry/EntryTabs';
import { AutoSaveInput } from 'components/Input';
import IndividualComments from 'components/IndividualComments';
import Tag from 'components/Tag';
import IndividualNuanceTypeSelector from 'components/Individual/IndividualNuanceTypeSelector';
import GraphyDropzone from 'components/Graphy/GraphyDropzone';
import MainNuancesEditor from 'components/MainNuancesEditor';
import EntryEditionMainInfoLayout from 'components/Entry/EntryEditionMainInfoLayout';
import { IndividualGraphyEdition } from 'components/IndividualGraphies';
import { IndividualTitlesEdition } from 'components/IndividualTitles';
import { IndividualNamesEdition } from 'components/IndividualNames';
import { IndividualSequencesEdition } from 'components/IndividualSequences';
import { IndividualRelativesEdition } from 'components/IndividualRelatives';
import PeriodsSelector from 'components/PeriodsSelector';

const mainNuanceLocales = ['fr'];

const IndividualEditionContent = ({ entry }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isPublishable] = usePublishable(entry);
  const comment = useSelector((state) =>
    individualCommentsSelectors.getByIndividualId(state, entry.id)
  );
  const graphyIds = useSelector((state) =>
    individualGraphiesSelectors.getIdsByIndividualId(state, entry.id)
  );
  const isNamesPublishable = useSelector((state) => isIndividualNamesPublishable(state, entry.id));
  const isRelativesPublishable = useSelector((state) =>
    isIndividualRelativesPublishable(state, entry.id)
  );
  const isSequencesPublishable = useSelector((state) =>
    isIndividualSequencesPublishable(state, entry.id)
  );
  const isTitulariesPublishable = useSelector((state) =>
    isIndividualTitulariesPublishable(state, entry.id)
  );
  const [isCommentPublishable] = usePublishable(comment);

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      edited: {
        transliteration: get(entry, 'edited.transliteration', ''),
      },
    },
  });
  const onSubmit = (data) => dispatch(updateIndividual({ id: entry.id, data }));
  const onError = (errors, e) => console.log(errors, e);

  const handleMainNuanceUpdate = (locale, newNuance) =>
    onSubmit({ edited: { mainNuances: { [locale]: newNuance } } });

  const handleMainNuanceTypeUpdate = (newMainNuanceType) => {
    dispatch(
      updateIndividual({ id: entry.id, data: { edited: { mainNuanceType: newMainNuanceType } } })
    );
  };

  const handleEditionStatusUpdate = (newEditionStatus) => {
    dispatch(updateIndividual({ id: entry.id, data: { editionStatus: newEditionStatus } }));
  };

  const handlePeriodsUpdate = (periods) => {
    dispatch(updateIndividual({ id: entry.id, data: { edited: { attestationPeriods: periods } } }));
  };

  const handleGraphySelection = (file) => {
    dispatch(uploadFileAndCreateGraphy(entry.id, file));
  };

  return (
    <Stack space="var(--s0)">
      <EntryEditionMainInfoLayout
        actionsBarLeft={
          <>
            <EntryInfoPopup
              entryPrefix={INDIVIDUAL_PREFIX}
              entryId={entry.id}
              saveDate={entry.saveDate}
              publishDate={entry.publishDate}
            />

            <DropdownMenu
              itemsCount={2}
              renderButton={(buttonProps) => (
                <DropdownButton {...buttonProps}>
                  <EntryEditionStatus status={entry.editionStatus} />
                </DropdownButton>
              )}
            >
              {Object.values(EDITION_STATUS).map((status) => (
                <MenuItem
                  key={status}
                  isSelectable
                  isSelected={entry.editionStatus === status}
                  onClick={() => handleEditionStatusUpdate(status)}
                >
                  {t(`entry.editionStatus.${status}`)}
                </MenuItem>
              ))}
            </DropdownMenu>
          </>
        }
        actionsBarRight={<Tag>{t('entry.natures.types.individual')}</Tag>}
      >
        <div data-publishable={isPublishable('mainNuanceType')}>
          <IndividualNuanceTypeSelector
            type={get(entry, 'edited.mainNuanceType', undefined)}
            onSelect={handleMainNuanceTypeUpdate}
          />
        </div>

        <div data-publishable={isPublishable('transliteration')}>
          <AutoSaveInput
            italic
            serif
            size="large"
            placeholder={t('entry.transliteration')}
            {...register('edited.transliteration', { required: true })}
            onSave={handleSubmit(onSubmit, onError)}
          />
        </div>

        {graphyIds.map((graphyId) => (
          <IndividualGraphyEdition
            key={graphyId}
            graphyId={graphyId}
            individualId={entry.id}
            dir={i18n.dir()}
          />
        ))}

        {graphyIds.length < 2 && (
          <GraphyDropzone id="individual-graphy-dropzone" onSelect={handleGraphySelection} />
        )}

        <MainNuancesEditor
          resource={entry}
          onUpdate={handleMainNuanceUpdate}
          locales={mainNuanceLocales}
        />

        <div style={{ marginBottom: 'var(--s1)' }}>
          <PeriodsSelector
            label={t('individual.lived')}
            values={get(entry, 'edited.attestationPeriods', [])}
            onChange={handlePeriodsUpdate}
          />
        </div>
      </EntryEditionMainInfoLayout>

      <EntryTabs
        selectors={[
          <NamesIcon id="individualNames" data-publishable={isNamesPublishable} />,
          <RelativesIcon id="individualRelatives" data-publishable={isRelativesPublishable} />,
          <TitlesIcon id="individualTitles" data-publishable={isTitulariesPublishable} />,
          <SequencesIcon id="individualSequences" data-publishable={isSequencesPublishable} />,
          <CommentsIcon id="comments" data-publishable={isCommentPublishable('text')} />,
        ]}
      >
        <div id="individualNames">
          <IndividualNamesEdition individualId={entry.id} />
        </div>
        <div id="individualRelatives">
          <IndividualRelativesEdition individualId={entry.id} />
        </div>
        <div id="individualTitles">
          <IndividualTitlesEdition individualId={entry.id} />
        </div>
        <div id="individualSequences">
          <IndividualSequencesEdition individualId={entry.id} />
        </div>
        <div id="comments">
          <IndividualComments individualId={entry.id} isEditVersion />
        </div>
      </EntryTabs>
    </Stack>
  );
};

IndividualEditionContent.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default IndividualEditionContent;
