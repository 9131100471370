import dictionaryReferencesFactory from './dictionaryReferencesFactory';
import DictionaryReferencesDataService from 'services/DictionaryReferencesDataService';

const dictionaryReferencesDataService = new DictionaryReferencesDataService('titleId');

const extraReducers = {
  'word/delete/fulfilled': (state, action) => {
    delete state.byEntryId[action.payload.entryId];
  },
};

const { reducer, actions, selectors } = dictionaryReferencesFactory(
  'titleDictionaryReferences',
  'title',
  extraReducers,
  dictionaryReferencesDataService
);

const {
  loadDictionaryReferences,
  unpublishEntryDictionaryReferences: unpublishTitleDictionaryReferences,
  publishEntryDictionaryReferences: publishTitleDictionaryReferences,
  createOrUpdateDictionaryReferences,
  resetDictionaryReferencesOfEntry: resetDictionaryReferencesOfTitle,
} = actions;

export {
  reducer,
  loadDictionaryReferences,
  unpublishTitleDictionaryReferences,
  publishTitleDictionaryReferences,
  createOrUpdateDictionaryReferences,
  resetDictionaryReferencesOfTitle,
  selectors,
};
