import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Cluster, Stack } from 'components/Layout';
import { openSource } from 'slices/ui/thesaurus';
import { getAttestationById } from 'slices/attestations';
import { getSourceById } from 'slices/sources';
import { KARNAK_URL } from 'utils/constants';
import { isNotEmptyHtml } from 'utils/util';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { ReactComponent as EditIcon } from 'images/icon-edit.svg';
import { preventEventDefaultAndStopPropagation } from 'components/Editor/eventUtils';
import Editor from 'components/Editor';
import SourcePeriods from 'components/Thesaurus/SourcePeriods';
import SourceInfo from 'components/Thesaurus/SourceInfo';
import SourceIcon from 'components/Thesaurus/SourceIcon';
import IconButton from 'components/Button/IconButton';
import Button from 'components/Button';
import Icon from 'components/Icon';
import './GraphyAttestation.scss';

const GraphyAttestation = ({ id, hideAttestationDetails, onRemove, onEdit, onSelectSource }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reference,
    transliteration,
    traduction,
    sourceId,
    bibliography,
    karnakId,
    attestationPeriod,
  } = useSelector((state) => getAttestationById(state, id));
  const source = useSelector((state) => getSourceById(state, sourceId));

  const handleOpenSource = useCallback(() => {
    if (source) {
      dispatch(openSource(source.id));
    }
  }, [dispatch, source]);

  return (
    <>
      {!source && onSelectSource && (
        <div className="graphy-attestation-no-source" dir="auto">
          <h1 className="graphy-attestation-reference">
            <Editor readOnly initialValue={reference} onValidate={noop} />
          </h1>
          {attestationPeriod && (
            <div className="graphy-attestation-source-periods">
              <SourcePeriods periods={[attestationPeriod]} t={t} />
            </div>
          )}
          <p>{t('attestation.noSource')}</p>
          <Button size="small" theme="green" onClick={() => onSelectSource(id)}>
            {t('attestation.selectSource')}
          </Button>
        </div>
      )}
      <article className="graphy-attestation" dir="auto" onClick={handleOpenSource}>
        <Stack space="var(--s-1)">
          {!source && !onSelectSource && (
            <>
              <h1 className="graphy-attestation-reference">
                <Editor readOnly initialValue={reference} onValidate={noop} />
              </h1>
              {attestationPeriod && (
                <div className="graphy-attestation-source-periods">
                  <SourcePeriods periods={[attestationPeriod]} t={t} />
                </div>
              )}
            </>
          )}
          {source && (
            <div className="graphy-attestation-source">
              <Stack space="var(--s-3)">
                <Cluster align="center" space="var(--s-2)">
                  <SourceIcon type={source.type} />
                  <h1 className="graphy-attestation-source-title">{source.title}</h1>
                </Cluster>

                {source.inventoryNumber && (
                  <h2 className="graphy-attestation-source-id">{source.inventoryNumber}</h2>
                )}
                <div className="graphy-attestation-reference">
                  <Editor readOnly initialValue={reference} onValidate={noop} />
                </div>
                {((source.periods && source.periods.length > 0) || attestationPeriod) && (
                  <div className="graphy-attestation-source-periods">
                    {!attestationPeriod && (
                      <SourcePeriods
                        periods={source.periods}
                        isUncertain={source.isPeriodUncertain}
                        t={t}
                      />
                    )}
                    {attestationPeriod && <SourcePeriods periods={[attestationPeriod]} t={t} />}
                  </div>
                )}
              </Stack>
            </div>
          )}

          {!hideAttestationDetails && (
            <>
              <div className="graphy-attestation-transliteration">
                <Editor readOnly initialValue={transliteration} onValidate={noop} />
              </div>
              <div className="graphy-attestation-traduction">
                <Editor readOnly initialValue={traduction} onValidate={noop} />
              </div>
            </>
          )}

          <SourceInfo label={t('attestation.bibliography')}>
            {isNotEmptyHtml(bibliography) && (
              <Editor readOnly initialValue={bibliography} onValidate={noop} />
            )}
          </SourceInfo>

          {!hideAttestationDetails && (
            <SourceInfo label="KIU">
              {karnakId && (
                <a
                  href={`${KARNAK_URL}/${karnakId}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  {karnakId}
                </a>
              )}
            </SourceInfo>
          )}
        </Stack>
        {(onEdit || onRemove) && (
          <div className="graphy-attestation-actions">
            <Cluster space="var(--s-3)">
              {onEdit && (
                <IconButton
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onEdit(id);
                  }}
                  theme="transparent"
                  data-testid="edit-attestation-of-graphy-button"
                >
                  <Icon size={22}>
                    <EditIcon />
                  </Icon>
                </IconButton>
              )}
              {onRemove && (
                <IconButton
                  onMouseDown={preventEventDefaultAndStopPropagation}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onRemove(id);
                  }}
                  theme="transparent"
                  data-testid="remove-attestation-from-graphy-button"
                >
                  <Icon size={22}>
                    <TrashIcon />
                  </Icon>
                </IconButton>
              )}
            </Cluster>
          </div>
        )}
      </article>
    </>
  );
};

GraphyAttestation.defaultProps = {
  hideAttestationDetails: false,
  onSelectSource: null,
  onEdit: null,
  onRemove: null,
};

GraphyAttestation.propTypes = {
  id: PropTypes.number.isRequired,
  hideAttestationDetails: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onSelectSource: PropTypes.func,
};

export default GraphyAttestation;
